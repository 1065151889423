import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DistributionFormControl } from './distribution-form-control';

@Component({
  selector: 'app-distribution-form-control',
  templateUrl: './distribution-form-control.component.html',
  styleUrls: ['./distribution-form-control.component.scss']
})
export class DistributionFormControlComponent extends AbstractFormControlComponent<DistributionFormControl> implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;

  ngOnInit(): void {
  }
}
