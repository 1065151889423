import { PerformanceProductType } from '@webapi/services/portfolio.service';

export enum PerformanceType {
  GL = 1,
  GSM = 2,
  GH = 3
}

export const PerformanceMenuSteps = {
  GL: {
    id: 1,
    title: 'Gestion libre'
  },
  GSM: {
    id: 2,
    title: 'Gestion sous mandat'
  },
  GH: {
    id: 3,
    title: 'Gestion à horizon'
  }
};

export enum RecommedationProfile {
  Prudent = 1,
  Balanced = 2, // Équilibré
  Dynamic = 3,
  Offensive = 4,
  PrudentDurable = 5,
  BalancedDurable = 6, // Équilibré Durable
  DynamicDurable = 7,
  OffensiveDurable = 8
}

export const RecommedationProfiles = {
  Prudent: {
    id: RecommedationProfile.Prudent,
    title: 'Profil Prudent'
  },
  Balanced: {
    id: RecommedationProfile.Balanced,
    title: 'Profil Équilibré'
  },
  Dynamic: {
    id: RecommedationProfile.Dynamic,
    title: 'Profil Dynamique'
  },
  Offensive: {
    id: RecommedationProfile.Offensive,
    title: 'Profil Offensif'
  },
  // Durable - it means answer #12 is yes
  PrudentDurable: {
    id: RecommedationProfile.PrudentDurable,
    title: 'Profil Prudent Durable'
  },
  BalancedDurable: {
    id: RecommedationProfile.BalancedDurable,
    title: 'Profil Équilibré Durable'
  },
  DynamicDurable: {
    id: RecommedationProfile.DynamicDurable,
    title: 'Profil Dynamique Durable'
  },
  OffensiveDurable: {
    id: RecommedationProfile.OffensiveDurable,
    title: 'Profil Offensif Durable'
  }
};

export const PerformaceYearsCollection = [
  { id: 1, title: '2024' },
  { id: 2, title: '2023' },
  { id: 3, title: '2022' },
  { id: 4, title: '2021' },
  { id: 5, title: '2020' },
  { id: 6, title: '2019' },
  { id: 7, title: '2018' },
  { id: 8, title: '2017' },
  { id: 9, title: '2016' },
  { id: 10, title: '2015' }
];

export const PerformaceRangesCollection = [
  { id: 1, title: '2 dernières années', titleShort: '2 ans', value: 2 },
  { id: 2, title: '3 dernières années', titleShort: '3 ans', value: 3 },
  { id: 3, title: '4 dernières années', titleShort: '4 ans', value: 4 },
  { id: 4, title: '5 dernières années', titleShort: '5 ans', value: 5 },
  { id: 5, title: '6 dernières années', titleShort: '6 ans', value: 6 },
  { id: 6, title: '7 dernières années', titleShort: '7 ans', value: 7 },
  { id: 7, title: '8 dernières années', titleShort: '8 ans', value: 8 },
  { id: 8, title: '9 dernières années', titleShort: '9 ans', value: 9 },
  { id: 9, title: '10 dernières années', titleShort: '10 ans', value: 10 }
];

export interface RecomendationProfileFormModel {
  productType: PerformanceProductType;
  managementTypeId: number;
  profileTypeId: number;
  year: number;
  performanceYear: number;
  range: number;
  performanceRange: number;
}
