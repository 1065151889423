<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyDetails" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="row mt-4">
  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
    <p *ngIf="isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitlePeri' | labelText"></p>
    <p *ngIf="!isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitleNotPeri' | labelText"></p>
  </div>
</div>

<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" class="mb-5">
    <div class="mt-4">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h1 class="section-title">{{ 'IDENTITY.Identity' | labelText }}</h1>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                  <app-combobox-form-control [parentForm]="form" [controlName]="'civility'" [title]="'COMMON.YourCivility' | labelText"> </app-combobox-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-name-form-control [parentForm]="form" [controlName]="'commonName'" [title]="'COMMON.SpouseName' | labelText"> </app-name-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-name-form-control [parentForm]="form" [controlName]="'birthName'" [title]="'COMMON.BirthLastName' | labelText"> </app-name-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-name-form-control [parentForm]="form" [controlName]="'firstName'" [title]="'Common.FirstName' | labelText"> </app-name-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-date-form-control [parentForm]="form" [controlName]="'dob'" [title]="'Common.DateOfBirth' | labelText" [ngClass]="{ wtihManualError: dobValueIsNotInRangeLimit }">
                  </app-date-form-control>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4" *ngIf="dobValueIsNotInRangeLimit">
                  <div class="error-block">
                    <span class="text">
                      {{ 'COMMON.YouCannotSubscribeOnline' | labelText }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-dropdown-form-control [parentForm]="form" [controlName]="'countryOfBirth'" [title]="'IDENTITY.BirthPlace' | labelText"> </app-dropdown-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-2">
                  <app-zipcode-form-control [parentForm]="form" [controlName]="'postalCodeOfBirth'" [title]="'IDENTITY.BirthPostalCode' | labelText"> </app-zipcode-form-control>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                  <app-name-form-control [parentForm]="form" [controlName]="'cityOfBirth'" [title]="''"> </app-name-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-dropdown-form-control [parentForm]="form" [controlName]="'nationality'" [title]="'IDENTITY.Nationality' | labelText"> </app-dropdown-form-control>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  {{ 'Identity.NirTitle' | labelText }}

                  <app-tooltip [text]="nirTooltipText"> </app-tooltip>

                  <app-nir-form-control [parentForm]="form" [controlName]="'nir'" class="no-title" [title]="''"> </app-nir-form-control>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6" *ngIf="isNirValidatorError">
                  <span class="error-text">
                    {{ 'Common.NirError' | labelText }}
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-dropdown-form-control [parentForm]="form" [controlName]="'familySituation'" (onChange)="familySituationChanged($event)" [title]="'IDENTITY.Status' | labelText">
                  </app-dropdown-form-control>
                </div>
              </div>

              <div class="row" [ngClass]="{ hidden: !isMaried }">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  <app-dropdown-form-control [parentForm]="form" [controlName]="'matrimonialRegime'" [title]="'IDENTITY.MatrimonialRegime' | labelText"> </app-dropdown-form-control>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
                  {{ 'IDENTITY.PoliticalPerson' | labelText }}

                  <app-tooltip [title]="'Qu’est ce qu’une personne politiquement exposée ?'" [text]="politicallyPersonTooltipText"> </app-tooltip>

                  <app-combobox-form-control class="no-title" [parentForm]="form" [controlName]="'politicallyPerson'" (onChange)="onPoliticallyPersonChanges($event)" [title]="''">
                  </app-combobox-form-control>
                </div>
              </div>

              <div class="row mt-2" *ngIf="isPoliticallyPerson">
                <div class="col">
                  {{ 'IDENTITY.CaseCorrespondSituation' | labelText }}
                  <div class="row">
                    <div class="col">
                      <app-dropdown-form-control
                        [parentForm]="form"
                        [controlName]="'politicallyPersonCommonTypeNumber'"
                        (onChange)="onPoliticallyPersonCommonTypeNumberChanges($event)"
                        [title]="''"
                        class="no-title error-to-bottom"
                      >
                      </app-dropdown-form-control>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isPoliticallyPersonCommonType">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <app-dropdown-form-control [parentForm]="form" [controlName]="'politicallyPersonTypeNumber'" [title]="''" class="no-title error-to-bottom"> </app-dropdown-form-control>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row with-speech mt-5">
                <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
                  <span class="icon icon-aside"></span>
                </div>
                <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                  <div class="speech">
                    <div class="row">
                      <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <span>
                          {{ 'IDENTITY.Id' | labelText }}
                        </span>
                        <p class="fs-14">{{ 'IDENTITY.IdType' | labelText }}</p>
                        <p class="fs-14">
                          <b> {{ 'IDENTITY.Proof1Content2' | labelText }} </b>
                          <br />
                          {{ 'IDENTITY.Proof1Content3' | labelText }}
                        </p>
                      </div>
                      <div class="col-xs-10 col-xs-offset-1 col-sm-4 col-md-4 col-lg-4">
                        <app-dropdown-form-control
                          class="no-title"
                          [parentForm]="form"
                          [controlName]="'firstIdentificationDocumentType'"
                          [title]="''"
                          [placeholder]="'Common.PlaceholderSelectTypeDocument' | labelText"
                        >
                        </app-dropdown-form-control>

                        <app-file-uploader
                          [files]="firstDocsRestoredUploaderFiles"
                          (onChange)="firstDocsUploaderChanged($event)"
                          (onStatusChange)="firstDocsUploaderStatusChanged($event)"
                        ></app-file-uploader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="valider-button-block">
        <button type="submit" class="btn btn-blue valider" [disabled]="isFormDisabled">
          {{ 'Common.Validate' | labelText }}
        </button>
      </div>
    </div>

    <div class="card disabled">
      <div class="row">
        <div class="col-12 text-left">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h1 class="section-title">{{ 'ADDRESS.ContactDetails' | labelText }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card disabled">
      <div class="row">
        <div class="col-12 text-left">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h1 class="section-title">{{ 'SITUATION.ProAndPatrimonialSituation' | labelText }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card disabled">
      <div class="row">
        <div class="col-12 text-left">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h1 class="section-title">{{ 'BANK_DETAILS.YourBankDetails' | labelText }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
