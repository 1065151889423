import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { ZipCodeFormControl } from './zipcode-form-control';

@Component({
    selector: 'app-zipcode-form-control',
    templateUrl: './zipcode-form-control.component.html',
})
export class ZipCodeFormControlComponent extends AbstractFormControlComponent<ZipCodeFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    faCheck: any = faCheck;

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }
}
