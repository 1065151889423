import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Beneficiaries, GpaConfiguration, ContractTransfer, Emails, ExternalServices, FundsOrigin, General, Payments, Questionnaire, SepaMandate, SettingsClient, Subscription, Summary, SubscriptionWithdrawal, EmailTemplate, AssociatedQuestion } from '../MIF.Subscription.WebApi';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private webApi: SettingsClient, private http: HttpClient) { }
  //private emailTemplatesData: any = {};

  private gpaConfiguration: GpaConfiguration;
  private generalConfiguration: General;
  private externalServicesConfiguration: ExternalServices;
  private subscriptionConfiguration: Subscription;
  private beneficiariesConfiguration: Beneficiaries;
  private summaryConfiguration: Summary;
  private sepaMandateConfiguration: SepaMandate;
  private emailsConfiguration: Emails;
  private subscriptionWithdrawalConfiguration: SubscriptionWithdrawal;
  private questionnaireConfiguration: Questionnaire;
  private paymentsConfiguration: Payments;
  private contractTransferConfiguration: ContractTransfer;
  private fundsOriginConfiguration: FundsOrigin;

  public async getTemplateByProduct(productId: number): Promise<EmailTemplate[]> {
    return this.webApi.getTemplateByProduct(productId).pipe(
      map((response: EmailTemplate[]) => {
        //this.emailTemplatesData[productId] = response;
        return response;
      })
    ).toPromise();
  }

  public async restoreOriginal(templateId: number): Promise<EmailTemplate> {
    return this.webApi.restoreOriginal(templateId).toPromise();
  }

  public async updateTemplate(template: EmailTemplate): Promise<EmailTemplate> {
    return this.webApi.updateTemplate(template).toPromise();
  }

  public async getGpaConfiguration(): Promise<GpaConfiguration> {
    if (this.gpaConfiguration) {
      return new Promise(resolve => {
        resolve(this.gpaConfiguration);
      });
    } else {
      return this.webApi.getGpaConfiguration().pipe(
        map((response: GpaConfiguration) => {
          this.gpaConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveGpaConfiguration(settings: GpaConfiguration): Promise<GpaConfiguration> {
    return this.webApi.saveGpaConfiguration(settings).pipe(
      map((response: number) => {
        this.gpaConfiguration = settings;
        return this.gpaConfiguration;
      })
    ).toPromise();
  }

  public async getGeneralConfiguration(): Promise<General> {
    if (this.generalConfiguration) {
      return new Promise(resolve => {
        resolve(this.generalConfiguration);
      });
    } else {
      return this.webApi.getGeneralConfiguration().pipe(
        map((response: General) => {
          this.generalConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveGeneralConfiguration(settings: General): Promise<General> {
    return this.webApi.saveGeneralConfiguration(settings).pipe(
      map((response: number) => {
        this.generalConfiguration = settings;
        return this.generalConfiguration;
      })
    ).toPromise();
  }

  public async getExternalServicesConfiguration(): Promise<ExternalServices> {
    if (this.externalServicesConfiguration) {
      return new Promise(resolve => {
        resolve(this.externalServicesConfiguration);
      });
    } else {
      return this.webApi.getExternalServicesConfiguration().pipe(
        map((response: ExternalServices) => {
          this.externalServicesConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveExternalServicesConfiguration(settings: ExternalServices): Promise<ExternalServices> {
    return this.webApi.saveExternalServicesConfiguration(settings).pipe(
      map((response: number) => {
        this.externalServicesConfiguration = settings;
        return this.externalServicesConfiguration;
      })
    ).toPromise();
  }

  public async getSubscriptionConfiguration(): Promise<Subscription> {
    if (this.subscriptionConfiguration) {
      return new Promise(resolve => {
        resolve(this.subscriptionConfiguration);
      });
    } else {
      return this.webApi.getSubscriptionConfiguration().pipe(
        map((response: Subscription) => {
          this.subscriptionConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveSubscriptionConfiguration(settings: Subscription): Promise<Subscription> {
    return this.webApi.saveSubscriptionConfiguration(settings).pipe(
      map((response: number) => {
        this.subscriptionConfiguration = settings;
        return this.subscriptionConfiguration;
      })
    ).toPromise();
  }

  public async getBeneficiariesConfiguration(): Promise<Beneficiaries> {
    if (this.beneficiariesConfiguration) {
      return new Promise(resolve => {
        resolve(this.beneficiariesConfiguration);
      });
    } else {
      return this.webApi.getBeneficiariesConfiguration().pipe(
        map((response: Beneficiaries) => {
          this.beneficiariesConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveBeneficiariesConfiguration(settings: Beneficiaries): Promise<Beneficiaries> {
    return this.webApi.saveBeneficiariesConfiguration(settings).pipe(
      map((response: number) => {
        this.beneficiariesConfiguration = settings;
        return this.beneficiariesConfiguration;
      })
    ).toPromise();
  }

  public async getSummaryConfiguration(): Promise<Summary> {
    if (this.summaryConfiguration) {
      return new Promise(resolve => {
        resolve(this.summaryConfiguration);
      });
    } else {
      return this.webApi.getSummaryConfiguration().pipe(
        map((response: Summary) => {
          this.summaryConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveSummaryConfiguration(settings: Summary): Promise<Summary> {
    return this.webApi.saveSummaryConfiguration(settings).pipe(
      map((response: number) => {
        this.summaryConfiguration = settings;
        return this.summaryConfiguration;
      })
    ).toPromise();
  }

  public async getSepaMandateConfiguration(): Promise<SepaMandate> {
    if (this.sepaMandateConfiguration) {
      return new Promise(resolve => {
        resolve(this.sepaMandateConfiguration);
      });
    } else {
      return this.webApi.getSepaMandateConfiguration().pipe(
        map((response: SepaMandate) => {
          this.sepaMandateConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveSepaMandateConfiguration(settings: SepaMandate): Promise<SepaMandate> {
    return this.webApi.saveSepaMandateConfiguration(settings).pipe(
      map((response: number) => {
        this.sepaMandateConfiguration = settings;
        return this.sepaMandateConfiguration;
      })
    ).toPromise();
  }

  public async getEmailsConfiguration(): Promise<Emails> {
    if (this.emailsConfiguration) {
      return new Promise(resolve => {
        resolve(this.emailsConfiguration);
      });
    } else {
      return this.webApi.getEmailsConfiguration().pipe(
        map((response: Emails) => {
          this.emailsConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveEmailsConfiguration(settings: Emails): Promise<Emails> {
    return this.webApi.saveEmailsConfiguration(settings).pipe(
      map((response: number) => {
        this.emailsConfiguration = settings;
        return this.emailsConfiguration;
      })
    ).toPromise();
  }

  public async getSubscriptionWithdrawalConfiguration(): Promise<SubscriptionWithdrawal> {
    if (this.subscriptionWithdrawalConfiguration) {
      return new Promise(resolve => {
        resolve(this.subscriptionWithdrawalConfiguration);
      });
    } else {
      return this.webApi.getSubscriptionWithdrawalConfiguration().pipe(
        map((response: SubscriptionWithdrawal) => {
          this.subscriptionWithdrawalConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveSubscriptionWithdrawalConfiguration(settings: SubscriptionWithdrawal): Promise<SubscriptionWithdrawal> {
    return this.webApi.saveSubscriptionWithdrawalConfiguration(settings).pipe(
      map((response: number) => {
        this.subscriptionWithdrawalConfiguration = settings;
        return this.subscriptionWithdrawalConfiguration;
      })
    ).toPromise();
  }

  public async getQuestionnaireConfiguration(): Promise<Questionnaire> {
    if (this.questionnaireConfiguration) {
      return new Promise(resolve => {
        resolve(this.questionnaireConfiguration);
      });
    } else {
      return this.webApi.getQuestionnaireConfiguration().pipe(
        map((response: Questionnaire) => {
          this.questionnaireConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveQuestionnaireConfiguration(settings: Questionnaire): Promise<Questionnaire> {
    return this.webApi.saveQuestionnaireConfiguration(settings).pipe(
      map((response: number) => {
        this.questionnaireConfiguration = settings;
        return this.questionnaireConfiguration;
      })
    ).toPromise();
  }

  public async getPaymentsConfiguration(): Promise<Payments> {
    if (this.paymentsConfiguration) {
      return new Promise(resolve => {
        resolve(this.paymentsConfiguration);
      });
    } else {
      return this.webApi.getPaymentsConfiguration().pipe(
        map((response: Payments) => {
          this.paymentsConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async savePaymentsConfiguration(settings: Payments): Promise<Payments> {
    return this.webApi.savePaymentsConfiguration(settings).pipe(
      map((response: number) => {
        this.paymentsConfiguration = settings;
        return this.paymentsConfiguration;
      })
    ).toPromise();
  }

  public async getContractTransferConfiguration(): Promise<ContractTransfer> {
    if (this.contractTransferConfiguration) {
      return new Promise(resolve => {
        resolve(this.contractTransferConfiguration);
      });
    } else {
      return this.webApi.getContractTransferConfiguration().pipe(
        map((response: ContractTransfer) => {
          this.contractTransferConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveContractTransferConfiguration(settings: ContractTransfer): Promise<ContractTransfer> {
    return this.webApi.saveContractTransferConfiguration(settings).pipe(
      map((response: number) => {
        this.contractTransferConfiguration = settings;
        return this.contractTransferConfiguration;
      })
    ).toPromise();
  }
  public async getFundsOriginConfiguration(): Promise<FundsOrigin> {
    if (this.fundsOriginConfiguration) {
      return new Promise(resolve => {
        resolve(this.fundsOriginConfiguration);
      });
    } else {
      return this.webApi.getFundsOriginConfiguration().pipe(
        map((response: FundsOrigin) => {
          this.fundsOriginConfiguration = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async saveFundsOriginConfiguration(settings: FundsOrigin): Promise<FundsOrigin> {
    return this.webApi.saveFundsOriginConfiguration(settings).pipe(
      map((response: number) => {
        this.fundsOriginConfiguration = settings;
        return this.fundsOriginConfiguration;
      })
    ).toPromise();
  }

  public async updateAssociatedQuestions(model: AssociatedQuestion[]): Promise<void> {
    return this.webApi.updateAssociatedQuestions(model).pipe(
      map((response: void) => {
        return response;
      })
    ).toPromise();
  }

  public async getAssociatedQuestions(): Promise<AssociatedQuestion[]> {
    return this.webApi.getAssociatedQuestions().pipe(
      map((response: AssociatedQuestion[]) => {
        return response;
      })
    ).toPromise();

    // return this.http.get('./assets/test.json').pipe(
    //   map((response: AssociatedQuestion[]) => {
    //     return response;
    //   })
    // ).toPromise()
  }

}
