import { FormControl } from '@angular/forms';

export function sncfValidator() {
    return (control: FormControl) => {
        const sncf = control.value;

        if (sncf) {
            if (!/[0-9]{7,8}[a-zA-Z]{1}$/.test(sncf)) {
                return {
                    sncfValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
