import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'performance'
})
export class PerformancePipe implements PipeTransform {
  constructor() {

  }

  transform(value: number): string {
    let res = '';

    if (!value) {
      return res;
    }

    res = value > 0 ? `+${value} %` : `${value} %`;

    return res.replace('.', ',');
  }
}
