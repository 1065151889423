<div class="p-3">
  <a href="javascript:void(0)" (click)="onFooterDocClick()" class="legal-info">
    <fa-icon [icon]="faQuestionCircle"></fa-icon>
    <span class="legal-title">{{ footerDocText }}</span>
  </a>
  <p class="legal-line">
    {{ 'FOOTER.CNILText1' | labelText }}
  </p>
  <p class="legal-line">
    {{ 'FOOTER.CNILText2' | labelText }}
  </p>
</div>
