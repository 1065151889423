import { constants } from '../../../shared/constants/constants';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetQuestionnaireRequest, IGetQuestionnaireResponse, Questionnaire, Client } from '../MIF.Subscription.Parrot';
import { deepCopy } from '../../extensions/extensions';

@Injectable({
  providedIn: 'root'
})
export class WsCrmQuestionnaireService {
  private questions: Questionnaire;

  constructor(private webApi: Client) { }

  public async getQuestions(): Promise<any> {
    if (this.questions) {
      return new Promise(resolve => {
        resolve(deepCopy(this.questions));
      })
    }

    const request = new GetQuestionnaireRequest({
      identifiantDemarche: constants.identifiantDemarche
    });

    await this.webApi.getQuestionnaire(request)
      .pipe(
        map((response: IGetQuestionnaireResponse) => {
          this.questions = response.questionnaire;
          return this.questions;
        })
      ).toPromise();

    return new Promise(resolve => {
      resolve(deepCopy(this.questions));
    })
  }
}