<div>
  <div class="modal-header" [ngClass]="cssClass" *ngIf="title">
    <span class="error-mark" *ngIf="isErrorType">
      <fa-icon [icon]="faClose"></fa-icon>
    </span>
    <h5 class="modal-title" [innerHtml]="title"></h5>
  </div>

  <div class="modal-body" *ngIf="body">
    <p [innerHtml]="body | safeHtml"></p>
  </div>

  <div class="modal-body" *ngIf="finalAgreeOpened">
    <p>
      <span class="recap-promo compact">
        <ul class="list-arrows">
          <span *ngFor="let doc of finalAgreeDocs; let i = index">
            <li class="pointer link-like" *ngIf="finalAgreeOpened.indexOf(i) === -1" (click)="finalAgreeFileClick(i); fileClick()">
              <span
                >{{ doc.libelle }}<span *ngIf="doc.nomenclature"> (réf. {{ doc.nomenclature }})</span></span
              ><span *ngIf="i < finalAgreeDocs?.length - 1">,</span><span *ngIf="i === finalAgreeDocs?.length - 1">.</span>
            </li>
          </span>
          <span *ngFor="let termsDoc of finalAgreeDocsContractDocuments; let i = index">
            <li class="pointer link-like" *ngIf="finalAgreeContractsOpened.indexOf(i + 1) === -1" (click)="finalAgreeContractFileClick(i); fileClick()">
              <span
                >{{ termsDoc.libelle }}<span *ngIf="termsDoc.nomenclature"> (réf. {{ termsDoc.nomenclature }})</span></span
              ><span *ngIf="i < finalAgreeDocsContractDocuments?.length - 1">,</span><span *ngIf="i === finalAgreeDocsContractDocuments?.length - 1">.</span>
            </li>
          </span>
        </ul>
      </span>
    </p>
  </div>

  <div class="modal-footer">
    <div>
      <button class="btn btn-blue" (click)="hideConfirmationModal()">
        {{ 'Common.OkText' | labelText }}
      </button>
    </div>
  </div>
</div>
