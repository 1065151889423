import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'performanceFraction'
})
export class PerformanceFractionPipe implements PipeTransform {
  constructor() {}

  transform(value: number, isPerformanceFractionWithDecimals: boolean, decimalsCount: number): string {
    if (!value && value !== 0) {
      return '';
    }

    let formattedValue = `${value}`;
    formattedValue = formattedValue.replace('.', ',');

    if (isPerformanceFractionWithDecimals && formattedValue.indexOf(',') === -1) {
      if (decimalsCount === 1) {
        formattedValue += ',0';
      } else {
        formattedValue += ',00';
      }
    }

    if (isPerformanceFractionWithDecimals && formattedValue.indexOf(',') !== -1) {
      if (formattedValue.split(',')?.[1]?.length === 1 && decimalsCount === 2) {
        formattedValue += '0';
      }
    }

    return `${formattedValue} %`;
  }
}
