<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input
      matInput
      appTrim
      appCapitalizeAll
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
      [matAutocomplete]="auto"
      (keyup)="onInputValueChange($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="itemSelected($event)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option[autocompleteLabelField]">
        {{ option[autocompleteLabelField] }}
      </mat-option>
    </mat-autocomplete>

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.required">{{ requiredText }}</span>
      <span *ngIf="control.errors?.maxlength">{{ 'HOME.WrongEntryErrorText1' | labelText }} {{ control.maxLength }} {{ 'HOME.WrongEntryErrorText2' | labelText }}</span>
    </mat-error>
  </mat-form-field>
</div>
