export enum ProfileStepName {
    MembershipCheck = 'Formulaire',
    ResidenceQuestions = 'Résidence',
    HealthQuestions = 'Questionnaire de santé (pour GPA et GFD)',
    MyRate = 'Mon tarif (pour GPA et GFD)',
    MyProfile = 'Projet de versement',
    MyProfileSteps1 = 'Questionnaire par page (Question 1)',
    MyProfileSteps2 = 'Questionnaire par page (Question 2)',
    MyProfileSteps3 = 'Questionnaire par page (Question 3)',
    MyProfileSteps4 = 'Questionnaire par page (Question 4)',
    MyProfileSteps5 = 'Questionnaire par page (Question 5)',
    MyProfileSteps6 = 'Questionnaire par page (Question 6)',
    MyProfileSteps7 = 'Questionnaire par page (Question 7)',
    MyProfileSteps8 = 'Questionnaire par page (Question 8)',
    MyProfileSteps9 = 'Questionnaire par page (Question 9)',
    MyProfileSteps10 = 'Questionnaire par page (Question 10)',
    MyProfileSteps11 = 'Questionnaire par page (Question 11)',
    MyProfileStepSummary = 'Recommandation',
    MyContracts1 = 'Sélection des fonds',
    MyContracts2 = 'Rappel de vos versements',
    MyContracts3 = 'Répartition',
    MyBeneficiaries = 'Bénéficiaires',
    MyBeneficiariesNominative = 'Bénéficiaires nominative',
    MyBeneficiariesLibre = 'Bénéficiaires libre',
    MyBeneficiariesContract = 'Bénéficiaires contract',
    MyDetails = 'Identité',
    MyContacts = 'Coordonnées',
    MyFinancial = 'Situation professionnelle et patrimoniale',
    MyBankDetails = 'Coordonnées bancaires',
    Signature = 'Récapitulatif',
    PhoneConfirmation = 'Confirmation'
}
