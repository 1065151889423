import { FormControl } from '@angular/forms';

export function memberNumberValidator() {
    return (control: FormControl) => {
        const memberNumber = control.value;

        if (memberNumber) {
            if (!/[0-9]{5}[a-zA-Z]{1}[0-9]{5}$/.test(memberNumber)) {
                return {
                    memberNumberValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
