<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" class="info" [class.multiline]="isMultiline">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appTrim
      *ngIf="!isMultiline"
      [attr.maxlength]="maxLength"
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control"
      [formControlName]="controlName"
    />
    <textarea
      matInput
      appTrim
      *ngIf="isMultiline"
      [attr.maxlength]="maxLength"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control multiline"
      [rows]="rowsCount"
      [formControlName]="controlName"
      #textArea
      (click)="getCaretPos(textArea)"
      (keyup)="getCaretPos(textArea)"
    ></textarea>

    <mat-error class="info-error" *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">{{ 'RECOMMENDATION.RecommandationCompleteClauseNextStepText' | labelText }} </span>
    </mat-error>
  </mat-form-field>
  <p class="entered-chars txt-default">{{ valueLength }} / {{ maxLength }}</p>
</div>
