import { Injectable } from '@angular/core';
import { BaseDataService } from '@components/data/base-data.service';
import { ProfileStepCode } from '@models/profile-step-code';

@Injectable({
  providedIn: 'root'
})
export class AnswersDataService extends BaseDataService {
  constructor() {
    super();
  }

  public getLatestStepRoute(): string {
    switch (this.data?.profileStepCode) {
      case ProfileStepCode.MembershipCheck:
        return 'membership-check';
      case ProfileStepCode.ResidenceQuestions:
        return 'residence-questions';
      case ProfileStepCode.HealthQuestions:
        return 'my-profile';
      case ProfileStepCode.MyRate:
        return 'my-contract';
      case ProfileStepCode.MyProfile:
        return 'my-profile';
      case ProfileStepCode.MyProfileSteps:
        return 'my-profile/steps';
      case ProfileStepCode.MyProfileStepSummary:
        return ProfileStepCode.MyProfileStepSummary;
      case ProfileStepCode.MyContracts:
        return 'my-contract';
      case ProfileStepCode.MyBeneficiaries:
        return 'my-beneficiaries';
      case ProfileStepCode.MyBeneficiariesNominative:
        return 'my-beneficiaries/nominative';
      case ProfileStepCode.MyBeneficiariesLibre:
        return 'my-beneficiaries/libre';
      case ProfileStepCode.MyDetails:
        return 'my-details';
      case ProfileStepCode.MyContacts:
        return 'my-details/contacts';
      case ProfileStepCode.MyFinancial:
        return 'my-details/financial-situation';
      case ProfileStepCode.MyBankDetails:
        return 'my-details/bank-details';
      default:
        return 'membership-check';
    }
  }
}
