import { collections } from '@constants/collections';
import moment from 'moment';
import { constants } from '@constants/constants';
import {
  Cotisation2,
  Fonds,
  FondsRepartition,
  GetTarifsPrevoyanceGPAResponse,
  ModeGestion,
  Pays,
  Question,
  Questionnaire,
  Recommandation,
  Informations,
  Reponse,
  Theme,
  Motifs,
  Poche
} from '@webapi/MIF.Subscription.Parrot';
import { AgeScore, Beneficiary, Offer, Profile, ProfileFund, ProfileQuestion } from '@webapi/MIF.Subscription.WebApi';
import { FundType } from '@models/fund-type';
import { FormArray, FormGroup } from '@angular/forms';
import { ChartDataSets } from 'chart.js';
import { GpaSubscriptionModel } from '@signature/signature.extentions';
import { PoliticallyPersonDataModel } from '@my-details/my-details.component';
import { MinMaxObject } from '@components/offer/view-offer/view-offer.component';
import { environment } from 'projects/mif/environments/environment';
import { PocheEnum } from '@models/poche';
import { CodeProduitType } from '@models/code-produit-type';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { DobLimits } from '@webapi/services/ws-referentiel-metier.service';

function getCurrentProductType(): string {
  switch (environment.orgId) {
    case constants.orgId.cems.value: {
      return CodeProduitType.CEMS;
    }
    case constants.orgId.cims.value: {
      return CodeProduitType.CIMS;
    }
    case constants.orgId.gpa.value: {
      return CodeProduitType.ATDN;
    }
    case constants.orgId.gfd.value: {
      return CodeProduitType.GFD;
    }
    case constants.orgId.peri.value: {
      return CodeProduitType.PERI;
    }
    default: {
      return CodeProduitType.CEMS;
    }
  }
}

function getCurrentProductName(): string {
  switch (environment.orgId) {
    case constants.orgId.cems.value: {
      return constants.productNames.cems.fullName;
    }
    case constants.orgId.gpa.value: {
      return constants.productNames.gpa.fullName;
    }
    case constants.orgId.gfd.value: {
      return constants.productNames.gfd.fullName;
    }
    case constants.orgId.peri.value: {
      return constants.productNames.peri.fullName;
    }
    case constants.orgId.cims.value: {
      return constants.productNames.cims.fullName;
    }
    default: {
      return CodeProduitType.CEMS;
    }
  }
}

function formatDate(date: string): string {
  if (date) {
    return moment(date).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DD[T]HH:mm:ss.SSSZ');
  }

  return null;
}

function getRiskColor(riskValue: number): string {
  switch (riskValue) {
    case 1:
      return '#67a129';
    case 2:
      return '#67a129';
    case 3:
      return '#f8eb00';
    case 4:
      return '#f8eb00';
    case 5:
      return '#ffca11';
    case 6:
      return '#ffca11';
    case 7:
      return '#e8773e';
    default:
      return 'gray';
  }
}

function getRiskTextColor(riskValue: number): string {
  switch (riskValue) {
    case 1:
      return '#000';
    case 2:
      return '#000';
    case 3:
      return '#000';
    case 4:
      return '#000';
    case 5:
      return '#000';
    case 6:
      return '#000';
    case 7:
      return '#000';
    default:
      return '000000';
  }
}

function getNumericListFromCollection(collection: collectionItem[]): string {
  let html = '<ol>';

  collection.forEach((item: collectionItem) => {
    html += `<li> ${item.value} </li>`;
  });

  html += '</ol>';
  return html;
}

function isQuestion12AnsweredYes(questionnaire: Questionnaire, profile: Profile): boolean {
  let res = false;

  if (questionnaire && profile) {
    const theme12 = questionnaire.themes[questionnaire.themes.length - 1];
    const answer = getAnswerTextById(theme12.questions[0], profile);
    if (answer === constants.yesLibelle) {
      res = true;
    }
  }

  return res;
}

function question12YesAnswerExtraText(labelTextPipe: LabelTextPipe) {
  const isPeri: boolean = environment.orgId === constants.orgId.peri.value;
  const isCems: boolean = environment.orgId === constants.orgId.cems.value;
  const isCims: boolean = environment.orgId === constants.orgId.cims.value;

  let text = '';

  if (isCems || isCims) {
    text = `${labelTextPipe.transform('Questionnaire.CemsQuestion12YesAnswerExtraText1')} <b>${labelTextPipe.transform(
      'Questionnaire.CemsQuestion12YesAnswerExtraText2'
    )}</b> ${labelTextPipe.transform('Questionnaire.CemsQuestion12YesAnswerExtraText3')}`;
  }

  if (isPeri) {
    text = `${labelTextPipe.transform('Questionnaire.PeriQuestion12YesAnswerExtraText1')} <b>${labelTextPipe.transform(
      'Questionnaire.PeriQuestion12YesAnswerExtraText2'
    )}</b>, ${labelTextPipe.transform('Questionnaire.PeriQuestion12YesAnswerExtraText3')}. <br/> ${labelTextPipe.transform('Questionnaire.PeriQuestion12YesAnswerExtraText4')}`;
  }

  return text;
}

function getNumericListQuestions(questionnaire: Questionnaire, profile: Profile): string {
  let html = '<ol>';

  questionnaire.themes.forEach((theme: Theme) => {
    if (theme.libelle === 'TWO_COLUMNS' || theme.libelle === 'ONE_COLUMN') {
      html += `<li> ${theme.aide.replace(/<[^>]*>/g, '')}`;

      if (theme.questions.length > 0) {
        html += '<ul class="list-arrows">';

        if (theme.libelle === 'TWO_COLUMNS') {
          theme.questions.forEach((item: Question, index: number) => {
            if (index % 2 !== 0) {
              const prevItem = theme.questions[index - 1];
              html += `<li> ${item.libelle.split('|')[0].replace(/<[^>]*>/g, '')} <span class='answer'>${getTwoColumnAnswersTextById(item, prevItem, profile)} </span> </li>`;
            }
          });
        } else {
          theme.questions.forEach((item: Question) => {
            html += `<li> ${item.libelle.split('|')[0].replace(/<[^>]*>/g, '')} <span class='answer'>${getAnswerTextById(item, profile)} </span> </li>`;
          });
        }

        html += '</ul>';
      }

      html += '</li>';
    } else {
      theme.questions.forEach((item: Question) => {
        html += `<li> ${fixQuestionText(item.libelle)} <span class='answer'>${getAnswerTextById(item, profile)} </span> </li>`;
      });
    }
  });

  html += '</ol>';
  return html;
}

function fixQuestionText(text: string) {
  return text
    .replace(/<[^>]*>/g, '')
    .replace('Quel est votre', 'Quel est votre ')
    .replace('(un seul choix possible)', ' (un seul choix possible)')
    .replace('a un potentiel', 'a un potentiel ')
    .replace('phrase décrit', 'phrase décrit ');
}

function getAnswerTextById(item: Question, profile: Profile) {
  let answerText = '';

  profile.profileQuestions.forEach((savedQuestion: ProfileQuestion) => {
    if (savedQuestion.questionId === item.identifiant) {
      item.reponses.forEach((response: Reponse) => {
        if (response.identifiant === savedQuestion.answerId) {
          answerText = response.libelle;
        }
      });
    }
  });

  return refactorAnswer(answerText);
}

function getTwoColumnAnswersTextById(item: Question, prevItem: Question, profile: Profile) {
  let answerText = '';

  profile.profileQuestions.forEach((savedQuestion: ProfileQuestion) => {
    if (savedQuestion.questionId === prevItem.identifiant) {
      prevItem.reponses.forEach((response: Reponse) => {
        if (response.identifiant === savedQuestion.answerId) {
          answerText = response.libelle;
        }
      });
    }
  });

  profile.profileQuestions.forEach((savedQuestion: ProfileQuestion) => {
    if (savedQuestion.questionId === item.identifiant) {
      item.reponses.forEach((response: Reponse) => {
        if (response.identifiant === savedQuestion.answerId) {
          answerText += `, ${response.libelle}`;
        }
      });
    }
  });

  return refactorAnswer(answerText);
}

function refactorAnswer(text: string) {
  return text.replace('<b>', '').replace('</b>', '');
}

function getProfileTypeData(profileTypeCode: number) {
  switch (profileTypeCode) {
    case constants.securitaireProfileCims: {
      return constants.sécuritaire;
    }
    case 0: {
      return constants.sécuritaire;
    }
    case constants.securitaireProfileLess40KId: {
      return constants.sécuritaire10;
    }
    case constants.securitaireProfileMore40KId: {
      return constants.sécuritaire35;
    }
    case constants.prudent.id: {
      return constants.prudent;
    }
    case constants.equilibré.id: {
      return constants.equilibré;
    }
    case constants.dynamique.id: {
      return constants.dynamique;
    }
    case constants.offensif.id: {
      return constants.offensif;
    }
    default: {
      return {};
    }
  }
}

function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Octets';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function getFundPerformance(fond: FondsRepartition): string {
  const fondPerformance = fond.fonds.performance.sort((f1, f2) => f1.ordre - f2.ordre)[0];

  return fondPerformance.taux + ' %';
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

async function getBase64(file: File) {
  const reader = new FileReader();

  const promise = new Promise<string>(resolve => {
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      resolve('');
    };
  });

  return promise;
}

async function getBase64WithOutMimeType(file: File) {
  const reader = new FileReader();

  const promise = new Promise<string>(resolve => {
    reader.readAsDataURL(file);
    reader.onload = function () {
      let text = reader.result as string;

      resolve(text.split('base64,')[1]);
    };
    reader.onerror = function (error) {
      resolve('');
    };
  });

  return promise;
}

async function openFileInNewTab(fileBase64: string) {
  const base64 = `data:application/pdf;base64,${fileBase64}`;
  const blob = await (await fetch(base64)).blob();
  const fileURL = URL.createObjectURL(blob);

  window.open(fileURL, '_blank');
}

async function openFileByExtentionInNewTab(name: string, fileBase64: string) {
  const arr = name.split('.');
  let ext, preExt;

  if (arr && arr.length > 1) {
    ext = arr[arr.length - 1];
    preExt = ext === 'jpeg' || ext === 'jpg' || ext === 'tif' ? 'image' : 'application';
  }

  const base64 = `data:${preExt}/${ext};base64,${fileBase64}`;
  const blob = await (await fetch(base64)).blob();
  const fileURL = URL.createObjectURL(blob);

  window.open(fileURL, '_blank');
}

async function openXlsxFileInNewTab(name: string, fileBase64: string) {
  const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
  const base64 = `${mediaType}${fileBase64}`;
  const blob = await (await fetch(base64)).blob();
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = name;
  link.click();
}

function getProfileColor(libelle: string) {
  switch (libelle) {
    case constants.sécuritaire.name:
      return constants.sécuritaire10.color;
    case constants.prudent.name:
      return constants.prudent.color;
    case constants.equilibré.name:
      return constants.equilibré.color;
    case constants.dynamique.name:
      return constants.dynamique.color;
    case constants.offensif.name:
      return constants.offensif.color;
    default:
      return constants.sécuritaire10.color;
  }
}

function getCountryById(countries: Pays[], id: number) {
  let value = '';

  countries?.forEach((c: Pays) => {
    if (c.id === id) {
      value = c.langFR;
    }
  });

  return value;
}

function getReferencelibelleLongById(array: Informations[], id: number) {
  let value = '';

  array?.forEach((item: Informations) => {
    if (item.id === id) {
      value = item.libelleLong;
    }
  });

  return value;
}

function getReferencelibelleById(array: any[], id: number) {
  let value = '';

  array?.forEach((item: any) => {
    if (item.id === id) {
      value = item.libelle;
    }
  });

  return value;
}

function getReferencelibelleCourtById(array: Informations[], id: number) {
  let value = '';

  array?.forEach((item: Informations) => {
    if (item.id === id) {
      value = item.libelleCourt;
    }
  });

  return value;
}

function getRelationById(id: number, isFirstUpperCase: boolean = false, isPrimaryBen: boolean = false) {
  const relationCollection = isPrimaryBen ? collections.relationCimsPrimaryBen : collections.relation;
  let value = '';

  relationCollection.forEach((item: any) => {
    if (item.id === id) {
      value = item.description.toLowerCase();
    }
  });

  if (isFirstUpperCase) {
    value = value.charAt(0).toUpperCase() + value.slice(1);
  }

  return value;
}

function getPrimaryBeneficiaryText(ben: Beneficiary, countries: Pays[], nationalities: Informations[], civilities: Informations[], labelTextPipe: LabelTextPipe): any {
  let text = `${labelTextPipe.transform('Beneficiaries.ISetPrimBenText')} : `;
  text += '<ul>';
  text += `<li class="mt-2 mb-2"> ${getRelationById(ben.relationTypeCode, true, true)} ${getReferencelibelleCourtById(civilities, ben.person.civilityCode)} ${ben.person.firstName} ${
    ben.person.lastName
  }, ${ben.person.civilityCode === constants.mCivilityCodeId ? 'né' : 'née'} le ${moment.utc(ben.person.dateOfBirth).format(constants.dateInputFormat)} à ${ben.placeOfBirth.city} (${
    ben.placeOfBirth.zipCode
  }), ${getCountryById(countries, ben.placeOfBirth.country)}, de nationalité ${getReferencelibelleLongById(nationalities, ben.person.nationalityCode)}, demeurant au ${
    ben.currentAddress.addressLine
  }, ${ben.currentAddress.zipCode}, ${ben.currentAddress.city} (${getCountryById(countries, ben.currentAddress.country)})</li>`;
  text += '</ul>';

  return text;
}

function getGfdBeneficiaryClauseText(profile: Profile, countries: Pays[], nationalities: Informations[], civilities: Informations[], labelTextPipe: LabelTextPipe): any {
  let text = `${labelTextPipe.transform('Beneficiaries.ISetBensText')} : <br/>`;

  text += '<ul>';

  if (profile.gfdProfile.beneficiaryTypeCode === constants.beneficiaresTypesGfd.funeralOperator.code) {
    text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('BENEFICIARIES.FuneralOperator')} </li>`;
  }

  profile.gfdProfile.beneficiaries.forEach((ben: Beneficiary) => {
    text += `<li class="mt-2 mb-2"> mon ${getRelationById(ben.relationTypeCode)} ${getReferencelibelleCourtById(civilities, ben.person.civilityCode)} ${ben.person.firstName} ${ben.person.lastName}, ${
      ben.person.civilityCode === constants.mCivilityCodeId ? 'né' : 'née'
    } le ${moment.utc(ben.person.dateOfBirth).format(constants.dateInputFormat)} à ${ben.placeOfBirth.city} (${ben.placeOfBirth.zipCode}), ${getCountryById(
      countries,
      ben.placeOfBirth.country
    )}, de nationalité ${getReferencelibelleLongById(nationalities, ben.person.nationalityCode)}, demeurant au ${ben.currentAddress.addressLine}, ${ben.currentAddress.zipCode}, ${
      ben.currentAddress.city
    } (${getCountryById(countries, ben.currentAddress.country)})</li>`;
  });

  if (profile.gfdProfile.isDefaultBeneficiaryInheritance) {
    text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('NOMINATIVE_CLAUSE.SharedEquallyBetweenHeirs')} </li>`;
  }

  text += '</ul>';

  return text;
}

function getDobLimits(dobLimits: DobLimits): InitDobLimitsResult {
  const maxAge = dobLimits.maxAge;
  let dobMinDateValidation;

  // gpa, gfd - December 31 of the current year
  if (isGpa() || isGfd()) {
    dobMinDateValidation = moment.utc().set('month', 0).set('date', 1).subtract(maxAge, 'year').toDate();
  } else if (isPeri()) {
    // peri
    dobMinDateValidation = moment.utc().subtract(maxAge, 'year').add(1, 'day').toDate();
  } else {
    // cims, cems
    dobMinDateValidation = moment
      .utc()
      .subtract(maxAge + 1, 'year')
      .add(1, 'day')
      .toDate();
  }

  const dobMaxDateValidation = moment.utc().subtract(dobLimits.minAge, 'year').toDate();
  const dobMaxDate = moment.utc().toDate();

  return { dobMinDateValidation, dobMaxDateValidation, dobMaxDate } as InitDobLimitsResult;
}

export interface InitDobLimitsResult {
  dobMinDateValidation: Date;
  dobMaxDateValidation: Date;
  dobMaxDate: Date;
}

function getBeneficiaryClauseText(profile: Profile, countries: Pays[], nationalities: Informations[], civilities: Informations[], labelTextPipe: LabelTextPipe): any {
  switch (profile.beneficiaryClauseCode) {
    case constants.beneficiaresTypes.standard.code: {
      if (isPeri()) {
        return labelTextPipe.transform('BENEFICIARIES.PeriStandardClauseDescription');
      } else if (isCims()) {
        return labelTextPipe.transform('BENEFICIARIES.CimsStandardClauseDescription');
      } else {
        return labelTextPipe.transform('BENEFICIARIES.StandardClauseDescription');
      }
    }
    case constants.beneficiaresTypes.nominative.code: {
      let text = '';

      if (isPeri()) {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeathPeri')} : <br/>`;
      } else if (isCims()) {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeathCims')} : <br/>`;
      } else {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeath')} : <br/>`;
      }

      text += '<ul>';

      const beneficiaries = isGpa() ? profile.gpaProfile?.beneficiaries : profile.beneficiaries;

      beneficiaries?.forEach((ben: Beneficiary) => {
        text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('Beneficiaries.PreAllocatedShareText')} ${ben.beneficialPart}%, ${getRelationById(
          ben.relationTypeCode
        )} ${getReferencelibelleCourtById(civilities, ben.person.civilityCode)} ${ben.person.firstName} ${ben.person.lastName}, ${
          ben.person.civilityCode === constants.mCivilityCodeId ? 'né' : 'née'
        } le ${moment.utc(ben.person.dateOfBirth).format(constants.dateInputFormat)} à ${ben.placeOfBirth.city} (${ben.placeOfBirth.zipCode}), ${getCountryById(
          countries,
          ben.placeOfBirth.country
        )}, de nationalité ${getReferencelibelleLongById(nationalities, ben.person.nationalityCode)}, demeurant au ${ben.currentAddress.addressLine}, ${ben.currentAddress.zipCode}, ${
          ben.currentAddress.city
        } (${getCountryById(countries, ben.currentAddress.country)})</li>`;
      });

      if (profile.isDefaultBeneficiaryInheritance) {
        text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('NOMINATIVE_CLAUSE.SharedEquallyBetweenHeirs')} </li>`;
      }

      text += '</ul>';

      return text;
    }
    case constants.beneficiaresTypes.libre.code: {
      let text = isCims() ? labelTextPipe.transform('FREE_CLAUSE.InfoTitleCims') : labelTextPipe.transform('FREE_CLAUSE.InfoTitleCommon');
      text = text.replace('FIRSTNAME_LASTNAME', `${profile.person.firstName} ${profile.person.lastName}`);

      text += '<br/>';
      text += profile.beneficiaryFreeClauseText;

      return text;
    }
    default:
      return '';
  }
}

function getBeneficiaryGpaClauseText(
  modelGpa: GpaSubscriptionModel,
  profile: Profile,
  countries: Pays[],
  nationalities: Informations[],
  civilities: Informations[],
  labelTextPipe: LabelTextPipe
): string {
  switch (modelGpa.beneficiaryClauseCode) {
    case constants.beneficiaresTypes.standard.code: {
      if (isPeri()) {
        return labelTextPipe.transform('Beneficiaries.PeriStandardClauseDescription');
      } else {
        return labelTextPipe.transform('Beneficiaries.StandardClauseDescription');
      }
    }
    case constants.beneficiaresTypes.nominative.code: {
      let text = '';
      if (isPeri()) {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeathPeri')} : <br/>`;
      } else if (isCims()) {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeathCims')} : <br/>`;
      } else {
        text += `${labelTextPipe.transform('Beneficiaries.IsetBenInCaseDeath')} : <br/>`;
      }

      text += '<ul>';

      modelGpa.beneficiaries?.getRawValue().forEach((ben: any) => {
        if (ben.relation && ben.civility && ben.dob && ben.countryOfBirth && ben.nationality && ben.country) {
          text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('Beneficiaries.PreAllocatedShareText')} ${ben.allocatedShare}%,
                    ${getRelationById(ben.relation)}
                    ${getReferencelibelleCourtById(civilities, ben.civility)} ${ben.firstName} ${ben.lastName}, ${ben.civility === constants.mCivilityCodeId ? 'né' : 'née'} le
                    ${moment.utc(ben.dob).format(constants.dateInputFormat)} à ${ben.cityOfBirth} (${ben.postalCodeOfBirth}),
                    ${getCountryById(countries, ben.countryOfBirth)}, de nationalité
                    ${getReferencelibelleLongById(nationalities, ben.nationality)}, demeurant au ${ben.adress}, ${ben.postalCode}, ${ben.city}
                    (${getCountryById(countries, ben.country)})</li>`;
        }
      });

      if (modelGpa.isEqualParts) {
        text += `<li class="mt-2 mb-2"> ${labelTextPipe.transform('NOMINATIVE_CLAUSE.SharedEquallyBetweenHeirs')} </li>`;
      }

      text += '</ul>';

      return text;
    }

    case constants.beneficiaresTypes.libre.code: {
      let text = isCims() ? labelTextPipe.transform('FREE_CLAUSE.InfoTitleCims') : labelTextPipe.transform('FREE_CLAUSE.InfoTitleCommon');
      text = text.replace('FIRSTNAME_LASTNAME', `${profile.person.firstName} ${profile.person.lastName}`);

      text += '<br/>';
      text += modelGpa.beneficiaryFreeClauseText;

      return text;
    }
    default:
      return '';
  }
}

function getBeneficiaryClause(beneficiaryClauseCode: number): any {
  switch (beneficiaryClauseCode) {
    case constants.beneficiaresTypes.standard.code: {
      return 'Clause standard';
    }
    case constants.beneficiaresTypes.nominative.code: {
      return 'Clause nominative';
    }
    case constants.beneficiaresTypes.libre.code: {
      return 'Clause libre';
    }
    default:
      return '';
  }
}

function getFirstIdentificationDocumentTypeName(code: number) {
  let name = '';

  collections.firstIdentificationDocumentTypes.forEach((item: any) => {
    if (item.id === code) {
      name = item.description;
    }
  });

  return name;
}

function getAfterRepartitionText(clientProfile: any, selectedFunds: Fonds[], labelTextPipe: LabelTextPipe) {
  const msg1: string = labelTextPipe.transform('Summary.AfterRepartitionText1');
  const msg2: string = labelTextPipe.transform('Summary.AfterRepartitionText2');
  const msg3: string = labelTextPipe.transform('Summary.AfterRepartitionText3');
  const msg4: string = labelTextPipe.transform('Summary.AfterRepartitionText4');

  if (!clientProfile) {
    return '';
  }

  const html = '';
  const isVi = !!clientProfile.initialPaymentAmount;
  const isVp = !!clientProfile.scheduledPaymentAmount;
  const isTp = !!clientProfile.transferContractCode;
  const isGsm = clientProfile.managementTypeCode === constants.gestionSousMandat.id;
  const isScpi = selectedFunds.filter(f => f.typeFonds.id === FundType.SCPI)?.length > 0;
  const gLSsansScpi = clientProfile.managementTypeCode === constants.gestionLibre.id && !isScpi;
  const gLAvecScpi = clientProfile.managementTypeCode === constants.gestionLibre.id && isScpi;

  if (clientProfile.isScheduledPaymentsOnly() || clientProfile.isContractTransferOnly() || clientProfile.isScheduledPaymentsOnly()) {
    return '';
  }

  //  VI + VP / GL sans SCPI
  if (isVi && isVp && gLSsansScpi) {
    return msg1;
  }

  // VI + VP / GSM
  if (isVi && isVp && isGsm) {
    return `${msg2} <br/> ${msg3}`;
  }

  // VI + VP / GL avec SCPI
  if (isVi && isVp && gLAvecScpi) {
    return msg4;
  }

  // TP + VI / GL sans SCPI   and   TP + VI / GL avec SCPI
  if (isTp && isVi && (gLSsansScpi || gLAvecScpi)) {
    return '';
  }

  // TP + VI / GSM
  if (isTp && isVi && isGsm) {
    return msg2;
  }

  // TP + VP / GL sans SCPI
  if (isTp && isVp && gLSsansScpi) {
    return msg1;
  }

  // TP + VP / GSM
  if (isTp && isVp && isGsm) {
    return `${msg2} <br/> ${msg3}`;
  }

  // TP + VP / GL avec SCPI
  if (isTp && isVp && gLAvecScpi) {
    return msg4;
  }

  // TP + VI + VP / GL sans SCPI
  if (isTp && isVi && isVp && gLSsansScpi) {
    return msg1;
  }

  // TP + VI + VP / GSM
  if (isTp && isVi && isVp && isGsm) {
    return `${msg2} <br/> ${msg3}`;
  }

  // TP + VI + VP / GL avec SCPI
  if (isTp && isVi && isVp && gLAvecScpi) {
    return msg4;
  }

  return html;
}

function areEqual(array1: number[], array2: number[]): boolean {
  if (array1.length === array2.length) {
    return array1.every(element => {
      if (array2.includes(element)) {
        return true;
      }

      return false;
    });
  }

  return false;
}

function isManualDistributionFondsNamesEqualsRecommendet(selectedFonds: number[], recommendation: Recommandation): boolean {
  let selectedMode = recommendation.produitRecommande.modeGestion.filter((mode: ModeGestion) => {
    return mode.id === constants.gestionLibre.id;
  })[0];

  const pocheUCFondsRepartitionsIds =
    selectedMode?.poche
      .filter((item: Poche) => {
        return item.libelle === PocheEnum.UC;
      })[0]
      ?.fondsRepartition.map((fr: FondsRepartition) => {
        return fr.fonds.id;
      }) ?? [];

  const pocheEurosFondsRepartitionsIds =
    selectedMode?.poche
      .filter((item: Poche) => {
        return item.libelle === PocheEnum.EURO;
      })[0]
      ?.fondsRepartition.map((fr: FondsRepartition) => {
        return fr.fonds.id;
      }) ?? [];

  const allRecommendedFondsIds = [...pocheUCFondsRepartitionsIds, ...pocheEurosFondsRepartitionsIds];

  return areEqual(allRecommendedFondsIds, selectedFonds);
}

function isManualDistributionEqualsRecommendet(distributionFonds: FormArray, recommendation: Recommandation): boolean {
  const selectedMode = recommendation.produitRecommande.modeGestion.filter((mode: ModeGestion) => {
    return mode.id === constants.gestionLibre.id;
  })[0];

  const pocheUCFondsRepartitions =
    selectedMode?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0]?.fondsRepartition ?? [];

  const pocheEurosFondsRepartitions =
    selectedMode?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.EURO;
    })[0]?.fondsRepartition ?? [];

  const allFondsRepartitions = pocheUCFondsRepartitions.concat(pocheEurosFondsRepartitions);
  const distributionFondsValues = distributionFonds.controls.map((fg: FormGroup) => {
    return fg.value;
  });

  if (distributionFondsValues?.length !== allFondsRepartitions?.length) {
    return false;
  }
  let isTheSameSetAndPercentages = true;

  allFondsRepartitions.forEach((fr: FondsRepartition) => {
    const fondRepartition = distributionFondsValues.find((df: any) => {
      return df.fond.id === fr.fonds.id;
    });

    if (!fondRepartition || fondRepartition.fraction !== fr.repartition) {
      isTheSameSetAndPercentages = false;
    }
  });

  return isTheSameSetAndPercentages;
}

function isUserProfileDistributionEqualsRecommendet(userProfile: Profile, recommendation: Recommandation): boolean {
  let isTheSame = true;

  let selectedMode = recommendation.produitRecommande.modeGestion.filter((mode: ModeGestion) => {
    return mode.id === userProfile.managementTypeCode;
  })[0];

  const pocheUCFondsRepartitions =
    selectedMode?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.UC;
    })[0]?.fondsRepartition ?? [];

  const pocheEurosFondsRepartitions =
    selectedMode?.poche.filter((item: Poche) => {
      return item.libelle === PocheEnum.EURO;
    })[0]?.fondsRepartition ?? [];

  const allFonds = pocheUCFondsRepartitions?.map(f => f?.fonds).concat(pocheEurosFondsRepartitions?.map(f => f?.fonds));
  const allFondsRepartitions = pocheUCFondsRepartitions.concat(pocheEurosFondsRepartitions);

  if (userProfile.profileFunds.length !== allFonds.length) {
    return false;
  }

  userProfile.profileFunds.forEach((selected: ProfileFund) => {
    if (isTheSame) {
      let recomProfileFund = allFondsRepartitions.filter((item: FondsRepartition) => {
        return item.fonds.id === selected.fundId;
      })[0];
      if (!recomProfileFund) {
        isTheSame = false;
        return;
      }

      if (selected.portion !== recomProfileFund.repartition) {
        isTheSame = false;
        return;
      }
    }
  });

  return isTheSame;
}

function getLineChartData(res: GetTarifsPrevoyanceGPAResponse): ChartDataSets[] {
  let amounts: any = [];

  res.cotisation.forEach((item: Cotisation2) => {
    amounts.push(item.mntCotisation);

    if (item.age === 70) {
      for (let i = 71; i <= 75; i++) {
        amounts.push(item.mntCotisation);
      }
    }
  });

  return [{ data: amounts, label: '€' }];
}

function getLineChartLabels(res: GetTarifsPrevoyanceGPAResponse): any[] {
  let labels: any = [];

  res.cotisation.forEach((item: Cotisation2) => {
    labels.push(item.age);

    if (item.age === 70) {
      for (let i = 71; i <= 75; i++) {
        labels.push(i);
      }
    }
  });

  labels.push(`Age`);

  return labels;
}

function getPoliticallyPersonDataModel(politicallyPersonTypes: Motifs[], savedTypeId: number): PoliticallyPersonDataModel {
  let commonTypeId;
  let typeId = savedTypeId;

  politicallyPersonTypes.forEach(commonType => {
    commonType.sousmotifs.forEach(type => {
      if (type.id === savedTypeId) {
        commonTypeId = commonType.id;
      }
    });
  });
  return {
    commonTypeId,
    typeId
  } as PoliticallyPersonDataModel;
}

function roundValue(value: number, fractionDigits: number = 2): number {
  if (fractionDigits < 0) {
    throw new Error('Number of fraction digits should be a positive value.');
  }

  const fractionMultiplyer = Math.pow(10, fractionDigits);
  const res = Math.round(value * fractionMultiplyer) / fractionMultiplyer;
  return res;
  // return evenRound(value, fractionDigits);
}

function roundTo100(arr: number[]) {
  let output = [];
  let acc = roundValue(arr[0]);
  output.push(acc);

  for (let i = 1; i < arr.length; i++) {
    let roundedCur = evenRound(arr[i]);
    const currentAcc = acc;
    if (acc == 0) {
      output.push(roundedCur);
      acc += arr[i];
      continue;
    }
    acc += arr[i];
    output.push(evenRound(acc) - evenRound(currentAcc));
  }

  return output;
}

function evenRound(value: number, fractionDigits: number = 2) {
  var d = fractionDigits || 0;
  var m = Math.pow(10, d);
  var n = +(d ? value * m : value).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

function isSameDate(date: any, date2: any): boolean {
  return moment.utc(date).isSame(date2, 'day') && moment.utc(date).isSame(date2, 'month') && moment.utc(date).isSame(date2, 'year');
}

function getConditionAgeInMinutes(age: number, type: number): number {
  switch (type) {
    case constants.ageTypes.min.id: {
      return age;
    }
    case constants.ageTypes.hour.id: {
      return age * 60;
    }
    case constants.ageTypes.day.id: {
      return age * 60 * 24;
    }
    default: {
      return age;
    }
  }
}

function isInMinMaxRange(value: number, limits: MinMaxObject): boolean {
  let isOk = false;

  if (limits) {
    if (limits.min && limits.max) {
      isOk = value >= limits.min && value <= limits.max;
    } else if (limits.min) {
      isOk = value >= limits.min;
    } else if (limits.max) {
      isOk = value <= limits.max;
    }
  }

  return isOk;
}

function getIdOfMondatGestionByName(name: string): number {
  switch (name) {
    case constants.prudent.name: {
      return constants.prudent.id;
    }
    case constants.equilibré.name: {
      return constants.equilibré.id;
    }
    case constants.dynamique.name: {
      return constants.dynamique.id;
    }
    case constants.offensif.name: {
      return constants.offensif.id;
    }
    default:
      return 0;
  }
}

function getOfferFormatedGeneralConditions(offer: Offer): string {
  const productName = getCurrentProductName();
  let res = offer.description.generalConditions ?? '';

  res = res.replace(/{produit}/g, productName);
  res = res.replace(/{ouvertureDate}/g, moment(offer.statusStartDate).format(constants.dateInputFormat));
  res = res.replace(/{fermetureDate}/g, moment(offer.statusEndDate).format(constants.dateInputFormat));

  return res;
}

function getOfferFormatedGeneralConditionsForPdf(offer: Offer): string {
  const productName = getCurrentProductName();
  let res = offer.description.generalConditions ?? '';
  const isFirstDiv = res.startsWith('<');
  const isFirstStrong = res.startsWith('<strong>') || res.startsWith('<b>');

  if (!isFirstDiv || isFirstStrong) {
    res = '<div style="font-size: 12px; color: #4c4c4c;">' + res;
  }

  res = res.replace(/<div>/g, '<div style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<span>/g, '<span style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<p>/g, '<p style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<ul>/g, '<ul style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<ol>/g, '<ol style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<li>/g, '<li style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<b>/g, '<b style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<i>/g, '<i style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<u>/g, '<u style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<strike>/g, '<strike style="font-size: 12px; color: #4c4c4c;">');
  res = res.replace(/<div style="/g, '<div style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<span style="/g, '<span style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<p style="/g, '<p style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<ul style="/g, '<ul style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<ol style="/g, '<ol style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<li style="/g, '<li style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<b style="/g, '<b style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<i style="/g, '<i style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<u style="/g, '<u style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/<strike style="/g, '<strike style="font-size: 12px; color: #4c4c4c;');
  res = res.replace(/font-size: 12px; color: #4c4c4c;font-size: 12px; color: #4c4c4c;/g, 'font-size: 12px; color: #4c4c4c;');
  res = res.replace(/{produit}/g, productName);
  res = res.replace(/{ouvertureDate}/g, moment(offer.statusStartDate).format(constants.dateInputFormat));
  res = res.replace(/{fermetureDate}/g, moment(offer.statusEndDate).format(constants.dateInputFormat));

  if (!isFirstDiv || isFirstStrong) {
    res = res + '</div>';
  }

  res = res.replace(/>/, '>*');

  return res;
}

function uniqueBy(array: any, cond: any): any {
  return array.filter((e: any, i: number) => array.findIndex((e2: any) => cond(e, e2)) === i);
}

function getProductNameById(productId: string): string {
  return constants.productNamesArray.filter((product: any) => {
    return product.id == productId;
  })[0]?.name;
}

function calculateScore(profileQuestionsModel: any, profileQuestions: any, clientAge: any, ageScores: any): any {
  let score = 0;

  profileQuestionsModel.forEach((savedQuestion: ProfileQuestion) => {
    profileQuestions.forEach((question: Question) => {
      if (savedQuestion.questionId === question.identifiant) {
        question.reponses.forEach((response: Reponse) => {
          if (savedQuestion.answerId === response.identifiant) {
            score += response.points;
          }
        });
      }
    });
  });

  ageScores.forEach((range: AgeScore) => {
    if (isInMinMaxRange(clientAge, { min: range.minAge, max: range.maxAge } as MinMaxObject)) {
      score += range.score;
    }
  });

  return score;
}

function getIsOverwritedToGSM(recommandationProfilId: number): boolean {
  return isOverwrited(constants.gestionSousMandat.name, recommandationProfilId);
}

function getIsOverwritedToGL(recommandationProfilId: number): boolean {
  return isOverwrited(constants.gestionLibre.name, recommandationProfilId);
}

function isOverwrited(name: string, recommandationProfilId: number): boolean {
  let isOverwrited = false;
  const tabConfig = constants.overwriterRecommendationTabDefaultSelection as OverrideTabConfig;

  if (tabConfig && tabConfig.startDate && tabConfig.endDate) {
    if (name.toLowerCase() == tabConfig.defaultTabSelected?.toLowerCase()) {
      const start = moment(tabConfig.startDate, constants.configDateFormat);
      const end = moment(tabConfig.endDate, constants.configDateFormat).add(1, 'day');

      if (moment().isBetween(start, end) === true) {
        isOverwrited = true;
      }

      if (tabConfig.profileTypeId) {
        isOverwrited = recommandationProfilId === tabConfig.profileTypeId;
      }
    }
  }

  return isOverwrited;
}

function deepCopy(obj: any) {
  var copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export class OverrideTabConfig {
  defaultTabSelected: string;
  profileTypeId: number;
  startDate: string;
  endDate: string;
}

function isCems(): boolean {
  return environment.orgId === constants.orgId.cems.value;
}

function isCims(): boolean {
  return environment.orgId === constants.orgId.cims.value;
}

function isGpa(): boolean {
  return environment.orgId === constants.orgId.gpa.value;
}

function isGfd(): boolean {
  return environment.orgId === constants.orgId.gfd.value;
}

function isPeri(): boolean {
  return environment.orgId === constants.orgId.peri.value;
}

function cleanObj(obj: any) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

function arrayMove(arr: any, oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;

    while (k--) {
      arr.push(undefined);
    }
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

function scrollToSelector(selector: string): void {
  const elementList = document.querySelectorAll(selector);
  const element = elementList[0] as HTMLElement;
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export {
  isCems,
  isCims,
  isGpa,
  isGfd,
  isPeri,
  deepCopy,
  roundValue,
  evenRound,
  roundTo100,
  getRiskColor,
  getRiskTextColor,
  formatDate,
  getNumericListFromCollection,
  formatBytes,
  getNumericListQuestions,
  getFundPerformance,
  getProfileColor,
  openFileInNewTab,
  getBeneficiaryClause,
  getBeneficiaryClauseText,
  getBeneficiaryGpaClauseText,
  arrayBufferToBase64,
  getBase64,
  getBase64WithOutMimeType,
  getProfileTypeData,
  getReferencelibelleLongById,
  getReferencelibelleById,
  getCountryById,
  getFirstIdentificationDocumentTypeName,
  openFileByExtentionInNewTab,
  getAfterRepartitionText,
  isManualDistributionFondsNamesEqualsRecommendet,
  isManualDistributionEqualsRecommendet,
  isUserProfileDistributionEqualsRecommendet,
  getLineChartData,
  getLineChartLabels,
  getPoliticallyPersonDataModel,
  getGfdBeneficiaryClauseText,
  isSameDate,
  getConditionAgeInMinutes,
  isInMinMaxRange,
  getIdOfMondatGestionByName,
  openXlsxFileInNewTab,
  uniqueBy,
  getOfferFormatedGeneralConditions,
  getProductNameById,
  calculateScore,
  getOfferFormatedGeneralConditionsForPdf,
  getIsOverwritedToGSM,
  getIsOverwritedToGL,
  getCurrentProductType,
  getCurrentProductName,
  getPrimaryBeneficiaryText,
  isQuestion12AnsweredYes,
  question12YesAnswerExtraText,
  cleanObj,
  arrayMove,
  scrollToSelector,
  getDobLimits
};

export class collectionItem {
  value: string;
}
