import { Pipe, PipeTransform } from '@angular/core';
import { PerformaceRangesCollection } from '@shared/models/performance';

@Pipe({
  name: 'performanceRange'
})
export class PerformanceRangePipe implements PipeTransform {
  transform(value: number): string {
    let text = '';

    if (value === null || value === undefined) {
      return text;
    }

    const range = PerformaceRangesCollection.find((item: any) => {
      return item.id === value;
    });

    if (range) {
      const date = new Date();
      const previousYear = date.getFullYear() - 1;
      const rangeStartYear = previousYear - range.value + 1;

      text = `${range.titleShort} (${rangeStartYear} - ${previousYear})`;
    }

    return text;
  }
}
