import { Injectable } from '@angular/core';
import { SettingsService } from '@webapi/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  googleTagManagerId: string;
  adviceId: string;

  constructor(public settingsService: SettingsService) {}

  async init(): Promise<AppConfigService> {
    const config = await this.settingsService.getExternalServicesConfiguration();

    return new Promise(resolve => {
      Object.assign(this, { googleTagManagerId: config.gtmIdentifier });
      Object.assign(this, { adviceId: config.adviceIdentifier });
      resolve(this);
    });
  }
}
