import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseDataService {
    data: any = {};

    constructor() {
        this.data = {};
    }

    initData(data: any) {
        this.data = data;
    }

    isData(key: string) {
        return this.data && this.data[key] !== undefined;
    }

    getData(key: string) {
        return this.getDescendantPropValue(key);
    }

    private getDescendantPropValue(modelName: string) {
        const arr = modelName.split(".");
        var value = this.data;
        for (var i = 0; i < arr.length; i++) {
            value = value[arr[i]];
        }
        return value;
    }

    private setData(key: string, data: any) {
        this.data[key] = data;
    }

    updateData(key: string, data: any) {
        if (this.isData(key)) {
            const existingData = this.getData(key);
            const updatedData = Object.assign(existingData, data)
            this.setData(key, updatedData);

        } else {
            this.setData(key, data)
        }
    }

    clearData() {
        this.data = {};
    }
}
