<div class="form-group inline" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appTrim
      type="text"
      [validation]="false"
      mask="00000"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control form-control-sm"
      [formControlName]="controlName"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <!-- <mat-error>
      <span *ngIf="control.errors?.required">{{ requiredText }}</span>
      <span *ngIf="control.errors?.zipCodeValidator">Formats: XXXXX</span>
    </mat-error> -->
  </mat-form-field>
</div>
