<div class="main-page text-center">
  <app-header></app-header>
  <div class="back-btn" *ngIf="isPrevPageExists">
    <div (click)="navigateToPrevPage()"><span class="mb-1 img-back" alt=""></span> <br>{{ 'HOME.PreviousPageText' | labelText }}</div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-1 col-md-1 col-lg-11">
      </div>
      <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10 page-content centered-by-margin">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-footer></app-footer>
    <div class="bottom-nav">
      <a *ngFor="let link of linksInTheFooter" href="javascript:void(0)" (click)="onFooterLinkClick(link)"> {{ link.label }} </a>
    </div>
  </div>
</div>
