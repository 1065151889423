<div class="form-group selection-list" [class.required]="isRequired" [class.disabled]="isDisabled" [class.inline]="isInline" [class.compact]="isCompact" [formGroup]="parentForm">
  <mat-label *ngIf="title" class="pr-2">{{ title }}</mat-label>

  <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
    <fa-icon [icon]="faCheck"></fa-icon>
  </span>

  <mat-error class="checkbox-error" *ngIf="control.touched && control.errors && !control.hideErrorMessages">
    <span>
      {{ requiredText }}
    </span>
  </mat-error>

  <br *ngIf="!isInline" />

  <mat-selection-list #list [formControlName]="controlName" (ngModelChange)="change()">
    <mat-list-option *ngFor="let item of collection; let i = index" value="{{ item[keyPropertyName] || item }}">
      {{ item[valuePropertyName] || item }}
    </mat-list-option>
    <mat-list-option *ngIf="addOther" value="{{ otherAswer }}"> {{ 'Common.Other' | labelText }} </mat-list-option>
  </mat-selection-list>

  <div *ngIf="isOtherAnswer" class="input-inside-checkbox-list">
    <div class="row">
      <div class="col-6">
        <app-name-with-digits-form-control
          (onChange)="otherChange($event)"
          class="{{ class }}"
          [parentForm]="form"
          [controlName]="'other'"
          [title]="''"
          [placeholder]="'Common.PlaceholderSet' | labelText"
        >
        </app-name-with-digits-form-control>
      </div>
    </div>
  </div>
</div>
