<div
  class="form-group"
  [class.required]="isRequired"
  [formGroup]="parentForm"
>
  <mat-form-field
    [floatLabel]="'never'"
    appearance="fill"
    [class.readonly]="readOnlyCss"
  >
    <!-- <mat-label>{{ title }}</mat-label> -->

    <input
      matInput
      type="text"
      [validation]="false"
      mask="00/00/0000"
      placeholder="jj/mm/aaaa"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
    />

    <!-- <span
      class="success-mark"
      *ngIf="control.value && !control.errors && !control.hideSuccessMark"
    >
      <fa-icon [icon]="faCheck"></fa-icon>
    </span> -->

    <!-- <mat-error>
      <span *ngIf="control.errors?.required">{{ requiredText }}</span>
    </mat-error> -->
  </mat-form-field>
</div>
