import { Pipe, PipeTransform } from '@angular/core';
import { getWithdrawalTypeNameById } from '@extensions/admin.extensions';

@Pipe({
  name: 'withdrawalTypeName'
})
export class WithdrawalTypePipe implements PipeTransform {
  constructor() {

  }

  transform(value: number): string {
    let res = '';

    if (!value) {
      return res;
    }

    res = getWithdrawalTypeNameById(value);

    return res;
  }
}
