import { constants } from '@constants/constants';
import { Reponse } from '@webapi/MIF.Subscription.Parrot';
import { Profile, ProfileQuestion } from '@webapi/MIF.Subscription.WebApi';

declare module '../webapi/MIF.Subscription.WebApi' {
  interface Profile {
    isSecurityProfile(): boolean;
    isWithTransfer(): boolean;
    isWithInitialPayment(): boolean;
    isWithScheduledPayments(): boolean;
    isPaymentAboveThreshold(threshold: number): boolean;
    isUcSelectionMandatory(initialPaymentThreshold: number): boolean;
    isScheduledPaymentsOnly(): boolean;
    isContractTransferOnly(): boolean;
    isInitialPaymentOnly(): boolean;
    initialPaymentTotalAmount(): number;
    scheduledPaymentTotalAmount(): number;
    isQuestion12AnsweredYes(questions: any): boolean;
  }
}

Profile.prototype.isSecurityProfile = function (): boolean {
  return this.profileTypeCode === constants.securitaireProfileLess40KId ||
    this.profileTypeCode === constants.securitaireProfileMore40KId;
};

Profile.prototype.isWithTransfer = function (): boolean {
  return this.transferContractCode > 0;
};

Profile.prototype.isWithInitialPayment = function (): boolean {
  return this.initialPaymentAmount > 0;
};

Profile.prototype.isWithScheduledPayments = function (): boolean {
  return this.scheduledPaymentAmount > 0;
};

Profile.prototype.isPaymentAboveThreshold = function (threshold: number): boolean {
  return this.initialPaymentAmount >= threshold;
};

Profile.prototype.isUcSelectionMandatory = function (initialPaymentThreshold: number): boolean {
  const case1 = this.isSecurityProfile() && !this.isWithTransfer() && this.isPaymentAboveThreshold(initialPaymentThreshold);
  const case2 = !this.isSecurityProfile() && !this.isWithTransfer();

  return case1 || case2;
};

Profile.prototype.initialPaymentTotalAmount = function (): number {
  return (this.initialPaymentAmount ?? 0) + (this.transferContractAmount ?? 0);
};

Profile.prototype.scheduledPaymentTotalAmount = function (): number {
  return this.scheduledPaymentAmount ?? 0;
};

Profile.prototype.isScheduledPaymentsOnly = function (): boolean {
  return this.isWithScheduledPayments() && !this.isWithInitialPayment() && !this.isWithTransfer();
};

Profile.prototype.isContractTransferOnly = function (): boolean {
  return this.isWithTransfer() && !this.isWithInitialPayment() && !this.isWithScheduledPayments();
};

Profile.prototype.isInitialPaymentOnly = function (): boolean {
  return this.isWithInitialPayment() && !this.isWithTransfer() && !this.isWithScheduledPayments();
};

Profile.prototype.isQuestion12AnsweredYes = function (questions: any): boolean {
  const profileThemes = questions.themes;
  const question12YesAnswerId = profileThemes[11]?.questions[0]?.reponses?.find((resp: Reponse) => {
    return resp.libelle === constants.yesLibelle;
  }).identifiant;
  const question12Id = profileThemes[11].questions[0]?.identifiant;

  const question12Saved = this.profileQuestions?.find((question: ProfileQuestion) => {
    return question.questionId === question12Id;
  })

  return question12Saved.answerId === question12YesAnswerId;
};
