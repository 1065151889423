import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { constants } from '../../constants/constants';
import { ConditionType, GroupType, LookupsClient, OfferStatus, OptionValue, Product, Section } from '../MIF.Subscription.WebApi';

@Injectable({
  providedIn: 'root'
})
export class LookupsService {

  constructor(private webApi: LookupsClient) { }

  private offerStatuses: any;
  private offerProducts: any;
  private conditionTypes: any;
  private sectionsData: any = {};
  private conditionValues: OptionValue[];

  public async getOfferStatuses(): Promise<OfferStatus[]> {
    if (this.offerStatuses) {
      return new Promise(resolve => {
        resolve(this.offerStatuses);
      });
    } else {
      return this.webApi.getOfferStatuses().pipe(
        map((response: OfferStatus[]) => {
          this.offerStatuses = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async getOfferProducts(): Promise<Product[]> {
    if (this.offerProducts) {
      return new Promise(resolve => {
        resolve(this.offerProducts);
      });
    } else {
      return this.webApi.getProducts().pipe(
        map((response: Product[]) => {
          this.offerProducts = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async getConditionTypes(): Promise<ConditionType[]> {
    const policesId = 3;
    const sponsorId = 5;

    if (this.conditionTypes) {
      return new Promise(resolve => {
        resolve(this.conditionTypes);
      });
    } else {
      return this.webApi.getConditionTypes().pipe(
        map((response: ConditionType[]) => {
          this.conditionTypes = response.filter((item: ConditionType) => {
            return item.id !== policesId && item.id !== sponsorId;
          });
          return this.conditionTypes;
        })
      ).toPromise();
    }
  }

  public async getConditionValues(): Promise<OptionValue[]> {
    if (this.conditionValues) {
      return new Promise(resolve => {
        resolve(this.conditionValues);
      });
    } else {
      return this.webApi.getConditionValues().pipe(
        map((response: OptionValue[]) => {
          this.conditionValues = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async getSectionsForOffer(): Promise<Section[]> {
    let sections = await this.getSectionByType(GroupType.Offer);
    let sortedSections: Section[] = [];

    constants.sectionsSortOrder.forEach(function (name) {
      let found = false;

      sections = sections.filter(function (section) {
        if (!found && section.name == name) {
          sortedSections.push(section);
          found = true;
          return false;
        } else
          return true;
      })
    })

    return new Promise(resolve => {
      resolve(sortedSections);
    });
  }

  public async getSectionsForLabels(): Promise<Section[]> {
    return this.getSectionByType(GroupType.Label);
  }

  public async getSectionByType(type: number): Promise<Section[]> {
    if (this.sectionsData[type]) {
      return new Promise(resolve => {
        resolve(this.sectionsData[type]);
      });
    } else {
      return this.webApi.getSections(type).pipe(
        map((response: Section[]) => {
          this.sectionsData[type] = response;
          return response;
        })
      ).toPromise();
    }
  }

}
