import { Injectable, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { collections } from '../constants/collections';
import { AbstractFormControl } from './abstract-form-control';

@Injectable()

export abstract class AbstractFormControlComponent<T extends AbstractFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    requiredText: string = collections.messages.required;

    get isRequired(): boolean {
        return this.control?.isRequired ?? false;
    }

    get isDisabled(): boolean {
        return this.control?.isDisabled ?? false;
    }

    get control(): T {
        return this.parentForm.get(this.controlName) as T;
    }
}
