import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { BaseChartDirective, Label, MultiDataSet } from 'ng2-charts';
import { getRiskColor } from '@extensions/extensions';
import { FondDistribution, Fund } from '../my-contract.component';

@Component({
  selector: 'app-pie-distribution',
  templateUrl: './pie-distribution.component.html',
  styleUrls: ['./pie-distribution.component.scss']
})
export class PieDistributionComponent implements OnInit, OnChanges {

  @Input() distributionFonds: FormArray;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public chartsOptions: (any & { annotation: any }) = {
    cutoutPercentage: 0,
    title: {
      display: false,
      position: 'left',
    },
    legend: {
      display: false
    },
    responsive: true,
    elements: {
      line: {
        tension: 0
      }
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          let label = data.labels[tooltipItem.index];
          let count = data
            .datasets[tooltipItem.datasetIndex]
            .data[tooltipItem.index];
          return `${label}%`;
        },
        title: function (tooltipItem: any, data: any) {
          return '';
        }
      },
    },
  };
  chartLabels: Label[];
  chartPercentages: MultiDataSet;
  chartColors: any;
  chartPercentagesExpanded: any[];
  chartColorsExpanded: any[];
  chartLabelsExpanded: any[];
  extendedFonds: any[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.distributionFonds && changes?.distributionFonds.currentValue !== undefined) {
      this.distributionFonds = changes.distributionFonds.currentValue;
      this.initExtandedFonds();
    }
  }

  updateCharts(labels: Label[], percentages: MultiDataSet, collors: any[]): void {
    this.chartLabels = labels;
    this.chartPercentages = percentages;
    this.chartColors = collors;
  }

  getRiskColor = getRiskColor;

  ngOnInit(): void {
    this.distributionFonds.controls.forEach((f: FormArray) => {
      f.valueChanges.subscribe((changes: any) => {
        this.initExtandedFonds();
      })
    })
  }

  initExtandedFonds(): void {
    this.extendedFonds = [];
    this.chartLabelsExpanded = [];
    this.chartPercentagesExpanded = [];
    this.chartColorsExpanded = [
      {
        backgroundColor: []
      }
    ];

    this.distributionFonds.controls.forEach((f: FormArray) => {
      let fondDistrib = f.controls as unknown as FondDistribution;

      const fond = fondDistrib.fond.value as Fund;

      this.chartPercentagesExpanded.push(parseFloat(fondDistrib.fraction.value));
      this.chartColorsExpanded[0].backgroundColor.push(getRiskColor(fond.risk));
      this.chartLabelsExpanded.push(fondDistrib.fraction.value);

      this.extendedFonds.push({
        id: fondDistrib.id.value,
        title: fondDistrib.fraction.value,
        isEuro: fondDistrib.id.value === 1,
        description: fondDistrib.fond.value,
        fond: fond
      });
    });

    this.updateCharts(this.chartLabelsExpanded, this.chartPercentagesExpanded, this.chartColorsExpanded);
  }

}