import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FileResponse, Label, LabelsClient } from '@webapi/MIF.Subscription.WebApi';

@Injectable({
  providedIn: 'root'
})
export class LabelsDataService {
  private labels: Label[] = [];

  constructor(
    private webApi: LabelsClient
  ) { }

  public async getAllLabels(): Promise<Label[]> {
    if (!this.labels || this.labels.length === 0) {
      await this.initliaze();
    }

    return this.labels;
  }

  getData(key: string): string {
    const label = this.labels.find(l => l.name.toLowerCase() === key.toLowerCase());

    if (!label) {
      return ''; // todo?: we can set some text for detect such cases
    }

    return label?.isHidden ? '' : label?.value;
  }

  updateLabel(label: Label): Promise<FileResponse> {
    label.updatedOn = new Date();
    return this.webApi.updateLabel(label).pipe(
      map((response: FileResponse) => {
        return response;
      })
    ).toPromise();
  }

  async initliaze(): Promise<void> {
    try {
      this.labels = await this.webApi.getLabels().toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
