<div class="form-group distribution inline" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'always'" appearance="fill" [class.readonly]="true" class="distribution-input">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      mask="separator.2"
      decimalMarker=","
      thousandSeparator=" "
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control"
      [formControlName]="controlName"
    />
  </mat-form-field>

  <span class="euroInclus"> € </span>
</div>
