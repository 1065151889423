<div class="form-group mobile" [class.required]="isRequired" [class.hide-show-phone]="hidePhoneAvailable" [class.with-status-mark]="!hideSuccessMark || !hideErrorMark" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss" class="with-prefix">
    <mat-label>{{ title }}</mat-label>

    <span class="phone-prefix" matPrefix>
      {{ matPrefix }}
    </span>

    <input
      appPhoneTrim
      matInput
      [id]="controlName"
      (change)="change()"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
      [placeholder]="'0622334455'"
      maxlength="10"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <span class="show-hide" *ngIf="control.value && hidePhoneAvailable" (click)="hideShowPhoneToogle()">
      <fa-icon *ngIf="showPhone" [icon]="faEye"></fa-icon>
      <fa-icon *ngIf="!showPhone" [icon]="faEyeSlash"></fa-icon>
    </span>

    <span class="error-mark" *ngIf="control.touched && control.errors && !hideErrorMark">
      <fa-icon [icon]="faClose"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.mobileValidator"> {{ 'HOME.MobilePhoneError' | labelText }} </span>
      <span *ngIf="control.errors?.phoneRemoteValidator">
        {{ 'HOME.RecommandationWrongPhoneNumber1' | labelText }} {{ control.phoneTypeText }} {{ 'HOME.RecommandationWrongPhoneNumber2' | labelText }}
      </span>
    </mat-error>
  </mat-form-field>
</div>
