import { constants } from '@constants/constants';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileUploadControl, FileUploadValidators, ValidationErrors } from '@iplab/ngx-file-upload';
import { formatBytes } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { SubscriptionHandler } from '../subscriptionHandler';
import { STATUS } from '@iplab/ngx-file-upload/lib/helpers/control.class';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() files: File[];
  @Input() hideAfterUpload?: boolean;
  @Output() onChange: EventEmitter<File[]> = new EventEmitter();
  @Output() onStatusChange: EventEmitter<STATUS> = new EventEmitter();

  public fileUploadControl: FileUploadControl;
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  constructor(
    private labelTextPipe: LabelTextPipe
  ) { }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  ngOnInit(): void {
    this.fileUploadControl = new FileUploadControl(
      {
        listVisible: false,
        discardInvalid: false,
        accept: constants.fileUploaderSupportedTypes
      },
      [
        FileUploadValidators.accept(constants.fileUploaderSupportedTypes),
        FileUploadValidators.fileSize(constants.fileUploaderMaxFileSize)
        // FileUploadValidators.sizeRange({ minSize: 1 }) // check for 0 bytes
      ]
    );

    if (this.files) {
      this.fileUploadControl?.clear();

      this.files.forEach((file: File) => {
        this.fileUploadControl?.addFile(file);
      });
    }

    this.subscriptionHandler.subscriptions = this.fileUploadControl.valueChanges.subscribe((values: File[]) => {
      this.onChange.next(values);
    });

    this.subscriptionHandler.subscriptions = this.fileUploadControl.statusChanges.subscribe((status: STATUS) => {
      this.onStatusChange.next(status)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.files && changes?.files.currentValue !== undefined) {
      this.fileUploadControl?.clear();

      this.files.forEach((file: File) => {
        this.fileUploadControl?.addFile(file);
      });
    }
  }

  removeFile(file: any): void {
    if (confirm(this.labelTextPipe.transform('COMMON.AttachedFileConfirmationDeletion'))) {
      this.fileUploadControl.removeFile(file);
    }
  }

  get isHideUploader(): boolean {
    if (this.hideAfterUpload) {
      return this.fileUploadControl.size > 0;
    } else {
      return false;
    }
  }

  get errors(): Array<ValidationErrors> {
    const errorsList = this.fileUploadControl.getError();

    if (errorsList?.length > 0) {
      return errorsList;
    } else {
      return null;
    }
  }

  get errorMessages(): string[] {
    let messages: string[];

    if (this.errors) {
      this.errors.forEach((error: ValidationErrors) => {
        const errorsFileSize = error.fileSize;
        const errorsFileTypes = error.fileTypes;
        // const errorsSizeRange = error.sizeRange; // check for 0 bytes

        // if (errorsSizeRange) {
        //   if (errorsSizeRange) {
        //     errorsSizeRange.forEach((frError: any) => {
        //       const text = `COMMON.FileIsEmpty'`;

        //       if (!messages) {
        //         messages = [text];
        //       } else {
        //         messages.push(text);
        //       }
        //     });
        //   }
        // }

        if (errorsFileTypes) {
          errorsFileTypes.forEach((ftError: any) => {
            const text = `${this.labelTextPipe.transform('COMMON.FileTypeWrong')}`;

            if (!messages) {
              messages = [text];
            } else {
              messages.push(text);
            }
          });
        }

        if (errorsFileSize) {
          errorsFileSize.forEach((fsError: any) => {
            const text = `${this.labelTextPipe.transform('COMMON.FileTooBig')} (${formatBytes(fsError.actual)}). ${this.labelTextPipe.transform('COMMON.MaxFileSize')} : ${formatBytes(fsError.maxSize)}.`;

            if (!messages) {
              messages = [text];
            } else {
              messages.push(text);
            }
          });
        }
      });
      return messages;
    } else {
      return null;
    }
  }

}

export enum FileUploaderStatus {
  INVALID = 0,
  VALID = 1,
  DISABLED = 2
}
