import { ValidatorFn } from '@angular/forms';
import { MyDetailsDataService } from 'projects/mif/app/services/my-details.data.service';
import { nirValidator } from '../../validators/nirValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class NirFormControl extends AbstractFormControl {
    constructor(
        required?: boolean,
        disabled: boolean = false,
        public options?: NirOptions
    ) {
        super(required, disabled);
    }

    protected getValidators(): ValidatorFn[] {
        return [nirValidator()];
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }

    get myDetailsDataService(): MyDetailsDataService {
        return this.options?.myDetailsDataService;
    }
}

export interface NirOptions {
    hideSuccessMark?: boolean;
    myDetailsDataService: MyDetailsDataService
}
