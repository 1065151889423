import { Injectable } from '@angular/core';
import {
  RechercheAdresseRequest,
  RechercheVilleRequest,
  ResponseDQE,
  ResponseDQE6,
  ValidationEmailRequest,
  ValidationNumTelRequest,
  Client,
  RechercheRueRequest,
  ResponseDQE5,
  ValidationNumTelResponse
} from '../MIF.Subscription.Parrot';
import { constants } from '@shared/constants/constants';

@Injectable({
  providedIn: 'root'
})
export class WsDqeService {
  constructor(private webApi: Client) {}

  public async validatePhoneNumber(phoneNumber: string): Promise<boolean> {
    const request = new ValidationNumTelRequest({ numTel: phoneNumber, codePaysISO: 'FRA', format: 2, idCanal: 1 });
    const response = (await this.webApi.validationNumTel(request).toPromise()) as ValidationNumTelResponse;

    // "La demande de validation du numéro de téléphone a échoué. Merci de renouveler la demande ultérieurement."
    if (response.erreurs && response.erreurs[0]?.codeErreur === constants.validatePhoneServiceUnavailableCode) {
      return true;
    }

    if (response.validation?.Type) {
      return true;
    }

    return false;
  }

  public async rechercheVilleByPostal(postalCode: number): Promise<any[]> {
    const request = new RechercheVilleRequest({
      nomVille: '',
      codePostal: postalCode.toString(),
      codePaysISO: 'FRA',
      idCanal: 1
    });
    const response = await this.webApi.rechercheVille(request).toPromise();

    return response.rechercheVille?.filter((item: ResponseDQE) => {
      return item.Localite !== '';
    });
  }

  public async rechercheAdresseByCity(city: string): Promise<any[]> {
    const request = new RechercheAdresseRequest({
      adresse: city,
      codePaysISO: 'FRA',
      idCanal: 1
    });

    const response = await this.webApi.rechercheAdresse(request).toPromise();

    return response.rechercheAdresse?.filter((item: ResponseDQE6) => {
      return item.Localite !== '';
    });
  }

  public async rechercheRueByCity(idVille: string, searchText: string): Promise<any[]> {
    const request = new RechercheRueRequest({
      taille: 38,
      idVille: parseInt(idVille, 10),
      adresse: searchText,
      codePaysISO: 'FRA',
      idCanal: 1
    });

    const response = await this.webApi.rechercheRue(request).toPromise();

    return response.rechercheRue?.filter((item: ResponseDQE5) => {
      return item.Localite !== '';
    });
  }

  public async validateEmail(email: string): Promise<EmailValidationResult> {
    const request = new ValidationEmailRequest({ email, isRectify: false, isExtendedSyntax: false, isCheckUser: true, idCanal: 1 });
    const response = await this.webApi.validationEmail(request).toPromise();

    switch (response.validation?.IdError) {
      case EmailValidationCodes.Valid:
      case EmailValidationCodes.CoudNotCheckName:
      case EmailValidationCodes.BoxFull:
      case EmailValidationCodes.ServiceNotAvailable:
      case undefined: {
        let res = {
          hasError: false
        };

        return res;
      }
      default: {
        let res = {
          hasError: true,
          errorCode: response.validation?.IdError
        };

        return res;
      }
    }
  }
}

export enum EmailValidationCodes {
  Valid = 0,
  CoudNotCheckName = 1,
  BoxFull = 3,
  ServiceNotAvailable = 99
}

export interface EmailValidationResult {
  hasError: boolean;
  errorCode?: number | null;
}
