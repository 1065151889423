import { constants } from '@constants/constants';
import { AssociatedQuestion, Profile, ProfileQuestion, Questionnaire } from '@webapi/MIF.Subscription.WebApi';
import { Component, OnInit } from '@angular/core';
import { faCheck, faExclamation, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ProgressBarService } from '@services/progress-bar.service';
import { WsCrmQuestionnaireService } from '@webapi/services/ws-crm-questionnaire.service';
import { CustomerService } from '@webapi/services/customer.service';
import { MandatGestion, MandatGestion2, Question, Recommandation, Reponse, Theme } from '@webapi/MIF.Subscription.Parrot';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { ContratTransferableFourgousExtended, WsPolicesService } from '@webapi/services/ws-polices.service';
import { calculateScore, getProfileColor, isCems, isCims, isPeri, question12YesAnswerExtraText } from '@extensions/extensions';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import moment from 'moment';
import { SettingsService } from '@webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { LabelTextPipe } from '@pipes/label-text.pipe';

@Component({
  selector: 'app-profile-steps',
  templateUrl: './profile-steps.component.html',
  styleUrls: ['./profile-steps.component.scss']
})
export class ProfileStepsComponent implements OnInit {
  isCems = isCems();
  isPeri = isPeri();
  isCims = isCims();
  activeAnswerIndex: number;
  activeBtns: string;
  activeThemeNumber = 0;
  activeTheme: Theme;
  faCheck = faCheck;
  faExclamation = faExclamation;
  profileThemes: Theme[] = [];
  profileQuestions: Question[] = [];
  profileQuestionsForm = new FormGroup({});
  answeredTheme = 0;
  activeBtn1: boolean;
  activeBtn2: boolean;
  activeBtn3: boolean;
  activeBtn4: boolean;
  activeBtn5: boolean;
  activeBtn6: boolean;
  activeBtn7: boolean;
  activeBtn8: boolean;
  activeBtn9: boolean;
  activeBtn10: boolean;

  clickedButton1 = false;
  clickedButton2 = false;
  clickedButton3 = false;
  clickedButton4 = false;
  clickedButton5 = false;
  clickedButton6 = false;
  clickedButton7 = false;
  clickedButton8 = false;
  clickedButton9 = false;
  clickedButton10 = false;
  clickedButton11 = false;
  clickedButton12 = false;
  clickedButton13 = false;
  clickedButton14 = false;
  clickedButton15 = false;
  clickedButton16 = false;
  clickedButton17 = false;
  clickedButton18 = false;
  clickedButton19 = false;
  clickedButton20 = false;

  question12Id: number;
  question12YesAnswerId: number;

  moneyMarket: boolean;
  bounds: boolean;
  derivatives: boolean;
  faInfoCircle = faInfoCircle;
  summary = false;
  theme7: any = [];
  theme10: any = [];
  activeQuestionText = '';
  activeQuestionText2 = '';
  clientProfile: Profile;
  mandatGestion: MandatGestion;
  recommandation: Recommandation;
  transferableContracts: ContratTransferableFourgousExtended[];
  showRecommendationState = false;
  dataLoaded = false;
  maxAnsweredThemeNumber = 0;
  questionnaireConfiguration: Questionnaire;
  ProfileStepName = ProfileStepName;
  questionChanging: boolean;
  questions: any;
  productNames = constants.productNames;
  associatedQuestions: AssociatedQuestion[];
  incoherentAnswersIndexesForCurrentTheme: CurrentIncoherentItem[] = [];
  conditionQuestionThemeIndexes: number[] = [];
  conditionQuestionThemeIndexesExtended: ConditionQuestionThemeIndexesExtendedItem[] = [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public progressBarService: ProgressBarService,
    public wsCrmQuestionnaireService: WsCrmQuestionnaireService,
    public wsReferentielMetierService: WsReferentielMetierService,
    public wsPolicesService: WsPolicesService,
    public customerService: CustomerService,
    public bsModalService: BsModalService,
    public settingsService: SettingsService,
    public labelTextPipe: LabelTextPipe
  ) { }

  async ngOnInit(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();
    this.progressBarService.sendStep(0);
    await this.fetchInitData();
    await this.restoreAnswersOnForm();
    if (history.state.showRecommendation) {
      this.showRecommendationState = history.state.showRecommendation;
      this.goToSummary();
    } else {
      this.dataLoaded = true;
    }
  }

  async fetchInitData(): Promise<void> {
    this.questionnaireConfiguration = await this.settingsService.getQuestionnaireConfiguration();
    this.associatedQuestions = await this.settingsService.getAssociatedQuestions();
    this.questions = await this.wsCrmQuestionnaireService.getQuestions();
    this.profileThemes = this.questions.themes;
    this.question12YesAnswerId = this.profileThemes[11]?.questions[0]?.reponses?.find((resp: Reponse) => {
      return resp.libelle === constants.yesLibelle;
    }).identifiant;
    this.question12Id = this.profileThemes[11].questions[0]?.identifiant;

    this.profileThemes.forEach((element: Theme) => {
      element.questions.forEach((question: Question) => {
        this.profileQuestions.push(question);
        this.profileQuestionsForm.addControl(question.identifiant.toString(), new FormControl(''));
      });
    });

    this.questionsRefactoring();
    this.getSteps();
  }

  getSteps(): void {
    this.activeTheme = this.profileThemes[this.activeThemeNumber];
    this.refactoringActiveThemeQuestion();
    this.activeThemeNumber = 1;
    this.summary = false;
    this.showRecommendationState = false;
  }

  async restoreActiveQuestionAnswer(): Promise<void> {
    this.activeAnswerIndex = null;

    if (this.clientProfile) {
      if (this.activeTheme.ordre === 10) {
        this.restoreTheme10Answers(this.profileQuestionsModel);
      } else if (this.activeTheme.ordre === 11) {
        this.restoreTheme11Answers(this.profileQuestionsModel);
      } else {
        const questionId = this.activeTheme.questions[0].identifiant;

        this.profileQuestionsModel.forEach((savedQuestion: ProfileQuestion) => {
          if (savedQuestion.questionId === questionId) {
            const answerIndex = this.getAnswerIndex(savedQuestion.questionId, savedQuestion.answerId);

            if (answerIndex !== undefined) {
              this.activeAnswerIndex = answerIndex;
            }
          }
        });
      }
    }
  }

  restoreTheme11Answers(profileQuestionsModel: any): void {
    this.activeTheme.questions.forEach((question: Question, activeThemequestionsIndex: number) => {
      const questionId = question.identifiant;

      profileQuestionsModel.forEach((savedQuestion: ProfileQuestion) => {
        if (savedQuestion.questionId === questionId) {
          const answerIndex = this.getAnswerIndex(savedQuestion.questionId, savedQuestion.answerId);

          if (answerIndex !== undefined) {
            const value = answerIndex === 0;

            switch (activeThemequestionsIndex) {
              case 0: {
                this.moneyMarketFundsChange(value);
                break;
              }
              case 1: {
                this.boundsChanged(value);
                break;
              }
              case 2: {
                this.derivativesChanged(value);
                break;
              }
            }
          }
        }
      });
    });
  }

  restoreTheme10Answers(profileQuestionsModel: any): void {
    this.activeTheme.questions.forEach((question: Question, activeThemequestionsIndex: number) => {
      const questionId = question.identifiant;

      profileQuestionsModel.forEach((savedQuestion: ProfileQuestion) => {
        if (savedQuestion.questionId === questionId) {
          const answerIndex = this.getAnswerIndex(savedQuestion.questionId, savedQuestion.answerId);

          if (answerIndex !== undefined) {
            const value = answerIndex === 0;

            switch (activeThemequestionsIndex) {
              case 0: {
                this.activeBtn1Clicked(value);
                break;
              }
              case 1: {
                this.activeBtn2Clicked(value);
                break;
              }
              case 2: {
                this.activeBtn3Clicked(value);
                break;
              }
              case 3: {
                this.activeBtn4Clicked(value);
                break;
              }
              case 4: {
                this.activeBtn5Clicked(value);
                break;
              }
              case 5: {
                this.activeBtn6Clicked(value);
                break;
              }
              case 6: {
                this.activeBtn7Clicked(value);
                break;
              }
              case 7: {
                this.activeBtn8Clicked(value);
                break;
              }
              case 8: {
                this.activeBtn9Clicked(value);
                break;
              }
              case 9: {
                this.activeBtn10Clicked(value);
                break;
              }
            }

          }
        }
      });
    });
  }

  async restoreAnswersOnForm(): Promise<void> {
    if (this.clientProfile) {
      this.profileQuestionsModel.forEach((item: ProfileQuestion) => {
        this.profileQuestionsForm.get(item.questionId.toString()).patchValue(item.answerId);
      });

      if (!this.showRecommendationState) {
        this.restoreQuestionsVisibility(this.profileQuestionsModel);
      }

    }

    if (!this.showRecommendationState) {
      this.restoreActiveQuestionAnswer();
    }
  }

  get isQuestion12AnsweredYes(): boolean {
    let res = false;

    let question12FormControl = this.profileQuestionsForm.get(this.question12Id + '');

    if (question12FormControl && question12FormControl.value == this.question12YesAnswerId) {
      res = true;
    }

    return res;
  }

  restoreQuestionsVisibility(profileQuestionsModel: ProfileQuestion[]): void {
    if (profileQuestionsModel && profileQuestionsModel.length > 0) {
      let maxQuestionId = 0;
      profileQuestionsModel.forEach(profileQuestionsModel => {
        if (profileQuestionsModel.questionId > maxQuestionId) {
          maxQuestionId = profileQuestionsModel.questionId;
        }
      });

      this.profileThemes.forEach((theme: Theme, indexTheme: number) => {
        theme.questions.forEach((item: Question) => {
          if (item.identifiant === maxQuestionId) {
            this.maxAnsweredThemeNumber = indexTheme + 1;
            //this.activeThemeNumber = indexTheme + 1;
            //this.activeTheme = this.profileThemes[indexTheme];
            //this.answeredTheme = indexTheme;
            this.progressBarService.sendStep(0);
          }
        });
      });
    }
  }

  getAnswerIndex(questionId: number, answerId: number): number {
    let answerIndex;

    this.profileQuestions.filter((question: Question) => {
      return question.identifiant === questionId;
    })[0].reponses.forEach((response: Reponse, index: number) => {
      if (response.identifiant === answerId) {
        answerIndex = index;
      }
    });

    return answerIndex;
  }

  questionsRefactoring(): void {
    // theme7
    const theme7reponsesCount = this.profileThemes[6].questions[0]?.reponses?.length;

    for (let i = 0; i < theme7reponsesCount; i++) {
      const answer = this.profileThemes[6].questions[0].reponses[i].libelle.split('<b>').pop().split('</b>')[0];
      const subAnswer = this.profileThemes[6].questions[0].reponses[i].libelle.split('(').pop().split(')')[0];
      const obj = {
        description: answer,
        perspective: subAnswer
      };
      this.theme7.push(obj);
    }

    // theme10
    const answer = this.profileThemes[9].questions[0].libelle.split('|').pop().split('"')[0];
    const answer2 = this.profileThemes[9].questions[1].libelle.split('|').pop().split('"')[0];
    const titles = {
      acceptance: answer,
      disacceptance: answer2,
    };

    this.theme10.push(titles);

    const theme10QuestionsLength = this.profileThemes[9].questions?.length;

    for (let j = 0; j < theme10QuestionsLength; j += 2) {
      const text1 = this.profileThemes[9].questions[j].libelle.split('<b>').pop().split('</b>')[0];
      const text2 = this.profileThemes[9].questions[j].libelle.split('</b>').pop().split('|')[0];
      const text3 = text1.concat(text2);
      this.theme10.push(text3);
    }
  }

  async answerChanged(theme: Theme, index: number, answerId: number): Promise<void> {
    if (!this.questionChanging) {
      this.activeAnswerIndex = index;

      if (theme.ordre === 7) {
        answerId = theme.questions[0].reponses[index].identifiant;
      }

      this.profileQuestionsForm.controls[theme.questions[0].identifiant].setValue(answerId);

      await this.updateProfileQuestions();

      if (this.activeThemeNumber === 3 || this.activeThemeNumber === 12) {
        this.questionChanging = false;
        return;
      }

      setTimeout(() => {
        this.questionChanging = !this.isIncoherentAnswersForCurrentTheme;

        if (this.questionChanging) {
          this.stepForward();
        }
      })
    }

  }

  async onAnswerOnSpecificTheme(theme: Theme, questionIndex: number, answerIndex: number): Promise<void> {
    const answerId = theme.questions[questionIndex - 1].reponses[answerIndex].identifiant;

    this.profileQuestionsForm.controls[theme.questions[questionIndex - 1].identifiant].setValue(answerId);

    await this.updateProfileQuestions();
  }

  async updateProfileQuestions(): Promise<void> {
    const formModel = this.profileQuestionsForm.getRawValue();
    const profileQuestionModel: ProfileQuestion[] = [];

    Object.keys(formModel).forEach((key: string) => {
      if (formModel[key]) {
        profileQuestionModel.push(new ProfileQuestion({
          questionId: parseInt(key, 10),
          answerId: parseInt(formModel[key], 10)
        }));
      }
    });

    this.clientProfile = await this.customerService.updateQuestionsModel(profileQuestionModel);
  }

  get profileQuestionsModel(): ProfileQuestion[] {
    return this.clientProfile?.profileQuestions as ProfileQuestion[]
  }

  activeBtnClicked(value: boolean, btnToSetValue: any, clickedBtnIfYes: any, clickedBtnIfNo: any): void {
    btnToSetValue = value;

    if (value === true) {
      clickedBtnIfYes = true;
    } else {
      clickedBtnIfNo = true;
    }
  }

  activeBtn1Clicked(value: boolean): void {
    this.activeBtn1 = value;

    if (value === true) {
      this.clickedButton1 = true;
    } else {
      this.clickedButton2 = true;
    }
  }

  activeBtn2Clicked(value: boolean): void {
    this.activeBtn2 = value;
    if (value === true) {
      this.clickedButton3 = true;
    } else {
      this.clickedButton4 = true;
    }
  }

  activeBtn3Clicked(value: boolean): void {
    this.activeBtn3 = value;
    if (value === true) {
      this.clickedButton5 = true;
    } else {
      this.clickedButton6 = true;
    }
  }

  activeBtn4Clicked(value: boolean): void {
    this.activeBtn4 = value;
    if (value === true) {
      this.clickedButton7 = true;
    } else {
      this.clickedButton8 = true;
    }
  }

  activeBtn5Clicked(value: boolean): void {
    this.activeBtn5 = value;
    if (value === true) {
      this.clickedButton9 = true;
    } else {
      this.clickedButton10 = true;
    }
  }

  activeBtn6Clicked(value: boolean): void {
    this.activeBtn6 = value;
    if (value === true) {
      this.clickedButton11 = true;
    } else {
      this.clickedButton12 = true;
    }
  }

  activeBtn7Clicked(value: boolean): void {
    this.activeBtn7 = value;
    if (value === true) {
      this.clickedButton13 = true;
    } else {
      this.clickedButton14 = true;
    }
  }

  activeBtn8Clicked(value: boolean): void {
    this.activeBtn8 = value;
    if (value === true) {
      this.clickedButton15 = true;
    } else {
      this.clickedButton16 = true;
    }
  }

  activeBtn9Clicked(value: boolean): void {
    this.activeBtn9 = value;
    if (value === true) {
      this.clickedButton17 = true;
    } else {
      this.clickedButton18 = true;
    }
  }

  activeBtn10Clicked(value: boolean): void {
    this.activeBtn10 = value;
    if (value === true) {
      this.clickedButton19 = true;
    } else {
      this.clickedButton20 = true;
    }
  }

  get isTheme10NotAnswered(): boolean {
    return (!this.clickedButton1 && !this.clickedButton2) ||
      (!this.clickedButton3 && !this.clickedButton4) ||
      (!this.clickedButton5 && !this.clickedButton6) ||
      (!this.clickedButton7 && !this.clickedButton8) ||
      (!this.clickedButton9 && !this.clickedButton10) ||
      (!this.clickedButton11 && !this.clickedButton12) ||
      (!this.clickedButton13 && !this.clickedButton14) ||
      (!this.clickedButton15 && !this.clickedButton16) ||
      (!this.clickedButton17 && !this.clickedButton18) ||
      (!this.clickedButton19 && !this.clickedButton20)
  }


  get isTheme11NotAnswered(): boolean {
    return this.moneyMarket === undefined || this.bounds === undefined || this.derivatives === undefined;;
  }

  moneyMarketFundsChange(value: boolean): void {
    this.moneyMarket = value;
  }

  boundsChanged(value: boolean): void {
    this.bounds = value;
  }

  derivativesChanged(value: boolean): void {
    this.derivatives = value;
  }

  stepBack(): void {
    this.summary = false;
    this.showRecommendationState = false;
    setTimeout(() => {
      const prevStep = this.activeThemeNumber - 1;
      this.activeTheme = this.profileThemes[prevStep - 1];
      this.progressBarService.sendStep(prevStep - 1);
      this.refactoringActiveThemeQuestion();
      this.activeThemeNumber = prevStep;
      this.activeAnswerIndex = 20;
      this.restoreActiveQuestionAnswer();
    }, 50);
  }

  get isGoToSummaryDisabled(): boolean {
    let disabled = true;

    if (this.activeTheme?.ordre === 12) {
      disabled = this.activeAnswerIndex === null || this.activeAnswerIndex === undefined;
    }

    return disabled;
  }

  stepForward(): void {
    setTimeout(() => {
      this.nextQuestion(this.activeThemeNumber + 1);
      this.activeAnswerIndex = 20;
      this.restoreActiveQuestionAnswer();
      this.questionChanging = false;
    }, 50);
  }

  goToQuestion(num: number): void {
    if (num <= this.maxAnsweredThemeNumber + 1) {
      this.activeTheme = this.profileThemes[num - 1];
      this.progressBarService.sendStep(num - 1);
      this.refactoringActiveThemeQuestion();
      this.activeThemeNumber = num;
      this.restoreActiveQuestionAnswer();
      this.summary = false;
      this.showRecommendationState = false;
    }
  }

  nextQuestion(num: number): void {
    this.activeTheme = this.profileThemes[num - 1];
    this.refactoringActiveThemeQuestion();
    this.activeThemeNumber = num;
    this.answeredTheme = this.activeThemeNumber - 1;
    this.progressBarService.sendStep(this.answeredTheme);
    this.restoreActiveQuestionAnswer();
    if (num > this.maxAnsweredThemeNumber) {
      this.maxAnsweredThemeNumber = num - 1;
    }
  }

  refactoringActiveThemeQuestion(): void {
    this.activeQuestionText = this.activeTheme.questions[0].libelle;
    if (this.activeTheme.ordre === 10 || this.activeTheme.ordre === 11) {
      this.activeQuestionText2 = this.activeTheme.aide.replace('&nbsp;', ' ').replace('<br/>', ' ');
    }
    if (this.activeTheme.ordre === 12) {
      this.activeQuestionText2 = `(${this.activeTheme.questions[0].aide.replace('&nbsp;', ' ').replace('<br/>', ' ')})`
    }
  }

  getProfileColor(): string {
    return getProfileColor(this.recommandation.profil.libelle);
  }

  isIncoherentAnswerByIndex(questionId: number, answerIndex: number): boolean {
    let res = false;

    this.incoherentAnswersIndexesForCurrentTheme.forEach((currentIncoherentItem: CurrentIncoherentItem) => {
      if (currentIncoherentItem.currentIncoherentQuestionId === questionId) {
        res = currentIncoherentItem.currentIncoherentAnswerIndex === answerIndex;
      }
    })

    return res;
  }

  isIncoherentAnswerForSpecTheme(questionId: number): boolean {
    let res = false;

    this.incoherentAnswersIndexesForCurrentTheme.forEach((currentIncoherentItem: CurrentIncoherentItem) => {
      if (currentIncoherentItem.currentIncoherentQuestionId === questionId) {
        res = currentIncoherentItem.currentIncoherentAnswerIndex === 0 || currentIncoherentItem.currentIncoherentAnswerIndex === 1;
      }
    })

    return res;
  }

  getConditionQuestionThemeIndexesSpecificTheme(questionId: number) {
    return this.conditionQuestionThemeIndexesExtended.find((item: ConditionQuestionThemeIndexesExtendedItem) => {
      return item.questionId === questionId;
    })?.indexTheme ?? '';
  }

  get question12YesAnswerExtraText(): string {
    return question12YesAnswerExtraText(this.labelTextPipe);
  }

  get isIncoherentAnswersForCurrentTheme(): boolean {
    let res = false
    this.incoherentAnswersIndexesForCurrentTheme = [];
    this.conditionQuestionThemeIndexes = [];

    let activeThemeQuestionIds = this.activeTheme?.questions?.map((activeThemeQuestion: Question) => {
      return activeThemeQuestion.identifiant;
    });

    let associatedQuestionsForCurrentTheme = this.associatedQuestions?.filter((associatedQuestion: AssociatedQuestion) => {
      return activeThemeQuestionIds?.indexOf(associatedQuestion.inconsistentQuestionId) !== -1;
    });


    if (activeThemeQuestionIds || associatedQuestionsForCurrentTheme?.length === 0) {
      res = false;
    }

    associatedQuestionsForCurrentTheme?.forEach((associatedQuestion: AssociatedQuestion) => {
      let savedInconsistentQuestionConditionQuestion = this.profileQuestionsModel.find((savedQuestionAnswer: ProfileQuestion) => {
        return savedQuestionAnswer.questionId === associatedQuestion.questionId;
      })
      let savedInconsistentQuestion = this.profileQuestionsModel.find((savedQuestionAnswer: ProfileQuestion) => {
        return savedQuestionAnswer.questionId === associatedQuestion.inconsistentQuestionId;
      })

      if (associatedQuestion.answerId === savedInconsistentQuestionConditionQuestion?.answerId && associatedQuestion.inconsistentAnswerId === savedInconsistentQuestion?.answerId) {
        res = true;

        let currentQuestion = this.activeTheme?.questions?.find((question: Question) => {
          return question.identifiant === associatedQuestion.inconsistentQuestionId;
        });

        this.profileThemes.forEach((theme: Theme, indexTheme: number) => {
          theme.questions.forEach((item: Question) => {
            if (item.identifiant === associatedQuestion.questionId) {
              this.conditionQuestionThemeIndexes.push(indexTheme + 1);
              this.conditionQuestionThemeIndexesExtended.push({
                indexTheme: indexTheme + 1,
                questionId: associatedQuestion.inconsistentQuestionId
              } as ConditionQuestionThemeIndexesExtendedItem);
            }
          })
        })

        let currentIncoherentAnswerIndex = currentQuestion?.reponses.findIndex((reponse: Reponse) => {
          return reponse.identifiant === associatedQuestion.inconsistentAnswerId;
        })

        if (!isNaN(currentIncoherentAnswerIndex)) {
          this.incoherentAnswersIndexesForCurrentTheme.push({
            currentIncoherentAnswerIndex: currentIncoherentAnswerIndex,
            currentIncoherentQuestionId: currentQuestion.identifiant
          } as CurrentIncoherentItem);
        }

      }
    })

    return res;
  }



  async goToSummary(): Promise<void> {
    const profileQuestionsModel = this.customerService.getProfileQuestionsModel();
    const clientAge = moment().diff(this.clientProfile.person.dateOfBirth, 'years', false);
    const score = calculateScore(profileQuestionsModel, this.profileQuestions, clientAge, this.questionnaireConfiguration.ageScores);

    if (this.clientProfile.socialNumberB) {
      this.transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.clientProfile.socialNumberB);
    }

    const montantVerse = this.clientProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
    const dateEstimationDepartRetraite = this.isPeri ? this.clientProfile.retirementPlan.expectedRetirementDate : null;
    const flagDurabilite = this.isQuestion12AnsweredYes ? true : false;
    const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(score, !!this.clientProfile.transferContractCode, montantVerse, false, dateEstimationDepartRetraite, flagDurabilite);

    if (recommandationResponse.erreurs?.length > 0) {
      this.bsModalService.show(AlertModalComponent, {
        initialState: {
          type: modalTypes.error,
          title: recommandationResponse.erreurs[0].codeErreur + ' ' + recommandationResponse.erreurs[0].libelleErreur
        },
        class: 'modal-lg',
      });
      return;
    }

    this.clientProfile.score = score;
    this.clientProfile.questionnaireIdentifier = this.questions?.identifiantQuestionnaire;
    this.clientProfile.recommendationText = recommandationResponse.recommandation?.libelle;
    this.clientProfile.profileQuestions = profileQuestionsModel;
    this.customerService.saveProfile(this.clientProfile);

    this.recommandation = recommandationResponse.recommandation;

    if (this.recommandation.id === constants.securitaireProfileLess40KId) { // amountVerse < 40000 (and/or transferPACTE true)
      this.mandatGestion = new MandatGestion({
        id: this.recommandation.id,
        allocation: this.recommandation.produitRecommande.modeGestion[0].poche[0].fondsRepartition
      });
    } else if (this.recommandation.id === constants.securitaireProfileMore40KId) { // amountVerse >= 40000
      this.mandatGestion = new MandatGestion({
        id: this.recommandation.id,
        allocation: this.recommandation.produitRecommande.modeGestion[0].poche[0].fondsRepartition
      });
    } else {
      const recommendedMandatGestionId = this.recommandation.produitRecommande.modeGestion[0].mandatGestion?.id;
      const mandatGestionResponse = await this.wsReferentielMetierService.getMandatGestion(recommendedMandatGestionId);

      if (mandatGestionResponse.erreurs?.length > 0) {
        this.bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: mandatGestionResponse.erreurs[0].codeErreur + ' ' + mandatGestionResponse.erreurs[0].libelleErreur
          },
          class: 'modal-lg',
        });
        return;
      }

      this.mandatGestion = mandatGestionResponse?.mandatGestion.filter((item: MandatGestion2) => {
        return item.id === recommendedMandatGestionId;
      })[0];
    }

    this.summary = true;
    this.activeThemeNumber = 12;
    this.progressBarService.sendStep(11);
    this.dataLoaded = true;
  }
}

export class ConditionQuestionThemeIndexesExtendedItem {
  indexTheme: number;
  questionId: number;
}

export class CurrentIncoherentItem {
  currentIncoherentAnswerIndex: number;
  currentIncoherentQuestionId: number;
}
