import { FormControl } from '@angular/forms';

export function mobileValidator() {
  return (control: FormControl) => {
    const phone = control.value;
    if (phone) {
      if (!/(?:6|06|7|07)[0-9]{8}$/.test(phone)) {
        return {
          mobileValidator: true
        };
      } else {
        return null;
      }
    }

    return null;
  };
}
