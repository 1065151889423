<div class="row mt-5">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="chart-wrapper">
      <div class="piechart-legend">
        <div class="piechart-legend-item" *ngFor="let fond of extendedFonds; let i = index">
          <div class="piechart-legend-item-color" [style.background-color]="getRiskColor(fond.fond.risk)"></div>
          {{ fond.fond.name }}
        </div>
      </div>
      <canvas baseChart [legend]="false" [options]="chartsOptions" width="10rem" height="10rem" [data]="chartPercentages" [labels]="chartLabels" [colors]="chartColors" [chartType]="'doughnut'">
      </canvas>
    </div>
  </div>
</div>
