<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyBeneficiaries" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="mt-5 my-beneficiaries">
  <div class="card">
    <div class="warning">{{ 'BENEFICIARIES.BeneficiariesHelp' | labelText }}</div>
    <app-view-gpa-offer *ngIf="isGpaProposalAvailable" [gpaConfiguration]="gpaConfiguration"></app-view-gpa-offer>

    <div class="row">
      <div class="col">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'Common.MyBeneficiairiesTitle' | labelText }}</h1>
            </div>
          </div>

          <div class="row text-left">
            <div class="col">
              <p class="txt-default mt-2 mb-4" [innerHTML]="subTitleText"></p>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
              <div class="row with-speech">
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-9">
                  <div class="speech">
                    <div class="speech-title">{{ 'BENEFICIARIES.StandardClause' | labelText }}</div>
                    <p>
                      {{ clauseStandardText }}
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                  <div class="table-like">
                    <button
                      class="btn btn-primary radio-label mr-2"
                      type="button"
                      [ngClass]="{ 'active-btn': clauseStandard }"
                      (click)="switchBeneficiareType(constants.beneficiaresTypes.standard.code)"
                    >
                      {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row with-speech">
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-9">
                  <div class="speech">
                    <div class="speech-title">
                      {{ 'Beneficiaries.NominativeClause' | labelText }}
                      <app-tooltip [title]="'Common.Designation' | labelText" [text]="clauseNominativeTooltipText"> </app-tooltip>
                    </div>
                    <p *ngIf="beneficiariesConfiguration">{{ clauseNominativeText }}</p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                  <div class="table-like">
                    <button
                      class="btn btn-primary radio-label mr-2"
                      type="button"
                      [ngClass]="{ 'active-btn': clauseNominative }"
                      (click)="switchBeneficiareType(constants.beneficiaresTypes.nominative.code)"
                    >
                      {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="row with-speech">
                <div class="col-xs-12 col-sm-12 col-md-7 col-lg-9">
                  <div class="speech">
                    <div class="speech-title">
                      {{ 'BENEFICIARIES.BeneficiariesFreeTitle' | labelText }}
                      <app-tooltip [title]="'Common.Designation' | labelText" [text]="clauseLibreTooltipText"> </app-tooltip>
                    </div>
                    <p>{{ 'BENEFICIARIES.BeneficiariesFreeDescription' | labelText }}</p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-3">
                  <div class="table-like">
                    <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': clauseLibre }" (click)="switchBeneficiareType(constants.beneficiaresTypes.libre.code)">
                      {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="legal-line" *ngIf="isBeneficiaryPage && isGpaProposalAvailable">
      {{ beneficiaryGpaOfferText }}
    </p>
  </div>
</div>
