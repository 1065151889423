import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from '../models/validation-error';

export function fractionLimitValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.fraction;
    if (!value) {
      return null;
    }

    if (+value > maxValue) {
      return {
        type: ValidationError.MaxFraction,
        value: maxValue,
        message: `Maximum limit is ${maxValue}%`
      };
    }

    return null;
  };
}
