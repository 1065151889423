import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';
import { getRiskColor, getRiskTextColor } from '@extensions/extensions';
import { Fonds } from '@webapi/MIF.Subscription.Parrot';
import { FondDistribution } from '../my-contract.component';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit, OnChanges {
  @Input() distributionFonds: FormArray;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.distributionFonds && changes?.distributionFonds.currentValue !== undefined) {
      this.distributionFonds = changes.distributionFonds.currentValue;
      this.recalculate();
    }
  }

  distributionElements: DistributionElement[] = [];
  getRiskColor = getRiskColor;
  getRiskTextColor = getRiskTextColor;

  ngOnInit(): void {
    this.distributionFonds.controls.forEach((f: FormArray) => {
      f.valueChanges.subscribe((changes: any) => {
        this.recalculate()
      })
    })
  }

  recalculate(): void {
    this.distributionElements = [];
    let amountOfPercentages = 0;

    this.distributionFonds.controls.forEach((f: FormArray) => {
      let fondDistrib = f.controls as unknown as FondDistribution;

      if (fondDistrib.fraction && fondDistrib.fraction.value && parseFloat(fondDistrib.fraction.value) > 0) {
        let percent = parseFloat(fondDistrib.fraction.value);

        this.distributionElements.push({
          fond: fondDistrib.fond.value,
          fraction: percent,
          left: amountOfPercentages
        } as DistributionElement)

        amountOfPercentages += parseFloat(percent.toFixed(2));
      }
    })

    if (parseFloat(amountOfPercentages.toFixed(2)) > 100) {
      this.distributionElements = [];
    }
  }

}

export class DistributionElement {
  fond: Fonds;
  fraction: number;
  left?: number;
}
