import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '@services/spinner.service';
import { constants } from '@constants/constants';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private spinnerService: SpinnerService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.spinnerService.isLoading.next(this.requests.length > 0);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let delayExpired = false;

    const timeout = setTimeout(() => {
      delayExpired = true;
      this.spinnerService.isLoading.next(true);
    }, constants.autoSpinnerDelay);

    return next.handle(request).pipe(
      catchError((err, caught) => {
        clearTimeout(timeout);
        this.spinnerService.isLoading.next(false);
        return throwError(err);
      }),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          clearTimeout(timeout);
          if (delayExpired) {
            this.spinnerService.isLoading.next(false);
          }
        }
      }),
      finalize(() => {
        clearTimeout(timeout);
        this.removeRequest(request);
        if (delayExpired) {
          this.spinnerService.isLoading.next(false);
        }
      })
    );
  }
}
