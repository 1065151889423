import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';

@Component({
  selector: 'app-subscription-complete',
  templateUrl: './subscription-complete.component.html',
  styleUrls: ['./subscription-complete.component.scss']
})
export class SubscriptionCompleteComponent implements OnInit {

  constructor(
    private router: Router,
    private progressBarService: ProgressBarService
  ) { }

  ngOnInit() {
    this.progressBarService.sendStep(0);
  }

  goToNewSubscription(): void {
    this.router.navigate(['membership-check']);
  }

}
