<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyBeneficiariesNominative" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="mt-5" [class.cemsSummary]="cemsSummary">
  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row" *ngIf="!cemsSummary">
            <div class="col">
              <h1 class="section-title">{{ 'Common.MyBeneficiairiesTitle' | labelText }}</h1>
            </div>
          </div>

          <div class="row text-left" *ngIf="!cemsSummary">
            <div class="col">
              <p class="txt-default mt-2 mb-3">
                {{ clauseNominativeSubTitle }}
              </p>
            </div>
          </div>

          <div class="row text-left" *ngIf="!cemsSummary">
            <div class="col">
              <h5 class="beneficial-subheader">
                {{ 'NOMINATIVE_CLAUSE.YourChoice' | labelText }} <b> {{ 'NOMINATIVE_CLAUSE.NominativeClauseText' | labelText }} </b>
                <a class="red-link ml-4" href="javascript:void(0)" routerLink="/my-beneficiaries"> {{ 'Common.Modify' | labelText }} </a>
              </h5>
            </div>
          </div>

          <div class="row text-left" *ngIf="!cemsSummary && isCims">
            <div class="col">
              <p class="txt-default mb-4">
                {{ 'NOMINATIVE_CLAUSE.SecondBenText' | labelText }}
              </p>
            </div>
          </div>

          <div class="row form-panel">
            <div class="col-12">
              <div *ngFor="let formGroup of beneficiaries?.controls; index as i" class="row">
                <ng-container [formGroup]="formGroup">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <h5 class="beneficiare-title" [ngClass]="{ 'mt-5': i > 0 }">
                          {{ 'NOMINATIVE_CLAUSE.Ben' | labelText }} <span *ngIf="isCims">{{ 'NOMINATIVE_CLAUSE.BenSecondExtraText' | labelText }}</span> n°{{ i + 1 }}:
                        </h5>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-combobox-form-control [parentForm]="formGroup" [controlName]="'civility'" [title]="'COMMON.Civility' | labelText"> </app-combobox-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-name-form-control [parentForm]="formGroup" [controlName]="'firstName'" [title]="'Common.Nom' | labelText"> </app-name-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-name-form-control [parentForm]="formGroup" [controlName]="'lastName'" [title]="'Common.Prenom' | labelText"> </app-name-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-dropdown-form-control [parentForm]="formGroup" [controlName]="'relation'" [title]="'NOMINATIVE_CLAUSE.Link' | labelText"> </app-dropdown-form-control>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-2">
                        <app-number-form-control
                          [parentForm]="formGroup"
                          [controlName]="'allocatedShare'"
                          [title]="'NOMINATIVE_CLAUSE.ShareAllocated' | labelText"
                          [ngClass]="{ 'error-new-row-991': cemsSummary }"
                        >
                        </app-number-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-date-form-control [parentForm]="formGroup" [controlName]="'dob'" [title]="'Common.Dob' | labelText" [minDate]="benMinDate" [maxDate]="benMaxDate"> </app-date-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-dropdown-form-control [parentForm]="formGroup" [controlName]="'countryOfBirth'" [title]="'Common.PlaceOfBirth' | labelText"> </app-dropdown-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <span class="separated-title mt-3"> {{ 'Common.PlaceOfBirthZip' | labelText }} </span>

                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-2">
                        <app-zipcode-form-control [parentForm]="formGroup" [controlName]="'postalCodeOfBirth'" [title]="" class="no-title"> </app-zipcode-form-control>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-name-form-control [parentForm]="formGroup" [controlName]="'cityOfBirth'" [title]="" class="no-title"> </app-name-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-dropdown-form-control [parentForm]="formGroup" [controlName]="'nationality'" [title]="'Common.Nationality' | labelText"> </app-dropdown-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-dropdown-form-control [parentForm]="formGroup" [controlName]="'country'" [title]="'Common.Country' | labelText"> </app-dropdown-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <span class="separated-title mt-3"> {{ 'Common.PlaceOfResidenceZip' | labelText }} </span>

                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-2">
                        <app-zipcode-form-control [parentForm]="formGroup" [controlName]="'postalCode'" [title]="" class="no-title"> </app-zipcode-form-control>
                      </div>

                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-name-form-control [parentForm]="formGroup" [controlName]="'city'" [title]="" class="no-title"> </app-name-form-control>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                        <app-name-with-digits-form-control [parentForm]="formGroup" [controlName]="'adress'" [title]="'Common.AdressResidence' | labelText"> </app-name-with-digits-form-control>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="afterFormButtons">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 mb-4">
              <button type="button" class="btn btn-blue w100" [disabled]="beneficiariesCount === maxNumberOfBeneficiaries" (click)="addBeneficiare()">
                <fa-icon [icon]="faPlus"></fa-icon>
                {{ 'NOMINATIVE_CLAUSE.AddBeneficiary' | labelText }}
              </button>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
              <button *ngIf="isPopBneficiariesExist" type="button" class="btn btn-blue w100" (click)="popBeneficiare()">
                <fa-icon [icon]="faMinus"></fa-icon>
                {{ 'NOMINATIVE_CLAUSE.LessBeneficiary' | labelText }}
              </button>
            </div>
          </div>
        </div>

        <div class="mt-5">
          <mat-checkbox class="big-text" [(ngModel)]="isEqualParts" (change)="isEqualPartsChanged($event)">{{ 'NOMINATIVE_CLAUSE.SharedEquallyBetweenHeirs' | labelText }} </mat-checkbox>
          <app-tooltip [text]="'NOMINATIVE_CLAUSE.HeirOfDifferentLevel' | labelText"> </app-tooltip>
        </div>
      </div>
    </div>
  </div>

  <div class="valider-button-block mb-5" *ngIf="!cemsSummary">
    <button type="button" class="btn btn-blue valider" (click)="onSubmit()">
      {{ 'Common.Validate' | labelText }}
    </button>
  </div>
</div>
