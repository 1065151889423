import { FormControl } from '@angular/forms';

export function zipCodeValidator() {
    return (control: FormControl) => {
        const zipCode = control.value;

        if (zipCode) {
            if (zipCode.length < 5) {
                return {
                    zipCodeValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
