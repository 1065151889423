import { constants } from '../../shared/constants/constants';

export const collections = {
  step: {
    myProfile: { value: 'MON PROFIL' },
    simpleQuestion: { value: 'QUESTIONNAIRE SIMPLIFIÉ' },
    qfdQuestion: { value: 'QUESTIONNAIRE' },
    myContract: { value: 'MON CONTRAT' },
    myTarif: { value: 'MON TARIF' },
    beneficiaries: { value: 'MES BÉNÉFICIAIRES' },
    myDetails: { value: 'MES COORDONNÉES' },
    signature: { value: 'SIGNATURE' },
    recapitulatif: { value: 'RÉCAPITULATIF' }
  },
  yesNo: [
    {
      name: constants.yesAnswer,
      description: 'Oui'
    },
    {
      name: constants.noAnswer,
      description: 'Non'
    }
  ],
  yesNoBoolean: [
    {
      name: true,
      description: 'Oui'
    },
    {
      name: false,
      description: 'Non'
    }
  ],
  gfdPrivilegiers: [
    {
      description: constants.gfdPrivilegiers.financial.name,
      code: constants.gfdPrivilegiers.financial.code
    },
    {
      description: constants.gfdPrivilegiers.financialAndOrganisation.name,
      code: constants.gfdPrivilegiers.financialAndOrganisation.code
    }
  ],
  gfdLifetimeTypes: [
    {
      id: 1,
      periodicitePaiement: 4,
      name: 'Viagère',
      description: 'Une même cotisation répartie mensuellement, durant toute votre vie.'
    },
    {
      id: 2,
      periodicitePaiement: 2,
      name: 'Temporaire sur une durée de 5 ans',
      description: 'Une même cotisation répartie mensuellement, pendant 5 ans.'
    },
    {
      id: 3,
      periodicitePaiement: 3,
      name: 'Temporaire sur une durée de 10 ans',
      description: 'Une même cotisation répartie mensuellement, pendant 10 ans.'
    },
    {
      id: 4,
      periodicitePaiement: 1,
      name: 'Cotisation unique',
      description: 'Une seule cotisation, par prélèvement unique du montant total du capital garanti.'
    }
  ],
  closedIdsEtat: [6, 8],
  gfdLifetimeTypesData: {
    viagere: 4,
    ans5: 2,
    ans10: 3,
    cotisationUnique: 1
  },
  relation: [
    {
      id: 1,
      name: 'EPOUX',
      description: 'Époux(se)'
    },
    {
      id: 2,
      name: 'CONCUBIN',
      description: 'Concubin(e)'
    },
    {
      id: 3,
      name: 'PARTENAIRE-DE-PACS',
      description: 'Partenaire de PACS'
    },
    {
      id: 4,
      name: 'PARENT',
      description: 'Parent(e)'
    },
    {
      id: 5,
      name: 'ENFANT',
      description: 'Enfant'
    },
    {
      id: 6,
      name: 'FRERE',
      description: 'Frère(soeur)'
    },
    {
      id: 7,
      name: 'GRAND-PERE',
      description: 'Grand-père(mère)'
    },
    {
      id: 8,
      name: 'PETIT-FILS',
      description: 'Petit(e)-fils(fille)'
    },
    {
      id: 9,
      name: 'ONCLE',
      description: 'Oncle(tante)'
    },
    {
      id: 10,
      name: 'COUSIN',
      description: 'Cousin(e)'
    },
    {
      id: 11,
      name: 'FILLEUL',
      description: 'Filleul(e)'
    },
    {
      id: 12,
      name: 'AUTRE-LIEN',
      description: 'Autre lien'
    }
  ],
  relationCimsPrimaryBen: [
    {
      id: 1,
      name: 'Fils/Fille',
      description: 'Fils/Fille'
    },
    {
      id: 2,
      name: 'Petits-fils/Petite-fille',
      description: 'Petits-fils/Petite-fille'
    },
    {
      id: 3,
      name: 'Neveu/Nièce',
      description: 'Neveu/Nièce'
    },
    {
      id: 4,
      name: 'Autre lien',
      description: 'Autre lien'
    }
  ],
  addressConfirmationDocumentTypes: [
    {
      id: 2,
      name: 'JUSTIFICATIF_DOMICILE',
      description: 'Justificatif de domicile'
    }
  ],
  bancDetailsDocumentTypes: [
    {
      id: 3,
      name: 'RIB',
      description: 'RIB'
    }
  ],
  firstIdentificationDocumentTypes: [
    {
      id: 1,
      name: 'CNI',
      description: 'Carte nationale d’identité'
    },
    {
      id: 10,
      name: 'PASSEPORT',
      description: 'Passeport'
    },
    {
      id: 11,
      name: 'CARTE_SEJOUR',
      description: 'Carte de séjour'
    }
  ],
  officerOrPensionerTypes: [
    {
      id: 0,
      name: 'SNCF',
      description: 'SNCF'
    },
    {
      id: 1,
      name: 'RATP',
      description: 'RATP'
    }
  ],
  originOfContractFundsItems: [
    {
      id: 0,
      name: 'Revenus',
      description: 'Revenus'
    },
    {
      id: 1,
      name: 'Héritage, succession',
      description: 'Héritage, succession'
    },
    {
      id: 2,
      name: 'Vente Immobilière',
      description: 'Vente Immobilière'
    }
  ],
  profileDocuments: {
    firstIdentificationDocument: { typeId: 0 },
    secondIdentificationDocument: { typeId: 1 },
    addressConfirmationDocument: { typeId: 2 },
    ribDocument: { typeId: 3 }
  },
  proofOfFundDocuments: {
    surrenderLifeInsurancePolicy: { typeId: 0 },
    savings: { typeId: 1 },
    succession: { typeId: 2 },
    estateSale: { typeId: 3 },
    cashflow: { typeId: 4 },
    other: { typeId: 5 }
  },
  performance: {
    prevYear: { id: 1, label: 'Année passée' },
    curYear: { id: 2, label: 'Année en cours' },
    year1: { id: 3, label: '1 an glissant' },
    year3: { id: 4, label: '3 ans glissants' },
    year5: { id: 5, label: '5 ans glissants' }
  },
  performances: [
    { id: 1, label: 'Année passée' },
    { id: 2, label: 'Année en cours' },
    { id: 3, label: '1 an glissant' },
    { id: 4, label: '3 ans glissants' },
    { id: 5, label: '5 ans glissants' }
  ],
  performancesUc: [
    { id: 2, label: 'Année en cours' },
    { id: 3, label: '1 an glissant' },
    { id: 4, label: '3 ans glissants' },
    { id: 5, label: '5 ans glissants' }
  ],
  performancesEuro: [{ id: 1, label: 'Année passée' }],
  gpaCapitals: [
    { id: 1, value: 1000, label: '1 000 €' },
    { id: 2, value: 5000, label: '5 000 €' },
    { id: 3, value: 10000, label: '10 000 €' },
    { id: 4, value: 15000, label: '15 000 €' },
    { id: 5, value: 50000, label: '50 000 €' },
    { id: 6, value: 100000, label: '100 000 €' },
    { id: 7, value: 150000, label: '150 000 €' }
  ],
  conditionUserTypes: [
    { id: 1, label: 'Client' },
    { id: 2, label: 'Prospect' }
  ],
  userTypes: [
    { id: 1, label: 'Prospect' },
    { id: 2, label: 'Sociétaire ne possédant pas déjà un contrat de prévoyance GPA' }
  ],
  ageTypes: [
    { id: 1, label: 'jour(s)' },
    { id: 2, label: 'heure(s)' },
    { id: 3, label: 'minute(s)' }
  ],
  withdrawalTypes: [
    { id: 1, label: 'Création de la souscription' },
    { id: 2, label: 'Dernière activité de la souscription' }
  ],
  conditionSponsors: [
    { id: 1, label: 'Sélectionné' },
    { id: 2, label: 'Non sélectionné' }
  ],
  conditionSavingsSecurityProfiles: [
    { id: 1, label: 'Profil sécuritaire' },
    { id: 2, label: 'Autre profil' }
  ],
  conditionSavingsTransferPacts: [
    { id: 1, label: `L’utilisateur possède un contrat éligible` },
    { id: 2, label: `L’utilisateur a choisi un transfert Pacte` },
    { id: 3, label: `L’utilisateur n'a pas choisi un transfert Pacte` }
  ],
  garanteex2x3Types: [
    { id: 1, label: 'Sélectionné' },
    { id: 2, label: 'Non sélectionné' }
  ],
  sponsorsipTypes: [
    { id: 1, label: 'L’utilisateur a sélectionné la valeur “Parrainage”' },
    { id: 2, label: 'L’utilisateur a sélectionné une valeur différente de “Parrainage”' },
    { id: 3, label: 'L’utilisateur n’a sélectionné aucune valeur dans ce champs' }
  ],
  footerLinksTypes: [
    { label: 'URL', value: 'URL' },
    { label: 'Document', value: 'DOCUMENT' }
  ],
  messages: {
    required: 'Merci de renseigner ce champ',
    requiredValidDate: 'Merci de renseigner ce champs avec une date valide',
    politicallyPersonTypeNumberError: 'Merci de renseigner ce champ',
    ratpNumberError: 'Le matricule RATP est composé de 6 chiffres',
    sponsorNumberError: 'Le n° de parrain saisi n’est pas valide  (n° composé de 7 chiffres).',
    memberNumberError: 'Le n° de membre correspondant saisi n’est pas valide (n° composé de 1 à 3 chiffre(s))',
    mifLorsCodeError: 'Le code conseiller saisi n’est pas valide (code composé de 4 caractères).',
    freePaymentMinError: 'Le montant minimum du versement libre est de AMOUNT_VALUE €',
    scheduledPaymentMinError: 'Le montant minimum des versements programmés est de AMOUNT_VALUE €',
    missingArgMessage: 'Veuillez remplir les informations manquantes',
    promoCodeIncorrectMessage: 'Le code promotionnel saisi n’est pas valide.'
  }
};
