<app-i-advize *ngIf="userData" [pageType]="ProfileStepName.MyProfile" [clientProfile]="userData"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="my-profile">
  <form [formGroup]="paymentPlanForm" *ngIf="paymentPlanForm">
    <div class="text-center mt-5 mb-5">
      <h5>{{ 'PAYMENTS.YouPaymentPlan' | labelText }}</h5>
    </div>
    <div>
      <div class="card">
        <div class="card-body p-left">
          <app-view-offer [clientProfile]="userDataForViewOffer" [pageName]="constants.pages.payments.name"></app-view-offer>

          <div class="row" *ngIf="isShowDoTransfer">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-10">
              <app-combobox-form-control
                (onChange)="doTransferContractChange($event)"
                [parentForm]="paymentPlanForm"
                [controlName]="'doTransferContract'"
                [title]="'PAYMENTS.DoYouTransferMifContract' | labelText"
              >
              </app-combobox-form-control>
            </div>
          </div>

          <div class="transfertFourgousContainer" *ngIf="dataRestored && socialNumberB && isDoTransfer">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <app-dropdown-form-control
                  (onChange)="transferContractChange($event)"
                  [parentForm]="paymentPlanForm"
                  [controlName]="'contract'"
                  [title]="'PAYMENTS.ChooseMifContractToTransfer' | labelText"
                >
                </app-dropdown-form-control>
              </div>
            </div>

            <div class="row mt-3" *ngIf="selectedContract">
              <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8 fw-600">
                {{ 'Payments.PaymentDetailAccountTemplate1' | labelText }} {{ selectedContract.numeroContrat }}, {{ 'Payments.PaymentDetailAccountTemplate2' | labelText }}
                {{ selectedContract.dateEffet }}, {{ 'Payments.PaymentDetailAccountTemplate3' | labelText }} {{ selectedContract.valeurRachat | currency : 'EUR' : 'symbol' : null : 'fr' }}
                {{ 'Payments.PaymentDetailAccountTemplate4' | labelText }}
              </div>
            </div>

            <div class="row mt-2 mb-3">
              <div class="col-xs-12 col-sm-7 col-md-8 col-lg-8 recap recap-my-profil">
                <ul class="list-arrows">
                  <li>
                    {{ 'PAYMENTS.PaymentInstructionTransfert11' | labelText }} <span class="bold-span">{{ 'PAYMENTS.PaymentInstructionTransfert12' | labelText }}</span>
                  </li>
                  <li>{{ 'PAYMENTS.PaymentInstructionTransfert2' | labelText }}</li>
                  <li>
                    {{ 'PAYMENTS.PaymentInstructionTransfert31' | labelText }} <span class="bold-span">{{ 'PAYMENTS.PaymentInstructionTransfert32' | labelText }}</span>
                  </li>
                  <li>{{ 'PAYMENTS.PaymentInstructionTransfert4' | labelText }}</li>
                  <li>
                    <span class="bold-span">{{ 'PAYMENTS.PaymentInstructionTransfert5' | labelText }}</span>
                  </li>
                  <li>{{ 'PAYMENTS.PaymentInstructionTransfert6' | labelText }}</li>
                </ul>

                <a href="javascript:void(0)" (click)="onTermsOfTransferClick()" class="link-like gray">{{ 'PAYMENTS.PaymentInstructionTransfertLink' | labelText }}</a>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <app-combobox-form-control
                  (onChange)="doFreePaymentChange($event)"
                  [parentForm]="paymentPlanForm"
                  [controlName]="'doFreePayment'"
                  [title]="'Payments.DoYouWantToMakeAPayment' | labelText"
                >
                </app-combobox-form-control>
              </div>
            </div>

            <div class="row mb-3" *ngIf="isDoFreePayment">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-5">
                <app-number-form-control [parentForm]="paymentPlanForm" [controlName]="'freePayment'" [title]="'Payments.FreePaymentTitle' | labelText" [fraisInclus]="true"> </app-number-form-control>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" *ngIf="paymentPlanForm.controls['freePayment'].errors?.max">
                <div class="error-block">
                  <span class="text">
                    {{ 'Common.YouCannotSubscribeOnline' | labelText }}
                  </span>
                </div>
              </div>
              <div
                class="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                *ngIf="!!paymentPlanForm.controls['freePayment'].value && !paymentPlanForm.controls['freePayment'].errors?.min && paymentPlanForm.controls['freePayment'].value < 1000"
              >
                <div class="error-block">
                  <span class="text"> {{ minAmountErrorBlockText }} </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-3" *ngIf="!isDoTransfer || !socialNumberB || contractTransferConfiguration.authorizeFullPayment === false" [class.hidden]="isFirstPaymentHidden && !isPeri">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-5">
              <app-number-form-control [parentForm]="paymentPlanForm" [controlName]="'firstPayment'" [title]="'PAYMENTS.FisrtPaymentAmount' | labelText" [fraisInclus]="true">
              </app-number-form-control>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4" *ngIf="paymentPlanForm.controls['firstPayment'].errors?.max">
              <div class="error-block">
                <span class="text"> {{ 'Common.YouCannotSubscribeOnline' | labelText }} </span>
              </div>
            </div>
            <div
              class="col-xs-12 col-sm-5 col-md-5 col-lg-4"
              *ngIf="!!paymentPlanForm.controls['firstPayment'].value && !paymentPlanForm.controls['firstPayment'].errors?.min && paymentPlanForm.controls['firstPayment'].value < 1000"
            >
              <div class="error-block">
                <span class="text"> {{ minAmountErrorBlockText }} </span>
              </div>
            </div>
          </div>

          <!-- end no contracts or do not transfer or no socialNumberB -->

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-6">
              <app-combobox-form-control (onChange)="doScheduledPaymentChange($event)" [parentForm]="paymentPlanForm" [controlName]="'doScheduledPayment'" [title]="getDoScheduledPaymentTitle">
              </app-combobox-form-control>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4" *ngIf="isDoScheduledPayment">
              <div class="error-block noScheduledPaymentsForScpi">
                <span class="text">
                  {{ 'PAYMENTS.NoScheduledPaymentsForScpi' | labelText }}
                </span>
              </div>
            </div>
          </div>

          <div class="row mt-1 mb-5" *ngIf="isDoScheduledPayment">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-5">
              <app-number-form-control [parentForm]="paymentPlanForm" [controlName]="'schedulePayment'" [title]="'PAYMENTS.SchedulePaymentTitle' | labelText" [fraisInclus]="true">
              </app-number-form-control>
            </div>
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4" *ngIf="paymentPlanForm.controls['schedulePayment'].errors?.max">
              <div class="error-block">
                <span class="text"> {{ 'Common.YouCannotSubscribeOnline' | labelText }} </span>
              </div>
            </div>
          </div>

          <div *ngIf="isPeri" class="deduction-scheme mb-4">
            <p class="title">
              {{ 'Payments.TaxDreductionSchemeTitle' | labelText }}
            </p>
            <span class="text">
              <p>
                {{ 'Payments.TaxDreductionSchemeText1' | labelText }}
                <span class="fw-700">
                  {{ 'Payments.TaxDreductionSchemeText2' | labelText }}
                </span>
              </p>
              <p>
                {{ 'Payments.TaxDreductionSchemeText3' | labelText }}
              </p>
              <p class="fw-700">
                {{ 'Payments.TaxDreductionSchemeText4' | labelText }}
              </p>
              <p>
                <app-checkbox-form-control [title]="'Payments.HasTaxDeductionTitle' | labelText" [parentForm]="paymentPlanForm" [controlName]="'hasTaxDeduction'"> </app-checkbox-form-control>
              </p>
              <p>
                {{ 'Payments.TaxDreductionSchemeText5' | labelText }}
              </p>
            </span>
          </div>

          <div class="row text-center">
            <div class="col">
              <div class="valider-button-block">
                <button *ngIf="!isPeri" type="submit" (click)="submitPaymentPlan()" class="btn btn-blue valider" [disabled]="this.paymentPlanForm.invalid">
                  <span>
                    {{ 'COMMON.Validate' | labelText }}
                  </span>
                </button>
                <button *ngIf="isPeri" type="submit" (click)="submitPeri()" class="btn btn-blue valider" [disabled]="this.paymentPlanForm.invalid">
                  <span>
                    {{ 'COMMON.Continue' | labelText }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- isMember: {{ this.userData.isMember ? 'true' : 'false' }} <br />
          isSelectedContract: {{ this.selectedContract ? 'true' : 'false' }} <br />
          isTransferableContracts: {{ isTransferableContracts ? 'true' : 'false' }} <br />
          transferContractCode: {{ this.userData.transferContractCode ? this.userData.transferContractCode : 'no code' }}<br />
          isDoFreePayment: {{ isDoFreePayment ? 'true' : 'false' }}<br />
          contractTransferConfiguration.authorizeFullPayment: {{ this.contractTransferConfiguration.authorizeFullPayment ? 'true' : 'false' }}<br /> -->
        </div>
      </div>
    </div>
  </form>
</div>
