import { Pipe, PipeTransform } from '@angular/core';
import { PerformaceYearsCollection } from '@shared/models/performance';

@Pipe({
  name: 'performanceYear'
})
export class PerformanceYearPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }

    const year = PerformaceYearsCollection.find((item: any) => {
      return item.id === value;
    });

    return year ? year.title : '';
  }
}
