import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneAsterisks'
})
export class PhoneAsterisksPipe implements PipeTransform {
  constructor() {}

  transform(value: string, showPhone: boolean): string {
    if (!showPhone) {
      return value.replace(/\d(?=\d{4})/g, '*');
    }
    return value;
  }
}
