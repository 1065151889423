import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { constants } from '@shared/constants/constants';
import { Erreur10 } from '@webapi/MIF.Subscription.Parrot';
import { WsSepaliaService } from '@webapi/services/ws-sepalia.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AsyncIbanValidator implements AsyncValidator {
  constructor(private wsSepaliaService: WsSepaliaService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    return this.wsSepaliaService.getBICFromIBANObservable(control.value).pipe(
      map(response => {
        // const bic = response.BIC;
        // const bIcErrors = response.erreurs;
        const isBicCheckingServiceAavailable =
          response.erreurs.findIndex((err: Erreur10) => {
            return err.codeErreur === constants.getBicFromIbanServiceUnavailableCode;
          }) === -1;

        if (isBicCheckingServiceAavailable) {
          setTimeout(() => {
            control.markAsTouched();
            control.updateValueAndValidity();
          }, 1);
          return { ibanValidator: true };
        } else {
          control.setErrors(null);
          control.updateValueAndValidity();
          return null;
        }
      }),

      catchError(() => of(null))
    );
  }
}
