import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import {
  Document3,
  DocumentsProduitSignatureSouscriptionEnLigneRequest,
  DocumentsProduitSignatureSouscriptionEnLigneResponse,
  Fonds,
  GetDocumentsFichierRequest,
  GetDocumentsFichierResponse,
  GetDocumentsProduitRequest,
  GetDocumentsProduitResponse,
  GetRecommandationRequest,
  GetRecommandationResponse,
  GetReglesMetierProduitRequest,
  GetReglesMetierProduitResponse,
  GetRUMRequest,
  GetRUMResponse,
  MandatGestionRequest,
  MandatGestionResponse,
  Produit3
} from './../MIF.Subscription.Parrot';
import { constants } from '@constants/constants';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ListeReferencesMetierRequest, ListeReferencesMetierResponse, Informations, Client } from '../MIF.Subscription.Parrot';
import moment from 'moment';
import { MinAmountPerMode } from '@models/min-amount-per-mode';
import { getCurrentProductType } from '@extensions/extensions';
import { CodeProduitType } from '@models/code-produit-type';

@Injectable({
  providedIn: 'root'
})
export class WsReferentielMetierService {
  private originOfContractFundsItems: Informations[];
  private skipedoriginOfContractFundsItems: any[] = [constants.notCommunicatedAnswer, constants.othersAnswer, constants.otherAnswerFR];
  private allCemsReferentielMetiers: Produit3[];
  private allGpaReferentielMetiers: any;
  private allGfdReferentielMetiers: any;
  private allPeriReferentielMetiers: any;
  private allCimsReferentielMetiers: any;
  private pivotAge: number;
  private minAmountPerMode: MinAmountPerMode;
  private mandatGestionDataWithDocs: any = {};
  private mandatGestionDataWithoutDocs: any = {};
  private produitSignatureSouscriptionEnLigneDocuments: any = {};
  private originOfContractFundsOtherId: number;
  private gpaCapitalLimits: GpaCapitalData;
  private gfdCapitalLimits: GfdCapitalData;
  public documentsProduitFullBody: import('./../MIF.Subscription.Parrot').Document;

  constructor(private webApi: Client, private http: HttpClient) {}

  public async initialize(): Promise<void> {
    try {
      switch (environment.orgId) {
        case constants.orgId.cems.value: {
          await this.getAllCemsReferentielMetier();
          break;
        }
        case constants.orgId.gpa.value: {
          await this.getAllCemsReferentielMetier();
          await this.getAllGpaReferentielMetier();
          break;
        }
        case constants.orgId.gfd.value: {
          await this.getAllCemsReferentielMetier();
          await this.getAllGfdReferentielMetier();
          break;
        }
        case constants.orgId.peri.value: {
          await this.getAllCemsReferentielMetier();
          await this.getAllPeriReferentielMetier();
          break;
        }
        case constants.orgId.cims.value: {
          await this.getAllCemsReferentielMetier();
          await this.getAllCimsReferentielMetier();
          break;
        }
        default: {
          await this.getAllCemsReferentielMetier();
          break;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  private async getAllCemsReferentielMetier(): Promise<Produit3[]> {
    if (this.allCemsReferentielMetiers) {
      return new Promise(resolve => {
        resolve(this.allCemsReferentielMetiers);
      });
    } else {
      const request = new GetReglesMetierProduitRequest({
        codeProduit: CodeProduitType.CEMS,
        lireDocuments: false,
        idCanal: 4,
        idDemarche: 1
      });

      return this.webApi
        .getReglesMetierProduit(request)
        .pipe(
          map((response: GetReglesMetierProduitResponse) => {
            this.allCemsReferentielMetiers = response.produit;
            return this.allCemsReferentielMetiers;
          })
        )
        .toPromise();
    }
  }

  private async getAllGpaReferentielMetier(): Promise<any> {
    if (this.allGpaReferentielMetiers) {
      return new Promise(resolve => {
        resolve(this.allGpaReferentielMetiers);
      });
    } else {
      const request = new GetReglesMetierProduitRequest({
        codeProduit: CodeProduitType.ATDN,
        idTypeProduit: 2,
        lireDocuments: false,
        idCanal: 4,
        idDemarche: 1
      });

      return this.webApi
        .getReglesMetierProduit(request)
        .pipe(
          map((response: GetReglesMetierProduitResponse) => {
            this.allGpaReferentielMetiers = response.produit;
            return this.allGpaReferentielMetiers;
          })
        )
        .toPromise();
    }
  }

  private async getAllGfdReferentielMetier(): Promise<any> {
    if (this.allGfdReferentielMetiers) {
      return new Promise(resolve => {
        resolve(this.allGfdReferentielMetiers);
      });
    } else {
      const request = new GetReglesMetierProduitRequest({
        codeProduit: CodeProduitType.GFD,
        idTypeProduit: 2,
        lireDocuments: false,
        idCanal: 4,
        idDemarche: 1
      });

      return this.webApi
        .getReglesMetierProduit(request)
        .pipe(
          map((response: GetReglesMetierProduitResponse) => {
            this.allGfdReferentielMetiers = response.produit;
            return this.allGfdReferentielMetiers;
          })
        )
        .toPromise();
    }
  }

  private async getAllPeriReferentielMetier(): Promise<any> {
    if (this.allPeriReferentielMetiers) {
      return new Promise(resolve => {
        resolve(this.allPeriReferentielMetiers);
      });
    } else {
      const request = new GetReglesMetierProduitRequest({
        codeProduit: CodeProduitType.PERI,
        lireDocuments: false,
        idCanal: 4,
        idDemarche: 1
      });

      return this.webApi
        .getReglesMetierProduit(request)
        .pipe(
          map((response: GetReglesMetierProduitResponse) => {
            this.allPeriReferentielMetiers = response.produit;
            return this.allPeriReferentielMetiers;
          })
        )
        .toPromise();
    }
  }

  private async getAllCimsReferentielMetier(): Promise<any> {
    if (this.allCimsReferentielMetiers) {
      return new Promise(resolve => {
        resolve(this.allCimsReferentielMetiers);
      });
    } else {
      const request = new GetReglesMetierProduitRequest({
        codeProduit: CodeProduitType.CIMS,
        lireDocuments: false,
        idCanal: 4,
        idDemarche: 1
      });

      return this.webApi
        .getReglesMetierProduit(request)
        .pipe(
          map((response: GetReglesMetierProduitResponse) => {
            this.allCimsReferentielMetiers = response.produit;
            return this.allCimsReferentielMetiers;
          })
        )
        .toPromise();
    }
  }

  public async getPivotAge(): Promise<number> {
    await this.getAllGpaReferentielMetier();

    if (this.pivotAge) {
      return new Promise<number>(resolve => {
        resolve(this.pivotAge);
      });
    }

    const regles = this.allGpaReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.ATDN)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const age = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGpa.PIVOT_AGE;
      })[0].valeur,
      10
    );
    this.pivotAge = age;

    return new Promise<number>(resolve => {
      resolve(this.pivotAge);
    });
  }

  public async getGpaCapitalLimits() {
    await this.getAllGpaReferentielMetier();

    if (this.gpaCapitalLimits) {
      return new Promise<GpaCapitalData>(resolve => {
        resolve(this.gpaCapitalLimits);
      });
    }

    const regles = this.allGpaReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.ATDN)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const min = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGpa.CAPITAL_MIN_BASE;
      })[0].valeur,
      10
    );
    const max = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGpa.CAPITAL_MAX_BASE;
      })[0].valeur,
      10
    );
    const step = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGpa.CAPITAL_STEP;
      })[0].valeur,
      10
    );
    const maxX2X3 = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGpa.CAPITAL_MAX_BASE_TO_X2X3;
      })[0].valeur,
      10
    );
    this.gpaCapitalLimits = { min, max, step, maxX2X3 } as GpaCapitalData;

    return new Promise<GpaCapitalData>(resolve => {
      resolve(this.gpaCapitalLimits);
    });
  }

  public async getGfdCapitalLimits() {
    await this.getAllGfdReferentielMetier();

    if (this.gfdCapitalLimits) {
      return new Promise<GfdCapitalData>(resolve => {
        resolve(this.gfdCapitalLimits);
      });
    }

    const regles = this.allGfdReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.GFD)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const min = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGfd.CAPITAL_MIN_BASE;
      })[0].valeur,
      10
    );
    const max = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGfd.CAPITAL_MAX_BASE;
      })[0].valeur,
      10
    );
    const step = parseInt(
      regles.filter((e: any) => {
        return e.code?.trim() === RegleCodesGfd.CAPITAL_STEP;
      })[0].valeur,
      10
    );

    this.gfdCapitalLimits = { min, max, step } as GfdCapitalData;

    return new Promise<GfdCapitalData>(resolve => {
      resolve(this.gfdCapitalLimits);
    });
  }

  public async getFundsRules(): Promise<Produit3[]> {
    let response = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].fonds;

    switch (environment.orgId) {
      case constants.orgId.cems.value: {
        response = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].fonds;
        break;
      }
      case constants.orgId.cims.value: {
        response = this.allCimsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CIMS)[0].fonds;
        break;
      }
      case constants.orgId.peri.value: {
        response = this.allPeriReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.PERI)[0].fonds;
        break;
      }
      case constants.orgId.gfd.value: {
        response = this.allGfdReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.GFD)[0].fonds;
        break;
      }
      case constants.orgId.gpa.value: {
        response = this.allGpaReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.ATDN)[0].fonds;
        break;
      }
    }

    return new Promise<Produit3[]>(resolve => {
      resolve(response);
    });
  }

  public async getDobLimits() {
    let regles: any;
    let minAgeCode: any;
    let maxAgeCode: any;

    switch (environment.orgId) {
      case constants.orgId.cems.value: {
        regles = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.ELIGIBILITE)[0].regle;
        minAgeCode = RegleCodesCems.MIN_AGE;
        maxAgeCode = RegleCodesCems.MAX_AGE;
        break;
      }
      case constants.orgId.gpa.value: {
        regles = this.allGpaReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.ATDN)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;
        minAgeCode = RegleCodesGpa.MIN_AGE;
        maxAgeCode = RegleCodesGpa.MAX_AGE;
        break;
      }
      case constants.orgId.gfd.value: {
        regles = this.allGfdReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.GFD)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;
        minAgeCode = RegleCodesGfd.MIN_AGE;
        maxAgeCode = RegleCodesGfd.MAX_AGE;
        break;
      }
      case constants.orgId.peri.value: {
        regles = this.allPeriReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.PERI)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;
        minAgeCode = RegleCodesPeri.MIN_AGE;
        maxAgeCode = RegleCodesPeri.MAX_AGE;
        break;
      }
      case constants.orgId.cims.value: {
        regles = this.allCimsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CIMS)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.ELIGIBILITE)[0].regle;
        minAgeCode = RegleCodesCims.MIN_AGE;
        maxAgeCode = RegleCodesCims.MAX_AGE;
        break;
      }
      default: {
        regles = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.ELIGIBILITE)[0].regle;
        minAgeCode = RegleCodesCems.MIN_AGE;
        maxAgeCode = RegleCodesCems.MAX_AGE;
        break;
      }
    }

    return new Promise<DobLimits>(resolve => {
      resolve(this.getDobLimitsFromData(regles, minAgeCode, maxAgeCode));
    });
  }

  private getDobLimitsFromData(eligibiliteRegles: any, minAgeCode: any, maxAgeCode: any): DobLimits {
    const minAge = eligibiliteRegles.filter((e: any) => {
      return e.code?.trim() === minAgeCode;
    })[0].valeur;
    const maxAge = eligibiliteRegles.filter((e: any) => {
      return e.code?.trim() === maxAgeCode;
    })[0].valeur;
    const dobLimits = { minAge: parseInt(minAge, 10), maxAge: parseInt(maxAge, 10) } as DobLimits;

    return dobLimits;
  }

  public async getFreePaymentAmountLimits(): Promise<PaymentAmountLimits> {
    switch (environment.orgId) {
      case constants.orgId.peri.value: {
        return this.getPaymentAmountLimitsByCodes(this.allPeriReferentielMetiers, RegleCodesPeri.FREE_PAYMENT_MIN, RegleCodesPeri.FREE_PAYMENT_MAX);
      }
      case constants.orgId.cims.value: {
        return this.getPaymentAmountLimitsByCodes(this.allCimsReferentielMetiers, RegleCodesCims.FREE_PAYMENT_MIN, RegleCodesCims.FREE_PAYMENT_MAX);
      }
      default: {
        return this.getPaymentAmountLimitsByCodes(this.allCemsReferentielMetiers, RegleCodesCems.FREE_PAYMENT_MIN, RegleCodesCems.FREE_PAYMENT_MAX);
      }
    }
  }

  public async getScheduledPaymentAmountLimits(): Promise<PaymentAmountLimits> {
    switch (environment.orgId) {
      case constants.orgId.peri.value: {
        return this.getPaymentAmountLimitsByCodes(this.allPeriReferentielMetiers, RegleCodesPeri.SCHEDULED_PAYMENT_MIN, RegleCodesPeri.SCHEDULED_PAYMENT_MAX);
      }
      case constants.orgId.cims.value: {
        return this.getPaymentAmountLimitsByCodes(this.allCimsReferentielMetiers, RegleCodesCims.SCHEDULED_PAYMENT_MIN, RegleCodesCims.SCHEDULED_PAYMENT_MAX);
      }
      default: {
        return this.getPaymentAmountLimitsByCodes(this.allCemsReferentielMetiers, RegleCodesCems.SCHEDULED_PAYMENT_MIN, RegleCodesCems.SCHEDULED_PAYMENT_MAX);
      }
    }
  }

  private async getPaymentAmountLimitsByCodes(allReferentielMetiers: Produit3[], minCode: string, maxCode: string): Promise<PaymentAmountLimits> {
    const prodCode = getCurrentProductType();
    const produitRegles = allReferentielMetiers.filter((p: any) => p.code?.trim() === prodCode)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const minAmount = produitRegles.filter((e: any) => {
      return e.code?.trim() === minCode;
    })[0].valeur;
    const maxAmount = produitRegles.filter((e: any) => {
      return e.code?.trim() === maxCode;
    })[0].valeur;
    const paymentAmountLimits = { minAmount, maxAmount } as PaymentAmountLimits;

    return new Promise<PaymentAmountLimits>(resolve => {
      resolve(paymentAmountLimits);
    });
  }

  public async getMinAmountPerMode(): Promise<MinAmountPerMode> {
    if (this.minAmountPerMode) {
      return new Promise<MinAmountPerMode>(resolve => {
        resolve(this.minAmountPerMode);
      });
    }

    if (environment.orgId === constants.orgId.peri.value) {
      return this.getPeriMinAmounts();
    } else if (environment.orgId === constants.orgId.cims.value) {
      return this.getCimsMinAmounts();
    } else {
      return this.getCemsMinAmounts();
    }
  }

  getCimsMinAmounts(): Promise<MinAmountPerMode> {
    const produitRegles = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const minAmount = produitRegles.filter((e: any) => {
      return e.code?.trim() === RegleCodesCims.AMOUNT_MIN_CEMS_GSM;
    })[0].valeur;

    this.minAmountPerMode = {
      GSM: parseInt(minAmount, 10)
    } as MinAmountPerMode;

    return new Promise<MinAmountPerMode>(resolve => {
      resolve(this.minAmountPerMode);
    });
  }

  getCemsMinAmounts(): Promise<MinAmountPerMode> {
    const produitRegles = this.allCemsReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.CEMS)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const minAmount = produitRegles.filter((e: any) => {
      return e.code?.trim() === RegleCodesCems.AMOUNT_MIN_CEMS_GSM;
    })[0].valeur;

    this.minAmountPerMode = {
      GSM: parseInt(minAmount, 10)
    } as MinAmountPerMode;

    return new Promise<MinAmountPerMode>(resolve => {
      resolve(this.minAmountPerMode);
    });
  }

  getPeriMinAmounts(): Promise<MinAmountPerMode> {
    const produitRegles = this.allPeriReferentielMetiers.filter((p: any) => p.code?.trim() === CodeProduitType.PERI)[0].typeRegle.filter((r: any) => r.libelle === TypeRegle.PRODUITS)[0].regle;

    const minAmountGH = produitRegles.filter((e: any) => {
      return e.code?.trim() === RegleCodesPeri.AMOUNT_MIN_PERI_GH;
    })[0].valeur;
    const minAmountGSM = produitRegles.filter((e: any) => {
      return e.code?.trim() === RegleCodesPeri.AMOUNT_MIN_PERI_GSM;
    })[0].valeur;
    const minAmountGL = produitRegles.filter((e: any) => {
      return e.code?.trim() === RegleCodesPeri.AMOUNT_MIN_PERI_GL;
    })[0].valeur;

    this.minAmountPerMode = {
      GH: parseInt(minAmountGH, 10),
      GSM: parseInt(minAmountGSM, 10),
      GL: parseInt(minAmountGL, 10)
    } as MinAmountPerMode;

    return new Promise<MinAmountPerMode>(resolve => {
      resolve(this.minAmountPerMode);
    });
  }

  public async getRum() {
    const request = new GetRUMRequest({});

    return this.webApi
      .getRUM(request)
      .pipe(
        map((response: GetRUMResponse) => {
          return response.RUM;
        })
      )
      .toPromise();
  }

  public async getDocumentsFichier(idDocument: number) {
    const request = new GetDocumentsFichierRequest({
      //repertoireRefMetier?: string | undefined;
      //cheminFichier?: string | undefined;
      idDocument: idDocument,
      lireDocuments: true
    });

    return this.webApi
      .getDocumentsFichier(request)
      .pipe(
        map((response: GetDocumentsFichierResponse) => {
          return response.document[0];
        })
      )
      .toPromise();
  }

  // public async getFunds(lireDocuments: boolean = false) {
  //   const request = new GetFondsProduitRequest({
  //     codeProduit: CodeProduitType.CEMS,
  //     lireDocuments
  //   });

  //   return this.webApi.getFondsProduit(request).pipe( // todo ? getReglesMetierProduit
  //     map((response: GetFondsProduitResponse) => {
  //       return this.filterFundsByConfigFile(response.fonds);
  //     })
  //   ).toPromise();
  // }

  public filterFundsByConfigFile(fonds: Fonds[]): Fonds[] {
    if (constants.fundsVisibleDuringLimitedPeriod && constants.fundsVisibleDuringLimitedPeriod.length > 0) {
      constants.fundsVisibleDuringLimitedPeriod.forEach((fondConfigItem: FondConfigItem) => {
        if (fondConfigItem.startDisplayDate && fondConfigItem.startDisplayDate) {
          fonds.forEach((fond: Fonds, index: number) => {
            if (fond.codeISIN == fondConfigItem.isin && fond.libelle.toLowerCase() == fondConfigItem.name?.toLowerCase()) {
              let start = moment(fondConfigItem.startDisplayDate, constants.configDateFormat);
              let end = moment(fondConfigItem.endDisplayDate, constants.configDateFormat).add(1, 'day');

              if (moment(new Date()).isBetween(start, end) === false) {
                fonds.splice(index, 1);
              }
            }
          });
        }
      });
    }

    return fonds;
  }

  public async getCemsDocumentsProduitSignatureSouscriptionEnLigne(fundIds: number[]) {
    return await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.CEMS, fundIds);
  }

  public async getGpaDocumentsProduitSignatureSouscriptionEnLigne() {
    return await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.ATDN);
  }

  public async getExtraDocumentsForGpaSubscriptionOffer() {
    let gpaDocs = await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.ATDN);

    gpaDocs = gpaDocs.filter((doc: Document3) => {
      return (
        constants.extraDocumentsForGpaSubscriptionOfferIds.findIndex((id: number) => {
          return id + '' === doc.id;
        }) !== -1
      );
    });

    return gpaDocs;
  }

  public async getPeriDocumentsProduitSignatureSouscriptionEnLigne(fundIds: number[]) {
    return await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.PERI, fundIds);
  }

  public async getGfdDocumentsProduitSignatureSouscriptionEnLigne() {
    return await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.GFD);
  }

  public async getCimsDocumentsProduitSignatureSouscriptionEnLigne(fundIds: number[]) {
    return await this.getProduitSignatureSouscriptionEnLigne(CodeProduitType.CIMS, fundIds);
  }

  public async getProduitSignatureSouscriptionEnLigne(codeProduit: string, fundIds?: number[]): Promise<Document3[]> {
    if (!fundIds) {
      if (this.produitSignatureSouscriptionEnLigneDocuments[codeProduit]) {
        return new Promise(resolve => {
          resolve(this.produitSignatureSouscriptionEnLigneDocuments[codeProduit]);
        });
      } else {
        const request = new DocumentsProduitSignatureSouscriptionEnLigneRequest({
          codeProduit,
          lireDocuments: true
        });

        return this.webApi
          .getDocumentsProduitSignatureSouscriptionEnLigne(request)
          .pipe(
            map((response: DocumentsProduitSignatureSouscriptionEnLigneResponse) => {
              this.produitSignatureSouscriptionEnLigneDocuments[codeProduit] = response.document;
              return response.document;
            })
          )
          .toPromise();
      }
    } else if (this.produitSignatureSouscriptionEnLigneDocuments[codeProduit + fundIds.sort().join('')]) {
      return new Promise(resolve => {
        resolve(this.produitSignatureSouscriptionEnLigneDocuments[codeProduit + fundIds.sort().join('')]);
      });
    } else {
      const request = new DocumentsProduitSignatureSouscriptionEnLigneRequest({
        codeProduit,
        lireDocuments: true,
        fonds: fundIds
      });

      return this.webApi
        .getDocumentsProduitSignatureSouscriptionEnLigne(request)
        .pipe(
          map((response: DocumentsProduitSignatureSouscriptionEnLigneResponse) => {
            this.produitSignatureSouscriptionEnLigneDocuments[codeProduit + fundIds.sort().join('')] = response.document;
            return response.document;
          })
        )
        .toPromise();
    }
  }

  public async getRecommandation(
    answersScore: number,
    transfertPacte: boolean,
    montantVerse: number,
    lireDocuments: boolean = false,
    dateEstimationDepartRetraite?: Date,
    flagDurabilite: boolean = false
  ) {
    const request = new GetRecommandationRequest({
      score: answersScore,
      codeProduit: getCurrentProductType(),
      idDemarche: 1,
      montantVerse: montantVerse,
      transfertPACTE: transfertPacte,
      lireDocuments: lireDocuments,
      dateEstimationDepartRetraite: dateEstimationDepartRetraite,
      flagDurabilite: flagDurabilite
      // echeancePrecise: true,
      // souscriptionProfitEnfant: true
    });

    return this.webApi
      .getRecommandation(request)
      .pipe(
        map((response: GetRecommandationResponse) => {
          return response;
        })
      )
      .toPromise();
  }

  public async getMandatGestion(idMandatGestion: number, withDocuments: boolean = false): Promise<MandatGestionResponse> {
    if (withDocuments) {
      if (this.mandatGestionDataWithDocs[idMandatGestion]) {
        return new Promise(resolve => {
          resolve(this.mandatGestionDataWithDocs[idMandatGestion]);
        });
      } else {
        const request = new MandatGestionRequest({
          idMandatGestion,
          codeProduit: getCurrentProductType(),
          lireDocuments: withDocuments
        });

        return this.webApi
          .getMandatGestion(request)
          .pipe(
            map((response: MandatGestionResponse) => {
              this.mandatGestionDataWithDocs[idMandatGestion] = response;
              return response;
            })
          )
          .toPromise();
      }
    } else {
      if (this.mandatGestionDataWithoutDocs[idMandatGestion]) {
        return new Promise(resolve => {
          resolve(this.mandatGestionDataWithoutDocs[idMandatGestion]);
        });
      } else {
        const request = new MandatGestionRequest({
          idMandatGestion,
          codeProduit: getCurrentProductType(),
          lireDocuments: withDocuments
        });

        return this.webApi
          .getMandatGestion(request)
          .pipe(
            map((response: MandatGestionResponse) => {
              this.mandatGestionDataWithoutDocs[idMandatGestion] = response;
              return response;
            })
          )
          .toPromise();
      }
    }
  }

  public async getOriginOfContractFundsItems(): Promise<any> {
    if (this.originOfContractFundsItems) {
      return new Promise(resolve => {
        resolve(this.originOfContractFundsItems);
      });
    }

    await this.getOriginOfContractFundsItemsRequest(ReferenceTypesId.ORIGIN_OF_FOUNDS_ID);

    if (this.skipedoriginOfContractFundsItems) {
      this.skipedoriginOfContractFundsItems.forEach((value: any) => {
        this.originOfContractFundsItems = this.originOfContractFundsItems.filter((item: Informations) => {
          if (item.libelleLong === constants.otherAnswerFR) {
            this.originOfContractFundsOtherId = item.id;
          }
          return item.libelleLong !== value;
        });
      });
    }

    return new Promise(resolve => {
      resolve(this.originOfContractFundsItems);
    });
  }

  public async getOriginOfContractFundsOtherId(): Promise<any> {
    return this.originOfContractFundsOtherId;
  }

  private async getOriginOfContractFundsItemsRequest(idTypeReference: number): Promise<any> {
    const request = new ListeReferencesMetierRequest({
      idTypeReference
    });

    return this.webApi
      .getListeReferencesMetier(request)
      .pipe(
        map((response: ListeReferencesMetierResponse) => {
          this.originOfContractFundsItems = response.reference;
          return this.originOfContractFundsItems;
        })
      )
      .toPromise();
  }

  public async getCemsDocumentsProduit(idTypeDocument: number): Promise<any> {
    return this.getDocumentsProduit(idTypeDocument, CodeProduitType.CEMS);
  }

  public async getAtdnDocumentsProduit(idTypeDocument: number): Promise<any> {
    return this.getDocumentsProduit(idTypeDocument, CodeProduitType.ATDN);
  }

  public async getGfdDocumentsProduit(idTypeDocument: number): Promise<any> {
    return this.getDocumentsProduit(idTypeDocument, CodeProduitType.GFD);
  }

  public async getPeriDocumentsProduit(idTypeDocument: number): Promise<any> {
    return this.getDocumentsProduit(idTypeDocument, CodeProduitType.PERI);
  }

  public async getCimsDocumentsProduit(idTypeDocument: number): Promise<any> {
    return this.getDocumentsProduit(idTypeDocument, CodeProduitType.CIMS);
  }

  private async getDocumentsProduit(idTypeDocument: number, codeProduit: string): Promise<any> {
    const request = new GetDocumentsProduitRequest({
      idTypeDocument,
      codeProduit: codeProduit,
      lireDocuments: true
    });

    return this.webApi
      .getDocumentsProduit(request)
      .pipe(
        map((response: GetDocumentsProduitResponse) => {
          this.documentsProduitFullBody = response.document[0];
          return response.document[0].fichierAttache;
        })
      )
      .toPromise();
  }
}

export enum ReferenceTypesId {
  ORIGIN_OF_FOUNDS_ID = 3
}

export enum TypeRegle {
  ELIGIBILITE = 'Eligibilité',
  PRODUITS = 'Produits'
}

export enum RegleCodesCems {
  MIN_AGE = 'ELMI0001',
  MAX_AGE = 'ELMX0001',
  FREE_PAYMENT_MIN = 'VLBM0001',
  FREE_PAYMENT_MAX = 'VLBMX001',
  SCHEDULED_PAYMENT_MIN = 'VPBM0001',
  SCHEDULED_PAYMENT_MAX = 'VPMX0001',
  AMOUNT_MIN_CEMS_GSM = 'VLNM0004'
}

export enum RegleCodesCims {
  MIN_AGE = 'ELMI0001',
  MAX_AGE = 'ELMX0001',
  FREE_PAYMENT_MIN = 'VLBM0008',
  FREE_PAYMENT_MAX = 'VLBMX003',
  SCHEDULED_PAYMENT_MIN = 'VPBM0017',
  SCHEDULED_PAYMENT_MAX = 'VPMX0003',
  // AMOUNT_MIN_CEMS_GSM = "VLNM0008" // todo: cims no such code in the response
  AMOUNT_MIN_CEMS_GSM = 'VLNM0004'
}

export enum RegleCodesGpa {
  MIN_AGE = 'ELMI0004',
  MAX_AGE = 'ELMX0004',
  PIVOT_AGE = 'ELMX0003',
  CAPITAL_MIN_BASE = 'CAPI0001',
  CAPITAL_MAX_BASE = 'CAPI0002',
  CAPITAL_STEP = 'CAPI0005',
  CAPITAL_MAX_BASE_TO_X2X3 = 'CAPI0004'
}

export enum RegleCodesGfd {
  MIN_AGE = 'ELMI0002',
  MAX_AGE = 'ELMX0002',
  CAPITAL_MIN_BASE = 'CAPI0007',
  CAPITAL_MAX_BASE = 'CAPI0008',
  CAPITAL_STEP = 'CAPI0006'
}

export enum RegleCodesPeri {
  MIN_AGE = 'AGMI0003',
  MAX_AGE = 'AGMX0003',
  FREE_PAYMENT_MIN = 'VLNM005',
  FREE_PAYMENT_MAX = 'VLBMX02',
  SCHEDULED_PAYMENT_MIN = 'VPNM0014',
  SCHEDULED_PAYMENT_MAX = 'VPMX0002',
  AMOUNT_MIN_PERI_GH = 'RPMI016',
  AMOUNT_MIN_PERI_GSM = 'RPMI015',
  AMOUNT_MIN_PERI_GL = 'RPMI014'
}

export class DobLimits {
  minAge: number;
  maxAge: number;
}

export class GpaCapitalData {
  min: number;
  max: number;
  step: number;
  maxX2X3: number;
}

export class GfdCapitalData {
  min: number;
  max: number;
  step: number;
}

export class PaymentAmountLimits {
  minAmount: string;
  maxAmount: string;
}

export class FondConfigItem {
  name: string;
  isin: string;
  startDisplayDate: string;
  endDisplayDate: string;
}
