import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { GetTarifsPrevoyanceGFDResponse } from '@webapi/MIF.Subscription.Parrot';
import { Profile } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';
import { WsPrevoyanceGFDService } from '@webapi/services/ws-pevoyance-gfd';
import { collections } from '@constants/collections';
import { LabelTextPipe } from '@pipes/label-text.pipe';

@Component({
  selector: 'app-signature-gfd-plan',
  templateUrl: './signature-gfd-plan.component.html',
  styleUrls: ['./signature-gfd-plan.component.scss']
})
export class SignatureGfdPlanComponent implements OnInit {
  @Input() clientProfile: Profile;
  clientAge: number;
  planDetails: GetTarifsPrevoyanceGFDResponse;

  constructor(private router: Router, private customerService: CustomerService, private wsPrevoyanceGFDService: WsPrevoyanceGFDService, private labelTextPipe: LabelTextPipe) {}

  async ngOnInit() {
    this.clientAge = moment().diff(this.clientProfile.person.dateOfBirth, 'years', false);
    this.planDetails = (await this.wsPrevoyanceGFDService.getTarifsPrevoyanceGFD(
      this.clientAge,
      this.clientProfile.gfdProfile.contributionTypeCode,
      this.clientProfile.gfdProfile.guaranteedCapitalCode,
      10
    )) as GetTarifsPrevoyanceGFDResponse; // todo: age param
  }

  goToMyContract() {
    this.router.navigate(['my-contract'], { queryParams: { userId: this.customerService.userId } });
  }

  get isCotisationUniqueTypeSelected(): boolean {
    return this.clientProfile.gfdProfile.contributionTypeCode === collections.gfdLifetimeTypesData.cotisationUnique;
  }

  get ansText(): string {
    let text = '';

    if (this.clientProfile) {
      switch (this.clientProfile.gfdProfile.contributionTypeCode) {
        case collections.gfdLifetimeTypesData.viagere: {
          text = this.labelTextPipe.transform('SUMMARY.ProvisioningGFDDuration');
          break;
        }
        case collections.gfdLifetimeTypesData.ans5: {
          text = this.labelTextPipe.transform('SUMMARY.ProvisioningGFDDurationFiveYears');
          break;
        }
        case collections.gfdLifetimeTypesData.ans10: {
          text = this.labelTextPipe.transform('SUMMARY.ProvisioningGFDDurationTenYears');
          break;
        }
      }
    }

    return text;
  }
}
