<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appCapitalizeAllNoEvents
      appAsyncIbanValidator
      (change)="change()"
      mask="AAAA AAAA AAAA AAAA AAAA AAAA AAA"
      placeholder="FR00 0000 0000 0000 0000 0000 000"
      [validation]="false"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.pending">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>
  </mat-form-field>

  <mat-error class="iban-error" *ngIf="control.touched">
    <!-- <span *ngIf="control.errors?.required">{{ requiredText }}</span> -->
    <span *ngIf="control.errors?.ibanValidator || control.errors?.required"> {{ 'BANK_DETAILS.ForeignIBAN' | labelText }} </span>
  </mat-error>
</div>
