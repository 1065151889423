<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyBankDetails" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<!-- <div class="row mt-3" *ngIf="isBicErrors && isBicCheckingServiceAavailable">
  <div class="col">
    <div class="alert alert-danger">{{ 'BANK_DETAILS.BankDetailsWrongIban' | labelText }}.</div>
  </div>
</div> -->

<div class="row mt-4">
  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
    <p *ngIf="isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitlePeri' | labelText"></p>
    <p *ngIf="!isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitleNotPeri' | labelText"></p>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" class="mb-5">
  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'IDENTITY.Identity' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyDetails()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'ADDRESS.ContactDetails' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyContacts()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'SITUATION.ProAndPatrimonialSituation' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyFinancial()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'BANK_DETAILS.YourBankDetails' | labelText }}</h1>
            </div>
          </div>

          <!-- <h5>{{ 'BANK_DETAILS.FillInYourBankDetails' | labelText }}</h5> -->

          <div *ngIf="isBancairesPrelevements">
            <div class="row">
              <div class="col-12">
                <h5 class="mb-0 mt-2">{{ 'BANK_DETAILS.MandateTitle' | labelText }}</h5>
              </div>
            </div>

            <div class="row with-col-speech" *ngFor="let item of bancairesPrelevements">
              <div class="speech col-xs-12 col-sm-12 col-md-10 col-lg-9">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9">
                    <div class="speech-title">
                      <strong>{{ 'BANK_DETAILS.MandateReference' | labelText }} {{ item.RUM }} </strong>
                    </div>
                    <p>{{ item.IBAN | iban }}</p>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
                    <button type="button" [class.active-btn]="isSelectedBancaire(item)" (click)="selectBancaire(item)" class="btn btn-blue xs-100">
                      {{ 'BANK_DETAILS.MandateChooseButton' | labelText }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button *ngIf="!manualEnteringIban" type="button" class="btn btn-blue mt-1" (click)="showIbanForm()">
              <fa-icon [icon]="faPlus"></fa-icon>
              {{ 'BANK_DETAILS.MandateAddButton' | labelText }}
            </button>
          </div>

          <div class="row mb-6" *ngIf="manualEnteringIban">
            <div class="col-xs-12 col-sm-12 col-md-11 col-lg-8">
              <app-iban-form-control [parentForm]="form" [controlName]="'iban'" [title]="'Votre IBAN'"> </app-iban-form-control>

              <p class="txt-mentions mt-1">{{ 'BANK_DETAILS.IbanComposition' | labelText }}</p>

              <p *ngIf="bic">
                {{ 'BANK_DETAILS.BICInformation' | labelText }}
                <span class="bic">
                  {{ bic }}
                </span>
              </p>
            </div>
          </div>

          <div class="row with-speech" *ngIf="manualEnteringIban">
            <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
              <span class="icon icon-aside"></span>
            </div>
            <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
              <div class="speech">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                    <span> {{ 'BANK_DETAILS.AddYourBankDetails' | labelText }} </span>
                    <p class="fs-14">
                      <b class="text-italic"> {{ 'BANK_DETAILS.RIBContent1' | labelText }} </b>
                      <br />
                      {{ 'BANK_DETAILS.RIBContent2' | labelText }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                    <app-file-uploader
                      [files]="ribRestoredUploaderFiles"
                      (onChange)="ribFilesUploaderChanged($event)"
                      (onStatusChange)="ribFilesUploaderStatusChanged($event)"
                      [hideAfterUpload]="true"
                    ></app-file-uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-center mt-5">
            <div class="col">
              <div class="valider-button-block">
                <button type="submit" [disabled]="isFormDisabled" class="btn btn-blue valider">{{ 'BANK_DETAILS.ValidatePersonalData' | labelText }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
