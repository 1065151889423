import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { MifadvisorCodeFormControl } from './mif-advisor-code-form-control';
import { collections } from '@shared/constants/collections';

@Component({
  selector: 'app-mif-advisor-code-form-control',
  templateUrl: './mif-advisor-code-form-control.component.html'
})
export class MifadvisorCodeFormControlComponent extends AbstractFormControlComponent<MifadvisorCodeFormControl> {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  faCheck: any = faCheck;
  collections = collections;

  public get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }
}
