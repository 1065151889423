import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import moment from 'moment';
import { Profile, ProfileStatus, Subscription } from 'projects/mif/app/shared/webapi/MIF.Subscription.WebApi';
import { CustomerService } from 'projects/mif/app/shared/webapi/services/customer.service';
import { SettingsService } from 'projects/mif/app/shared/webapi/services/settings.service';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionGuard implements CanActivate {
  subscriptionConfiguration: Subscription;
  constructor(
    private settingsService: SettingsService,
    private customerService: CustomerService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.settingsService.getSubscriptionConfiguration().then((subscriptionConfiguration: Subscription) => {
      return this.customerService.getUserData(next.queryParams?.userId).then((profile: Profile) => {

        if (next.queryParams?.userId && profile === undefined) { // case when userId param is wrong
          return this.redirectToSubscriptionTimeEnd();
        }

        const subscriptionAgeInDays = moment().diff(profile?.createdOn, 'days', false);
        const profileIsCompleted = profile?.profileStatus === ProfileStatus.Completed;

        if (subscriptionAgeInDays === 0 && !profileIsCompleted) {
          return true;
        }

        const subscriptionTimeEnd = subscriptionAgeInDays > subscriptionConfiguration.expirationPeriod;

        if (subscriptionTimeEnd) {
          return this.customerService.clearAllData().then(() => {
            return this.redirectToSubscriptionTimeEnd();
          })
        } else if (profileIsCompleted) {
          return this.redirectToSubscriptionComplete();
        } else {
          return true;
        }
      }).catch((err: any) => {
        return this.redirectToSubscriptionTimeEnd();
      });
    })
  }

  private redirectToSubscriptionComplete(): boolean {
    this.router.navigate(['subscription-complete']);
    return false;
  }

  private redirectToSubscriptionTimeEnd(): boolean {
    this.router.navigate(['subscription-time-end']);
    return false;
  }

}
