import { FormArray } from '@angular/forms';
import { HealthQuestionsModel } from '@shared/extensions/user-data-extensions';
import { LabelTextPipe } from '@shared/pipes/label-text.pipe';
import { Regle } from '@webapi/MIF.Subscription.Parrot';
import { Profile } from '@webapi/MIF.Subscription.WebApi';

function generateTableData(fundInEuroRegle: Regle, initialPaymentWithoutPercents: number): TableItem[] {
  const startYear = new Date().getFullYear();
  const data: TableItem[] = [];
  const fundPercent = parseFloat(fundInEuroRegle.valeur.replace(',', '.'));

  for (let i = 0; i < 8; i++) {
    data.push({
      year: startYear + i,
      date: `31/12/${startYear + i}`,
      sumPaid: initialPaymentWithoutPercents,
      repurchaseValue: calculateRepurchaseValue(data, i, fundPercent, initialPaymentWithoutPercents)
    } as TableItem);
  }

  return data;
}

function calculateRepurchaseValue(data: TableItem[], i: number, fundPercent: number, initialPaymentWithoutPercents: number): number {
  if (i > 0) {
    return data[i - 1].repurchaseValue - (data[i - 1].repurchaseValue / 100) * fundPercent;
  }

  return initialPaymentWithoutPercents - (initialPaymentWithoutPercents / 100) * fundPercent;
}

function getHowDidYouHearMifText(model: AdvertisementSourceDataModel, labelTextPipe: LabelTextPipe): string {
  if (model.advertisementSourceCode) {
    if (model.advertisementSourceCode === Sources.PARRAINAGE && model.sponsorNumber) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform(
        'SUMMARY.HowDidYouHearMifText2'
      )}</span> ${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText3')} <span style="color: #189bd5" class="answer"> ${model.sponsorNumber}</span>. `;
    }
    if (model.advertisementSourceCode === Sources.MEMBRE_CORRESPONDANT) {
      if (model.memberNumber && model.firstName && model.lastName) {
        return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform(
          'SUMMARY.HowDidYouHearMifText4'
        )}</span> ${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText5')} <span style="color: #189bd5" class="answer">${model.memberNumber}</span> et <span style="color: #189bd5" class="answer">${
          model.firstName
        } ${model.lastName}</span>. `;
      } else if (model.firstName && model.lastName) {
        // only first and lastname
        return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform(
          'SUMMARY.HowDidYouHearMifText4'
        )}</span> ${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText6')} <span style="color: #189bd5" class="answer">${model.firstName} ${model.lastName}</span>. `;
      } else if (model.memberNumber) {
        // only code
        return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform(
          'SUMMARY.HowDidYouHearMifText4'
        )}</span> ${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText7')} <span style="color: #189bd5" class="answer">${model.memberNumber}</span>. `;
      }
    }
    if (model.advertisementSourceCode === Sources.INTERNET) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText8')}</span>. `;
    }
    if (model.advertisementSourceCode === Sources.RESEAUX_SOCIAUX) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText1')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText9')}</span>. `;
    }
    if (model.advertisementSourceCode === Sources.BOUCHE_A_OREILLE) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText10')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText11')}</span>. `;
    }
    if (model.advertisementSourceCode === Sources.PRESSE) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText10')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText12')}</span>. `;
    }
    if (model.advertisementSourceCode === Sources.AUTRE) {
      return `${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText10')} <span style="color: #189bd5" class="answer">${labelTextPipe.transform('SUMMARY.HowDidYouHearMifText13')}</span>. `;
    }
  }
  return '';
}

function isShowSEPA(clientProfile: Profile) {
  let isShow = true;

  if (!!clientProfile.transferContractCode && !clientProfile.initialPaymentAmount && !clientProfile.scheduledPaymentAmount) {
    isShow = false;
  }

  return isShow;
}

export { generateTableData, getHowDidYouHearMifText, isShowSEPA };

export class TableItem {
  year: number;
  date: string;
  sumPaid: number;
  repurchaseValue: number;
}

export class FileLink {
  uniqueId: string;
  name: string;
}

export enum Sources {
  INTERNET = 'INTERNET',
  PARRAINAGE = 'PARRAINAGE',
  RESEAUX_SOCIAUX = 'RESEAUX_SOCIAUX',
  BOUCHE_A_OREILLE = 'BOUCHE_A_OREILLE',
  PRESSE = 'PRESSE',
  PARTENAIRE = 'PARTENAIRE',
  MEMBRE_CORRESPONDANT = 'MEMBRE_CORRESPONDANT',
  AUTRE = 'AUTRE'
}

export interface GpaSubscriptionModel {
  health: HealthQuestionsModel;
  reuseBeneficiaries: boolean;
  beneficiaryClauseCode?: number;
  beneficiaryFreeClauseText?: string;
  beneficiaries?: FormArray;
  isMultipliedCoverage?: boolean;
  isEqualParts?: boolean;
}

export interface AdvertisementSourceDataModel {
  advertisementSourceCode?: string;
  sponsorNumber?: string;
  memberNumber?: string;
  firstName?: string;
  lastName?: string;
  promoCode?: string;
  partnerCode?: string;
  mifAdvisorCode?: string;
  utm?: string;
}
