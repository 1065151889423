import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {

  transform(value: boolean): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value ? 'Oui' : 'Non';
  }
}
