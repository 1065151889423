import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NumberFormControl } from './number-form-control';

@Component({
  selector: 'app-number-form-control',
  templateUrl: './number-form-control.component.html',
  styleUrls: ['./number-form-control.component.scss']
})
export class NumberFormControlComponent extends AbstractFormControlComponent<NumberFormControl> implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() fraisInclus?: boolean;
  @Input() aCeJour?: boolean;
  @Input() inline: boolean;

  faCheck: any = faCheck;

  ngOnInit(): void {}

  get minLength(): number {
    return this.control.minLength;
  }

  get maxLength(): number {
    return this.control.maxLength;
  }

  get placeholder(): string {
    return this.control.placeholder || '';
  }

  get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }

  public get isInline(): boolean {
    return this.inline || false;
  }

  get disabledGreenBorder(): boolean {
    return this.control.disabledGreenBorder || false;
  }

  get allowNegativeNumbers(): boolean {
    return this.control.allowNegativeNumbers || false;
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }

  public get hideErrorMessages(): boolean {
    return this.control.hideErrorMessages || false;
  }
}
