import { PhoneFormControl } from '@form/phone-form-control/phone-form-control';
import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LabelsDataService } from '@services/labels-data.service';
import { ProgressBarService } from '@services/progress-bar.service';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { constants } from '@constants/constants';
import { DropDownFormControl } from '@form/dropdown-form-control/dropdown-form-control';
import { EmailFormControl } from '@form/email-form-control/email-form-control';
import { NameWithDigitsFormControl } from '@form/name-with-digits-form-control/name-with-digits-form-control';
import { ZipCodeFormControl } from '@form/zipcode-form-control/zipcode-form-control';
import { WsCRMClientService } from '@webapi/services/ws-crmclient.service';
import { Pays } from '@webapi/MIF.Subscription.Parrot';
import { MobileFormControl } from '@form/mobile-form-control/mobile-form-control';
import { CustomerService } from '@webapi/services/customer.service';
import { getMyContactsModel } from '@extensions/user-data-extensions';
import { ExternalServices, Profile, ProfileDocument, Subscription } from '@webapi/MIF.Subscription.WebApi';
import { collections } from '@constants/collections';
import { WsDqeService } from '@webapi/services/ws-dqe.service';
import { NameAutocompleteFormControl } from '@form/name-autocomplete-form-control/name-autocomplete-form-control';
import { SettingsService } from '@webapi/services/settings.service';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { ProfileStepName } from '@models/profile-step-name';
import { isPeri, scrollToSelector } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { FileUploaderStatus } from '@shared/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  public isPeri = isPeri();
  public countries: Pays[];
  public franceId: any;
  public addressConfirmationRestoredDocumentFiles: File[];
  public addressConfirmationDocumentFiles: File[];
  public mobilePhoneControl: PhoneFormControl;
  public landLinePhoneControl: PhoneFormControl;
  public emailControl: PhoneFormControl;
  public form: FormGroup;
  public clientProfile: Profile;
  public clientProfileForViewOffer: Profile;
  public postalCodeControl: ZipCodeFormControl;
  public cityControl: NameAutocompleteFormControl;
  public cityAutocompleteOptions: any[];
  public adressAutocompleteOptions: any[];
  public autocompleteZipCode: any;
  public constants: any = constants;
  public externalServicesConfiguration: ExternalServices;
  public subscriptionConfiguration: Subscription;
  public nameOfTheRoadAndPlaceControl: NameAutocompleteFormControl;
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public prevNameOfTheRoadAndPlaceControlValue: string;
  public ProfileStepName = ProfileStepName;
  public contactsDocsUploaderStatus: FileUploaderStatus;

  constructor(
    private router: Router,
    private bsModalService: BsModalService,
    private wsCRMClientService: WsCRMClientService,
    private progressBarService: ProgressBarService,
    private labelsDataService: LabelsDataService,
    private customerService: CustomerService,
    private wsDqeService: WsDqeService,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe,
    private renderer: Renderer2
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myDetails.contacts.initialStep);
    await this.fetchInitData();
    this.initNewFormGroup();
    this.initControls();
    await this.restoreAnswersOnForm();

    if (history.state.scrollToPhones) {
      this.scrollToPhones();
    }
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  async fetchInitData(): Promise<void> {
    this.subscriptionConfiguration = await this.settingsService.getSubscriptionConfiguration();
    this.externalServicesConfiguration = await this.settingsService.getExternalServicesConfiguration();
    this.countries = await this.wsCRMClientService.getAllCountries();

    this.countries.forEach((item: any) => {
      if (item['alpha3'] === this.subscriptionConfiguration.defaultCountryCode) {
        this.franceId = item['id'];
      }
    });
  }

  scrollToPhones(): void {
    setTimeout(() => {
      scrollToSelector('.email-row');
      setTimeout(() => {
        this.renderer.selectRootElement('#mobilePhone')?.focus();
      }, 300);
    }, 10);
  }

  initControls(): void {
    this.mobilePhoneControl = this.form.get('mobilePhone') as PhoneFormControl;
    this.landLinePhoneControl = this.form.get('landLinePhone') as PhoneFormControl;
    this.emailControl = this.form.get('email') as PhoneFormControl;
    this.postalCodeControl = this.form.get('postalCode') as ZipCodeFormControl;
    this.cityControl = this.form.get('city') as NameAutocompleteFormControl;
    this.nameOfTheRoadAndPlaceControl = this.form.get('nameOfTheRoadAndPlace') as NameAutocompleteFormControl;

    this.subscriptionHandler.subscriptions = this.nameOfTheRoadAndPlaceControl.valueChanges.subscribe((value: string) => {
      const isValid = this.nameOfTheRoadAndPlaceControl.valid;

      if (isValid && value && this.prevNameOfTheRoadAndPlaceControlValue !== value) {
        this.cityChange(this.cityControl.value);
        this.prevNameOfTheRoadAndPlaceControlValue = value;
      }
    });

    this.subscriptionHandler.subscriptions = this.form.valueChanges.subscribe((data: any) => {
      const model = this.form.getRawValue() as MyContactsFormModel;
      this.clientProfileForViewOffer = Object.assign(new Profile(), this.customerService.updateMyContactsModelWithoutSaving(this.clientProfile, model));
    });
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      country: new DropDownFormControl(
        true,
        false,
        {
          collection: this.countries,
          valuePropertyName: constants.countryDescriptionField,
          keyPropertyName: constants.countryKeyField
        },
        {
          defaultValue: this.franceId,
          hideErrorMark: true
        }
      ),
      postalCode: new ZipCodeFormControl(true, false, { hideSuccessMark: true }),
      city: new NameAutocompleteFormControl(true, false, { escapeFirstDigits: false }),
      nameOfTheRoadAndPlace: new NameAutocompleteFormControl(true, false, { maxLength: constants.maxLength.nDeVoieLieuDit, autocompleteLabelField: 'Voie', escapeFirstDigits: true }),
      additionalAddress: new NameWithDigitsFormControl(false, false, { capitalizeAll: true, maxLength: constants.maxLength.complementAdresse }),
      additionalAddress2: new NameWithDigitsFormControl(false, false, { capitalizeAll: true, maxLength: constants.maxLength.complementAdresse }),
      locality: new NameWithDigitsFormControl(false, false, { capitalizeAll: true, maxLength: constants.maxLength.locality }),
      email: new EmailFormControl(true, false),
      mobilePhone: new MobileFormControl(true, false),
      landLinePhone: new PhoneFormControl(false, false)
    });
  }

  async restoreAnswersOnForm(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile) {
      const dataModel = getMyContactsModel(this.clientProfile);

      dataModel.country = dataModel.country ? dataModel.country : this.franceId;

      this.form.patchValue(dataModel);

      if (this.postalCodeControl.value) {
        await this.postalCodeChange(this.postalCodeControl.value);

        if (this.cityControl.value) {
          this.cityChange(this.cityControl.value);
        }
      }

      this.clientProfileForViewOffer = Object.assign(new Profile(), this.clientProfile);
    }

    this.restoreFiles();
  }

  restoreFiles(): void {
    if (this.clientProfile.profileDocuments?.length > 0) {
      this.clientProfile.profileDocuments.forEach((item: ProfileDocument) => {
        if (item.typeId === collections.profileDocuments.addressConfirmationDocument.typeId) {
          const file = new File([''], item.file.fileName, {});

          if (!this.addressConfirmationRestoredDocumentFiles) {
            this.addressConfirmationRestoredDocumentFiles = [];
          }

          this.addressConfirmationRestoredDocumentFiles.push(file);
        }
      });
    }
  }

  async postalCodeChange(event: any): Promise<void> {
    if (this.externalServicesConfiguration.dqeAutoCompletion === false) {
      return;
    }

    if (event && event !== '' && this.postalCodeControl.status === constants.validStatus) {
      this.autocompleteZipCode = this.postalCodeControl.value;
      this.cityAutocompleteOptions = await this.wsDqeService.rechercheVilleByPostal(this.postalCodeControl.value);
    } else {
      this.autocompleteZipCode = null;
      this.cityAutocompleteOptions = [];
      this.adressAutocompleteOptions = [];
    }
  }

  async cityChange(event: any): Promise<void> {
    if (this.externalServicesConfiguration.dqeAutoCompletion === false) {
      return;
    }

    if (event && event !== '' && this.cityControl.status === constants.validStatus && this.cityAutocompleteOptions?.length > 0) {
      //const searchAddrStr = this.autocompleteZipCode ? `${this.autocompleteZipCode} ${this.cityControl.value}` : this.cityControl.value;
      const searchAddrStr = this.nameOfTheRoadAndPlaceControl.value;
      const cityAutocompleteOption = this.cityAutocompleteOptions.filter((item: any) => {
        return item.Localite === this.cityControl.value;
      });

      if (cityAutocompleteOption && cityAutocompleteOption[0]) {
        const idLocalite = cityAutocompleteOption[0].IDLocalite;

        this.adressAutocompleteOptions = await this.wsDqeService.rechercheRueByCity(idLocalite, searchAddrStr);
      } else {
        this.adressAutocompleteOptions = [];
      }
    } else {
      this.adressAutocompleteOptions = [];
    }
  }

  contactsDocsUploaderChanged(files: File[]): void {
    this.addressConfirmationDocumentFiles = files;
  }

  contactsDocsUploaderStatusChanged(status: any): void {
    this.contactsDocsUploaderStatus = status;
  }

  async emailChange(event?: any): Promise<void> {
    if (event && event !== '' && this.emailControl.status === constants.validStatus) {
      if (this.externalServicesConfiguration.dqeValidation === false) {
        return;
      }

      const emailICheck = await this.wsDqeService.validateEmail(this.emailControl.value);

      if (emailICheck.hasError) {
        this.emailControl.setErrors({ emailRemoteValidator: true });
      } else {
        this.emailControl.setErrors(null);
      }
    }
  }

  goToMyDetails(): void {
    this.router.navigate(['my-details'], { queryParams: { userId: this.customerService.userId } });
  }

  showConditionsModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: this.labelsDataService.getData('details.contactsRedBoxLinkContent')
      },
      class: 'modal-lg'
    });
  }

  async onSubmit(): Promise<void> {
    const phone = this.landLinePhoneControl.value;
    const mobile = this.mobilePhoneControl.value;
    let phoneInValid = false;
    let mobileInValid = false;

    if (phone && phone !== '' && this.externalServicesConfiguration.dqeValidation === true) {
      phoneInValid = !(await this.wsDqeService.validatePhoneNumber(phone));
      if (phoneInValid) {
        this.landLinePhoneControl.setErrors({ phoneRemoteValidator: true });
      }
    }

    if (mobile && mobile !== '' && this.externalServicesConfiguration.dqeValidation === true) {
      mobileInValid = !(await this.wsDqeService.validatePhoneNumber(mobile));
      if (mobileInValid) {
        this.mobilePhoneControl.setErrors({ phoneRemoteValidator: true });
      }
    }

    if (!phoneInValid && !mobileInValid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();

      if (this.form.valid) {
        this.processSuccess();
      }
    }
  }

  async processSuccess(): Promise<void> {
    const model = this.form.getRawValue() as MyContactsFormModel;

    model.addressConfirmationDocumentFiles = this.addressConfirmationDocumentFiles;

    const userId = await this.customerService.updateMyContactsModel(model, this.bsModalService, this.labelTextPipe);

    if (userId !== null) {
      this.router.navigate(['my-details/financial-situation'], { queryParams: { userId } });
    }
  }

  get isFormDisabled(): boolean {
    return this.form.invalid || this.contactsDocsUploaderStatus === FileUploaderStatus.INVALID;
  }
}

export interface MyContactsFormModel {
  country: number;
  postalCode: string;
  city: string;
  nameOfTheRoadAndPlace: string;
  additionalAddress: string;
  additionalAddress2?: string;
  locality?: string;
  mobilePhone: string;
  landLinePhone: string;
  email: string;
  addressConfirmationDocumentFiles: File[];
}
