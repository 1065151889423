<div class="header">
  <div class="row">
    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
      <a *ngIf="isCems" href="javascript:void(0)" (click)="onRetourAuProduitCemsClick()">
        <fa-icon [icon]="faLongArrowLeft"></fa-icon>
        {{ 'HEADER.BackToProduct' | labelText }}
      </a>
      <a *ngIf="isGpa" href="javascript:void(0)" (click)="onRetourAuProduitGpaClick()">
        <fa-icon [icon]="faLongArrowLeft"></fa-icon>
        {{ 'HEADER.BackToProduct' | labelText }}
      </a>
      <a *ngIf="isGfd" href="javascript:void(0)" (click)="onRetourAuProduitGfdClick()">
        <fa-icon [icon]="faLongArrowLeft"></fa-icon>
        {{ 'HEADER.BackToProduct' | labelText }}
      </a>
      <a *ngIf="isPeri" href="javascript:void(0)" (click)="onRetourAuProduitPeriClick()">
        <fa-icon [icon]="faLongArrowLeft"></fa-icon>
        {{ 'HEADER.BackToProduct' | labelText }}
      </a>
      <a *ngIf="isCims" href="javascript:void(0)" (click)="onRetourAuProduitCimsClick()">
        <fa-icon [icon]="faLongArrowLeft"></fa-icon>
        {{ 'HEADER.BackToProduct' | labelText }}
      </a>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 contact">
      <span class="icon-phone"></span>
      <strong class="phone-number"
        >{{ 'HEADER.ContactTelephoneNumber' | labelText }}
        <em class="part2"> - {{ 'HEADER.ContactTelephonePrice' | labelText }}</em>
      </strong>
      <span class="hidden-xs">{{ 'HEADER.ContactTelephoneHours' | labelText }}</span>
    </div>
    <div class="identification hidden-xs" *ngIf="identificationData">
      <div>
        {{ identificationData.firstName }} {{ identificationData.lastName }}
        <span class="no-space" *ngIf="identificationData.code">
          : {{ 'HEADER.ClientDocumentNumber' | labelText }}
          <span id="numero-dossier">
            {{ identificationData.code }}
          </span>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="header-tabs">
  <div class="row">
    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
      <a href="javascript:void(0)">
        <img src="assets/img/logo.svg" class="logo" alt="" (click)="onLogoCLick()" />
        <span *ngIf="isCems" (click)="onRetourAuProduitCemsClick()">
          {{ productName }} <strong>{{ 'Header.HeaderTextSubscribeOnline' | labelText }}</strong>
        </span>
        <span *ngIf="isCims" (click)="onRetourAuProduitCemsClick()">
          {{ productName }} <strong>{{ 'Header.HeaderTextSubscribeOnline' | labelText }}</strong>
        </span>
        <span *ngIf="isGpa" (click)="onRetourAuProduitGpaClick()">
          {{ productName }} <strong>{{ 'Header.HeaderTextSubscribeOnline' | labelText }}</strong>
        </span>
        <span *ngIf="isGfd" (click)="onRetourAuProduitGfdClick()">
          {{ productName }} <strong>{{ 'Header.HeaderTextSubscribeOnline' | labelText }}</strong>
        </span>
        <span *ngIf="isPeri" (click)="onRetourAuProduitPeriClick()">
          {{ productName }} <strong>{{ 'Header.HeaderTextMemberOnline' | labelText }}</strong>
        </span>
      </a>
    </div>
    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-9 tabs">
      <div
        class="tab-block"
        routerLink="/my-profile"
        replaceUrl="true"
        [class.inactive]="isMyProfileInactive"
        [ngClass]="{ 'viewed-header-link': isMyProfileViewed }"
        [routerLinkActive]="['active-link']"
      >
        <span class="step-number">1</span>
        <p>
          {{ getStep1Name() }}
        </p>
        <div class="progress-bar" [ngStyle]="{ 'width.%': progressWidth }"></div>
      </div>
      <div
        class="tab-block"
        routerLink="/my-contract"
        replaceUrl="true"
        [class.inactive]="isMyContractInactive"
        [ngClass]="{ 'viewed-header-link': isMyContractViewed }"
        [routerLinkActive]="['active-link']"
      >
        <span class="span-before-route-link" (click)="step2Click($event)"> </span>
        <span class="step-number">2</span>
        <p>
          {{ getStep2Name() }}
        </p>
      </div>
      <div
        class="tab-block"
        routerLink="/my-beneficiaries"
        replaceUrl="true"
        [class.inactive]="isMyBeneficiariesInactive"
        [ngClass]="{ 'viewed-header-link': isMyBeneficiariesViewed }"
        [routerLinkActive]="['active-link']"
      >
        <span class="step-number">3</span>
        <p>
          {{ collections.step.beneficiaries.value }}
        </p>
      </div>
      <div
        class="tab-block"
        routerLink="/my-details"
        replaceUrl="true"
        [class.inactive]="isMyDetailsInactive"
        [ngClass]="{ 'viewed-header-link': isMyDetailsViewed }"
        [routerLinkActive]="['active-link']"
      >
        <span class="step-number">4</span>
        <p>
          {{ collections.step.myDetails.value }}
        </p>
      </div>
      <div
        class="tab-block"
        routerLink="/signature"
        replaceUrl="true"
        [class.inactive]="isSignatureInactive"
        [ngClass]="{ 'viewed-header-link': isSignatureViewed }"
        [routerLinkActive]="['active-link']"
      >
        <span class="step-number">5</span>
        <p>
          {{ getStep5Name() }}
        </p>
      </div>
    </div>
  </div>
</div>
