import { FormControl } from '@angular/forms';

export function ibanValidator() {
  return (control: FormControl) => {
    const iban = control.value;

    if (iban) {
      if (!/[a-zA-Z][a-zA-Z][a-zA-Z0-9]{25}$/.test(iban)) {
        return {
          ibanValidator: true
        };
      } else {
        return null;
      }
    }

    return null;
  };
}
