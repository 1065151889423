import { Injectable } from '@angular/core';
import { BaseDataService } from '@components/data/base-data.service';

@Injectable({
  providedIn: 'root'
})
export class MyDetailsDataService extends BaseDataService {
  constructor() {
    super();
  }

}
