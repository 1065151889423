import * as moment from 'moment';

export function matDatepickerMaxValidator() {
  return (control: any) => {
    const date = control.value;
    const maxDate = control.maxDate;

    if (date && maxDate) {
      maxDate.value = maxDate.value === undefined ? maxDate : maxDate.value;

      if (moment.utc(date) > moment.utc(maxDate.value)) {
        return {
          matDatepickerMax: true
        };
      } else {
        return null;
      }
    }
    return null;
  };
}
