import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswersDataService } from '@services/answers-data.service';
import { ProgressBarService } from '@services/progress-bar.service';
import { constants } from '@shared/constants/constants';
import { getCurrentProductName } from '@shared/extensions/extensions';
import { NameWithDigitsFormControl } from '@shared/form/name-with-digits-form-control/name-with-digits-form-control';
import { ProfileStepCode } from '@shared/models/profile-step-code';
import { SecurityServiceResponse } from '@shared/webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@shared/webapi/services/customer.service';

@Component({
  selector: 'app-resume-check',
  templateUrl: './resume-check.component.html',
  styleUrls: ['./resume-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResumeCheckComponent implements OnInit {
  public form: FormGroup;
  userId: string;
  otpAnswer: SecurityServiceResponse;
  otpCheckAnswer: SecurityServiceResponse;
  isCodeError: boolean;
  productName: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private progressBarService: ProgressBarService,
    private customerService: CustomerService,
    private answersDataService: AnswersDataService
  ) {}

  codesSubmits: number = 0;

  ngOnInit() {
    this.progressBarService.sendStep(0);
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.initNewFormGroup();
    this.productName = getCurrentProductName();
    this.resendCode();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  initNewFormGroup(): void {
    this.form = new FormGroup({
      code: new NameWithDigitsFormControl(true, false, { capitalizeAll: false, hideErrorMessages: true, hideSuccessMark: true })
    });
  }

  async resendCode(): Promise<void> {
    this.isCodeError = false;
    try {
      this.otpAnswer = await this.customerService.sendOTP(this.userId);
    } catch (err) {
      this.router.navigate(['subscription-time-end']);
    }

    if (this.otpAnswer?.error?.code === constants.codes.blockedProfile || this.otpAnswer?.error?.code === constants.codes.notFound) {
      this.router.navigate(['subscription-time-end']);
    }
  }

  async submit(): Promise<void> {
    this.otpCheckAnswer = await this.customerService.checkOTP(this.userId, this.otpAnswer.transactionId, this.otpAnswer.signatoryId, this.form.get('code').value);

    this.codesSubmits += 1;

    this.form.get('code').patchValue(null);

    if (this.otpCheckAnswer.isSuccessful === true) {
      this.isCodeError = false;
      await this.processSuccessfulOtp();
    }
    if (this.otpCheckAnswer.isSuccessful === false && this.otpCheckAnswer.error?.code === constants.codes.wrongCode) {
      this.isCodeError = true;
    }
    if (this.otpCheckAnswer.isSuccessful === false && this.otpCheckAnswer.error?.code === constants.codes.invalidOtp) {
      this.codesSubmits = 3;
    }
  }

  async processSuccessfulOtp(): Promise<void> {
    const userProfile = await this.customerService.getUserData(this.userId);
    this.answersDataService.initData(userProfile);
    const step = this.answersDataService.getLatestStepRoute();

    if (step === ProfileStepCode.MyProfileStepSummary) {
      this.router.navigate(['my-profile/steps'], { queryParams: { userId: this.userId }, state: { showRecommendation: true } });
    } else {
      this.router.navigate([step], { queryParams: { userId: this.userId } });
    }
  }

  goToNewSubscription(): void {
    this.router.navigate(['membership-check']);
  }

  get isThreeWrongCodes(): boolean {
    return this.codesSubmits === 3;
  }
}
