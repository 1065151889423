import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PortfolioClient, PortfolioPerformance } from '../MIF.Subscription.WebApi';
import { RecomendationProfileFormModel } from '@shared/models/performance';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  private portfolioPerformances: PortfolioPerformance[];
  private portfolioPerformances$ = new Subject<PortfolioPerformance[]>();

  constructor(private webApi: PortfolioClient) {}

  getPortfolioPerformances$(): Observable<PortfolioPerformance[]> {
    return this.portfolioPerformances$.asObservable();
  }

  public async getPortfolioPerformances(refresh: boolean = false): Promise<PortfolioPerformance[]> {
    if (this.portfolioPerformances && !refresh) {
      return new Promise(resolve => {
        resolve(this.portfolioPerformances);
      });
    } else {
      return this.webApi
        .getPortfolioPerformance()
        .pipe(
          tap((response: PortfolioPerformance[]) => {
            this.portfolioPerformances = response;
            this.portfolioPerformances$.next(response);
          })
        )
        .toPromise();
    }
  }

  private async savePortfolioPerformances(): Promise<PortfolioPerformance[]> {
    return this.webApi
      .savePortfolioPerformance(this.portfolioPerformances)
      .pipe(
        tap((response: PortfolioPerformance[]) => {
          this.portfolioPerformances = response;
          this.portfolioPerformances$.next(response);
        })
      )
      .toPromise();
  }

  public async savePortfolioPerformanceForm(model: RecomendationProfileFormModel): Promise<PortfolioPerformance[]> {
    const indexOfUpdatingYear = this.portfolioPerformances.findIndex((item: PortfolioPerformance) => {
      return item.managementTypeId === model.managementTypeId && item.profileTypeId === model.profileTypeId && item.productTypeId === model.productType && !!item.year;
    });

    const indexOfUpdatingRange = this.portfolioPerformances.findIndex((item: PortfolioPerformance) => {
      return item.managementTypeId === model.managementTypeId && item.profileTypeId === model.profileTypeId && item.productTypeId === model.productType && !!item.range;
    });

    if (indexOfUpdatingYear !== -1) {
      this.portfolioPerformances[indexOfUpdatingYear].year = model.year;
      this.portfolioPerformances[indexOfUpdatingYear].value = model.performanceYear;
    } else {
      this.portfolioPerformances.push({
        productTypeId: model.productType,
        managementTypeId: model.managementTypeId,
        profileTypeId: model.profileTypeId,
        year: model.year,
        value: model.performanceYear
      } as PortfolioPerformance);
    }

    if (indexOfUpdatingRange !== -1) {
      this.portfolioPerformances[indexOfUpdatingRange].range = model.range;
      this.portfolioPerformances[indexOfUpdatingRange].value = model.performanceRange;
    } else {
      this.portfolioPerformances.push({
        productTypeId: model.productType,
        managementTypeId: model.managementTypeId,
        profileTypeId: model.profileTypeId,
        range: model.range,
        value: model.performanceRange
      } as PortfolioPerformance);
    }

    // this.portfolioPerformances = [];

    return this.savePortfolioPerformances();
  }
}

export enum PerformanceProductType {
  CEMS_CIMS = 1,
  PERI = 2
}
