import { Pipe, PipeTransform } from '@angular/core';
import { LabelsDataService } from '@services/labels-data.service';
import '@extensions/string.extensions';

@Pipe({
  name: 'labelText'
})

export class LabelTextPipe implements PipeTransform {
  constructor(private labelsDataService: LabelsDataService) {

  }
  
  transform(labelKey: string, ...replacements: any[]): string {
    if (!labelKey) {
      return '';
    }

    try {
      const label = this.labelsDataService.getData(labelKey) + '';
      return label.myFormatString(replacements);
    }
    catch
    {
      return 'LABEL_ERROR';
    }
  }
}
