import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NameAutocompleteFormControl } from './name-autocomplete-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-name-autocomplete-form-control',
    templateUrl: './name-autocomplete-form-control.component.html',
})
export class NameAutocompleteFormControlComponent extends AbstractFormControlComponent<NameAutocompleteFormControl> implements OnChanges {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() autocompleteOptions: any[] = [];

    faCheck: any = faCheck;

    filteredOptions: Observable<any[]>;
    prevValue: string;

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes?.parentForm && changes?.parentForm.currentValue !== undefined) {
        //     this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
        //         startWith(''),
        //         map(state => (state ? this._filterOptions(state) : this.autocompleteOptions?.slice())),
        //     );
        // }

        if (changes?.autocompleteOptions && changes?.autocompleteOptions.currentValue !== undefined) {

            this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
                startWith(''),
                map(state => (state ? this._filterOptions(state) : this.autocompleteOptions?.slice())),
            );

            if (changes?.autocompleteOptions.currentValue.length > 0) {
                const value = this.parentForm.get(this.controlName).value;

                this.filteredOptions = this.filteredOptions.pipe(
                    map(item => (value ? this._filterOptions(value) : this.autocompleteOptions?.slice()))
                )
            }
        }
    }

    onInputValueChange(event: any): void {
        this.prevValue = this.parentForm.get(this.controlName).value;

        this.filteredOptions = this.parentForm.get(this.controlName).valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filterOptions(state) : this.autocompleteOptions?.slice()))
        );
    }

    itemSelected(event: any): void {
        let selectedValue = event.option.value.allToUpperCase();
        let value = '';

        if (this.prevValue && this.prevValue.indexOf(' ') !== -1) {
            let splittedValue = this.prevValue.split(' ');
            let firstPart = splittedValue[0];
            if (firstPart.isNumeric()) {
                value = `${firstPart} `;
            }
        } else {
            if (this.prevValue?.isNumeric()) {
                value = `${this.prevValue} `.replace('  ', ' ');
            }
        }
        value += selectedValue;

        this.parentForm.get(this.controlName).patchValue(value);
    }

    public get maxLength(): number {
        return this.control.maxLength;
    }

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    public get autocompleteLabelField(): string {
        return this.control.autocompleteLabelField;
    }

    public get escapeFirstDigits(): boolean {
        return this.control.escapeFirstDigits;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }

    private _filterOptions(value: string): any[] {
        let filterValue = value.toLowerCase();

        if (filterValue && this.escapeFirstDigits && filterValue.indexOf(' ') !== -1) {
            let splittedValue = filterValue.split(' ');
            let firstPart = splittedValue[0];
            if (firstPart.isNumeric()) {
                filterValue = filterValue.replace(`${firstPart} `, '');
            }
        } else if (filterValue && this.escapeFirstDigits && filterValue.indexOf(' ') === -1) {
            if (filterValue.isNumeric()) {
                filterValue = '';
            }
        }

        return this.autocompleteOptions?.filter(item => item[this.autocompleteLabelField]?.toLowerCase().includes(filterValue));
    }
}
