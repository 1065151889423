import { FormControl } from '@angular/forms';

export function phoneValidator() {
    return (control: FormControl) => {
        const phone = control.value;

        if (phone) {
            if (!/^(0?)(([1-5]|[8-9]){1})([0-9]{8})$/.test(phone)) {
                return {
                    phoneValidator: true
                };
            } else {
                return null;
            }
        }

        return null;
    };
}
