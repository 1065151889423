import { debounceTime } from 'rxjs/operators';
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appDebounce]'
})
export class DebounceDirective {
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks.pipe(debounceTime(100)).subscribe(e => {
      this.debounceClick.emit(e);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
