import { Pipe, PipeTransform } from '@angular/core';
import { AgeScore } from '@webapi/MIF.Subscription.WebApi';

@Pipe({
  name: 'range'
})
export class RangePipe implements PipeTransform {
  constructor() {

  }

  transform(range: AgeScore): string {
    let res = '';

    if (!range) {
      return res;
    }

    res = `De ${range.minAge} à ${range.maxAge} ans`;

    return res;
  }
}
