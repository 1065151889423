import { ValidatorFn } from '@angular/forms';
import { memberNumberValidator } from '../../validators/memberNumberValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class MemberNumberFormControl extends AbstractFormControl {
    constructor(required?: boolean, disabled?: boolean, private options?: MemberNumberOptions) {
        super(required, disabled);
        this.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [memberNumberValidator()];
    }
}

export interface MemberNumberOptions {
    readOnlyCss?: boolean;
}
