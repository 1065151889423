import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { constants } from '@constants/constants';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { CustomerService } from '@webapi/services/customer.service';
import { General, Link, Profile } from '@webapi/MIF.Subscription.WebApi';
import { environment } from '@environments/environment';
import * as version from '@environments/versions';
import { SettingsService } from '@webapi/services/settings.service';
import { isCims } from '@extensions/extensions';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {
  isPrevPageExists = true;
  recommendationShowed = false;
  clientProfile: Profile;
  generalConfiguration: General;
  linksInTheFooter: Link[];
  isCims: boolean = isCims();

  constructor(
    public router: Router,
    private customerService: CustomerService,
    private dateAdapter: DateAdapter<Date>,
    private wsReferentielMetierService: WsReferentielMetierService,
    private settingsService: SettingsService
  ) {
    this.dateAdapter.setLocale('fr-FR');

    this.router.events.subscribe((event: any) => {
      if (event.url) {
        const url = event.url;
        if (url.indexOf('membership-check') === -1 && url !== '/' && url.indexOf('subscription-time-end') === -1 && url.indexOf('success') === -1 && url.indexOf('subscription-complete') === -1) {
          this.isPrevPageExists = true;
        } else {
          this.isPrevPageExists = false;
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.isPrevPageExists = false;
    this.generalConfiguration = await this.settingsService.getGeneralConfiguration();
    this.linksInTheFooter = this.generalConfiguration.footerLinks.filter((link: Link) => {
      return link.label !== constants.links.footerDocIdName && link.label !== constants.links.termsTransferOfContractDocIdName;
    });
    console.log('version: ' + JSON.stringify(version));
  }

  async navigateToPrevPage(): Promise<void> {
    const userId = this.customerService.userId;

    if (this.router.url.indexOf('success') !== -1) {
      this.router.navigate(['signature'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('phone-confirmation') !== -1) {
      this.router.navigate(['signature'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('signature') !== -1) {
      this.router.navigate(['my-details'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('my-details') !== -1) {
      this.router.navigate(['my-beneficiaries'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('my-beneficiaries/clause') !== -1) {
      this.router.navigate(['my-beneficiaries'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('my-beneficiaries/contract') !== -1) {
      this.router.navigate(['my-beneficiaries'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('my-beneficiaries/nominative') !== -1 || this.router.url.indexOf('my-beneficiaries/libre') !== -1) {
      if (this.isCims) {
        this.router.navigate(['my-beneficiaries/clause'], { queryParams: { userId } });
      } else {
        this.router.navigate(['my-beneficiaries'], { queryParams: { userId } });
      }
      return;
    }
    if (this.router.url.indexOf('my-beneficiaries') !== -1) {
      this.clientProfile = await this.customerService.getUserData();

      if (this.clientProfile.isRecommendationsFollowed) {
        this.router.navigate(['my-contract'], { queryParams: { userId }, state: { mismatchCheck: true } });
      } else {
        this.router.navigate(['my-contract'], { queryParams: { userId } });
      }
      return;
    }
    if (this.router.url.indexOf('my-contract') !== -1) {
      if (environment.orgId === constants.orgId.gpa.value || environment.orgId === constants.orgId.gfd.value) {
        this.router.navigate(['my-profile'], { queryParams: { userId: this.customerService.userId } });
        return;
      } else {
        this.router.navigate(['my-profile/steps'], { queryParams: { userId: this.customerService.userId }, state: { showRecommendation: true } });
        this.recommendationShowed = true;
        return;
      }
    }
    if (this.router.url.indexOf('my-profile/steps') !== -1 && history.state.showRecommendation && !this.recommendationShowed) {
      this.router.navigate(['my-profile/steps'], { queryParams: { userId: this.customerService.userId } });
      this.recommendationShowed = true;
      return;
    }
    if (this.router.url.indexOf('my-profile/steps') !== -1) {
      this.router.navigate(['my-profile'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('my-profile') !== -1) {
      this.router.navigate(['residence-questions'], { queryParams: { userId } });
      return;
    }
    if (this.router.url.indexOf('residence-questions') !== -1) {
      this.router.navigate(['membership-check'], { queryParams: { userId } });
      return;
    }
  }

  async onFooterLinkClick(link: Link): Promise<void> {
    if (link.typeCode === constants.footerLinksTypes.url) {
      window.open(link.value, '_blank');
    }

    if (link.typeCode === constants.footerLinksTypes.document) {
      const fileBase64 = await this.wsReferentielMetierService.getCemsDocumentsProduit(parseInt(link.value, 10));
      const base64 = `data:application/pdf;base64,${fileBase64}`;
      const blob = await (await fetch(base64)).blob();
      const fileURL = URL.createObjectURL(blob);

      window.open(fileURL, '_blank');
    }
  }
}
