import { Validators, ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';
import { mifAdvisorCodeValidator } from '@shared/validators/mifAdvisorCodeValidator';

export class MifadvisorCodeFormControl extends AbstractFormControl {
  constructor(required: boolean = false, disabled: boolean = false, private options?: MifadvisorCodeOptions) {
    super(required, disabled);
    super.setValue(null);
  }

  public get readOnlyCss(): boolean {
    return this.options?.readOnlyCss;
  }

  protected getValidators(): ValidatorFn[] {
    return [Validators.maxLength(4), mifAdvisorCodeValidator()];
  }

  get hideSuccessMark(): boolean {
    return this.options?.hideSuccessMark;
  }
}

export interface MifadvisorCodeOptions {
  readOnlyCss?: boolean;
  hideSuccessMark?: boolean;
}
