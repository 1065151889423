import { Component, OnInit } from '@angular/core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { getFooterDocFileBase64, getFooterDocLinkText } from '@extensions/env-related-extensions';
import { General, GpaConfiguration } from '@webapi/MIF.Subscription.WebApi';
import { SettingsService } from '@webapi/services/settings.service';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { Router } from '@angular/router';
import { getCurrentProductType } from '@shared/extensions/extensions';
import { constants } from '@shared/constants/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public faQuestionCircle = faQuestionCircle;
  public footerDocText: string;
  public generalConfiguration: General;
  public gpaConfiguration: GpaConfiguration;
  private product: string;

  constructor(public router: Router, private wsReferentielMetierService: WsReferentielMetierService, public settingsService: SettingsService, public labelTextPipe: LabelTextPipe) {}

  async ngOnInit(): Promise<void> {
    this.product = getCurrentProductType();
    this.generalConfiguration = await this.settingsService.getGeneralConfiguration();
    this.gpaConfiguration = await this.settingsService.getGpaConfiguration();
    this.footerDocText = this.labelTextPipe.transform(getFooterDocLinkText());
  }

  async onFooterDocClick(): Promise<void> {
    const fileBase64 = await getFooterDocFileBase64(this.wsReferentielMetierService, this.generalConfiguration.footerLinks);
    const base64 = `data:application/pdf;base64,${fileBase64}`;
    const blob = await (await fetch(base64)).blob();
    const fileURL = URL.createObjectURL(blob);

    window.open(fileURL, '_blank');
  }
}
