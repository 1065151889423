import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@models/validation-error';

export function minAmountValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.brutAmount;
    if (!value) {
      return null;
    }

    if (+value < minValue) {
      return {
        type: ValidationError.MinAmount,
        value: minValue,
        message: `Minimum value is ${minValue} euros`
      };
    }

    return null;
  };
}
