import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Offer, Profile } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';

@Component({
  selector: 'app-signature-gpa-plan',
  templateUrl: './signature-gpa-plan.component.html',
  styleUrls: ['./signature-gpa-plan.component.scss']
})
export class SignatureGpaPlanComponent implements OnInit {
  @Input() clientProfile: Profile;
  @Input() offer: Offer;

  constructor(
    private router: Router,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
  }

  goToMyContract() {
    this.router.navigate(['my-contract'], { queryParams: { userId: this.customerService.userId } });
  }

}
