import { constants } from '@constants/constants';
import { ConditionType, Offer, OfferDescription, OfferSection } from '@webapi/MIF.Subscription.WebApi';
import { collections } from '@constants/collections';
import { FlatOffer } from './offer.extensions';

function getConditionNameById(conditionTypes: ConditionType[], id: number): string {
    return conditionTypes?.filter((type: ConditionType) => type.id === id)[0].label;
}

function getConditionNameByIdFormatted(conditionTypes: ConditionType[], id: number): string {
    if (conditionTypes) {
        switch (id) {
            case constants.conditionTypes.userAge.id: {
                return 'Age de l\'utilisateur au 31/12';
            }
            case constants.conditionTypes.savingsSecurityProfile.id: {
                return 'Profil';
            }
            case constants.conditionTypes.savingsUCDistribtuion.id: {
                return 'Répartition UC';
            }
            case constants.conditionTypes.savingsTransferPact.id: {
                return 'Transfert Pacte';
            }
            case constants.conditionTypes.pensionCapitalGuarantee.id: {
                return 'Capital garanti';
            }
            case constants.conditionTypes.pensionCoveragex2x3.id: {
                return 'Garantie x2 / x3';
            }
            case constants.conditionTypes.sponsorship.id: {
                return 'Offre Parrainage';
            }
            default: return getConditionNameById(conditionTypes, id);
        }
    } else {
        return '';
    }
}

function getConditionValueById(id: number, value: any): string {
    let res = '';

    switch (id) {
        case constants.conditionTypes.userType.id: {
            res = getFormattedValueForArray(value, collections.conditionUserTypes);
            break;
        }
        case constants.conditionTypes.userAge.id: {
            res = getValueForMinMax(value);
            break;
        }
        case constants.conditionTypes.subscriptionAge.id: {
            value = JSON.parse(value);

            const isMinMax = value?.min && value?.max;
            const isMax = value?.max;
            const isMin = value?.min;

            if (isMinMax) {
                res = `Entre ${value?.min} ${getAgeTypeNameById(value?.minType)} ans et ${value?.max} ${getAgeTypeNameById(value?.maxType)} ans (inclus)`;
            } else if (isMax) {
                res = `${value?.max} ${getAgeTypeNameById(value?.maxType)} ans ou moins`;
            } else if (isMin) {
                res = `${value?.min} ${getAgeTypeNameById(value?.minType)} ans ou plus`;
            }
            break;
        }
        case constants.conditionTypes.savingsSecurityProfile.id: {
            res = getFormattedValueForArray(value, collections.conditionSavingsSecurityProfiles);
            break;
        }
        case constants.conditionTypes.savingsUCDistribtuion.id: {
            res = getValueForMinMax(value, '%');
            break;
        }
        case constants.conditionTypes.savingsTransferPact.id: {
            res = getFormattedValueForArray(value, collections.conditionSavingsTransferPacts);
            break;
        }
        case constants.conditionTypes.savingsInitialDeposit.id: {
            const jsonVal = JSON.parse(value);
            const valWithout = jsonVal.valInitial;
            const valScheduled = jsonVal.valScheduled;

            if (valWithout?.min && valWithout?.max) {
                res = `Entre ${valWithout.min}€ et ${valWithout?.max}€ (inclus) `;
            } else if (valWithout?.min) {
                res = `${valWithout?.min}€ ou plus `;
            } else if (valWithout?.max) {
                res = `${valWithout?.max}€ ou moins `;
            }

            const separator = res.length > 0 ? 'ou Versement complémentaire:' : '';

            if (valScheduled?.min && valScheduled?.max) {
                res += `${separator} Entre ${valScheduled.min}€ et ${valScheduled?.max}€ (inclus)`;
            } else if (valScheduled?.min) {
                res += `${separator} ${valScheduled?.min}€ ou plus`;
            } else if (valScheduled?.max) {
                res += `${separator} ${valScheduled?.max}€ ou moins`;
            }
            break;
        }
        case constants.conditionTypes.pensionCapitalGuarantee.id: {
            res = getValueForMinMax(value);
            break;
        }
        case constants.conditionTypes.pensionCoveragex2x3.id: {
            res = getFormattedValueForArray(value, collections.garanteex2x3Types);
            break;
        }
        case constants.conditionTypes.sponsorship.id: {
            res = getFormattedValueForArray(value, collections.sponsorsipTypes);
            break;
        }
        default: {
            res = `${value}`;
        }
    }

    return res;
}

function getFormattedValueForArray(value: any, collection: any[]): string {
    const arr: string[] = [];

    value = JSON.parse(value);

    value.forEach((val: any) => {
        const item = collection.filter((item: any) => {
            return item.id == val;
        })[0];

        arr.push(item?.label);
    });
    return arr.join(', ');
}

function getValueForMinMax(value: any, postfix: string = ''): string {
    let res = '';
    value = JSON.parse(value);
    const isMinMax = value?.min && value?.max;
    const isMax = value?.max;
    const isMin = value?.min;

    if (isMinMax) {
        res = `Entre ${value?.min}${postfix} et ${value?.max}${postfix} (inclus)`;
    } else if (isMax) {
        res = `${value?.max}${postfix} ou moins`;
    } else if (isMin) {
        res = `${value?.min}${postfix} ou plus`;
    }

    return res;
}

function getAgeTypeNameById(id: any): string {
    let res = '';

    res = collections.ageTypes.filter((item: any) => {
        return item.id == id;
    })[0].label;

    return res;
}

function getWithdrawalTypeNameById(id: any): string {
    let res = '';

    res = collections.withdrawalTypes.filter((item: any) => {
        return item.id == id;
    })[0].label;

    return res;
}

function getUpdatedOfferModel(model: FlatOffer, offer: Offer): Offer {
    if (!offer.description) {
        offer.description = new OfferDescription();
    }

    model.offerPages?.forEach((item: OfferSection) => {
        if (item.conditions === undefined || item.conditions?.length === 0) {
            item.overrideConditions = false;
            delete item.conditions;
        } else {
            item.overrideConditions = true;
        }
        if (item.offerText === undefined || item.offerText === null || item.offerText === '') {
            item.overrideOfferText = false;
            delete item.offerText;
        } else {
            item.overrideOfferText = true;
        }
    });

    offer.name = model.name;
    offer.promoCode = model.promoCode;
    offer.priority = model.priority;
    offer.statusId = model.statusId;
    offer.statusStartDate = model.statusStartDate;
    offer.statusEndDate = model.statusEndDate;
    offer.description.text = model.offerDescriptionText;
    offer.description.membershipForm = model.offerDescriptionMembershipForm;
    offer.description.generalConditions = model.offerDescriptionGeneralConditions;
    offer.conditions = model.offerConditions;
    offer.products = model.products.map((item: string) => parseInt(item, 10));
    offer.sections = model.offerPages;
    offer.rate = model.rate;
    offer.paymentCharges = model.paymentCharges;

    return offer;
}

export {
    getConditionNameById,
    getConditionNameByIdFormatted,
    getConditionValueById,
    getAgeTypeNameById,
    getUpdatedOfferModel,
    getWithdrawalTypeNameById
};
