import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { SpinnerService } from '@services/spinner.service';

@Injectable()
export class FilesUploadingInterceptor implements HttpInterceptor {
    constructor(
        private bsModalService: BsModalService,
        private spinnerService: SpinnerService,
        private labelTextPipe: LabelTextPipe
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): any {

        return next.handle(request).pipe(
            catchError(async (error, caught) => {
                if (error instanceof HttpErrorResponse && error.status === 400) {
                    const errorDetails = JSON.parse(await error.error.text());
                    const requestBody = JSON.parse(request.body as string);
                    const invalidFiles = errorDetails.invalidFiles;

                    if (requestBody?.length > 0 && invalidFiles?.length > 0) {
                        const modal = this.bsModalService.show(AlertModalComponent, {
                            initialState: {
                                type: modalTypes.error,
                                title: `${this.labelTextPipe.transform('Common.DocsWithErrorsModalTitle')} :`,
                                body: this.prepareErrorModalBody(requestBody, errorDetails)
                            },
                            class: 'modal-lg'
                        })

                        modal.content.onClose.subscribe(() => {
                            this.spinnerService.isLoading.next(false);
                        })
                    };
                }

                return throwError(error);
            })
        );
    }

    private prepareErrorModalBody(arrOfFiles: any[], errorDetails: any): string {
        const subTitle = this.labelTextPipe.transform('Common.DocsWithErrorsModalSubTitle');
        let body = `<span class='recap-li-font-style'> ${subTitle} <span> <span class="recap-promo compact"> <ul class="list-arrows">`;

        errorDetails.invalidFiles.forEach((invalidFileError: any) => {
            body += `<li> ${arrOfFiles[invalidFileError.index].file?.fileName} </li>`
        })

        body += '</ul> </span>';

        return body;
    }
}
