import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PerformanceType, RecommedationProfile } from '@shared/models/performance';
import { PortfolioPerformance } from '@webapi/MIF.Subscription.WebApi';
import { PerformanceProductType, PortfolioService } from '@webapi/services/portfolio.service';
import { SubscriptionHandler } from '../subscriptionHandler';
import { LabelTextPipe } from '@shared/pipes/label-text.pipe';

@Component({
  selector: 'app-view-performance',
  templateUrl: './view-performance.component.html',
  styleUrls: ['./view-performance.component.scss']
})
export class ViewPerformanceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() productType: PerformanceProductType;
  @Input() isQuestion12AnsweredYes: boolean;
  @Input() type: PerformanceType;
  @Input() profile: RecommedationProfile;
  @Input() onTabChanged: EventEmitter<string> = new EventEmitter();

  portfolioPerformances: PortfolioPerformance[];
  year: PortfolioPerformance;
  range: PortfolioPerformance;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  constructor(public portfolioService: PortfolioService, private labelTextPipe: LabelTextPipe) {}

  async ngOnInit() {
    await this.fetchInitData();
    this.restoreData();
    this.subscriptionHandler.subscriptions = this.onTabChanged.subscribe((type: string) => {
      this.restoreData();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.profile) {
      this.restoreData();
    }
  }

  async fetchInitData(): Promise<void> {
    this.portfolioPerformances = await this.portfolioService.getPortfolioPerformances();
  }

  restoreData(): void {
    if (!this.productType || !this.type || !this.profile || !this.portfolioPerformances) {
      return;
    }

    let profileToUseForFiltering = this.profile.toString();

    // Durable case processing - it means answer #12 is yes
    if (this.type === PerformanceType.GL && this.isQuestion12AnsweredYes === true) {
      switch (this.profile) {
        case RecommedationProfile.Prudent: {
          profileToUseForFiltering = RecommedationProfile.PrudentDurable.toString();
          break;
        }
        case RecommedationProfile.Balanced: {
          profileToUseForFiltering = RecommedationProfile.BalancedDurable.toString();
          break;
        }
        case RecommedationProfile.Dynamic: {
          profileToUseForFiltering = RecommedationProfile.DynamicDurable.toString();
          break;
        }
        case RecommedationProfile.Offensive: {
          profileToUseForFiltering = RecommedationProfile.OffensiveDurable.toString();
          break;
        }
      }
    }

    const indexOfYear = this.portfolioPerformances?.findIndex((item: PortfolioPerformance) => {
      return item.managementTypeId === this.type && item.profileTypeId === +profileToUseForFiltering && item.productTypeId === this.productType && !!item.year;
    });

    const indexOfRange = this.portfolioPerformances?.findIndex((item: PortfolioPerformance) => {
      return item.managementTypeId === this.type && item.profileTypeId === +profileToUseForFiltering && item.productTypeId === this.productType && !!item.range;
    });

    if (indexOfYear !== -1) {
      this.year = this.portfolioPerformances[indexOfYear];
    } else {
      this.year = null;
    }

    if (indexOfRange !== -1) {
      this.range = this.portfolioPerformances[indexOfRange];
    } else {
      this.range = null;
    }
  }

  get isPerformanceFractionWithDecimals(): boolean {
    if (!this.year?.value || !this.range?.value) {
      return false;
    }

    const isYearValueHasDecimal = this.year.value % 1 !== 0;
    const isRangeValueHasDecimal = this.range.value % 1 !== 0;

    return isYearValueHasDecimal || isRangeValueHasDecimal;
  }

  get decimalsCount(): number {
    if (!this.year?.value || !this.range?.value) {
      return 0;
    }

    const yearsValueDecimalCount = (this.year.value + '').split('.')?.[1]?.length ?? 0;
    const rangeValueDecimalCount = (this.range.value + '').split('.')?.[1]?.length ?? 0;

    return yearsValueDecimalCount > rangeValueDecimalCount ? yearsValueDecimalCount : rangeValueDecimalCount;
  }

  get tooltipText(): string {
    let text = '';

    if (!this.type || !this.profile || !this.portfolioPerformances) {
      return text;
    } else {
      switch (this.type) {
        case PerformanceType.GL: {
          text = this.labelTextPipe.transform('Recommendation.PerformanceTypeGLTooltip');
          break;
        }
        case PerformanceType.GSM: {
          text = this.labelTextPipe.transform('Recommendation.PerformanceTypeGSMTooltip');
          break;
        }
        case PerformanceType.GH: {
          text = this.labelTextPipe.transform('Recommendation.PerformanceTypeGHTooltip');
          break;
        }
      }
    }

    return text;
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}
