<app-kameleoon></app-kameleoon>

<ng-template #downloadDocumentCell let-fund="fund">
  <td class="download-icon-cell">
    <div class="lds-dual-ring" *ngIf="fund.get('busy').value"></div>
    <span *ngIf="!fund.get('busy').value"><img class="download-icon" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, fund)" /></span>
  </td>
</ng-template>

<ng-template #headerCells let-filterObj="filterObj" let-isUC="isUC">
  <thead>
    <tr>
      <th scope="col" class="table-header riscue">
        {{ 'FUNDS.Risk' | labelText }}
        <app-tooltip [title]="'Funds.RiskTooltipTitle' | labelText" [text]="'Funds.RiskTooltipText' | labelText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header name">
        {{ 'FUNDS.FundNameIsinCode' | labelText }}
      </th>
      <th scope="col" class="table-header company">
        {{ 'FUNDS.ManagementCompany' | labelText }}
      </th>
      <th scope="col" class="table-header zone">
        {{ 'Common.AssetClassZone' | labelText }}
      </th>
      <th scope="col" class="table-header labels">
        {{ 'Common.Label' | labelText }}
        <app-tooltip [class]="'tooltip640'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header sfdr">
        {{ 'Funds.SFDRTitle' | labelText }}
        <app-tooltip [class]="'tooltip640'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header performance">
        {{ 'Common.Performance' | labelText }}
        <span class="icon icon-filter filter-menu">
          <div class="filter-content">
            <ul class="list-unstyled" *ngIf="!isUC">
              <li *ngFor="let perf of collections.performances">
                <a [class.active]="isFilterItemActive(filterObj, perf)" href="javascript: void(0)" (click)="onFilterChange(filterObj, perf.id)">{{ perf.label }}</a>
              </li>
            </ul>
            <ul class="list-unstyled" *ngIf="isUC">
              <li *ngFor="let perf of collections.performancesUc">
                <a [class.active]="isFilterItemActive(filterObj, perf)" href="javascript: void(0)" (click)="onFilterChange(filterObj, perf.id)">{{ perf.label }}</a>
              </li>
            </ul>
          </div>
        </span>
        <app-tooltip [title]="'Funds.FundPerfTooltipTitle' | labelText" [text]="'Funds.FundPerfTooltipText' | labelText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header download">
        {{ 'FUNDS.RegulatoryDocumentHelpTitle' | labelText }}
        <app-tooltip [title]="'Funds.RegulatoryDocumentHelpTooltipTitle' | labelText" [text]="'Funds.RegulatoryDocumentHelpTooltipText' | labelText"> </app-tooltip>
      </th>
    </tr>
  </thead>
</ng-template>

<ng-template #headerCellsScpi let-filterObj="filterObj">
  <thead>
    <tr>
      <th scope="col" class="table-header riscue">
        {{ 'FUNDS.Risk' | labelText }}
        <app-tooltip [title]="'Funds.RiskTooltipTitle' | labelText" [text]="'Funds.RiskTooltipText' | labelText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header name">
        {{ 'FUNDS.FundNameIsinCode' | labelText }}
      </th>
      <th scope="col" class="table-header company">
        {{ 'FUNDS.ManagementCompany' | labelText }}
      </th>
      <th scope="col" class="table-header zone">
        {{ 'Common.AssetClassZone' | labelText }}
      </th>
      <th scope="col" class="table-header labels">
        {{ 'Common.Label' | labelText }}
        <app-tooltip [class]="'tooltip640'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header sfdr">
        {{ 'Funds.SFDRTitle' | labelText }}
        <app-tooltip [class]="'tooltip640'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
      </th>
      <th scope="col" class="table-header performance">
        {{ 'FUNDS.LastYearPerformance' | labelText }}
      </th>
      <th scope="col" class="table-header download">
        {{ 'FUNDS.RegulatoryDocumentHelpTitle' | labelText }}
        <app-tooltip [title]="'Funds.RegulatoryDocumentHelpTooltipTitle' | labelText" [text]="'Funds.RegulatoryDocumentHelpTooltipText' | labelText"> </app-tooltip>
      </th>
    </tr>
  </thead>
</ng-template>

<div class="mb-5 distributions">
  <div class="card can-followed-error" *ngIf="currentStep == stepType.FUNDS_SELECTION">
    <app-i-advize *ngIf="userProfile" [pageType]="ProfileStepName.MyContracts1" [clientProfile]="userProfile"></app-i-advize>

    <div class="row">
      <div class="col-lg-12">
        <h1 class="section-title">{{ 'FUNDS.YourFundsSelection' | labelText }}</h1>
      </div>
    </div>
    <!-- todo -->
    <div class="row">
      <div class="col-lg-12">
        <p class="h6">{{ 'FUNDS.Subtitle1' | labelText }} {{ fondsCount }} {{ 'FUNDS.Subtitle2' | labelText }}</p>

        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-8">
            <p class="warning-text" *ngIf="isUcSelectionRequired()">
              {{ 'FUNDS.SelectAtLeastOneUCToContinue' | labelText : maxEuroFundFraction }}
            </p>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
            <div class="error-block scpiMin">
              {{ 'FUNDS.MinimunInvestmentForScpi' | labelText }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="title">{{ 'FUNDS.UC' | labelText }}</p>
          </div>
        </div>

        <div class="row hidden-xs hidden-sm">
          <div class="col ox-s" [formGroup]="contractFormGroup">
            <table class="table fonds-table" formArrayName="fondsSCPI" *ngIf="fondsSCPI.length > 0">
              <ng-container *ngTemplateOutlet="headerCellsScpi; context: { filterObj: scpiFilters }"></ng-container>
              <tbody>
                <tr
                  *ngFor="let fond of fondsSCPI.controls; let i = index"
                  [formGroupName]="i"
                  (click)="onFondSelected(fond)"
                  [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
                >
                  <td>
                    <input type="checkbox" formControlName="selected" *ngIf="fond.get('showSelectInput').value" />
                    <app-tooltip [title]="'Common.MinimumPayment' | labelText" [text]="getFondTooltipText(fond)"> </app-tooltip>
                    <strong>{{ fond.get('fond').value.risk }}</strong>
                  </td>
                  <td>
                    <span>{{ fond.get('fond').value.name }}</span> <br />{{ fond.get('fond').value.codeISIN }}
                  </td>
                  <td>{{ fond.get('fond').value.managementCompanyName }}</td>
                  <td>
                    {{ fond.get('fond').value.assetClass }}
                    <br />
                    {{ getZonesHtml(fond) }}
                  </td>
                  <td>{{ fond.get('fond').value.signatairePRI }}</td>
                  <td>{{ fond.get('fond').value.classificationInvestissementResponsable }}</td>
                  <td>
                    {{ getPerformance(scpiFilters, fond.get('fond').value.performance) }}
                  </td>
                  <ng-container *ngTemplateOutlet="downloadDocumentCell; context: { fund: fond }"></ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="table-fake hidden-md hidden-lg" [formGroup]="contractFormGroup">
          <div formArrayName="fondsSCPI">
            <div
              *ngFor="let fond of fondsSCPI.controls; let i = index"
              [formGroupName]="i"
              (click)="onFondSelected(fond)"
              class="fonds-table-mobile"
              [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
            >
              <div class="row mt-2">
                <div class="col">
                  <input type="checkbox" formControlName="selected" *ngIf="fond.get('showSelectInput').value" />
                  <app-tooltip [title]="'Common.MinimumPayment' | labelText" [text]="getFondTooltipText(fond)"> </app-tooltip>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.Risk' | labelText }} :
                  {{ fond.get('fond').value.risk }}
                  <app-tooltip [title]="'FUNDS.RiskTooltipTitle' | labelText" [text]="'FUNDS.RiskTooltipText' | labelText"> </app-tooltip>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <strong>{{ fond.get('fond').value.name }}</strong>
                  <br />
                  {{ fond.get('fond').value.codeISIN }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.ManagementCompany' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.managementCompanyName }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.AssetClassZone' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.assetClass }}
                  <br />
                  {{ getZonesHtml(fond) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.LabelUpperCase' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.signatairePRI }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.SFDRTitle' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.classificationInvestissementResponsable }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.Performance' | labelText }} :
                  <br />
                  {{ getPerformance(scpiFilters, fond.get('fond').value.performance) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.RegulatoryDocumentHelpTitle' | labelText }} :
                  <br />
                  <br />
                  <span class="download-icon-cell">
                    <div class="lds-dual-ring" *ngIf="fond.get('busy').value"></div>
                    <span *ngIf="!fond.get('busy').value"><img class="download-icon" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, fond)" /></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 hidden-xs hidden-sm">
          <div class="col ox-s" [formGroup]="contractFormGroup">
            <table class="table fonds-table" formArrayName="fondsOther" *ngIf="fondsOther.length > 0">
              <ng-container *ngTemplateOutlet="headerCells; context: { filterObj: ucFilters, isUC: true }"></ng-container>
              <tbody>
                <tr
                  *ngFor="let fond of fondsOther.controls; let i = index"
                  [formGroupName]="i"
                  (click)="onFondSelected(fond)"
                  [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
                >
                  <td>
                    <input type="checkbox" formControlName="selected" />
                    <strong class="risk-other">{{ fond.get('fond').value.risk }}</strong>
                  </td>
                  <td>{{ fond.get('fond').value.name }} <br />{{ fond.get('fond').value.codeISIN }}</td>
                  <td>{{ fond.get('fond').value.managementCompanyName }}</td>
                  <td>
                    {{ fond.get('fond').value.assetClass }}
                    <br />
                    {{ getZonesHtml(fond) }}
                  </td>
                  <td>{{ fond.get('fond').value.signatairePRI }}</td>
                  <td>{{ fond.get('fond').value.classificationInvestissementResponsable }}</td>
                  <td>
                    {{ getPerformance(ucFilters, fond.get('fond').value.performance) }}
                  </td>
                  <ng-container *ngTemplateOutlet="downloadDocumentCell; context: { fund: fond }"></ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="table-fake hidden-md hidden-lg mt-4" [formGroup]="contractFormGroup">
          <div formArrayName="fondsOther">
            <div
              *ngFor="let fond of fondsOther.controls; let i = index"
              [formGroupName]="i"
              (click)="onFondSelected(fond)"
              class="fonds-table-mobile"
              [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
            >
              <div class="row mt-2">
                <div class="col">
                  <input type="checkbox" formControlName="selected" *ngIf="fond.get('showSelectInput').value" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.Risk' | labelText }} :
                  {{ fond.get('fond').value.risk }}
                  <app-tooltip [title]="'FUNDS.RiskTooltipTtitle' | labelText" [text]="'FUNDS.RiskTooltipText' | labelText"> </app-tooltip>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <strong>{{ fond.get('fond').value.name }}</strong>
                  <br />
                  {{ fond.get('fond').value.codeISIN }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.ManagementCompany' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.managementCompanyName }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.AssetClassZone' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.assetClass }}
                  <br />
                  {{ getZonesHtml(fond) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.LabelUpperCase' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.signatairePRI }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.SFDRTitle' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.classificationInvestissementResponsable }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.Performance' | labelText }} :
                  <br />
                  {{ getPerformance(ucFilters, fond.get('fond').value.performance) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.RegulatoryDocumentHelpTitle' | labelText }} :
                  <br />
                  <br />
                  <span class="download-icon-cell">
                    <div class="lds-dual-ring" *ngIf="fond.get('busy').value"></div>
                    <span *ngIf="!fond.get('busy').value"><img class="download-icon" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, fond)" /></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="title">{{ 'FUNDS.EuroFundsText' | labelText }}</p>
          </div>
        </div>

        <div class="row hidden-xs hidden-sm">
          <div class="col ox-s" [formGroup]="contractFormGroup">
            <table class="table fonds-table" formArrayName="fondsEuro" *ngIf="fondsEuro.length > 0">
              <thead>
                <tr>
                  <th scope="col" class="table-header"></th>
                  <th scope="col" class="table-header">
                    {{ 'FUNDS.FundName' | labelText }}
                  </th>
                  <th scope="col" class="table-header">
                    {{ 'Common.AssetClassZone' | labelText }}
                  </th>
                  <th scope="col" class="table-header labels">
                    {{ 'Common.Label' | labelText }}
                    <app-tooltip [class]="'tooltip640'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
                  </th>
                  <th scope="col" class="table-header sfdr">
                    {{ 'Funds.SFDRTitle' | labelText }}
                    <app-tooltip [class]="'tooltip640'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
                  </th>
                  <th scope="col" class="table-header">{{ 'Funds.LastYearPerformance' | labelText }}</th>
                  <th scope="col" class="table-header">
                    {{ 'FUNDS.FinancialInformation' | labelText }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let fond of fondsEuro.controls; let i = index"
                  [formGroupName]="i"
                  (click)="onFondSelected(fond)"
                  [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
                >
                  <td>
                    <input type="checkbox" formControlName="selected" />
                  </td>
                  <td>{{ fond.get('fond').value.name }} <br />{{ fond.get('fond').value.codeISIN }}</td>
                  <td>
                    {{ fond.get('fond').value.assetClass }}
                    <br />
                    {{ getZonesHtml(fond) }}
                  </td>
                  <td>{{ fond.get('fond').value.signatairePRI }}</td>
                  <td>{{ fond.get('fond').value.classificationInvestissementResponsable }}</td>
                  <td>
                    {{ getPerformance(euroFilters, fond.get('fond').value.performance) }}
                  </td>
                  <ng-container *ngTemplateOutlet="downloadDocumentCell; context: { fund: fond }"></ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="table-fake hidden-md hidden-lg" [formGroup]="contractFormGroup">
          <div formArrayName="fondsEuro">
            <div
              *ngFor="let fond of fondsEuro.controls; let i = index"
              [formGroupName]="i"
              (click)="onFondSelected(fond)"
              class="fonds-table-mobile"
              [ngClass]="{ disabledFond: fond.get('fond').value.disabled === true, hiddenFond: fond.get('fond').value.hidden === true }"
            >
              <div class="row mt-2">
                <div class="col">
                  <input type="checkbox" formControlName="selected" *ngIf="fond.get('showSelectInput').value" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.Risk' | labelText }} :
                  {{ fond.get('fond').value.risk }}
                  <app-tooltip [title]="'FUNDS.RiskTooltipTitle' | labelText" [text]="'FUNDS.RiskTooltipText' | labelText"> </app-tooltip>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <strong>{{ fond.get('fond').value.name }}</strong>
                  <br />
                  {{ fond.get('fond').value.codeISIN }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'FUNDS.ManagementCompany' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.managementCompanyName }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.AssetClassZone' | labelText }} :
                  <br />
                  {{ fond.get('fond').value.assetClass }}
                  <br />
                  {{ getZonesHtml(fond) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.LabelUpperCase' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.LabelTooltipTitle' | labelText" [text]="thLabelsTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.signatairePRI }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.SFDRTitle' | labelText }} : <app-tooltip [class]="'tooltip500'" [title]="'Funds.SFDRTooltipTitle' | labelText" [text]="thSfdrTooltipText"> </app-tooltip>
                  <br />
                  {{ fond.get('fond').value.classificationInvestissementResponsable }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Common.Performance' | labelText }} :
                  <br />
                  {{ getPerformance(euroFilters, fond.get('fond').value.performance) }}
                </div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  {{ 'Funds.RegulatoryDocumentHelpTitle' | labelText }} :
                  <br />
                  <br />
                  <span class="download-icon-cell">
                    <div class="lds-dual-ring" *ngIf="fond.get('busy').value"></div>
                    <span *ngIf="!fond.get('busy').value"><img class="download-icon" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, fond)" /></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row button-row">
          <div class="col">
            <button type="button" class="btn btn-primary validation-button" [disabled]="!isFundsSelectionValid() || showRecommendationNotFollowedFundStep" (click)="onValidateFondSelection()">
              {{ 'Common.Validate' | labelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="followed-error" *ngIf="!isRecommendationsFollowed && showRecommendationNotFollowedFundStep">
    {{ 'FUNDS.AlertMessageText' | labelText }}
    <span class="link-like" (click)="goToRecomendation()">{{ 'FUNDS.AlertMessageLink' | labelText }}</span
    >&nbsp; <span class="link-like" (click)="goToRappelVersements()">{{ 'FUNDS.AlertMessageTextEnd' | labelText }}</span
    >.
  </div>

  <div class="card disabled" *ngIf="currentStep == stepType.FUNDS_SELECTION">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'PAYMENTS.PaymentReminderIntro' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card disabled" *ngIf="currentStep == stepType.FUNDS_SELECTION">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'DISTRIBUTION.FundsDistributionTitle' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="(currentStep == stepType.RAPPEL_VERSEMENTS && !history?.state?.mismatchCheck) || (currentStep == stepType.REPARTITION && !history?.state?.mismatchCheck)">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'FUNDS.YourFundsSelection' | labelText }}</h1>
            </div>
            <div class="col-6 section-with-details">
              <div class="hidden-xs hidden-sm section-details">
                <span *ngFor="let c of distributionFonds.controls; let i = index">
                  <span *ngIf="i < 3"> {{ c.value.fond.name }} <br /> </span>
                </span>
                <span *ngIf="isDistributionFondsControlsTooltip" class="distribution-fonds-tooltip" pTooltip="{{ distributionFondsControlsTooltipText }}" [escape]="false"> ... </span>
              </div>
              <a class="red-link" href="javascript:void(0)" (click)="goToFondSelection()">
                {{ 'Common.Modify' | labelText }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card oui-card can-followed-error" *ngIf="currentStep == stepType.RAPPEL_VERSEMENTS">
    <app-i-advize *ngIf="userProfile" [pageType]="ProfileStepName.MyContracts2" [clientProfile]="userProfile"></app-i-advize>

    <div class="card-body">
      <app-view-offer [clientProfile]="userProfile" [pageName]="constants.pages.paymentsReminder.name" (onOffersCountChanged)="onOffersCountChanged($event)" [class]="'smart'"></app-view-offer>

      <h1 class="text-left fw-600 mt-2 mb-4">{{ 'PAYMENTS.PaymentReminderIntro' | labelText }}</h1>

      <form [formGroup]="mismatchCheckForm" *ngIf="mismatchCheckForm">
        <div class="row" *ngIf="isInitialPaymentAmount">
          <span class="separated-title" [ngClass]="offersCount > 0 ? 'w70' : 'w100'"> {{ 'PAYMENTS.InitialPaymentAmountTitle' | labelText }} </span>
          <div class="col-lg-5 col-md-7 col-xs-12 mb-2">
            <app-number-form-control [parentForm]="mismatchCheckForm" [controlName]="'amount'" class="no-title" [title]="''" [fraisInclus]="true"> </app-number-form-control>
          </div>
        </div>

        <div class="row" *ngIf="isTransferableContractAmount">
          <span class="separated-title" [ngClass]="offersCount > 0 ? 'w70' : 'w100'">{{ isTransferableContractAmountTitle }}</span>
          <div class="col-lg-5 col-md-7 col-xs-12 mb-2">
            <app-number-form-control [parentForm]="mismatchCheckForm" [controlName]="'transferableContractAmount'" class="no-title" [title]="''" [aCeJour]="true"> </app-number-form-control>
          </div>
        </div>

        <div class="row" *ngIf="isFreePaymentAmount">
          <span class="separated-title" [ngClass]="offersCount > 0 ? 'w70' : 'w100'">{{ 'PAYMENTS.PaymentFormComplementTitle' | labelText }}</span>
          <div class="col-lg-5 col-md-7 col-xs-12 mb-2">
            <app-number-form-control [parentForm]="mismatchCheckForm" [controlName]="'freePaymentAmount'" class="no-title" [title]="''" [fraisInclus]="true"> </app-number-form-control>
          </div>
        </div>

        <div class="row" *ngIf="isScheduledPaymentAmount">
          <span class="separated-title">{{ 'PAYMENTS.PaymentFormProgramAmountTitle' | labelText }}</span>
          <div class="col-lg-5 col-md-7 col-xs-12 mb-2">
            <app-number-form-control [parentForm]="mismatchCheckForm" [controlName]="'reqularPayments'" class="no-title" [title]="''" [fraisInclus]="true"> </app-number-form-control>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 text-center">
            <button type="submit" class="btn btn-blue valider" [disabled]="showRecommendationNotFollowedRappelStep && !isRecommendationsFollowed" (click)="onValidatePayments()">
              {{ 'Common.Validate' | labelText }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="followed-error" *ngIf="!isRecommendationsFollowed && showRecommendationNotFollowedRappelStep && userProfile.managementTypeCode !== constants.gestionLibre.id">
    {{ 'FUNDS.AlertMessageText' | labelText }}
    <span class="link-like" (click)="goToRecomendation()">{{ 'FUNDS.AlertMessageLink' | labelText }}</span
    >&nbsp; <span class="link-like" (click)="goToRepartitionStep()">{{ 'FUNDS.AlertMessageTextEnd' | labelText }}</span
    >.
  </div>

  <div class="card disabled" *ngIf="currentStep == stepType.RAPPEL_VERSEMENTS && !history?.state?.mismatchCheck">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'DISTRIBUTION.FundsDistributionTitle' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="currentStep == stepType.REPARTITION">
    <app-i-advize *ngIf="userProfile" [pageType]="ProfileStepName.MyContracts3" [clientProfile]="userProfile"></app-i-advize>

    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'PAYMENTS.PaymentReminderIntro' | labelText }}</h1>
            </div>
            <div class="col-6 section-with-details">
              <div class="hidden-xs hidden-sm section-details">
                <span *ngIf="isInitialPaymentAmount">{{ 'PAYMENTS.InitialPayment' | labelText }} {{ userProfile.initialPaymentAmount | euro }} <br /></span>
                <span *ngIf="isTransferableContractAmount">{{ 'PAYMENTS.AccountMIFTransfer' | labelText }} {{ userProfile.transferContractCode }} <br /> </span>
                <span *ngIf="isFreePaymentAmount">{{ 'PAYMENTS.AccountFreePayment' | labelText }} {{ userProfile.initialPaymentAmount | euro }} <br /> </span>
                <span *ngIf="isScheduledPaymentAmount"
                  >{{ 'PAYMENTS.ProgrammedPayment1' | labelText }} {{ userProfile.scheduledPaymentAmount | euro }}{{ 'PAYMENTS.ProgrammedPayment2' | labelText }}</span
                >
              </div>
              <a class="red-link empty" href="javascript:void(0)" (click)="goToFondSelection()"> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card can-followed-error" *ngIf="currentStep == stepType.REPARTITION">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="text-left fw-600 mt-2 mb-1">{{ 'DISTRIBUTION.FundsDistributionTitle' | labelText }}</h1>

          <p class="h6">
            {{ fundDistributionExlpanationLabelKey | labelText }}
          </p>
        </div>
      </div>

      <div class="row hidden-xs hidden-sm">
        <div class="col ox-s" [formGroup]="contractFormGroup">
          <table class="table distribution-table" formArrayName="distributionFonds" *ngIf="distributionFonds.length > 0">
            <thead>
              <tr>
                <th></th>
                <th class="table-header text-blue text-center" scope="row">
                  {{ 'DISTRIBUTION.ToBeDistributed' | labelText }}
                </th>
                <th scope="col" class="w155">
                  <span class="ditstribution-header fs-14 fw-600">{{ remainsToDistibuteAmount | euro }}</span>
                </th>
                <th scope="col">
                  <span class="ditstribution-header fs-14 fw-600">{{ remainsToDistibuteFraction | fraction }} </span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let c of distributionFonds.controls; let i = index" [formGroupName]="i">
                <tr>
                  <td class="risk-column" [style.border-left-color]="getRiskColor(c.value.fond.risk)">
                    {{ c.value.fond.name }}<br />{{ c.get('fond').value.codeISIN }}<br />{{ c.get('fond').value.assetClass }}
                    <br />
                    {{ getZonesHtml(c) }}
                    <br *ngIf="c.value.fond.type === scpiFundType" />
                    <p *ngIf="c.value.fond.type === scpiFundType" class="scpiFeesText full-table">
                      {{ 'Common.FeesInCaseWithdrawalText' | labelText : c.get('fond').value.feesInCaseWithdrawal }}
                    </p>
                  </td>
                  <td class="gross-icon-cell" [ngSwitch]="c.value.fond.type">
                    <img *ngSwitchCase="scpiFundType" class="gross-icon" src="../../../assets/img/montant-brut-2.svg" alt="" />
                    <img *ngSwitchDefault class="gross-icon" src="../../../assets/img/montant-brut-net-2.svg" alt="" />
                  </td>
                  <td class="centered mw-120" [ngSwitch]="c.value.fond.type">
                    <div *ngSwitchCase="scpiFundType">
                      <app-distribution-form-control [parentForm]="c" [controlName]="'brutAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                    </div>
                    <div *ngSwitchDefault class="distribution-values-default">
                      <app-distribution-form-control [parentForm]="c" [controlName]="'brutAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                      <app-distribution-form-control [parentForm]="c" [controlName]="'netAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                    </div>
                  </td>
                  <td class="centered mw-120" [ngSwitch]="c.value.fond.type">
                    <div *ngSwitchCase="scpiFundType">
                      <app-distribution-percent-form-control [parentForm]="c" [controlName]="'fraction'" [title]="''" class="no-title"></app-distribution-percent-form-control>
                    </div>
                    <div *ngSwitchDefault class="distribution-fraction-default">
                      <app-distribution-percent-form-control [parentForm]="c" [controlName]="'fraction'" [title]="''" class="no-title"></app-distribution-percent-form-control>
                    </div>
                  </td>
                  <td class="download-icon-cell" [ngSwitch]="c.value.fond.type">
                    <span *ngSwitchCase="scpiFundType">
                      {{ 'DISTRIBUTION.PaymentFeesSCPI' | labelText }} <br />
                      <div class="lds-dual-ring" *ngIf="c.get('busy').value"></div>
                      <span *ngIf="!c.get('busy').value"><img class="download-icon distr" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, c)" /></span>
                    </span>
                    <span *ngSwitchCase="otherFundType">
                      <div>{{ 'DISTRIBUTION.NoPaymentFees' | labelText }}</div>
                    </span>
                    <span *ngSwitchCase="euroFundType">
                      {{ 'DISTRIBUTION.PaymentFees' | labelText }} <br />
                      <app-distribution-form-control [parentForm]="c" [controlName]="'fees'" [title]="''" class="no-title"> </app-distribution-form-control>
                    </span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="table-fake-distribution hidden-md hidden-lg" [formGroup]="contractFormGroup">
        <div formArrayName="fondsEuro">
          <div *ngFor="let c of distributionFonds.controls; let i = index" [formGroupName]="i" class="fond-row">
            <div class="row mt-2 risk-column" [style.border-left-color]="getRiskColor(c.value.fond.risk)">
              <div class="col-12 col-sm-4">
                {{ c.value.fond.name }}<br />{{ c.get('fond').value.codeISIN }}<br />{{ c.get('fond').value.assetClass }}
                <br />
                {{ getZonesHtml(c) }}
              </div>
              <div class="col-12 col-sm-4" [ngSwitch]="c.value.fond.type">
                <div class="row" *ngSwitchCase="scpiFundType">
                  <div class="col-12">
                    <div class="row">
                      <div class="col">
                        <p class="text-blue">{{ 'Funds.Gross' | labelText }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-distribution-form-control [parentForm]="c" [controlName]="'brutAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngSwitchDefault>
                  <div class="col-12 col-sm-12">
                    <div class="row">
                      <div class="col">
                        <p class="text-blue">{{ 'Funds.Gross' | labelText }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12">
                        <app-distribution-form-control [parentForm]="c" [controlName]="'brutAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                        <p class="text-blue mt-2">{{ 'Funds.Net' | labelText }}</p>
                        <app-distribution-form-control [parentForm]="c" [controlName]="'netAmount'" [title]="''" class="no-title"> </app-distribution-form-control>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 centered" [ngSwitch]="c.value.fond.type">
                <div class="row percentage-row" *ngSwitchCase="scpiFundType">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <p class="text-hidden">{{ 'Funds.Gross' | labelText }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-distribution-percent-form-control [parentForm]="c" [controlName]="'fraction'" [title]="''" class="no-title"></app-distribution-percent-form-control>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row percentage-row" *ngSwitchDefault>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <p class="text-hidden">{{ 'Funds.Gross' | labelText }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-distribution-percent-form-control [parentForm]="c" [controlName]="'fraction'" [title]="''" class="no-title"></app-distribution-percent-form-control>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4"></div>
              <div class="col-12 col-sm-8 download-icon-cell mb-2" [ngSwitch]="c.value.fond.type">
                <div class="row" *ngSwitchCase="scpiFundType">
                  <div class="col-12">
                    <p class="scpiFeesText">
                      {{ 'Common.FeesInCaseWithdrawalText' | labelText : c.get('fond').value.feesInCaseWithdrawal }}
                    </p>
                  </div>
                  <div class="col text-left">
                    {{ 'Distribution.PaymentFeesSCPI' | labelText }} <br />
                    <div class="lds-dual-ring" *ngIf="c.get('busy').value"></div>
                    <span *ngIf="!c.get('busy').value"><img class="download-icon distr" src="../../../assets/img/download.svg" alt="" (click)="onRegulatoryDocumentClick($event, c)" /></span>
                  </div>
                </div>
                <div class="row" *ngSwitchCase="otherFundType">
                  <div class="col text-left">
                    <div>{{ 'Distribution.NoPaymentFees' | labelText }}</div>
                  </div>
                </div>
                <div class="row" *ngSwitchCase="euroFundType">
                  <div class="col-12 text-left">{{ 'Distribution.PaymentFees' | labelText }}</div>
                  <div class="col-12 text-left fees-control">
                    <app-distribution-form-control [parentForm]="c" [controlName]="'fees'" [title]="''" class="no-title"> </app-distribution-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-view-offer
        *ngIf="userDataForViewOffer"
        [clientProfile]="userDataForViewOffer"
        [pageName]="constants.pages.distribution.name"
        (onOffersCountChanged)="onOffersCountChanged($event)"
        [class]="'smart top-auto'"
      ></app-view-offer>

      <div class="col-sm-9 col-md-9 col-lg-9">
        <div class="distribution-errors mb-5" *ngIf="distributionFonds.invalid">
          <span class="row distr-error" *ngFor="let errorMessage of validationErrorMessages">
            {{ errorMessage }}
            <br />
          </span>
        </div>
      </div>

      <!-- <div *ngIf="offersCount" [ngClass]="'offerSeparator-' + this.offersCount"></div> -->

      <div class="mt-2 hidden-xs hidden-sm">
        <app-distribution [distributionFonds]="distributionFonds"></app-distribution>
      </div>

      <div class="mt-2 hidden-md hidden-lg">
        <app-pie-distribution [distributionFonds]="distributionFonds"></app-pie-distribution>
      </div>

      <div class="row button-row">
        <div class="col">
          <button type="button" class="btn btn-primary validation-button" (click)="onValidateDistribution()" [disabled]="!isDistributionValid || showRecommendationNotFollowedRepartitionStep">
            {{ 'Common.Validate' | labelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="followed-error" *ngIf="!isRecommendationsFollowed && showRecommendationNotFollowedRepartitionStep">
    {{ 'FUNDS.AlertMessageText' | labelText }}
    <span class="link-like" (click)="goToRecomendation()">{{ 'FUNDS.AlertMessageLink' | labelText }}</span
    >&nbsp; <span class="link-like" (click)="goToBeneficiaryStep()">{{ 'FUNDS.AlertMessageTextEnd' | labelText }}</span
    >.
  </div>
</div>
