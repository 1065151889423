import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { environment } from '@environments/environment';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { Link } from '@webapi/MIF.Subscription.WebApi';

function getStep1Name(): string {
    let value: string;

    switch (environment.orgId) {
        case constants.orgId.cems.value: {
            value = collections.step.myProfile.value;
            break;
        }
        case constants.orgId.gpa.value: {
            value = collections.step.simpleQuestion.value;
            break;
        }
        case constants.orgId.gfd.value: {
            value = collections.step.qfdQuestion.value;
            break;
        }
        default: {
            value = collections.step.myProfile.value
        }
    }

    return value;
}

function getStep2Name(): string {
    let value: string;

    switch (environment.orgId) {
        case constants.orgId.cems.value: {
            value = collections.step.myContract.value;
            break;
        }
        case constants.orgId.gpa.value: {
            value = collections.step.myTarif.value;
            break;
        }
        case constants.orgId.gfd.value: {
            value = collections.step.myTarif.value;
            break;
        }
        default: {
            value = collections.step.myContract.value
        }
    }

    return value;
}

function getStep5Name(): string {
    let value: string;

    switch (environment.orgId) {
        case constants.orgId.cems.value: {
            value = collections.step.signature.value;
            break;
        }
        case constants.orgId.gpa.value: {
            value = collections.step.recapitulatif.value;
            break;
        }
        case constants.orgId.gfd.value: {
            value = collections.step.recapitulatif.value;
            break;
        }
        default: {
            value = collections.step.signature.value
        }
    }

    return value;
}

function getFooterDocLinkText(): string {
    let value: string;

    if (environment.orgId === constants.orgId.cems.value) {
        value = 'Footer.FooterDocLinkTextCems'
    }
    if (environment.orgId === constants.orgId.gpa.value) {
        value = 'Footer.FooterDocLinkTextGpa'
    }
    if (environment.orgId === constants.orgId.gfd.value) {
        value = 'Footer.FooterDocLinkTextGfd'
    }
    if (environment.orgId === constants.orgId.peri.value) {
        value = 'Footer.FooterDocLinkTextPeri'
    }
    if (environment.orgId === constants.orgId.cims.value) {
        value = 'Footer.FooterDocLinkTextCims'
    }

    return value;
}

async function getFooterDocFileBase64(wsReferentielMetierService: WsReferentielMetierService, footerLinks: Link[]): Promise<string> {
    let fileBase64: string;
    const footerDocId = footerLinks.filter((link: Link) => {
        return link.label === constants.links.footerDocIdName;
    })[0].value;

    if (footerDocId) {
        if (environment.orgId === constants.orgId.cems.value) {
            fileBase64 = await wsReferentielMetierService.getCemsDocumentsProduit(parseInt(footerDocId, 10));
        }
        if (environment.orgId === constants.orgId.gpa.value) {
            fileBase64 = await wsReferentielMetierService.getAtdnDocumentsProduit(parseInt(footerDocId, 10));
        }
        if (environment.orgId === constants.orgId.gfd.value) {
            fileBase64 = await wsReferentielMetierService.getGfdDocumentsProduit(parseInt(footerDocId, 10));
        }
        if (environment.orgId === constants.orgId.peri.value) {
            fileBase64 = await wsReferentielMetierService.getPeriDocumentsProduit(parseInt(footerDocId, 10));
        }
        if (environment.orgId === constants.orgId.cims.value) {
            fileBase64 = await wsReferentielMetierService.getCimsDocumentsProduit(parseInt(footerDocId, 10));
        }
    }

    return fileBase64;
}

export {
    getStep1Name,
    getStep2Name,
    getStep5Name,
    getFooterDocLinkText,
    getFooterDocFileBase64
};
