import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from './auth/auth.service';
import * as version from '../../mif/environments/versions';

@Component({
  selector: 'app-root-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(private authService: AuthService) {}

  @ViewChild('sidenav') sidenav: MatSidenav;
  isShowing = false;
  isLoggedIn: boolean;

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe(async isAuthenticated => {
      this.isLoggedIn = isAuthenticated;
    });
    console.log('version: ' + JSON.stringify(version));
  }

  mouseenter(): void {
    this.isShowing = true;
  }

  mouseleave(): void {
    this.isShowing = false;
  }
}
