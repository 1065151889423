import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { constants } from '@constants/constants';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Tooltip } from 'primeng/tooltip';
import { AlertModalComponent, modalTypes } from '../modals/alert-modal/alert-modal.component';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() title: string;
  @Input() extraTitle: string;
  @Input() extraTitleClass: string = '';
  @Input() noIcon: boolean = false;
  @Input() text: string;
  @Input() class: string = '';
  @Input() hideDelay: number = constants.tooltipHideDelay;
  @Input() tooltipPosition: string = 'right';
  @Input() escape: boolean = false; // html rendering

  @ViewChild(Tooltip) tooltip!: Tooltip;
  public htmlText = '';
  isSmallDisplaySize: boolean;

  constructor(private bsModalService: BsModalService) {}

  ngOnInit(): void {
    this.isSmallDisplaySize = window.screen.width < 640;
    this.prepareText();
  }

  prepareText(): void {
    if (this.title) {
      this.htmlText = `<p class="tooltip-header"> <b>${this.title}</b> </p>`;
    }

    if (this.text) {
      this.htmlText += `<p> ${this.text} </p>`;
    }
  }

  showTooltip(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: this.htmlText
      },
      class: 'modal-lg'
    });
  }
}
