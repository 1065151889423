export enum ProfileStepCode {
    MembershipCheck = 'membershipCheck',
    ResidenceQuestions = 'residenceQuestions',
    HealthQuestions = 'healthQuestions',
    MyRate = 'myRate',
    MyProfile = 'myProfile',
    MyProfileSteps = 'myProfileSteps',
    MyProfileStepSummary = 'myProfileStepSummary',
    MyContracts = 'myContracts',
    MyBeneficiaries = 'myBeneficiaries',
    MyBeneficiariesNominative = 'myBeneficiariesNominative',
    MyBeneficiariesLibre = 'myBeneficiariesLibre',
    MyDetails = 'myDetails',
    MyContacts = 'myContacts',
    MyFinancial = 'myFinancial',
    MyBankDetails = 'myBankDetails',
    Signature = 'signature',
    PhoneConfirmation = 'phoneConfirmation'
}
