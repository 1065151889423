import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarService } from '@services/progress-bar.service';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { uniqueBy } from '@extensions/extensions';
import { IbanFormControl } from '@form/iban-form-control/iban-form-control';
import { ProfileStepName } from '@models/profile-step-name';
import { CoordonneesCompteBancairePrelevement, Erreur10, GetBICFromIBANResponse } from '@webapi/MIF.Subscription.Parrot';
import { Profile, ProfileDocument } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';
import { WsCoordonneesBancairesService } from '@webapi/services/ws-coordonnees-bancaires.service';
import { ContratTransferableFourgousExtended } from '@webapi/services/ws-polices.service';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { environment } from 'projects/mif/environments/environment';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { FileUploaderStatus } from '@shared/components/file-uploader/file-uploader.component';
import { WsSepaliaService } from '@webapi/services/ws-sepalia.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit, OnDestroy {
  public faPlus = faPlus;
  public isPeri: boolean = environment.orgId === constants.orgId.peri.value;
  ribRestoredUploaderFiles: File[];
  ribUploaderFiles: File[];
  ibanControl: IbanFormControl;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  checkIban: any;
  bIcErrors: Erreur10[];
  bic: string;
  checkedIbanValue: any;
  clientProfile: Profile;
  transferableContracts: ContratTransferableFourgousExtended[] = [];
  bancairesPrelevements: CoordonneesCompteBancairePrelevement[] = [];
  selectedCoordonneesCompteBancairePrelevement: CoordonneesCompteBancairePrelevement;
  manualEnteringIban: boolean = true;
  uniqueBy = uniqueBy;
  ProfileStepName = ProfileStepName;
  ribFilesUploaderStatus: any;
  isBicCheckingServiceAavailable: boolean;

  constructor(
    private router: Router,
    private progressBarService: ProgressBarService,
    private customerService: CustomerService,
    private wsCoordonneesBancairesService: WsCoordonneesBancairesService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private wsSepaliaService: WsSepaliaService,
    private bsModalService: BsModalService,
    private labelTextPipe: LabelTextPipe
  ) {}

  form = new FormGroup({
    iban: new IbanFormControl(true, false)
  });

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myDetails.bankDetails.initialStep);
    this.initControls();
    this.initSubscriptions();
    this.restoreAnswersOnForm();
  }

  initSubscriptions() {
    this.subscriptionHandler.subscriptions = this.ibanControl.statusChanges.subscribe(status => {
      if (status === constants.validStatus) {
        const ibanValue = this.ibanControl.value;

        this.wsSepaliaService.getBICFromIBANObservable(ibanValue).subscribe((response: GetBICFromIBANResponse) => {
          this.bic = response.BIC;
        });
      } else {
        this.bic = null;
      }
    });
  }

  markFormTouched(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }

  initControls(): void {
    this.ibanControl = this.form.get('iban') as IbanFormControl;
  }

  async restoreAnswersOnForm(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile) {
      if (this.clientProfile.socialNumberB) {
        this.bancairesPrelevements = await this.wsCoordonneesBancairesService.getCoordonneesComptesBancairesPrelevementArray(parseInt(this.clientProfile.socialNumberB, 10));
        await this.checkBancairesPrelevements(this.bancairesPrelevements);
        this.manualEnteringIban = !this.bancairesPrelevements || this.bancairesPrelevements?.length === 0;
      }

      if (this.clientProfile.iban && this.bancairesPrelevements?.length > 0) {
        const prevSelectedContract = this.bancairesPrelevements.find((item: CoordonneesCompteBancairePrelevement) => {
          return item.IBAN === this.clientProfile.iban;
        });

        if (prevSelectedContract) {
          this.manualEnteringIban = false;
          this.selectBancaire(prevSelectedContract);
        } else {
          this.setIban(this.clientProfile.iban);
        }
      } else if (this.clientProfile.iban) {
        this.setIban(this.clientProfile.iban);
      }
    }

    this.restoreFiles();
  }

  setIban(iban: string): void {
    this.form.get('iban').patchValue(iban);
    this.manualEnteringIban = true;
  }

  restoreFiles() {
    if (this.clientProfile.profileDocuments?.length > 0) {
      this.clientProfile.profileDocuments.forEach((item: ProfileDocument) => {
        if (item.typeId === collections.profileDocuments.ribDocument.typeId) {
          const file = new File([''], item.file.fileName, {});

          if (!this.ribRestoredUploaderFiles) {
            this.ribRestoredUploaderFiles = [];
          }

          this.ribRestoredUploaderFiles.push(file);
        }
      });
    }
  }

  get isBancairesPrelevements(): boolean {
    return this.bancairesPrelevements?.length > 0;
  }

  isSelectedBancaire(item: CoordonneesCompteBancairePrelevement): boolean {
    if (!item || !item.numeroContrat) {
      return false;
    }

    return this.selectedCoordonneesCompteBancairePrelevement?.numeroContrat[0] === item?.numeroContrat[0];
  }

  selectBancaire(item: CoordonneesCompteBancairePrelevement) {
    this.selectedCoordonneesCompteBancairePrelevement = item;
    this.bic = item.BIC;
    this.manualEnteringIban = false;
    this.ibanControl.markAsNotRequred();
    this.ibanControl.patchValue(item.IBAN);
    this.bIcErrors = null;
  }

  isSelectBancaire() {
    return !!this.selectedCoordonneesCompteBancairePrelevement;
  }

  showIbanForm() {
    this.ibanControl.markAsRequred();
    this.ibanControl.patchValue(null);
    this.bic = null;
    this.selectedCoordonneesCompteBancairePrelevement = null;
    this.manualEnteringIban = true;
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    this.processSuccess();
  }

  ribFilesUploaderStatusChanged(status: any): void {
    this.ribFilesUploaderStatus = status;
  }

  ribFilesUploaderChanged(files: File[]) {
    this.ribUploaderFiles = files;
  }

  goToMyDetails() {
    this.router.navigate(['my-details'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyContacts() {
    this.router.navigate(['my-details/contacts'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyFinancial() {
    this.router.navigate(['my-details/financial-situation'], { queryParams: { userId: this.customerService.userId } });
  }

  async checkBancairesPrelevements(bancairesPrelevements: CoordonneesCompteBancairePrelevement[]): Promise<void> {
    bancairesPrelevements?.forEach(async (item: CoordonneesCompteBancairePrelevement) => {
      if (!item.RUM || item.RUM.trim() === '') {
        item.RUM = await this.wsReferentielMetierService.getRum();
      }
    });
  }

  async checkAndUpdateRum(rum?: string): Promise<string> {
    if (!rum || rum.trim() === '') {
      rum = await this.wsReferentielMetierService.getRum();
    }

    return rum;
  }

  async processSuccess() {
    const model = this.form.getRawValue() as MyBankDetailsDataModel;

    if (this.bic) {
      model.bic = this.bic;
    }

    if (this.isSelectBancaire()) {
      model.rum = await this.checkAndUpdateRum(this.selectedCoordonneesCompteBancairePrelevement.RUM);
    } else {
      model.rum = await this.wsReferentielMetierService.getRum();
    }

    model.ribFiles = this.ribUploaderFiles;
    model.useExistingRum = !this.manualEnteringIban;

    const userId = await this.customerService.updateMyBankDetailsModel(model, this.bsModalService, this.labelTextPipe);

    if (userId !== null) {
      this.progressBarService.sendStep(constants.steps.signature.initialStep);

      setTimeout(() => {
        this.router.navigate(['signature'], { queryParams: { userId: userId } });
      });
    }
  }

  get isFormDisabled(): boolean {
    return this.form.invalid || this.ribFilesUploaderStatus === FileUploaderStatus.INVALID;
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}

export interface MyBankDetailsDataModel {
  iban: string;
  bic: string;
  ribFiles?: File[];
  rum: string;
  useExistingRum: boolean;
}
