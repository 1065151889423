import { Pipe, PipeTransform } from '@angular/core';
import { getAgeTypeNameById } from '@extensions/admin.extensions';

@Pipe({
  name: 'ageTypeName'
})
export class AgeTypeNamePipe implements PipeTransform {
  constructor() {

  }
  transform(value: number): string {
    let res = '';

    if (!value) {
      return res;
    }

    res = getAgeTypeNameById(value);

    return res;
  }
}
