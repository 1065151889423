<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyBeneficiariesLibre" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form">
  <div class="mt-5" [class.cemsSummary]="cemsSummary">
    <div class="card">
      <div class="row">
        <div class="col-12 text-left">
          <div class="card-body">
            <div class="row" *ngIf="!cemsSummary">
              <div class="col">
                <h1 class="section-title">{{ 'Common.MyBeneficiairiesTitle' | labelText }}</h1>
              </div>
            </div>

            <div class="row text-left" *ngIf="!cemsSummary">
              <div class="col">
                <p *ngIf="!isPeri" class="txt-default mt-2 mb-4">{{ 'Common.MyBeneficiairiesSubtitle' | labelText }}</p>
                <p *ngIf="isPeri" class="txt-default mt-2 mb-4">
                  {{ 'Beneficiaries.SubTitleTextPeri' | labelText }}
                </p>
              </div>
            </div>

            <div class="row text-left" *ngIf="!cemsSummary">
              <div class="col">
                <h5 class="beneficial-subheader">
                  {{ 'NOMINATIVE_CLAUSE.YourChoice' | labelText }} <b> {{ 'BENEFICIARIES.BeneficiariesFreeTitleLowerCase' | labelText }} </b>
                  <a class="red-link ml-4" href="javascript:void(0)" routerLink="/my-beneficiaries"> {{ 'Common.Modify' | labelText }} </a>
                </h5>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h6 class="fw-600">{{ 'FREE_CLAUSE.FreeClauseAdvice' | labelText }}</h6>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <ul class="advice txt-default">
                  <li>
                    {{ 'FREE_CLAUSE.BeneficiairiesIdentity' | labelText }}
                  </li>
                  <li>
                    {{ 'FREE_CLAUSE.ShareBetweenHeirsExplanation' | labelText }}
                  </li>
                  <li>
                    {{ 'FREE_CLAUSE.HeirOfDifferentLevel' | labelText }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="row">
              <span class="separated-title"> {{ infoTitle }} </span>
              <div class="col">
                <app-info-form-control [parentForm]="form" [title]="''" class="no-title" [controlName]="'beneficiaryFreeClauseText'"></app-info-form-control>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="valider-button-block" *ngIf="!cemsSummary">
      <button type="submit" class="btn btn-blue valider">
        {{ 'Common.Validate' | labelText }}
      </button>
    </div>
  </div>
</form>
