<div id="app">
  <mat-sidenav-container class="example-container" autosize>
    <mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" [disableClose]="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" *ngIf="isLoggedIn">
      <mat-nav-list>
        <mat-list-item routerLink="home" class="parent" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Accueil</a>
          <mat-icon mat-list-icon>dashboard</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="labels" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Libellés</a>
          <mat-icon mat-list-icon>list_alt</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="offers" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Offres promotionnelles</a>
          <mat-icon mat-list-icon>local_offer</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="emails" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Notifications email</a>
          <mat-icon mat-list-icon>notifications</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="settings" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Paramètres</a>
          <mat-icon mat-list-icon>settings</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="gpa-in-cems" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Offre GPA</a>
          <mat-icon mat-list-icon>subject</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="performance" [routerLinkActive]="['active-link']">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Performance passée</a>
          <mat-icon mat-list-icon>assessment</mat-icon>
        </mat-list-item>
        <mat-list-item routerLink="/authentication/logout">
          <a class="full-width" href="javascript:void(0);" [ngClass]="{ visible: isShowing }">Se déconnecter</a>
          <mat-icon mat-list-icon>exit_to_app</mat-icon>
        </mat-list-item>
      </mat-nav-list>
      <mat-nav-list> </mat-nav-list>
    </mat-sidenav>

    <div class="example-sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>
</div>
