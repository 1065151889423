import { Validators, ValidatorFn } from '@angular/forms';
import { zipCodeValidator } from '../../validators/zipCodeValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class ZipCodeFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private options?: ZipCodeOptions) {
        super(required, disabled);
        super.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [Validators.maxLength(5), zipCodeValidator()];
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }
}

export interface ZipCodeOptions {
    readOnlyCss?: boolean;
    hideSuccessMark?: boolean;
}
