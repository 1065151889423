import { ValidatorFn } from '@angular/forms';
import { sncfValidator } from '../../validators/sncfValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class SncfFormControl extends AbstractFormControl {
    constructor(required?: boolean, disabled?: boolean, private options?: SncfOptions) {
        super(required, disabled);
        this.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [sncfValidator()];
    }
}

export interface SncfOptions {
    readOnlyCss?: boolean;
}
