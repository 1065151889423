import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { PhoneFormControl } from './phone-form-control';

@Component({
    selector: 'app-phone-form-control',
    templateUrl: './phone-form-control.component.html',
})
export class PhoneFormControlComponent extends AbstractFormControlComponent<PhoneFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    faCheck: any = faCheck;
    faClose: any = faTimes;

    change(): void {
        this.onChange.emit(this.control.value);
    }

    public get hideErrorMark(): boolean {
        return this.control.hideErrorMark || false;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }
    
    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }
}
