<div class="view-performance" *ngIf="year && range">
  <div class="text-with-tooltip">
    <p class="text">{{ 'Recommendation.PerfLastYear' | labelText }} ({{ year.year | performanceYear }})</p>
    <app-tooltip [text]="tooltipText" [tooltipPosition]="'top'" class="perf-tooltip"></app-tooltip>
  </div>
  <p class="value">{{ year.value | performanceFraction : isPerformanceFractionWithDecimals : decimalsCount }}</p>

  <p class="text range">{{ 'Recommendation.PerfRange' | labelText }} {{ range.range | performanceRange }}</p>
  <p class="value">{{ range.value | performanceFraction : isPerformanceFractionWithDecimals : decimalsCount }}</p>
</div>
