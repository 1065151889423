import { ValidatorFn } from '@angular/forms';
import { phoneValidator } from '../../validators/phoneValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class PhoneFormControl extends AbstractFormControl {
    constructor(
        required?: boolean,
        disabled?: boolean,
        private options?: PhoneOptions
    ) {
        super(required, disabled, { updateOn: 'change' });
        this.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [phoneValidator()];
    }

    get hideErrorMark(): boolean {
        return this.options?.hideErrorMark;
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }

    get phoneTypeText(): string {
        return 'fixe';
    }
}

export interface PhoneOptions {
    readOnlyCss?: boolean;
    hideErrorMark?: boolean;
    hideSuccessMark?: boolean;
}
