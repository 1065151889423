import { ValidatorFn } from '@angular/forms';
import { CollectionDescription } from '../../models/collection-description.model';
import { AbstractFormControl } from '../abstract-form-control';

export class CheckboxListFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private collectionDescription: CollectionDescription = {}, private options?: CheckboxListOptions) {
        super(required, disabled);

        if (collectionDescription && collectionDescription.enableFirstChooseOption && collectionDescription.collection.length) {
            this.setValue(collectionDescription.collection[0][collectionDescription.keyPropertyName]);
        } else {
            this.setValue(null);
        }

        if (options && options.defaultValue) {
            this.setValue(options.defaultValue);
        }
    }

    public setCollection(collection: any[]) {
        this.collectionDescription.collection = collection;
    }

    public get collection(): any[] {
        return this.collectionDescription.collection;
    }

    public get multiple(): boolean {
        return this.options?.multiple;
    }

    public get addOther(): boolean {
        return this.options?.addOther;
    }

    public get otherId(): number {
        return this.options?.otherId;
    }

    public get keyPropertyName(): string {
        return this.collectionDescription.keyPropertyName;
    }

    public get valuePropertyName(): string {
        return this.collectionDescription.valuePropertyName;
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }

    get hideErrorMessages(): boolean {
        return this.options?.hideErrorMessages;
    }
}

export interface CheckboxListOptions {
    defaultValue?: any;
    multiple?: boolean;
    addOther?: boolean;
    otherId?: number;
    hideSuccessMark?: boolean;
    hideErrorMessages?: boolean;
}
