<div class="row mt-5">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <div class="graph-founds">
      <div class="range-investment box-frame">
        <div class="header">
          <p class="box-title">
            {{ 'DISTRIBUTION.DistributionSelection' | labelText }}
            <app-tooltip [title]="'Distribution.DistributionTooltipTitle' | labelText" [text]="'Distribution.DistributionTooltipText' | labelText"> </app-tooltip>
          </p>
        </div>
        <div class="content">
          <div class="visible-md visible-lg">
            <div class="input-container">
              <div class="input-container range-container">
                <span class="track range-track"></span>

                <span class="block-content" *ngFor="let item of distributionElements; let i = index">
                  <span class="legend" [style.width]="item.fraction + '%'" [style.left]="item.left + '%'" pTooltip="{{ item.fond.name }}" tooltipPosition="top" tooltipStyleClass="no-bg">
                    {{ item.fond.name }}</span
                  >
                  <span
                    class="track rec-track"
                    [ngClass]="{ first: i === 0 }"
                    [style.width]="item.fraction + '%'"
                    [style.left]="item.left + '%'"
                    [style.background-color]="getRiskColor(item.fond.risk)"
                    [style.color]="getRiskTextColor(item.fond.risk)"
                    >{{ item.fraction.toFixed(2) | fraction }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
