import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetTarifsPrevoyanceGFDRequest, GetTarifsPrevoyanceGFDResponse, PrevoyanceGFD, Client } from '../MIF.Subscription.Parrot';

@Injectable({
  providedIn: 'root'
})
export class WsPrevoyanceGFDService {
  constructor(private webApi: Client) {}

  public async getTarifsPrevoyanceGFD(clientAgeAtTheEndOfYear: number, periodicitePaiement: number, mntCapital: number, nbAnnees: number): Promise<any> {
    let prevoyanceGFD = new PrevoyanceGFD({
      age: clientAgeAtTheEndOfYear,
      periodicitePaiement: periodicitePaiement,
      mntCapital,
      nbAnnees
    });

    const request = new GetTarifsPrevoyanceGFDRequest({
      prevoyanceGFD
    });

    return this.webApi
      .getTarifsPrevoyanceGFD(request)
      .pipe(
        map((response: GetTarifsPrevoyanceGFDResponse) => {
          return response;
        })
      )
      .toPromise();
  }
}
