export class FundRule {
  code: string;
  label: string;
  value: string;
  getValueAsNumber(): number {
    const result = +this.value?.replace(/,/g, '.');
    if (!result) {
      return 0;
    }

    return result;
  }
}
