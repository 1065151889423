import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetPolicesRequest, GetPolicesResponse, Proposition, Client, Police3, Police2 } from '../MIF.Subscription.Parrot';

@Injectable({
  providedIn: 'root'
})
export class WsGetPolices {
  constructor(private webApi: Client) { }

  private data: any = {};

  public async getPolices(noSocietaireInterne: number): Promise<GetPolicesResponse> {
    if (this.data[noSocietaireInterne]) {
      return new Promise(resolve => {
        resolve(this.data[noSocietaireInterne]);
      })
    } else {
      const request = new GetPolicesRequest({
        noSocietaireInterne: noSocietaireInterne,
        proposition: new Proposition({
          getPropositions: true
        }),
        police: new Police2({
          getContrats: true,
          getSupportsContrats: true,
          getRentes: true
        })
      })

      return this.webApi.getPolices(request).pipe(
        map((response: GetPolicesResponse) => {
          this.data[noSocietaireInterne] = response;
          return response;
        })
      ).toPromise();
    }
  }
}