export const countries = {
  ALB: {
    inseeCode: 99125,
    countryName: 'ALBANIE'
  },
  DEU: {
    inseeCode: 99109,
    countryName: "REPUBLIQUE FEDERALE D'ALLEMAGNE"
  },
  AND: {
    inseeCode: 99130,
    countryName: 'ANDORRE'
  },
  ABW: {
    inseeCode: 99135,
    countryName: 'ARUBA'
  },
  AUT: {
    inseeCode: 99110,
    countryName: 'AUTRICHE'
  },
  BEL: {
    inseeCode: 99131,
    countryName: 'BELGIQUE'
  },
  BLR: {
    inseeCode: 99148,
    countryName: 'BIELORUSSIE'
  },
  RUS: {
    inseeCode: 99123,
    countryName: 'FEDERATION DE RUSSIE'
  },
  BIH: {
    inseeCode: 99118,
    countryName: 'BOSNIE-HERZEGOVINE'
  },
  BVT: {
    inseeCode: 99103,
    countryName: 'BOUVET (ILE)'
  },
  BGR: {
    inseeCode: 99111,
    countryName: 'BULGARIE'
  },
  HRV: {
    inseeCode: 99119,
    countryName: 'CROATIE'
  },
  DNK: {
    inseeCode: 99101,
    countryName: 'DANEMARK'
  },
  ESP: {
    inseeCode: 99134,
    countryName: 'ESPAGNE'
  },
  EST: {
    inseeCode: 99106,
    countryName: 'ESTONIE'
  },
  MKD: {
    inseeCode: 99156,
    countryName: 'MACEDOINE DU NORD'
  },
  FRO: {
    inseeCode: 99101,
    countryName: 'FEROE (ILES)'
  },
  FIN: {
    inseeCode: 99105,
    countryName: 'FINLANDE'
  },
  GIB: {
    inseeCode: 99133,
    countryName: 'GIBRALTAR'
  },
  GRC: {
    inseeCode: 99126,
    countryName: 'GRECE'
  },
  GGY: {
    inseeCode: 99132,
    countryName: 'GUERNESEY'
  },
  HUN: {
    inseeCode: 99112,
    countryName: 'HONGRIE'
  },
  IRL: {
    inseeCode: 99136,
    countryName: 'IRLANDE, ou EIRE'
  },
  ISL: {
    inseeCode: 99102,
    countryName: 'ISLANDE'
  },
  ITA: {
    inseeCode: 99127,
    countryName: 'ITALIE'
  },
  JEY: {
    inseeCode: 99132,
    countryName: 'JERSEY'
  },
  XKX: {
    inseeCode: 99157,
    countryName: 'KOSOVO'
  },
  LVA: {
    inseeCode: 99107,
    countryName: 'LETTONIE'
  },
  LIE: {
    inseeCode: 99113,
    countryName: 'LIECHTENSTEIN'
  },
  LTU: {
    inseeCode: 99108,
    countryName: 'LITUANIE'
  },
  LUX: {
    inseeCode: 99137,
    countryName: 'LUXEMBOURG'
  },
  MLT: {
    inseeCode: 99144,
    countryName: 'MALTE'
  },
  IMN: {
    inseeCode: 99132,
    countryName: 'MAN (ILE)'
  },
  MDA: {
    inseeCode: 99151,
    countryName: 'MOLDAVIE'
  },
  MCO: {
    inseeCode: 99138,
    countryName: 'MONACO'
  },
  MNE: {
    inseeCode: 99120,
    countryName: 'MONTENEGRO'
  },
  NOR: {
    inseeCode: 99103,
    countryName: 'NORVEGE'
  },
  NLD: {
    inseeCode: 99135,
    countryName: 'PAYS-BAS'
  },
  POL: {
    inseeCode: 99122,
    countryName: 'POLOGNE'
  },
  PRT: {
    inseeCode: 99139,
    countryName: 'PORTUGAL'
  },
  ROU: {
    inseeCode: 99114,
    countryName: 'ROUMANIE'
  },
  GBR: {
    inseeCode: 99132,
    countryName: 'ROYAUME-UNI'
  },
  SMR: {
    inseeCode: 99128,
    countryName: 'SAINT-MARIN'
  },
  SRB: {
    inseeCode: 99121,
    countryName: 'SERBIE'
  },
  SCG: {
    inseeCode: 99000,
    countryName: 'SERBIE-ET-MONTENEGRO'
  },
  SVK: {
    inseeCode: 99117,
    countryName: 'SLOVAQUIE'
  },
  SVN: {
    inseeCode: 99145,
    countryName: 'SLOVENIE'
  },
  SWE: {
    inseeCode: 99104,
    countryName: 'SUEDE'
  },
  ALA: {
    inseeCode: 99104,
    countryName: 'ILES ALAND'
  },
  CHE: {
    inseeCode: 99140,
    countryName: 'SUISSE'
  },
  SJM: {
    inseeCode: 99103,
    countryName: 'SVALBARD et ILE JAN MAYEN'
  },
  CZE: {
    inseeCode: 99116,
    countryName: 'TCHEQUIE'
  },
  TUR: {
    inseeCode: 99208,
    countryName: 'TURQUIE'
  },
  UKR: {
    inseeCode: 99155,
    countryName: 'UKRAINE'
  },
  VAT: {
    inseeCode: 99129,
    countryName: 'VATICAN, ou SAINT-SIEGE'
  },
  AFG: {
    inseeCode: 99212,
    countryName: 'AFGHANISTAN'
  },
  SAU: {
    inseeCode: 99201,
    countryName: 'ARABIE SAOUDITE'
  },
  ARM: {
    inseeCode: 99252,
    countryName: 'ARMENIE'
  },
  AZE: {
    inseeCode: 99253,
    countryName: 'AZERBAIDJAN'
  },
  BHR: {
    inseeCode: 99249,
    countryName: 'BAHREIN'
  },
  BGD: {
    inseeCode: 99246,
    countryName: 'BANGLADESH'
  },
  BTN: {
    inseeCode: 99214,
    countryName: 'BHOUTAN'
  },
  MMR: {
    inseeCode: 99224,
    countryName: 'BIRMANIE'
  },
  BRN: {
    inseeCode: 99225,
    countryName: 'BRUNEI'
  },
  KHM: {
    inseeCode: 99234,
    countryName: 'CAMBODGE'
  },
  CHN: {
    inseeCode: 99216,
    countryName: 'CHINE'
  },
  CYP: {
    inseeCode: 99254,
    countryName: 'CHYPRE'
  },
  KOR: {
    inseeCode: 99239,
    countryName: 'COREE (REPUBLIQUE DE)'
  },
  PRK: {
    inseeCode: 99238,
    countryName: 'COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)'
  },
  ARE: {
    inseeCode: 99247,
    countryName: 'EMIRATS ARABES UNIS'
  },
  MYS: {
    inseeCode: 99227,
    countryName: 'MALAISIE'
  },
  GEO: {
    inseeCode: 99255,
    countryName: 'GEORGIE'
  },
  IND: {
    inseeCode: 99223,
    countryName: 'INDE'
  },
  HKG: {
    inseeCode: 99230,
    countryName: 'HONG-KONG'
  },
  IDN: {
    inseeCode: 99231,
    countryName: 'INDONESIE'
  },
  IRN: {
    inseeCode: 99204,
    countryName: 'IRAN'
  },
  IRQ: {
    inseeCode: 99203,
    countryName: 'IRAQ'
  },
  ISR: {
    inseeCode: 99207,
    countryName: 'ISRAEL'
  },
  JPN: {
    inseeCode: 99217,
    countryName: 'JAPON'
  },
  JOR: {
    inseeCode: 99222,
    countryName: 'JORDANIE'
  },
  KAZ: {
    inseeCode: 99256,
    countryName: 'KAZAKHSTAN'
  },
  KGZ: {
    inseeCode: 99257,
    countryName: 'KIRGHIZISTAN'
  },
  KWT: {
    inseeCode: 99240,
    countryName: 'KOWEIT'
  },
  LAO: {
    inseeCode: 99241,
    countryName: 'LAOS'
  },
  LBN: {
    inseeCode: 99205,
    countryName: 'LIBAN'
  },
  MAC: {
    inseeCode: 99232,
    countryName: 'MACAO'
  },
  MDV: {
    inseeCode: 99229,
    countryName: 'MALDIVES'
  },
  MNG: {
    inseeCode: 99242,
    countryName: 'MONGOLIE'
  },
  NPL: {
    inseeCode: 99215,
    countryName: 'NEPAL'
  },
  OMN: {
    inseeCode: 99250,
    countryName: 'OMAN'
  },
  UZB: {
    inseeCode: 99258,
    countryName: 'OUZBEKISTAN'
  },
  PAK: {
    inseeCode: 99213,
    countryName: 'PAKISTAN'
  },
  PSE: {
    inseeCode: 99261,
    countryName: 'PALESTINE (Etat de)'
  },
  PHL: {
    inseeCode: 99220,
    countryName: 'PHILIPPINES'
  },
  AIA: {
    inseeCode: 99425,
    countryName: 'ANGUILLA'
  },
  QAT: {
    inseeCode: 99248,
    countryName: 'QATAR'
  },
  SGP: {
    inseeCode: 99226,
    countryName: 'SINGAPOUR'
  },
  LKA: {
    inseeCode: 99235,
    countryName: 'SRI LANKA'
  },
  SYR: {
    inseeCode: 99206,
    countryName: 'SYRIE'
  },
  TJK: {
    inseeCode: 99259,
    countryName: 'TADJIKISTAN'
  },
  TWN: {
    inseeCode: 99236,
    countryName: 'TAIWAN'
  },
  THA: {
    inseeCode: 99219,
    countryName: 'THAILANDE'
  },
  TLS: {
    inseeCode: 99262,
    countryName: 'TIMOR ORIENTAL'
  },
  TKM: {
    inseeCode: 99260,
    countryName: 'TURKMENISTAN'
  },
  VNM: {
    inseeCode: 99243,
    countryName: 'VIET NAM'
  },
  YEM: {
    inseeCode: 99251,
    countryName: 'YEMEN'
  },
  ZAF: {
    inseeCode: 99303,
    countryName: 'AFRIQUE DU SUD'
  },
  DZA: {
    inseeCode: 99352,
    countryName: 'ALGERIE'
  },
  AGO: {
    inseeCode: 99395,
    countryName: 'ANGOLA'
  },
  BEN: {
    inseeCode: 99327,
    countryName: 'BENIN'
  },
  BWA: {
    inseeCode: 99347,
    countryName: 'BOTSWANA'
  },
  BFA: {
    inseeCode: 99331,
    countryName: 'BURKINA'
  },
  BDI: {
    inseeCode: 99321,
    countryName: 'BURUNDI'
  },
  CMR: {
    inseeCode: 99322,
    countryName: 'CAMEROUN'
  },
  CPV: {
    inseeCode: 99396,
    countryName: 'CAP-VERT'
  },
  CAF: {
    inseeCode: 99323,
    countryName: 'CENTRAFRICAINE (REPUBLIQUE)'
  },
  COM: {
    inseeCode: 99397,
    countryName: 'COMORES'
  },
  COG: {
    inseeCode: 99324,
    countryName: 'CONGO'
  },
  COD: {
    inseeCode: 99312,
    countryName: 'CONGO (REPUBLIQUE DEMOCRATIQUE)'
  },
  CIV: {
    inseeCode: 99326,
    countryName: "COTE D'IVOIRE"
  },
  DJI: {
    inseeCode: 99399,
    countryName: 'DJIBOUTI'
  },
  EGY: {
    inseeCode: 99301,
    countryName: 'EGYPTE'
  },
  ERI: {
    inseeCode: 99317,
    countryName: 'ERYTHREE'
  },
  SWZ: {
    inseeCode: 99391,
    countryName: 'ESWATINI'
  },
  ETH: {
    inseeCode: 99315,
    countryName: 'ETHIOPIE'
  },
  GAB: {
    inseeCode: 99328,
    countryName: 'GABON'
  },
  GMB: {
    inseeCode: 99304,
    countryName: 'GAMBIE'
  },
  SHN: {
    inseeCode: 99306,
    countryName: 'SAINTE HELENE'
  },
  GHA: {
    inseeCode: 99329,
    countryName: 'GHANA'
  },
  GIN: {
    inseeCode: 99330,
    countryName: 'GUINEE'
  },
  GNQ: {
    inseeCode: 99314,
    countryName: 'GUINEE EQUATORIALE'
  },
  GNB: {
    inseeCode: 99392,
    countryName: 'GUINEE-BISSAU'
  },
  KEN: {
    inseeCode: 99332,
    countryName: 'KENYA'
  },
  LSO: {
    inseeCode: 99348,
    countryName: 'LESOTHO'
  },
  LBR: {
    inseeCode: 99302,
    countryName: 'LIBERIA'
  },
  LBY: {
    inseeCode: 99316,
    countryName: 'LIBYE'
  },
  MDG: {
    inseeCode: 99333,
    countryName: 'MADAGASCAR'
  },
  MWI: {
    inseeCode: 99334,
    countryName: 'MALAWI'
  },
  MLI: {
    inseeCode: 99335,
    countryName: 'MALI'
  },
  MAR: {
    inseeCode: 99350,
    countryName: 'MAROC'
  },
  ESH: {
    inseeCode: 99389,
    countryName: 'SAHARA OCCIDENTAL'
  },
  MUS: {
    inseeCode: 99390,
    countryName: 'MAURICE'
  },
  MRT: {
    inseeCode: 99336,
    countryName: 'MAURITANIE'
  },
  MOZ: {
    inseeCode: 99393,
    countryName: 'MOZAMBIQUE'
  },
  NAM: {
    inseeCode: 99311,
    countryName: 'NAMIBIE'
  },
  NER: {
    inseeCode: 99337,
    countryName: 'NIGER'
  },
  NGA: {
    inseeCode: 99338,
    countryName: 'NIGERIA'
  },
  IOT: {
    inseeCode: 99308,
    countryName: "OCEAN INDIEN (TERRITOIRE BRITANNIQUE DE L')"
  },
  UGA: {
    inseeCode: 99339,
    countryName: 'OUGANDA'
  },
  RWA: {
    inseeCode: 99340,
    countryName: 'RWANDA'
  },
  STP: {
    inseeCode: 99394,
    countryName: 'SAO TOME-ET-PRINCIPE'
  },
  SEN: {
    inseeCode: 99341,
    countryName: 'SENEGAL'
  },
  SYC: {
    inseeCode: 99398,
    countryName: 'SEYCHELLES'
  },
  SLE: {
    inseeCode: 99342,
    countryName: 'SIERRA LEONE'
  },
  SOM: {
    inseeCode: 99318,
    countryName: 'SOMALIE'
  },
  SDN: {
    inseeCode: 99343,
    countryName: 'SOUDAN'
  },
  SSD: {
    inseeCode: 99349,
    countryName: 'SOUDAN DU SUD'
  },
  TZA: {
    inseeCode: 99309,
    countryName: 'TANZANIE'
  },
  TCD: {
    inseeCode: 99344,
    countryName: 'TCHAD'
  },
  TGO: {
    inseeCode: 99345,
    countryName: 'TOGO'
  },
  TUN: {
    inseeCode: 99351,
    countryName: 'TUNISIE'
  },
  ZMB: {
    inseeCode: 99346,
    countryName: 'ZAMBIE'
  },
  ZWE: {
    inseeCode: 99310,
    countryName: 'ZIMBABWE'
  },
  USA: {
    inseeCode: 99404,
    countryName: "ETATS-UNIS D'AMERIQUE"
  },
  ATG: {
    inseeCode: 99441,
    countryName: 'ANTIGUA-ET-BARBUDA'
  },
  ATA: {
    inseeCode: 99000,
    countryName: 'ANTARCTIQUE'
  },
  ANT: {
    inseeCode: 99431,
    countryName: 'ANTILLES NEERLANDAISES'
  },
  ARG: {
    inseeCode: 99415,
    countryName: 'ARGENTINE'
  },
  BHS: {
    inseeCode: 99436,
    countryName: 'BAHAMAS'
  },
  BRB: {
    inseeCode: 99434,
    countryName: 'BARBADE'
  },
  BLZ: {
    inseeCode: 99429,
    countryName: 'BELIZE'
  },
  BMU: {
    inseeCode: 99425,
    countryName: 'BERMUDES'
  },
  BOL: {
    inseeCode: 99418,
    countryName: 'BOLIVIE'
  },
  BES: {
    inseeCode: 99443,
    countryName: 'BONAIRE, SAINT EUSTACHE ET SABA'
  },
  BRA: {
    inseeCode: 99416,
    countryName: 'BRESIL'
  },
  CYM: {
    inseeCode: 99425,
    countryName: 'CAIMANES (ILES)'
  },
  CAN: {
    inseeCode: 99401,
    countryName: 'CANADA'
  },
  CHL: {
    inseeCode: 99417,
    countryName: 'CHILI'
  },
  COL: {
    inseeCode: 99419,
    countryName: 'COLOMBIE'
  },
  CRI: {
    inseeCode: 99406,
    countryName: 'COSTA RICA'
  },
  CUB: {
    inseeCode: 99407,
    countryName: 'CUBA'
  },
  CUW: {
    inseeCode: 99444,
    countryName: 'Curacao'
  },
  DOM: {
    inseeCode: 99408,
    countryName: 'DOMINICAINE (REPUBLIQUE)'
  },
  DMA: {
    inseeCode: 99438,
    countryName: 'DOMINIQUE'
  },
  SLV: {
    inseeCode: 99414,
    countryName: 'EL SALVADOR'
  },
  ECU: {
    inseeCode: 99420,
    countryName: 'EQUATEUR'
  },
  SGS: {
    inseeCode: 99427,
    countryName: 'GEORGIE DU SUD ET LES ILES SANDWICH DU SUD'
  },
  GRD: {
    inseeCode: 99435,
    countryName: 'GRENADE'
  },
  GRL: {
    inseeCode: 99430,
    countryName: 'GROENLAND'
  },
  GTM: {
    inseeCode: 99409,
    countryName: 'GUATEMALA'
  },
  GUY: {
    inseeCode: 99428,
    countryName: 'GUYANA'
  },
  HTI: {
    inseeCode: 99410,
    countryName: 'HAITI'
  },
  HND: {
    inseeCode: 99411,
    countryName: 'HONDURAS'
  },
  JAM: {
    inseeCode: 99426,
    countryName: 'JAMAIQUE'
  },
  FLK: {
    inseeCode: 99427,
    countryName: 'MALOUINES, OU FALKLAND (ILES)'
  },
  MEX: {
    inseeCode: 99405,
    countryName: 'MEXIQUE'
  },
  MSR: {
    inseeCode: 99425,
    countryName: 'MONTSERRAT'
  },
  NIC: {
    inseeCode: 99412,
    countryName: 'NICARAGUA'
  },
  PAN: {
    inseeCode: 99413,
    countryName: 'PANAMA'
  },
  PRY: {
    inseeCode: 99421,
    countryName: 'PARAGUAY'
  },
  PER: {
    inseeCode: 99422,
    countryName: 'PEROU'
  },
  PRI: {
    inseeCode: 99432,
    countryName: 'PORTO RICO'
  },
  KNA: {
    inseeCode: 99442,
    countryName: 'SAINT-CHRISTOPHE-ET-NIEVES'
  },
  LCA: {
    inseeCode: 99439,
    countryName: 'SAINTE-LUCIE'
  },
  SXM: {
    inseeCode: 99445,
    countryName: 'SAINT-MARTIN (PARTIE NEERLANDAISE)'
  },
  VCT: {
    inseeCode: 99440,
    countryName: 'SAINT-VINCENT-ET-LES GRENADINES'
  },
  SUR: {
    inseeCode: 99437,
    countryName: 'SURINAME'
  },
  UMI: {
    inseeCode: 99432,
    countryName: "TERR. DES ETATS-UNIS D'AMERIQUE EN AMERIQUE"
  },
  TTO: {
    inseeCode: 99433,
    countryName: 'TRINITE-ET-TOBAGO'
  },
  TCA: {
    inseeCode: 99425,
    countryName: 'TURKS ET CAIQUES (ILES)'
  },
  URY: {
    inseeCode: 99423,
    countryName: 'URUGUAY'
  },
  VEN: {
    inseeCode: 99424,
    countryName: 'VENEZUELA'
  },
  VGB: {
    inseeCode: 99425,
    countryName: 'VIERGES BRITANNIQUES (ILES)'
  },
  VIR: {
    inseeCode: 99432,
    countryName: 'VIERGES DES ETATS-UNIS (ILES)'
  },
  AUS: {
    inseeCode: 99501,
    countryName: 'AUSTRALIE'
  },
  CXR: {
    inseeCode: 99501,
    countryName: 'CHRISTMAS (ILE)'
  },
  CCK: {
    inseeCode: 99501,
    countryName: 'COCOS ou KEELING (ILES)'
  },
  COK: {
    inseeCode: 99502,
    countryName: 'COOK (ILES)'
  },
  FJI: {
    inseeCode: 99508,
    countryName: 'FIDJI'
  },
  GUM: {
    inseeCode: 99505,
    countryName: 'GUAM'
  },
  HMD: {
    inseeCode: 99501,
    countryName: 'HEARD ET MACDONALD (ILES)'
  },
  KIR: {
    inseeCode: 99513,
    countryName: 'KIRIBATI'
  },
  MNP: {
    inseeCode: 99505,
    countryName: 'MARIANNES DU NORD (ILES)'
  },
  MHL: {
    inseeCode: 99515,
    countryName: 'MARSHALL (ILES)'
  },
  FSM: {
    inseeCode: 99516,
    countryName: 'MICRONESIE (ETATS FEDERES DE)'
  },
  NRU: {
    inseeCode: 99507,
    countryName: 'NAURU'
  },
  NIU: {
    inseeCode: 99502,
    countryName: 'NIUE'
  },
  NFK: {
    inseeCode: 99501,
    countryName: 'NORFOLK (ILE)'
  },
  NZL: {
    inseeCode: 99502,
    countryName: 'NOUVELLE-ZELANDE'
  },
  PLW: {
    inseeCode: 99517,
    countryName: 'PALAOS (ILES)'
  },
  PNG: {
    inseeCode: 99510,
    countryName: 'PAPOUASIE-NOUVELLE-GUINEE'
  },
  PCN: {
    inseeCode: 99503,
    countryName: 'PITCAIRN (ILE)'
  },
  SLB: {
    inseeCode: 99512,
    countryName: 'SALOMON (ILES)'
  },
  ASM: {
    inseeCode: 99505,
    countryName: 'SAMOA AMERICAINES'
  },
  WSM: {
    inseeCode: 99506,
    countryName: 'SAMOA OCCIDENTALES'
  },
  TKL: {
    inseeCode: 99502,
    countryName: 'TOKELAU'
  },
  TON: {
    inseeCode: 99509,
    countryName: 'TONGA'
  },
  TUV: {
    inseeCode: 99511,
    countryName: 'TUVALU'
  },
  VUT: {
    inseeCode: 99514,
    countryName: 'VANUATU'
  }
};
