import moment from 'moment';
import { constants } from '@constants/constants';
import { MyDetailsDataService } from '@services/my-details.data.service';
import { Informations } from '@webapi/MIF.Subscription.Parrot';

export function nirValidator() {
    return (control: any) => {
        const nir = control.value;
        const myDetailsDataService = control.myDetailsDataService as MyDetailsDataService;
        const formData = myDetailsDataService.data.form;
        const civilities = myDetailsDataService.data.civilities;

        if (nir) {
            if (!formData.civility || !formData.dob) {
                return {
                    nirValidatorNotEnoughData: true
                };
            }

            if (nir.replaceAll(' ', '').length === 15) {
                const isGenderOk: boolean = checkGender(nir, formData, civilities);
                const isDobOk: boolean = checkDob(nir, formData);
                // let isBirthZipCodeOk: boolean = checkPostalCodeOfBirth(nir, formData);
                const isControlKeyOk: boolean = checkControlKey(nir);

                // if (isGenderOk && isDobOk && isBirthZipCodeOk && isControlKeyOk) {
                if (isGenderOk && isDobOk && isControlKeyOk) {
                    return null;
                } else {
                    return {
                        nirValidator: true
                    };
                }
            } else {
                return {
                    nirValidator: true
                };
            }
        }

        return null;
    };
}

function checkGender(nir: string, formData: any, civilities: Informations[]): boolean {
    const isGenderOk = false;
    const manGender = 1;
    const womanGender = 2;
    const manGenderId = civilities?.find((item: Informations) => { return item.code === constants.codeMonsieur; }).id;
    const womanGenderId = civilities?.find((item: Informations) => { return item.code === constants.codeMadame; }).id;
    const genderPart = nir.slice(0, 1);

    if (+genderPart === manGender) {
        return +formData.civility === manGenderId;
    }

    if (+genderPart === womanGender) {
        return +formData.civility === womanGenderId;
    }

    return isGenderOk;
}

function checkDob(nir: string, formData: any): boolean {
    let isDobOk = false;
    const yearPart = nir.slice(1, 3);
    const monthPart = nir.slice(3, 5);
    const dob = moment.utc(formData.dob).format('MM-YY-DD');
    const month = dob.split('-')[0];
    const year = dob.split('-')[1];

    isDobOk = yearPart === year && monthPart === month

    return isDobOk;
}

function checkPostalCodeOfBirth(nir: string, formData: any): boolean {
    let isPostalCodeOfBirthOk = false;
    let zipPart = nir.slice(5, 7);

    if (formData.postalCodeOfBirth) {
        const firstZipTwoFigures = (formData.postalCodeOfBirth + '')?.slice(0, 2);

        if (zipPart === '2A' || zipPart === '2B' || zipPart === '2a' || zipPart === '2b') {
            zipPart = '20';
        }

        isPostalCodeOfBirthOk = zipPart === firstZipTwoFigures;
    }

    return isPostalCodeOfBirthOk;
}

function checkControlKey(nir: string): boolean {
    let isControlKeyOk = false;
    const controlKeyPart = nir.slice(13, 15);
    nir = nir.replace('2A', '19').replace('2B', '18').replace('2a', '19').replace('2b', '18');
    const nirPartForCalculation = nir.slice(0, 13);
    const controlKey = 97 - (+nirPartForCalculation % 97);

    isControlKeyOk = +controlKeyPart === controlKey;

    return isControlKeyOk;
}
