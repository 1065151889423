import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euro'
})
export class EuroPipe implements PipeTransform {
  constructor(private cp: CurrencyPipe) {

  }

  transform(value: number, toInt?: boolean): string {
    if (!value && value !== 0) {
      return '';
    }

    let res = this.cp.transform(value, 'EUR', 'symbol', null, 'fr');

    return toInt ? res.replace(',00', '') : res;
  }
}
