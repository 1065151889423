import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { LazyLoadingScriptService } from '@services/lazy-loading-script.service';

@Component({
    selector: 'app-kameleoon',
    templateUrl: './kameleoon.component.html'
})
export class KameleoonComponent implements OnInit, OnDestroy {
    kameleoonScriptId: string = 'kameleoonScriptId';
    kameleoonScriptId2: string = 'kameleoonScriptId2';
    jsProdUrl: string = 'https://07sz0fzefv.kameleoon.eu/kameleoon.js';
    jsStageUrl: string = 'https://crs2cyjr3a.kameleoon.eu/kameleoon.js';
    kameleoonIframeProdUrl: string = 'https://www.mifassur.com/static/iframe_kameleoon.html';
    kameleoonIframeStageUrl: string = 'https://webpreprod.mifassur.com/static/iframe_kameleoon.html';


    isStaging: boolean;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer2: Renderer2,
        private lazyLoadService: LazyLoadingScriptService
    ) {
    }

    ngOnDestroy(): void {
        if (environment.enableABTesting === true) {
            const elem = document.querySelector('#' + this.kameleoonScriptId);
            document.querySelector('#' + this.kameleoonScriptId)?.parentNode.removeChild(elem)

            const elem2 = document.querySelector('#' + this.kameleoonScriptId2);
            document.querySelector('#' + this.kameleoonScriptId2)?.parentNode.removeChild(elem2)
        }
    }

    async ngOnInit() {
        this.isStaging = !!environment.useABTestingStagingURL;

        if (environment.enableABTesting === true) {
            this.addCustomData();

            const scriptUrl = this.isStaging ? this.jsStageUrl : this.jsProdUrl;

            this.lazyLoadService.loadScript(scriptUrl).subscribe(() => { });
        }
    }

    addCustomData() {
        const kameleoonIframeUrl = this.isStaging ? this.kameleoonIframeStageUrl : this.kameleoonIframeProdUrl;
        const srcScript = this.renderer2.createElement('script');
        srcScript.type = 'text/javascript';
        srcScript.id = this.kameleoonScriptId;
        srcScript.text = `
            (function() {
                // Duration in milliseconds to wait while the Kameleoon application file is loaded
                var kameleoonLoadingTimeout = 1000;

                window.kameleoonQueue = window.kameleoonQueue || [];
                window.kameleoonStartLoadTime = new Date().getTime();
                if (! document.getElementById("kameleoonLoadingStyleSheet") && ! window.kameleoonDisplayPageTimeOut)
                {
                    var kameleoonS = document.getElementsByTagName("script")[0];
                    var kameleoonCc = "* { visibility: hidden !important; background-image: none !important; }";
                    var kameleoonStn = document.createElement("style");
                    kameleoonStn.type = "text/css";
                    kameleoonStn.id = "kameleoonLoadingStyleSheet";
                    if (kameleoonStn.styleSheet)
                    {
                        kameleoonStn.styleSheet.cssText = kameleoonCc;
                    }
                    else
                    {
                        kameleoonStn.appendChild(document.createTextNode(kameleoonCc));
                    }
                    kameleoonS.parentNode.insertBefore(kameleoonStn, kameleoonS);
                    window.kameleoonDisplayPage = function(fromEngine)
                    {
                        if (!fromEngine)
                        {
                            window.kameleoonTimeout = true;
                        }
                        if (kameleoonStn.parentNode)
                        {
                            kameleoonStn.parentNode.removeChild(kameleoonStn);
                        }
                    };
                    window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
                }
            }());
            `;

        const srcScript2 = this.renderer2.createElement('script');
        srcScript2.type = 'text/javascript';
        srcScript2.id = this.kameleoonScriptId2;
        srcScript2.text = `
            (function() {
                // Change the value of this URL to point to your own URL, where the iFrame is hosted
                window.kameleoonIframeURL = "${kameleoonIframeUrl}";
            
                window.kameleoonLightIframe = false;
                var kameleoonIframeOriginElement = document.createElement("a");
                kameleoonIframeOriginElement.href = kameleoonIframeURL;
                window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
                if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
                {
                    window.kameleoonLightIframe = true;
                    var kameleoonProcessMessageEvent = function(event)
                    {
                        if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
                        {
                            window.removeEventListener("message", kameleoonProcessMessageEvent);
                            window.kameleoonExternalIFrameLoaded = true;
                            if (window.Kameleoon)
                            {
                                eval(event.data);
                                Kameleoon.Analyst.load();
                            }
                            else
                            {
                                window.kameleoonExternalIFrameLoadedData = event.data;
                            }
                        }
                    };
                    if (window.addEventListener)
                    {
                        window.addEventListener("message", kameleoonProcessMessageEvent, false);
                    }
                    var iframeNode = document.createElement("iframe");
                    iframeNode.src = kameleoonIframeURL;
                    iframeNode.id = "kameleoonExternalIframe";
                    iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
                    document.head.appendChild(iframeNode);
                }
            }());
            `;

        this.renderer2.appendChild(this.document.head, srcScript);
        this.renderer2.appendChild(this.document.head, srcScript2);
    }

}
