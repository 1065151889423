import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobileFormControl } from '@form/mobile-form-control/mobile-form-control';
import { CustomerService } from '@webapi/services/customer.service';
import { DocumentSignatureRequest, ExternalServices, Profile, SignatureTransactionRequest } from '@webapi/MIF.Subscription.WebApi';
import { ActivatedRoute, Router } from '@angular/router';
import { NameWithDigitsFormControl } from '@form/name-with-digits-form-control/name-with-digits-form-control';
import { ProgressBarService } from '@services/progress-bar.service';
import { constants } from '@constants/constants';
import { WsDqeService } from '@webapi/services/ws-dqe.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent, modalTypes } from '@modals/alert-modal/alert-modal.component';
import { SettingsService } from '@webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { isPeri } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';

@Component({
  selector: 'app-phone-confirmation',
  templateUrl: './phone-confirmation.component.html',
  styleUrls: ['./phone-confirmation.component.scss']
})
export class PhoneConfirmationComponent implements OnInit {
  form: FormGroup;
  getSmsText = 'Envoyer le code par SMS';
  smsRequested = false;
  pdfContent: string;
  signatureTransactionRequest: SignatureTransactionRequest;
  userId: string;
  clientProfile: Profile;
  externalServicesConfiguration: ExternalServices;
  ProfileStepName = ProfileStepName;
  public isPeri = isPeri();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private progressBarService: ProgressBarService,
    private bsModalService: BsModalService,
    private wsDqeService: WsDqeService,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.signature.phoneConfirmation.initialStep);
    this.externalServicesConfiguration = await this.settingsService.getExternalServicesConfiguration();
    this.pdfContent = history.state.data;
    this.userId = this.route.snapshot.queryParamMap.get('userId');

    if (!this.pdfContent) {
      // the end-user should always come first from the Signature page
      this.router.navigate(['signature'], { queryParams: { userId: this.customerService.userId } });
    }

    this.form = new FormGroup({
      mobilePhone: new MobileFormControl(true, true, {
        readOnlyCss: true,
        hideErrorMark: true,
        hideSuccessMark: true
      }),
      sms: new NameWithDigitsFormControl(true, false, {
        capitalizeAll: true,
        minLength: 6,
        maxLength: 6,
        requiredErrorMessage: this.labelTextPipe.transform('SIGNATURE.SMSMessageCode') // todo: use the same label as in the proveance app
      })
    });

    await this.fetchInitData();
  }

  async fetchInitData(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile.contact.mobilePhone) {
      this.form.get('mobilePhone').patchValue(this.clientProfile.contact.mobilePhone);
    }
  }

  async getSms($event: any): Promise<void> {
    $event.preventDefault();
    const mobilePhone = this.clientProfile.contact.mobilePhone;
    let mobileInValid = false;

    if (mobilePhone && mobilePhone !== '' && this.externalServicesConfiguration.dqeValidation === true) {
      mobileInValid = !(await this.wsDqeService.validatePhoneNumber(mobilePhone));
      if (mobileInValid) {
        this.form.get('mobilePhone').setErrors({ phoneRemoteValidator: true });
      }
    }

    if (!mobileInValid) {
      this.getSmsText = this.labelTextPipe.transform('SIGNATURE.SendCodeButtonAgain');
      this.smsRequested = true;
      const singatureRequest = new DocumentSignatureRequest({
        profileId: this.userId,
        phoneNumber: `+33${mobilePhone}`,
        htmlContent: this.pdfContent
      });
      const signatureTransactionResponse = await this.customerService.signDocument(singatureRequest);
      this.signatureTransactionRequest = new SignatureTransactionRequest({
        profileId: this.userId,
        transactionId: signatureTransactionResponse.transactionId,
        signatoryId: signatureTransactionResponse.signatoryId,
        validationCode: null
      });
    }
  }

  goToMyProfile(): void {
    this.router.navigate(['my-details/contacts'], { queryParams: { userId: this.customerService.userId }, state: { scrollToPhones: true } });
  }

  async onSubmit($event: any): Promise<void> {
    $event.preventDefault();
    const sms = this.form.get('sms').value.toString();
    this.signatureTransactionRequest.validationCode = sms;
    const signatureTransactionResponse = await this.customerService.validateTransaction(this.signatureTransactionRequest);

    if (signatureTransactionResponse.apiError !== undefined && signatureTransactionResponse.apiError !== '') {
      this.bsModalService.show(AlertModalComponent, {
        initialState: {
          type: modalTypes.error,
          title: this.labelTextPipe.transform('SIGNATURE.WrongCodeText')
        }
      });
      this.form.get('sms').patchValue('');
    } else {
      this.router.navigate(['success'], {
        queryParams: { userId: this.customerService.userId },
        state: { data: signatureTransactionResponse }
      });
    }
  }
}
