import { ValidatorFn } from '@angular/forms';
import { ibanValidator } from '../../validators/ibanValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class IbanFormControl extends AbstractFormControl {
    constructor(required?: boolean, disabled?: boolean, private options?: IbanOptions) {
        super(required, disabled);
        this.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [ibanValidator()];
    }
}

export interface IbanOptions {
    readOnlyCss?: boolean;
}
