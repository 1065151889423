import { FormControl } from '@angular/forms';

export function mifAdvisorCodeValidator() {
  return (control: FormControl) => {
    const codeValue = control.value;

    if (codeValue) {
      if (codeValue.length < 4) {
        return {
          mifAdvisorCodeValidator: true
        };
      } else {
        return null;
      }
    }

    return null;
  };
}
