import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@models/validation-error';

export function zeroValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const brutAmountValue = control.value.brutAmount;
    const fractionValue = control.value.fraction;

    if (brutAmountValue === 0 || fractionValue === 0) {
      return {
        type: ValidationError.Zero,
        value: '',
        message: 'Zero error'
      };
    }

    return null;
  };
}
