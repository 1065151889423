<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill">
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appTrim
      (ngModelChange)="change()"
      type="email"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
      autocomplete="new-password"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.required">
        {{ requiredText }}
      </span>
      <span *ngIf="control.errors?.minlength"> {{ 'HOME.FormEmailError' | labelText }} </span>
      <span *ngIf="control.errors?.maxlength"> {{ 'HOME.FormEmailError' | labelText }} </span>
      <span *ngIf="!control.errors?.minlength && !control.errors?.maxlength && control.errors?.emailValidator"> {{ 'HOME.FormEmailError' | labelText }} </span>
      <span *ngIf="control.errors?.emailRemoteValidator"> {{ 'HOME.FormEmailError' | labelText }} </span>
    </mat-error>
  </mat-form-field>
</div>
