declare global {
  interface String {
    myFormatString(replacements: any[]): string;
    allToUpperCase(): string;
    isNumeric(): boolean;
    replaceAll(stringToFind: string, stringToReplace: string): string;
  }
}

String.prototype.isNumeric = function (): boolean {
  if (typeof this.valueOf() != 'string') return false;
  return /^-?\d+$/.test(this.valueOf());
};

String.prototype.myFormatString = function (replacements: any[]): string {
  return this.replace(/{(\d+)}/g, (match, index) => {
    return typeof replacements[index] !== 'undefined' ? replacements[index] : match;
  });
};

String.prototype.allToUpperCase = function (): string {
  if (this) {
    const arr: string[] = this.split(' ');
    const res: string[] = [];

    arr.forEach((word) => {
      res.push(word.toUpperCase());
    });

    return res.join(' ');
  } else {
    return '';
  }
};

String.prototype.replaceAll = function (stringToFind: string, stringToReplace: string) {
  var temp = this;
  var index = temp.indexOf(stringToFind);

  while (index != -1) {
    temp = temp.replace(stringToFind, stringToReplace);
    index = temp.indexOf(stringToFind);
  }

  return temp + '';
};

export { };
