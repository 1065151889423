import { ResidenceQuestionsModel } from '@extensions/user-data-extensions';
import { Component, OnInit } from '@angular/core';
import { faCheck, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { CustomerService } from '@webapi/services/customer.service';
import { ProgressBarService } from '@services/progress-bar.service';
import { Profile } from '@shared/webapi/MIF.Subscription.WebApi';
import { ProfileStepName } from '@shared/models/profile-step-name';

@Component({
  selector: 'app-residence-questions',
  templateUrl: './residence-questions.component.html',
  styleUrls: ['./residence-questions.component.scss']
})
export class ResidenceQuestionsComponent implements OnInit {
  frenchResidence = false;
  notFrenchResidence = false;

  ageCapable = false;
  ageNotCapable = false;

  americanResidence = false;
  notAmericanResidence = false;

  check1 = false;
  check2 = false;
  check3 = false;

  faCheck = faCheck;
  faTimes = faTimes;
  faInfoCircle = faInfoCircle;

  ProfileStepName = ProfileStepName;
  clientProfile: Profile;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private progressBarService: ProgressBarService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(0);
    await this.restoreAnswersOnForm();
  }

  async restoreAnswersOnForm() {
    const residenceQuestionsModel = this.customerService.getResidenceQuestionsModel();
    this.clientProfile = await this.customerService.getUserData();

    if (residenceQuestionsModel) {
      if (residenceQuestionsModel.isFrenchTaxResident !== undefined && residenceQuestionsModel.isFrenchTaxResident !== null) {
        this.check1 = residenceQuestionsModel.isFrenchTaxResident;
        this.frenchResidence = residenceQuestionsModel.isFrenchTaxResident;
        this.notFrenchResidence = !residenceQuestionsModel.isFrenchTaxResident;
      }

      if (residenceQuestionsModel.isLegallyCapable !== undefined && residenceQuestionsModel.isLegallyCapable !== null) {
        this.check2 = residenceQuestionsModel.isLegallyCapable;
        this.ageCapable = residenceQuestionsModel.isLegallyCapable;
        this.ageNotCapable = !residenceQuestionsModel.isLegallyCapable;
      }

      if (residenceQuestionsModel.isUsCitizen !== undefined && residenceQuestionsModel.isUsCitizen !== null) {
        this.check3 = !residenceQuestionsModel.isUsCitizen ? true : false;
        this.americanResidence = residenceQuestionsModel.isUsCitizen;
        this.notAmericanResidence = !residenceQuestionsModel.isUsCitizen;
      }
    }
  }

  switchIfFrenchResidence() {
    this.notFrenchResidence = false
    this.frenchResidence = true;
    this.check1 = true;
  }

  switchIfNotFrenchResidence() {
    this.notFrenchResidence = true
    this.frenchResidence = false;
    this.check1 = false;
  }

  switchIfAgeCapable() {
    this.ageCapable = true;
    this.ageNotCapable = false;
    this.check2 = true;
  }

  switchIfAgeNotCapable() {
    this.ageCapable = false;
    this.ageNotCapable = true;
    this.check2 = false;
  }

  switchIfAmericanResidence() {
    this.americanResidence = true;
    this.notAmericanResidence = false;
    this.check3 = false;
  }

  switchIfNotAmericanResidence() {
    this.americanResidence = false;
    this.notAmericanResidence = true;
    this.check3 = true;
  }

  async goToProfile() {
    const userId = await this.customerService.updateResidenceQuestionsModel({
      isFrenchTaxResident: this.frenchResidence,
      isLegallyCapable: this.frenchResidence,
      isUsCitizen: this.americanResidence
    } as ResidenceQuestionsModel);

    this.router.navigate(['/my-profile'], { queryParams: { userId: userId } });
  }
}
