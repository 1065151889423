import { ValidatorFn } from '@angular/forms';
import { AbstractFormControl } from '../abstract-form-control';

export class CheckboxFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false) {
        super(required, disabled);
    }

    protected getValidators(): ValidatorFn[] {
        return [];
    }
}

export interface CheckboxOptions {
}
