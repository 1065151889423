import { Pipe, PipeTransform } from '@angular/core';
import { LabelsDataService } from '@services/labels-data.service';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {
  constructor(private labelsDataService: LabelsDataService) {

  }
  
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.split(' ').join('').replace(/(.{4})/g, "$1 ");
  }
}
