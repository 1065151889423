import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { AsyncIbanValidator } from './iban.validator';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, timeout } from 'rxjs/operators';

@Directive({
  selector: '[appAsyncIbanValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AsyncIbanValidatorDirective),
      multi: true
    }
  ]
})
export class AsyncIbanValidatorDirective implements AsyncValidator {
  constructor(private validator: AsyncIbanValidator) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      debounceTime(100),
      switchMap(iban => this.validator.validate(control))
    );
  }
}
