import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { MobileFormControl } from './mobile-form-control';
import { filter } from 'rxjs/operators';
import { isPresent } from '@shared/utils/isPresent';

@Component({
  selector: 'app-mobile-form-control',
  templateUrl: './mobile-form-control.component.html',
  styleUrls: ['./mobile-form-control.component.scss']
})
export class MobileFormControlComponent extends AbstractFormControlComponent<MobileFormControl> implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;
  @Input() hidePhoneAvailable: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  faCheck: any = faCheck;
  faClose: any = faTimes;
  faEye: any = faEye;
  faEyeSlash: any = faEyeSlash;

  showPhone: boolean = true;
  matPrefix: string = '+33';
  originValue: string;
  phoneNumber: string;

  ngOnInit(): void {
    this.control.valueChanges.pipe(filter(isPresent)).subscribe(value => {
      if (!this.originValue) {
        this.originValue = value;
        this.phoneNumber = value;

        if (this.hidePhoneAvailable === true) {
          this.hideShowPhoneToogle();
        }
      }
    });
  }

  change(): void {
    this.onChange.emit(this.control.value?.indexOf('*') === -1 ? this.control.value : this.originValue);
  }

  hideShowPhoneToogle() {
    this.showPhone = !this.showPhone;
    this.matPrefix = this.showPhone ? '+33' : '***';
    if (this.showPhone === false) {
      const newValue = this.originValue.replace(/\d(?=\d{4})/g, '*');
      this.control.patchValue(newValue);
    } else {
      this.control.patchValue(this.originValue);
    }
  }

  public get hideErrorMark(): boolean {
    return this.control.hideErrorMark || false;
  }

  public get hideSuccessMark(): boolean {
    return this.control.hideSuccessMark || false;
  }

  public get readOnlyCss(): boolean {
    return this.control.readOnlyCss || false;
  }
}
