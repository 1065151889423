<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill" [class.readonly]="readOnlyCss">
    <mat-label>{{ title }}</mat-label>

    <input
      matInput
      appTrim
      appOnlyLetters
      *ngIf="!capitalizeAll"
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
    />

    <input
      matInput
      appTrim
      appOnlyLetters
      appCapitalizeAll
      *ngIf="capitalizeAll"
      [class.is-invalid]="control.invalid && control.touched"
      type="text"
      class="form-control form-control-sm"
      [formControlName]="controlName"
    />
    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error *ngIf="!control.hideErrorMessages">
      <span *ngIf="control.errors?.required">{{ requiredText }}</span>
      <span *ngIf="control.errors?.maxlength"> {{ 'Common.MaxLengthError' | labelText }} </span>
      <!-- <span *ngIf="control.errors?.maxlength">{{ 'HOME.WrongEntryErrorText1' | labelText }} {{ control.maxLength }} {{ 'HOME.WrongEntryErrorText2' | labelText }}</span> -->
    </mat-error>
  </mat-form-field>
</div>
