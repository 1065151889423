<div class="card">
  <div class="row">
    <div class="col-12 text-left">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="header mt-3">{{ 'SUMMARY.MyGPAContractText' | labelText }}</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
            <div class="txt-default mt-2 fw-600">
              {{ 'SUMMARY.ForesightGFDGuaranteedCapital' | labelText }} <span class="text-blue"> {{ clientProfile.gpaProfile?.guaranteedCapitalCode | euro: true }} </span>.
            </div>
            <div class="txt-default mt-4 mb-4 fw-600">
              {{ 'SUMMARY.ForesightIncluded' | labelText }} <br />
              <span class="answer text-blue"> - {{ 'SUMMARY.ForesightIncludedAllServices' | labelText }} </span><br />
              <span class="answer text-blue" *ngIf="clientProfile.gpaProfile?.isMultipliedCoverage"> - {{ 'SUMMARY.ATDNPlus60GuaranteeX2X3Title' | labelText }}</span>
            </div>
            <div class="txt-default fw-600">
              <span *ngIf="!offer">
                  {{ 'SUMMARY.SummaryForesightATDNContribution1' | labelText }} <span class="text-blue"> {{ clientProfile.gpaProfile?.monthlyPayment | euro: true }} </span> {{ 'SUMMARY.SummaryForesightATDNContribution2' | labelText }} <br />
              </span>
              <span *ngIf="offer">
                  Votre tarif la première année est de <span class="text-blue"> {{ offer.rate | euro: true }}</span> par mois au lieu de {{ clientProfile.gpaProfile?.monthlyPayment | euro: true }}
                <br />
              </span>
                {{ 'SUMMARY.SummaryForesightATDNNextContribution' | labelText }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyContract()">{{ 'Common.Modify' | labelText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
