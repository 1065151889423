import { Fonds } from '@webapi/MIF.Subscription.Parrot';
import { constants } from '@constants/constants';
import { FundRule } from '@models/fund-rule';
import { FundPerformance } from '@models/fund-performance';
import { FundType } from '@models/fund-type';

declare module '../webapi/MIF.Subscription.Parrot' {
  interface Fonds {
    getFeesInCaseWithdrawal(): number;
    getMinAmount(): number;
    getFundRules(): Array<FundRule>;
    getFundPerformance(): Array<FundPerformance>;
    isScpiFundType(): boolean;
  }
}

Fonds.prototype.getFeesInCaseWithdrawal = function (): number {
  const feesRate = this.typeRegle?.find(ruleType => ruleType.id === constants.fundsRuleTypeId)?.regle?.find(r => r.code?.trim() === RuleCodes.FeesInCaseWithdrawal)?.valeur;

  if (!feesRate) {
    return 0;
  }

  return +feesRate.replace(/,/g, '.');
};

Fonds.prototype.getMinAmount = function (): number {
  const minAmount = this.typeRegle?.find(ruleType => ruleType.id === constants.fundsRuleTypeId)?.regle?.find(r => r.code?.trim() === RuleCodes.MinAmount)?.valeur;

  if (!minAmount) {
    return 0;
  }

  return +minAmount.replace(/,/g, '.');
};

Fonds.prototype.getFundRules = function (): Array<FundRule> {
  const fundRules = this.typeRegle.find(r => r.id === constants.fundsRuleTypeId)?.regle;

  if (!fundRules) {
    return new Array<FundRule>();
  }

  return fundRules.map(r =>
    Object.assign(new FundRule(), {
      code: r.code,
      label: r.libelle,
      value: r.valeur
    })
  );
};

Fonds.prototype.getFundPerformance = function (): Array<FundPerformance> {
  const perfomrmance = this.performance.map(
    f =>
      ({
        id: f.id,
        label: f.libelle,
        rate: f.taux,
        year: f.annee,
        order: f.ordre
      } as FundPerformance)
  );

  return perfomrmance;
};

Fonds.prototype.isScpiFundType = function (): boolean {
  return FundType.SCPI === this.typeFonds.id;
};

export const RuleCodes = {
  MinAmount: 'VNMF0001',
  MaxFraction26: 'FOND0026',
  MaxFraction27: 'FOND0027', // For CEMS and CIMS the WS getReglesMetierProduit will stop returning the rules FOND0027 and FOND0028. For PERI, MIF will set the "valeur" property of the FOND0027 to 70 [MIFS-597]
  MaxFraction28: 'FOND0028', // For CEMS and CIMS the WS getReglesMetierProduit will stop returning the rules FOND0027 and FOND0028. [MIFS-597]
  PaymentThreshold: 'FOND0025', // 30000
  FeesInCaseWithdrawal: 'FOND0030' // Taux de frais spécifiques en cas de retrait
};
