import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '@webapi/services/customer.service';
import { ProgressBarService } from '../../../services/progress-bar.service';
import { constants } from '@constants/constants';
import { Profile } from '@webapi/MIF.Subscription.WebApi';
import { FormGroup } from '@angular/forms';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { collections } from '@constants/collections';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { debounceTime, tap } from 'rxjs/operators';
import { HealthQuestionsModel } from '@shared/extensions/user-data-extensions';

@Component({
  selector: 'app-health-questionnaire',
  templateUrl: './health-questionnaire.component.html',
  styleUrls: ['./health-questionnaire.component.scss']
})
export class HealthQuestionnaireComponent implements OnInit, OnDestroy {
  @Input() cemsSummary: boolean;
  @Input() subscribeGpaPer1Euro: boolean;
  @Output() onFormStatusChange: EventEmitter<boolean> = new EventEmitter();

  clientProfile: Profile;
  form: FormGroup;
  laidOffForIllnessControl: ComboBoxFormControl;
  tempUnableWorkControl: ComboBoxFormControl;
  offWorkMore30daysControl: ComboBoxFormControl;
  treatmentMore30daysControl: ComboBoxFormControl;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  constructor(private router: Router, private customerService: CustomerService, private progressBarService: ProgressBarService) {}

  async ngOnInit(): Promise<void> {
    if (!this.cemsSummary) {
      this.progressBarService.sendStep(constants.steps.myProfile.countOfTheSteps - 1);
    }
    await this.fetchInitData();
    this.initNewFormGroups();
    this.initControls();
    this.restoreAnswersOnForm();
    this.initSubscriptions();
  }

  async fetchInitData() {
    this.clientProfile = await this.customerService.getUserData();
  }

  initNewFormGroups(): void {
    this.form = new FormGroup({
      laidOffForIllness: new ComboBoxFormControl(
        true,
        false,
        {
          collection: collections.yesNo,
          valuePropertyName: 'description'
        },
        {
          hideSuccessMark: this.cemsSummary
        }
      ),
      tempUnableWork: new ComboBoxFormControl(
        true,
        false,
        {
          collection: collections.yesNo,
          valuePropertyName: 'description'
        },
        {
          hideSuccessMark: this.cemsSummary
        }
      ),
      offWorkMore30days: new ComboBoxFormControl(
        true,
        false,
        {
          collection: collections.yesNo,
          valuePropertyName: 'description'
        },
        {
          hideSuccessMark: this.cemsSummary
        }
      ),
      treatmentMore30days: new ComboBoxFormControl(
        true,
        false,
        {
          collection: collections.yesNo,
          valuePropertyName: 'description'
        },
        {
          hideSuccessMark: this.cemsSummary
        }
      )
    });
  }

  initControls() {
    this.laidOffForIllnessControl = this.form.get('laidOffForIllness') as ComboBoxFormControl;
    this.tempUnableWorkControl = this.form.get('tempUnableWork') as ComboBoxFormControl;
    this.offWorkMore30daysControl = this.form.get('offWorkMore30days') as ComboBoxFormControl;
    this.treatmentMore30daysControl = this.form.get('treatmentMore30days') as ComboBoxFormControl;
  }

  restoreAnswersOnForm() {
    const model = this.customerService.getHealthQuestionsModel();
    
    if (model.tempUnableWork === undefined || model.tempUnableWork === null) {
      this.restoreDataFromLSInCaseSubscribeGpaPer1Euro(model);
    }

    this.form.patchValue(model);

    this.onFormStatusChange.emit(this.form.valid);
  }

  restoreDataFromLSInCaseSubscribeGpaPer1Euro(model: HealthQuestionsModel): void {
    if (this.subscribeGpaPer1Euro) {
      const constantsYesAnswer = constants.yesAnswer;
      const tempHealthQuestionnaireFormKey = constants.localStorageKeys.tempHealthQuestionnaireForm;
      const tempModelDataStr = localStorage.getItem(tempHealthQuestionnaireFormKey);

      if (tempModelDataStr) {
        const tempModel = JSON.parse(tempModelDataStr) as HealthQuestionnaireFormModel;

        if (!!tempModel.laidOffForIllness && (model.laidOffForIllness === undefined || model.laidOffForIllness === null)) {
          model.laidOffForIllness = tempModel.laidOffForIllness === constantsYesAnswer;
          setTimeout(() => {
            this.isLaidOffForIllnessChange(tempModel.laidOffForIllness);
          });
        }

        if (!!tempModel.offWorkMore30days && (model.offWorkMore30days === undefined || model.offWorkMore30days === null)) {
          model.offWorkMore30days = tempModel.offWorkMore30days === constantsYesAnswer;
          setTimeout(() => {
            this.offWorkMore30daysChange(tempModel.offWorkMore30days);
          });
        }

        if (!!tempModel.tempUnableWork && (model.tempUnableWork === undefined || model.tempUnableWork === null)) {
          model.tempUnableWork = tempModel.tempUnableWork === constantsYesAnswer;
          setTimeout(() => {
            this.tempUnableWorkChange(tempModel.tempUnableWork);
          });
        }

        if (!!tempModel.treatmentMore30days && (model.treatmentMore30days === undefined || model.treatmentMore30days === null)) {
          model.treatmentMore30days = tempModel.treatmentMore30days === constantsYesAnswer;
          setTimeout(() => {
            this.treatmentMore30daysChange(tempModel.treatmentMore30days);
          });
        }

      }
    }
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.form.statusChanges.subscribe((status: string) => {
      const valid = status === constants.validStatus;
      this.onFormStatusChange.emit(valid);
    });

    this.subscriptionHandler.subscriptions = this.form.valueChanges
      .pipe(
        debounceTime(100),
        tap(data => {
          const model = JSON.stringify(this.form.getRawValue());
          localStorage.setItem(constants.localStorageKeys.tempHealthQuestionnaireForm, model);
        })
      )
      .subscribe();
  }

  markAllEnabled() {
    this.laidOffForIllnessControl.enable();
    this.tempUnableWorkControl.enable();
    this.offWorkMore30daysControl.enable();
    this.treatmentMore30daysControl.enable();
  }

  isLaidOffForIllnessChange(value: any) {
    if (value === constants.yesAnswer) {
      this.laidOffForIllnessControl.markAsInvalid();
      this.tempUnableWorkControl.disable();
      this.offWorkMore30daysControl.disable();
      this.treatmentMore30daysControl.disable();
    } else {
      this.markAllEnabled();
    }
    this.laidOffForIllnessControl.markAsTouched();
    this.laidOffForIllnessControl.patchValue(value);
  }

  tempUnableWorkChange(value: any) {
    if (value === constants.yesAnswer) {
      this.tempUnableWorkControl.markAsInvalid();
      this.laidOffForIllnessControl.disable();
      this.offWorkMore30daysControl.disable();
      this.treatmentMore30daysControl.disable();
    } else {
      this.markAllEnabled();
    }

    this.tempUnableWorkControl.markAsTouched();
    this.tempUnableWorkControl.patchValue(value);
  }

  offWorkMore30daysChange(value: any) {
    if (value === constants.yesAnswer) {
      this.offWorkMore30daysControl.markAsInvalid();
      this.laidOffForIllnessControl.disable();
      this.tempUnableWorkControl.disable();
      this.treatmentMore30daysControl.disable();
    } else {
      this.markAllEnabled();
    }
    this.offWorkMore30daysControl.markAsTouched();
    this.offWorkMore30daysControl.patchValue(value);
  }

  treatmentMore30daysChange(value: any) {
    if (value === constants.yesAnswer) {
      this.treatmentMore30daysControl.markAsInvalid();
      this.laidOffForIllnessControl.disable();
      this.tempUnableWorkControl.disable();
      this.offWorkMore30daysControl.disable();
    } else {
      this.markAllEnabled();
    }
    this.treatmentMore30daysControl.markAsTouched();
    this.treatmentMore30daysControl.patchValue(value);
  }

  get isLaidOffForIllness(): boolean {
    return this.laidOffForIllnessControl?.value === constants.yesAnswer;
  }

  get isTempUnableWork(): boolean {
    return this.tempUnableWorkControl?.value === constants.yesAnswer;
  }

  get isOffWorkMore30days(): boolean {
    return this.offWorkMore30daysControl?.value === constants.yesAnswer;
  }

  get isTreatmentMore30days(): boolean {
    return this.treatmentMore30daysControl?.value === constants.yesAnswer;
  }

  async submit() {
    const model = this.form.getRawValue();
    model.laidOffForIllness = model.laidOffForIllness === constants.yesAnswer;
    model.tempUnableWork = model.tempUnableWork === constants.yesAnswer;
    model.offWorkMore30days = model.offWorkMore30days === constants.yesAnswer;
    model.treatmentMore30days = model.treatmentMore30days === constants.yesAnswer;

    const userId = await this.customerService.updateHealthQuestionsModel(model);

    this.router.navigate(['my-contract'], { queryParams: { userId } }); // my-rate
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}

export interface HealthQuestionnaireFormModel {
  laidOffForIllness: string;
  tempUnableWork: string;
  offWorkMore30days: string;
  treatmentMore30days: string;
}
