import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { constants } from '@constants/constants';
import { InfoFormControl } from '@form/info-form-control/info-form-control';
import { ProfileStepName } from '@models/profile-step-name';
import { Beneficiaries, Profile } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';
import { SettingsService } from '@webapi/services/settings.service';
import { isCims, isPeri } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-clause-libre',
  templateUrl: './clause-libre.component.html',
  styleUrls: ['./clause-libre.component.scss']
})
export class ClauseLibreComponent implements OnInit, OnDestroy {
  @Input() cemsSummary: boolean;
  @Input() subscribeGpaPer1Euro: boolean;
  @Output() onFormStatusChange: EventEmitter<BeneficiareLibreFormModelWithStatus> = new EventEmitter();

  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public clientProfile: Profile;
  public infoTitle = '';
  public form: FormGroup;
  public beneficiariesConfiguration: Beneficiaries;
  public ProfileStepName = ProfileStepName;
  public isPeri: boolean = isPeri();
  public isCims: boolean = isCims();

  constructor(
    private router: Router,
    private progressBarService: ProgressBarService,
    private customerService: CustomerService,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe
  ) {}

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  async ngOnInit(): Promise<void> {
    if (!this.cemsSummary) {
      this.progressBarService.sendStep(constants.steps.myBeneficiaries.libre.initialStep);
    }

    await this.fetchInitData();

    this.form = new FormGroup({
      beneficiaryFreeClauseText: new InfoFormControl(true, true, {
        maxLength: this.beneficiariesConfiguration?.maxNumberOfCharacters,
        rows: this.cemsSummary ? 2 : this.beneficiariesConfiguration?.maxNumberOfLines
      })
    });

    await this.restoreAnswersOnForm();
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    if (this.cemsSummary) {
      this.subscriptionHandler.subscriptions = this.form.valueChanges
        .pipe(
          debounceTime(100),
          tap(data => {
            const model = JSON.stringify(this.form.getRawValue());
            localStorage.setItem(constants.localStorageKeys.tempClauseLibreForm, model);
          })
        )
        .subscribe();
    }

    this.subscriptionHandler.subscriptions = this.form.statusChanges.subscribe((status: string) => {
      const valid = status === constants.validStatus;
      const text = this.form.get('beneficiaryFreeClauseText').value;

      this.onFormStatusChange.emit({ status: valid, text });
    });
  }

  async fetchInitData(): Promise<void> {
    this.beneficiariesConfiguration = await this.settingsService.getBeneficiariesConfiguration();
  }

  async restoreAnswersOnForm(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile) {
      this.infoTitle = this.isCims ? this.labelTextPipe.transform('FREE_CLAUSE.InfoTitleCims') : this.labelTextPipe.transform('FREE_CLAUSE.InfoTitleCommon');
      this.infoTitle = this.infoTitle.replace('FIRSTNAME_LASTNAME', `${this.clientProfile.person.firstName} ${this.clientProfile.person.lastName}`);

      if (!this.cemsSummary && this.clientProfile.beneficiaryFreeClauseText) {
        this.form.get('beneficiaryFreeClauseText').patchValue(this.clientProfile.beneficiaryFreeClauseText);
      } else if (this.cemsSummary && this.clientProfile.gpaProfile?.beneficiaryFreeClauseText) {
        this.form.get('beneficiaryFreeClauseText').patchValue(this.clientProfile.gpaProfile.beneficiaryFreeClauseText);
        this.onFormStatusChange.emit({ status: true, text: this.clientProfile.gpaProfile.beneficiaryFreeClauseText });
      }

      const isSavedData = !!this.form?.get('beneficiaryFreeClauseText').value;

      const tempClauseLibreFormKey = constants.localStorageKeys.tempClauseLibreForm;

      if (this.subscribeGpaPer1Euro && !isSavedData) {
        const tempModelDataStr = localStorage.getItem(tempClauseLibreFormKey);

        if (tempModelDataStr) {
          const dataModel = JSON.parse(tempModelDataStr);
          this.form.patchValue(dataModel);
        }
      }
    }

    this.form.get('beneficiaryFreeClauseText').markAsUntouched();
    this.form.get('beneficiaryFreeClauseText').markAsPristine();
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      const beneficiaryFreeClauseTextControl = this.form.get('beneficiaryFreeClauseText') as InfoFormControl;
      const userId = await this.customerService.updateClauseLibreModel(beneficiaryFreeClauseTextControl.value);

      if (this.isCims) {
        this.router.navigate(['my-beneficiaries/contract'], { queryParams: { userId: this.customerService.userId } });
      } else {
        this.router.navigate(['/my-details'], { queryParams: { userId } });
      }
    } else {
      this.form.get('beneficiaryFreeClauseText').markAsTouched();
    }
  }
}

export interface BeneficiareLibreFormModelWithStatus {
  status: boolean;
  text: string;
}
