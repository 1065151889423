import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneTrim]'
})
export class PhoneTrimDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('blur') onBlur(): void {
    const value = this.ngControl.control.value;
    if (value) {
      this.ngControl.control.patchValue(value.replace('+33 ', '+33').trim());
    }
  }
}
