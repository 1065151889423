import { Component, OnInit } from '@angular/core';
import { LabelsDataService } from '@services/labels-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mif-subscription';

  constructor(private labelsDataService: LabelsDataService) { }

  ngOnInit(): void {
    // this.labelsDataService.initliaze();
  }
}
