import { collections } from '@shared/constants/collections';
import { constants } from '@shared/constants/constants';
import { CodeProduitType } from '@shared/models/code-produit-type';
import { ContratInformations, GetPolicesResponse } from '@webapi/MIF.Subscription.Parrot';
import { GpaConfiguration, Profile } from '@webapi/MIF.Subscription.WebApi';
import { isCems, isCims, isInMinMaxRange, isPeri } from './extensions';
import { ContratTransferableFourgousExtended } from '@webapi/services/ws-polices.service';
import moment from 'moment';

function checkUserAgeConditionForGpaOffer(clientProfile: Profile, gpaSettings: GpaConfiguration): boolean {
  const clientAgeAtTheEndOfYear = moment().set('month', 11).set('date', 31).diff(clientProfile.person.dateOfBirth, 'years', false);
  // const clientAge = moment().diff(clientProfile.person.dateOfBirth, 'years', false);

  return clientAgeAtTheEndOfYear <= gpaSettings.maxAge && clientAgeAtTheEndOfYear >= gpaSettings.minAge;
}

function checkIsMemberWhoDoesNotAlreadyHaveGPAContract(clientProfile: Profile, polices: GetPolicesResponse, gpaSubscriptionUserTypes: number[]): boolean {
  if (gpaSubscriptionUserTypes.indexOf(constants.userTypes.memberWhoDoesNotAlreadyHaveGPAContract.code) !== -1) {
    let contractsConditionToShowGpaOffer = false;

    if (clientProfile.isMember && clientProfile.socialNumberB && polices) {
      // case a member who does not already have a GPA contract at the time of CEMS/PERI/CIMS subscription)
      const ATDNContratInformations = polices.police?.contratInformations.filter((contact: ContratInformations) => {
        return contact.codeProduit === CodeProduitType.ATDN;
      });

      if (ATDNContratInformations?.length > 0) {
        const closedContracts = ATDNContratInformations.filter((contact: ContratInformations) => {
          return (
            collections.closedIdsEtat.findIndex((id: number) => {
              return id === contact.idEtat;
            }) !== -1
          );
        });

        contractsConditionToShowGpaOffer = closedContracts?.length === ATDNContratInformations.length;
      } else {
        contractsConditionToShowGpaOffer = true;
      }
    }

    return contractsConditionToShowGpaOffer;
  }

  return false;
}

function checkUserTypeConditionForGpaOffer(clientProfile: Profile, gpaSettings: GpaConfiguration, polices: GetPolicesResponse) {
  const isProspect = !clientProfile.isMember;
  const gpaSubscriptionUserTypes = gpaSettings.userTypes;

  if (isProspect && gpaSubscriptionUserTypes.indexOf(constants.userTypes.prospect.code) !== -1) {
    return true;
  }
  if (checkIsMemberWhoDoesNotAlreadyHaveGPAContract(clientProfile, polices, gpaSubscriptionUserTypes)) {
    return true;
  }
  return false;
}

function checkUserAmountConditionForGpaOffer(clientProfile: Profile, gpaSettings: GpaConfiguration, transferableContracts: any): boolean {
  const isProspect = !clientProfile.isMember;
  const isCustomerWithoutTransferContractCode = !clientProfile.transferContractCode;

  if (!clientProfile.initialPaymentAmount && clientProfile.scheduledPaymentAmount) {
    return isInMinMaxRange(clientProfile.scheduledPaymentAmount, { min: gpaSettings.minInvestedAmount, max: gpaSettings.maxInvestedAmount });
  }

  if (isProspect && !clientProfile.initialPaymentAmount) {
    return false;
  }

  if (isCustomerWithoutTransferContractCode && !clientProfile.initialPaymentAmount) {
    return false;
  }

  let amount = 0;

  if (clientProfile.initialPaymentAmount) {
    amount += clientProfile.initialPaymentAmount;
  }

  if (clientProfile.transferContractCode && transferableContracts?.length > 0) {
    transferableContracts.forEach((item: ContratTransferableFourgousExtended) => {
      if (item.numeroContrat === clientProfile.transferContractCode) {
        amount += item.valeurRachat;
      }
    });
  }

  return isInMinMaxRange(amount, { min: gpaSettings.minInvestedAmount, max: gpaSettings.maxInvestedAmount });
}

function checklIsSpecialGpaOfferAvailable(clientProfile: Profile, gpaSettings: GpaConfiguration, polices: GetPolicesResponse, transferableContracts: any): boolean {
  if (isCems() || isCims() || isPeri()) {
    if (clientProfile && gpaSettings) {
      const isAgeOk: boolean = checkUserAgeConditionForGpaOffer(clientProfile, gpaSettings);
      const isTypeOfUserOk: boolean = checkUserTypeConditionForGpaOffer(clientProfile, gpaSettings, polices);
      const isAmountForGpaOk = checkUserAmountConditionForGpaOffer(clientProfile, gpaSettings, transferableContracts);

      return isAgeOk && isTypeOfUserOk && isAmountForGpaOk;
    }
    return false;
  }
  return false;
}

export { checklIsSpecialGpaOfferAvailable };
