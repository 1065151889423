import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Document3 } from '../../../webapi/MIF.Subscription.Parrot';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';

@Component({
  selector: 'app-docs-modal',
  templateUrl: './docs-modal.component.html'
})
export class DocsModalComponent implements OnInit {
  public body: string;
  public title: string;
  public transferContractCode?: number;
  public cssClass: string = '';
  public type: number = 1;
  public onClose: Subject<boolean>;
  public finalAgreeOpened: number[];
  public finalAgreeDocs: Document3[];
  public finalAgreeFileClick: any;
  public finalAgreeContractsOpened: number[];
  public finalAgreeDocsContract: any;
  public finalAgreeDocsContractDocuments: any;
  public finalAgreeContractFileClick: any;
  public subscribeGpaPer1Euro: boolean;
  public isPeri: boolean;

  public faClose: any = faTimes;

  public constructor(private bsModalRef: BsModalRef, private wsReferentielMetierService: WsReferentielMetierService) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    switch (this.type) {
      case modalTypes.error: {
        this.cssClass = 'error-modal';
        break;
      }
      case modalTypes.warning: {
        this.cssClass = 'warning-modal';
        break;
      }
      case modalTypes.info: {
        this.cssClass = 'info-modal';
        break;
      }
    }

    if (this.transferContractCode) {
      this.finalAgreeDocsContractDocuments = [this.wsReferentielMetierService.documentsProduitFullBody];
    }
  }

  fileClick(): void {
    if (this.finalAgreeOpened.length === this.finalAgreeDocs.length) {
      if (!this.finalAgreeDocsContract) {
        this.hideConfirmationModal(true);
      } else if (this.finalAgreeDocsContract && this.finalAgreeContractsOpened?.length > 0) {
        this.hideConfirmationModal(true);
      }
    }
  }

  get isErrorType(): boolean {
    return this.type === modalTypes.error;
  }

  public show(body: string): void {
    this.body = body;
  }

  public hideConfirmationModal(moveNextStep?: boolean): void {
    this.onClose.next(moveNextStep);
    this.onClose.complete();
    this.bsModalRef.hide();
  }
}

export enum modalTypes {
  error = 1,
  warning = 2,
  info = 3
}
