import { Injectable } from '@angular/core';
import { GetDocumentsFondsRequest, Client } from '../../shared/webapi/MIF.Subscription.Parrot';

@Injectable({
  providedIn: 'root'
})
export class FundsService {
  private readonly scpiRegulatoryDocumentType = 59;
  private readonly regulatoryDocumentType = 8;
  private readonly scpiPrefix = 'SCPI';
  private readonly docPrefix = 'DIC_Avenant_web';

  constructor(private webApi: Client) {}

  async getFundRegulatoryDocument(fundId: number, title?: string): Promise<Blob> {
    const idTypeDocument = title?.indexOf(this.scpiPrefix) !== -1 ? this.scpiRegulatoryDocumentType : this.regulatoryDocumentType;

    const request = new GetDocumentsFondsRequest({
      idFonds: String(fundId),
      lireDocuments: true,
      idTypeDocument
    });

    const documentResponse = await this.webApi.getDocumentsFonds(request).toPromise();
    const documents = documentResponse.document;
    const match = documents?.find(d => d.libelle.startsWith(this.docPrefix));
    const document = match ?? documents[documents.length - 1];

    const fileResponse = await fetch(`data:application/pdf;base64,${document.fichierAttache}`);

    if (!fileResponse.ok) {
      throw new Error(`HTTP error! status: ${fileResponse.status}`);
    }

    return await fileResponse.blob();
  }
}
