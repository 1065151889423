import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContratTransferableFourgous, GetContratsTransferablesFourgousRequest, GetContratsTransferablesFourgousResponse, Client, GetPolicesRequest2, IGetPolicesResponse2 } from '../MIF.Subscription.Parrot';
import { getCurrentProductType } from '@shared/extensions/extensions';

@Injectable({
  providedIn: 'root'
})
export class WsPolicesService {
  constructor(private webApi: Client) { }

  private contractsData: any = {};

  public async getContratsTransferablesFourgousRequest(number: string): Promise<any> {
    if (this.contractsData[number]) {
      return new Promise(resolve => {
        resolve(this.contractsData[number].contratTransferableFourgous?.filter((item: ContratTransferableFourgous) => {
          return item.numeroContrat;
        })
          .map((item: ContratTransferableFourgousExtended) => {
            item.libelleContratExtended = `Contrat n°${item.numeroContrat} - ${item.libelleContrat}`;
            return item;
          }));
      })
    } else {
      const request = new GetContratsTransferablesFourgousRequest({
        codeProduit: getCurrentProductType(),
        numeroSocietaireInterne: parseInt(number, 10)
      });

      return this.webApi.getContratsTransferablesFourgous(request).pipe(
        map((response: GetContratsTransferablesFourgousResponse) => {
          this.contractsData[number] = response;
          return this.contractsData[number].contratTransferableFourgous?.filter((item: ContratTransferableFourgous) => {
            return item.numeroContrat;
          })
            .map((item: ContratTransferableFourgousExtended) => {
              item.libelleContratExtended = `Contrat n°${item.numeroContrat} - ${item.libelleContrat}`;
              return item;
            });
        })
      ).toPromise();
    }
  }

  public async getPolices2(numeroContrat: number): Promise<any> {
    const request = new GetPolicesRequest2({
      numeroContrat: numeroContrat,
    })

    return this.webApi.getPolices2(request).pipe(
      map((response: IGetPolicesResponse2) => {
        return response;
      })
    ).toPromise();
  }

}

export class ContratTransferableFourgousExtended extends ContratTransferableFourgous {
  libelleContratExtended: string;
}
