<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyFinancial" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<!-- showControlsWithErrors: {{ showControlsWithErrors }} -->

<div class="row mt-4">
  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
    <p *ngIf="isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitlePeri' | labelText"></p>
    <p *ngIf="!isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitleNotPeri' | labelText"></p>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" class="mb-5">
  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'IDENTITY.Identity' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyDetails()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'ADDRESS.ContactDetails' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyContacts()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'SITUATION.ProAndPatrimonialSituation' | labelText }}</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-combobox-form-control (onChange)="areSncfRatpChange($event)" [parentForm]="form" [controlName]="'areSncfRatp'" [title]="'SITUATION.FormerSncfOrRatpWorker' | labelText">
              </app-combobox-form-control>
            </div>
          </div>

          <span *ngIf="isSncfRatp">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <app-combobox-form-control (onChange)="officerOrPensionerChange($event)" [parentForm]="form" [controlName]="'officerOrPensioner'" [title]="'SITUATION.FormerWorkerOf' | labelText">
                </app-combobox-form-control>
              </div>
            </div>

            <div class="row" *ngIf="isSncf">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <app-sncf-form-control [parentForm]="form" [controlName]="'sncfNumber'" [title]="'SITUATION.SncfCpNumber' | labelText"> </app-sncf-form-control>
              </div>
            </div>

            <div class="row" *ngIf="isRatp">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <app-number-form-control [parentForm]="form" [controlName]="'ratpNumber'" [title]="'SITUATION.YourRatpEmployeeNumber' | labelText"> </app-number-form-control>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
                <app-dropdown-form-control [parentForm]="form" [controlName]="'professionalSituation'" [title]="'Common.ProfessionalSituation' | labelText"> </app-dropdown-form-control>
              </div>
            </div>
          </span>
          <!-- end of if isSncfRatp-->

          <div class="row" *ngIf="!isSncfRatp">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-dropdown-form-control
                [parentForm]="form"
                [controlName]="'socioProfessionalCategory'"
                (onChange)="onSocioProfessionalCategoryChange($event)"
                [title]="'Financial_Situation.SocioProfessionalCategory' | labelText"
              >
              </app-dropdown-form-control>
            </div>
          </div>

          <div class="row" *ngIf="!isSncfRatp && isProfessionsCollection">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-dropdown-form-control [parentForm]="form" [controlName]="'profession'" [title]="'Financial_Situation.Profession' | labelText"> </app-dropdown-form-control>
            </div>
          </div>

          <div class="row" *ngIf="!isSncfRatp">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-dropdown-form-control [parentForm]="form" [controlName]="'industry'" [title]="'Financial_Situation.Industry' | labelText"> </app-dropdown-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-dropdown-form-control
                [parentForm]="form"
                [controlName]="'personalNetMonthlyIncome'"
                (onChange)="personalNetMonthlyIncomeChange($event)"
                [title]="'Common.YourMonthlyNetWage' | labelText"
              >
              </app-dropdown-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
              <app-dropdown-form-control [parentForm]="form" [controlName]="'householdNetMonthlyIncome'" [title]="'Common.HouseholdMonthlyNetWage' | labelText"> </app-dropdown-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-checkbox-list-form-control
                [parentForm]="form"
                [controlName]="'originOfWealth'"
                [otherAnswer]="originOfWealthOtherAnswerValueRestored"
                [title]="'Common.OriginOfYourAssets' | labelText"
                (onOtherChange)="originOfWealthOtherChange($event)"
                [otherUniqClass]="'originOfWealthOther'"
              >
              </app-checkbox-list-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-checkbox-list-form-control
                [parentForm]="form"
                [controlName]="'originOfContractFunds'"
                [otherAnswer]="originOfContractFundsOtherAnswerValueRestored"
                [title]="'Common.OriginOfFundsTransferToYourContract' | labelText"
                (onOtherChange)="originOfContractFundsOtherChange($event)"
                [otherUniqClass]="'originOfContractFundsOther'"
              >
              </app-checkbox-list-form-control>
            </div>
          </div>

          <div class="row mt-5" *ngIf="isShowExtraTable">
            <form [formGroup]="extraTableForm">
              <div class="col">
                <p class="main-intro">
                  {{ 'ESTATE.AdditionaInformationDueToLargeInitialPayment' | labelText : myProfileFirstPayment : 'EUR' : true }}
                </p>
                <p class="main-intro">
                  {{ 'ESTATE.OriginOfFund' | labelText }}
                </p>

                <div class="form-table-funds form-patrimoine">
                  <div class="row">
                    <div class="hidden-xs col-lg-3 col-desktop-same-height"></div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height" style="height: 56px">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-10 col-lg-2 col-desktop-same-height">
                      <div class="label-like">{{ 'ESTATE.DateTransaction' | labelText }}</div>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-10 col-lg-2 col-desktop-same-height">
                      <div class="label-like">{{ 'ESTATE.AmountTransaction' | labelText }}</div>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-10 col-lg-2 col-desktop-same-height">
                      <div class="label-like">{{ 'ESTATE.ProvesOfOriginOfFunds' | labelText }}</div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control
                        [title]="'Estate.OriginLifeAssurance' | labelText"
                        [parentForm]="extraTableForm"
                        [controlName]="'surrenderLifeInsurancePolicy'"
                        (onChange)="surrenderLifeInsurancePolicyChanged($event)"
                      >
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-date-input-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'surrenderLifeInsurancePolicyDate'"> </app-date-input-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'surrenderLifeInsurancePolicyAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="surrenderLifeInsurancePolicyRestoredFiles"
                          (onChange)="surrenderLifeInsurancePolicyUploaderChanged($event)"
                          (onStatusChange)="surrenderLifeInsurancePolicyUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container">
                        <app-tooltip [title]="'Estate.WhatToAdd' | labelText" [text]="'Estate.WhatToAddText' | labelText"> </app-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control [title]="'Estate.OriginSavingTitle' | labelText" [parentForm]="extraTableForm" [controlName]="'savings'" (onChange)="savingsChanged($event)">
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-date-input-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'savingsDate'"> </app-date-input-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'savingsAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="savingRestoredFiles"
                          (onChange)="savingsUploaderChanged($event)"
                          (onStatusChange)="savingsUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container">
                        <app-tooltip [title]="'Estate.WhatToAdd' | labelText" [text]="'Estate.WhatToAddText2' | labelText"> </app-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control [title]="'Estate.OriginSuccessionTitle' | labelText" [parentForm]="extraTableForm" [controlName]="'succession'" (onChange)="successionChanged($event)">
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-date-input-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'successionDate'"> </app-date-input-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'successionAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="successionRestoredFiles"
                          (onChange)="successionUploaderChanged($event)"
                          (onStatusChange)="successionUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container">
                        <app-tooltip [title]="'Estate.WhatToAdd' | labelText" [text]="'Estate.WhatToAddText3' | labelText"> </app-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control [title]="'ESTATE.RealEstateSale' | labelText" [parentForm]="extraTableForm" [controlName]="'estateSale'" (onChange)="estateSaleChanged($event)">
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-date-input-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'estateSaleDate'"> </app-date-input-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'estateSaleAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="estateSaleRestoredFiles"
                          (onChange)="estateSaleUploaderChanged($event)"
                          (onStatusChange)="estateSaleUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container">
                        <app-tooltip [title]="'Estate.WhatToAdd' | labelText" [text]="'Estate.WhatToAddText4' | labelText"> </app-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control [title]="'Estate.OriginCashTitle' | labelText" [parentForm]="extraTableForm" [controlName]="'cashflow'" (onChange)="cashflowChanged($event)">
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height"></div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'cashflowAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="cashflowRestoredFiles"
                          (onChange)="cashflowUploaderChanged($event)"
                          (onStatusChange)="cashflowUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container">
                        <app-tooltip [title]="'Estate.WhatToAdd' | labelText" [text]="'Estate.WhatToAddText5' | labelText"> </app-tooltip>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-3 col-lg-3 col-desktop-same-height">
                      <app-checkbox-form-control [title]="'Estate.OriginOtherTitle' | labelText" [parentForm]="extraTableForm" [controlName]="'other'" (onChange)="otherChanged($event)">
                      </app-checkbox-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-date-input-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'otherDate'"> </app-date-input-form-control>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height">
                      <app-number-form-control class="no-title in-table-cell" [parentForm]="extraTableForm" [controlName]="'otherAmount'"> </app-number-form-control>
                      <span class="form-euro"><strong>€</strong></span>
                    </div>
                    <div class="hidden-xs hidden-sm hidden-md col-md-1 col-lg-1 col-desktop-same-height">
                      <div class="border-dashed"></div>
                    </div>
                    <div class="col-xs-12 col-sm-offset-1 col-sm-10 col-md-10 col-lg-2 col-desktop-same-height pr-0 pt-1">
                      <div class="upload-container">
                        <app-file-uploader
                          class="in-table-cell"
                          [files]="otherRestoredFiles"
                          (onChange)="otherUploaderChanged($event)"
                          (onStatusChange)="otherUploaderStatusChanged($event)"
                          [hideAfterUpload]="true"
                        ></app-file-uploader>
                      </div>
                      <div class="tooltip-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="row text-center mt-5">
            <div class="col">
              <div class="valider-button-block">
                <button type="submit" class="btn btn-blue valider" [disabled]="isFormDisabled">
                  {{ 'Common.Validate' | labelText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card disabled">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'BANK_DETAILS.YourBankDetails' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
