<div class="form-group" [class.required]="isRequired" [class.disabled]="isDisabled" [formGroup]="parentForm" [class.inline]="isInline">
  <mat-checkbox [formControlName]="controlName" (ngModelChange)="change()"> {{ title }} </mat-checkbox>

  <!-- <span class="success-mark" *ngIf="control.value && !control.errors">
    <fa-icon [icon]="faCheck"></fa-icon>
  </span> -->

  <!-- <mat-error class="checkbox-error" *ngIf="control.touched && control.errors">
    <span>
      {{ requiredText }}
    </span>
  </mat-error> -->
</div>
