<form class="card" *ngIf="form">
  <div class="card-body">
    <h5>
      {{ 'Common.ResumeSubscriptionProcessTitle' | labelText : productName }}
    </h5>

    <div class="row">
      <div class="col-xs-0 col-sm-0 col-md-3 col-lg-4"></div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <app-name-with-digits-form-control [parentForm]="form" [controlName]="'code'" [title]="''" class="no-title"> </app-name-with-digits-form-control>
      </div>
      <div *ngIf="isCodeError" class="col-xs-12 col-sm-12 col-md-3 col-lg-4 code-error">
        {{ 'Common.ErrorCode' | labelText }}
      </div>
    </div>

    <div class="row mt-2 justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <div class="valider-button-block">
          <button type="submit" (click)="submit()" [disabled]="form.invalid || isThreeWrongCodes || !this.otpAnswer" class="btn btn-blue valider">
            {{ 'Common.Validate' | labelText }}
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-2 justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6">
        <span class="link-like text-default mt-2" *ngIf="!isThreeWrongCodes" (click)="resendCode()">
          {{ 'Common.DidntReceiveCodeText' | labelText }}
        </span>
      </div>
    </div>

    <div *ngIf="isThreeWrongCodes" class="row mt-2 justify-content-center">
      <div class="col-12">
        {{ 'Common.ThreeUnsuccessfulAttemptsText1' | labelText }}
        <span class="link-like mt-2" (click)="goToNewSubscription()"> {{ 'Common.ThreeUnsuccessfulAttemptsText2' | labelText }}</span
        >.
      </div>
    </div>
  </div>
</form>
