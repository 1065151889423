import { collections } from '@constants/collections';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { constants } from '@constants/constants';
import { arrayMove, getOfferFormatedGeneralConditions, isCems, isCims, isGfd, isGpa, isInMinMaxRange, isPeri, isSameDate } from '@extensions/extensions';
import { Offer, OfferCondition, OfferSection, Product, Profile, ProfileFund, Section } from '@webapi/MIF.Subscription.WebApi';
import { LookupsService } from '@webapi/services/lookups.service';
import { OffersService } from '@webapi/services/offers.service';
import { AlertModalComponent, modalTypes } from '@modals/alert-modal/alert-modal.component';
import { WsPolicesService } from '@webapi/services/ws-polices.service';
import { SubscriptionHandler } from '@shared/components/subscriptionHandler';

@Component({
  selector: 'app-view-offer',
  templateUrl: './view-offer.component.html',
  styleUrls: ['./view-offer.component.scss']
})
export class ViewOfferComponent implements OnInit, OnChanges, OnDestroy {
  @Input() clientProfile: Profile;
  @Input() pageName: string;
  @Input() class: string;
  @Input() manualOverrideOffer: EventEmitter<Offer> = new EventEmitter();
  @Input() firstPage: boolean = false; // https://storyshaper.atlassian.net/browse/MIFS-595
  @Output() onOffersCountChanged: EventEmitter<number> = new EventEmitter();
  @Output() onOffersItem: EventEmitter<Offer> = new EventEmitter();
  @Output() onOffersItems: EventEmitter<Offer[]> = new EventEmitter();

  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

  isCems = isCems();
  isCims = isCims();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();

  pageOffers: Offer[];
  pagesTypes: Section[];
  offerProducts: Product[];
  isMember: boolean;
  offersFiltered: boolean;

  constructor(private offersService: OffersService, private lookupsService: LookupsService, private bsModalService: BsModalService, private wsPolicesService: WsPolicesService) {}

  async ngOnInit(): Promise<void> {
    if (!this.clientProfile) {
      return;
    }
    await this.initOffers();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.manualOverrideOffer.subscribe((offer: Offer) => {
      if (offer?.id !== this.pageOffers?.[0].id) {
        const offerIndex = this.pageOffers?.findIndex((o: Offer) => {
          return o.id === offer.id;
        });

        arrayMove(this.pageOffers, offerIndex, 0);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes?.clientProfile && changes?.clientProfile.currentValue !== undefined) {
      if (this.clientProfile?.isWithTransfer === undefined) {
        this.clientProfile = Object.assign(new Profile(), this.clientProfile);
      }
      await this.initOffers();
    }
  }

  async initOffers(): Promise<void> {
    this.offersFiltered = false;
    this.isMember = this.clientProfile.isMember !== undefined && this.clientProfile.isMember;
    this.pageOffers = await this.offersService.getAllOffers();
    this.offerProducts = await this.lookupsService.getOfferProducts();

    await this.filterPageOffers();
  }

  async filterPageOffers(): Promise<void> {
    if (this.firstPage) {
      this.filterByEnvironment();
      this.filterByStatus();
    } else {
      this.filterByEnvironment();
      this.filterBySection();
      this.filterByStatus();
      await this.filterByConditions();
      this.sortByPriority();
    }

    if (this.pageOffers?.length > 0) {
      this.onOffersItems.emit(this.pageOffers);
      if (!this.firstPage) {
        this.onOffersItem.emit(this.pageOffers[0]);
        this.onOffersCountChanged.emit(1);
      }
    } else {
      this.onOffersItems.emit(null);
      if (!this.firstPage) {
        this.onOffersItem.emit(null);
        this.onOffersCountChanged.emit(0);
      }
    }

    this.offersFiltered = true;
  }

  getProductNameById(productId: number): string {
    return this.offerProducts.filter((product: Product) => {
      return product.id === productId;
    })[0]?.label;
  }

  filterByEnvironment(): void {
    const filteredByEnvOffers: Offer[] = [];

    this.pageOffers.forEach((offer: Offer) => {
      offer.products.forEach((productId: number) => {
        const productName = this.getProductNameById(productId);

        switch (productName) {
          case constants.productNames.cems.name: {
            if (this.isCems) {
              filteredByEnvOffers.push(offer);
            }
            break;
          }
          case constants.productNames.cims.name: {
            if (this.isCims) {
              filteredByEnvOffers.push(offer);
            }
            break;
          }
          case constants.productNames.gpa.name: {
            if (this.isGpa) {
              filteredByEnvOffers.push(offer);
            }
            break;
          }
          case constants.productNames.gfd.name: {
            if (this.isGfd) {
              filteredByEnvOffers.push(offer);
            }
            break;
          }
          case constants.productNames.peri.name: {
            if (this.isPeri) {
              filteredByEnvOffers.push(offer);
            }
            break;
          }
          default:
            break;
        }
      });
    });

    this.pageOffers = filteredByEnvOffers;
  }

  filterBySection(): void {
    const filteredBySectionOffers: Offer[] = [];

    this.pageOffers.forEach((offer: Offer) => {
      offer.sections.forEach((section: OfferSection) => {
        if (section.sectionName === this.pageName) {
          filteredBySectionOffers.push(offer);
        }
      });
    });

    this.pageOffers = filteredBySectionOffers;
  }

  sortByPriority(): void {
    // fist one is with biggest priority
    this.pageOffers = this.pageOffers?.sort((a: Offer, b: Offer) => (a.priority > b.priority ? -1 : b.priority < a.priority ? 1 : 0));

    // if (this.pageOffers?.length > 0) {
    //   this.pageOffers = [this.pageOffers[0]];
    // }
  }

  filterByStatus(): void {
    const filteredByStatusOffers: Offer[] = [];

    this.pageOffers.forEach((offer: Offer) => {
      switch (offer.statusId) {
        case constants.offerStatuses.disabledId: {
          break;
        }
        case constants.offerStatuses.activeId: {
          filteredByStatusOffers.push(offer);
          break;
        }
        case constants.offerStatuses.activeByTimeId: {
          if (offer.statusStartDate && offer.statusEndDate) {
            if (moment().isBetween(offer.statusStartDate, offer.statusEndDate) || isSameDate(moment(), offer.statusStartDate) || isSameDate(moment(), offer.statusEndDate)) {
              // todo: check after date save isuue will be fixed
              filteredByStatusOffers.push(offer);
            }
          }
          break;
        }
      }
    });

    this.pageOffers = filteredByStatusOffers;
  }

  async filterByConditions(): Promise<void> {
    const filteredByConditions: Offer[] = [];

    await Promise.all(
      this.pageOffers.map(async offer => {
        const overridedConditions = this.getOverridedByPageConditions(offer);
        const conditions = overridedConditions || offer.conditions;
        let isUserTypeOk = true;
        let isUserAgeOk = true;
        let isUserSubscriptionAgeOk = true;
        let isUserSavingsSecurityProfileOk = true;
        let isUserSavingsUCDistribtuionOk = true;
        let isUserSavingsTransferPactOk = true;
        let isUserSavingsInitialDepositOk = true;
        let isUserPensionCapitalGuaranteeOk = true;
        let isUserPensionCoveragex2x3Ok = true;
        let isUserSponsorshipOk = true;
        let isPartnerCodeOk = true;

        await Promise.all(
          conditions.map(async condition => {
            if (condition.conditionTypeId === constants.conditionTypes.userType.id) {
              isUserTypeOk = this.isUserTypeOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.userAge.id) {
              isUserAgeOk = this.isUserAgeOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.subscriptionAge.id) {
              isUserSubscriptionAgeOk = this.isUserSubscriptionAgeOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.savingsSecurityProfile.id) {
              isUserSavingsSecurityProfileOk = this.isUserSavingsSecurityProfileOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.savingsUCDistribtuion.id) {
              isUserSavingsUCDistribtuionOk = this.isUserSavingsUCDistribtuionOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.savingsTransferPact.id) {
              isUserSavingsTransferPactOk = await this.isUserSavingsTransferPactOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.savingsInitialDeposit.id) {
              isUserSavingsInitialDepositOk = this.isUserSavingsInitialDepositOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.pensionCapitalGuarantee.id) {
              isUserPensionCapitalGuaranteeOk = this.isUserPensionCapitalGuaranteeOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.pensionCoveragex2x3.id) {
              isUserPensionCoveragex2x3Ok = this.isUserPensionCoveragex2x3Ok(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.sponsorship.id) {
              isUserSponsorshipOk = this.isUserSponsorshipOk(condition);
            }
            if (condition.conditionTypeId === constants.conditionTypes.partnerCode.id) {
              isPartnerCodeOk = this.isPartnerCodeOk(condition);
            }
          })
        );

        if (
          isUserTypeOk &&
          isUserAgeOk &&
          isUserSubscriptionAgeOk &&
          isUserSavingsSecurityProfileOk &&
          isUserSavingsUCDistribtuionOk &&
          isUserSavingsTransferPactOk &&
          isUserSavingsInitialDepositOk &&
          isUserPensionCapitalGuaranteeOk &&
          isUserPensionCoveragex2x3Ok &&
          isUserSponsorshipOk &&
          isPartnerCodeOk
        ) {
          filteredByConditions.push(offer);
        }
      })
    );

    this.pageOffers = filteredByConditions;
    //this.onOffersCountChanged.emit(filteredByConditions.length);
  }

  isUserTypeOk(condition: OfferCondition): boolean {
    let isOk = false;
    const answers = JSON.parse(condition.value) as Array<string>;

    if (answers) {
      if (answers.length === 2) {
        // any of user types
        isOk = true;
      } else if (answers[0] === collections.conditionUserTypes[0].id.toString()) {
        // Client
        isOk = this.isMember;
      } else if (answers[0] === collections.conditionUserTypes[1].id.toString()) {
        // Prospect
        isOk = !this.isMember;
      }
    }

    return isOk;
  }

  isPartnerCodeOk(condition: OfferCondition): boolean {
    const answer = JSON.parse(condition.value) as string;

    const isOk = answer === this.clientProfile.advertisementSource?.partnerCode;

    return isOk;
  }

  isUserAgeOk(condition: OfferCondition): boolean {
    const dobLimits = JSON.parse(condition.value) as MinMaxObject;
    let isOk = false;

    if (dobLimits.min && dobLimits.max) {
      const dobMinDateValidation = moment().set('month', 11).set('date', 31).subtract(Number(dobLimits.max), 'year').toDate();
      const dobMaxDateValidation = moment().subtract(dobLimits.min, 'year').toDate();
      isOk = moment(this.clientProfile.person.dateOfBirth).isBetween(dobMinDateValidation, dobMaxDateValidation) || isSameDate(this.clientProfile.person.dateOfBirth, dobMinDateValidation);
    } else if (dobLimits.min && !dobLimits.max) {
      const dobMinDateValidation = moment().set('month', 11).set('date', 31).subtract(Number(dobLimits.min), 'year').toDate();
      isOk = moment(this.clientProfile.person.dateOfBirth).isBefore(dobMinDateValidation) || isSameDate(this.clientProfile.person.dateOfBirth, dobMinDateValidation);
    } else if (!dobLimits.min && dobLimits.max) {
      const dobMaxDateValidation = moment().subtract(dobLimits.max, 'year').toDate();
      isOk = moment(this.clientProfile.person.dateOfBirth).isAfter(dobMaxDateValidation) || isSameDate(this.clientProfile.person.dateOfBirth, dobMaxDateValidation);
    }

    return isOk;
  }

  isUserSubscriptionAgeOk(condition: OfferCondition): boolean {
    const diffInMinutes = moment().diff(this.clientProfile.createdOn, 'minutes', false);
    const limits = JSON.parse(condition.value) as MinMaxWithTypeObject;
    const limitsMinMultiplier = this.getSubscriptionAgeMultiplier(limits.minType);
    const limitsMaxMultiplier = this.getSubscriptionAgeMultiplier(limits.maxType);
    limits.min = limits.min ? limits.min * limitsMinMultiplier : null;
    limits.max = limits.max ? limits.max * limitsMaxMultiplier : null;

    return isInMinMaxRange(diffInMinutes, limits);
  }

  isUserSavingsSecurityProfileOk(condition: OfferCondition): boolean {
    let isOk = false;
    const answers = JSON.parse(condition.value) as Array<string>;

    if (answers) {
      if (answers.length === 2) {
        // any of SavingsSecurityProfiles
        isOk = true;
      } else if (answers[0] === collections.conditionSavingsSecurityProfiles[0].id.toString()) {
        // Profil sécuritaire
        isOk = this.clientProfile.isSecurityProfile();
      } else if (answers[0] === collections.conditionSavingsSecurityProfiles[1].id.toString()) {
        // Autre profil
        isOk = !this.clientProfile.isSecurityProfile();
      }
    }

    return isOk;
  }

  isUserSavingsUCDistribtuionOk(condition: OfferCondition): boolean {
    const limits = JSON.parse(condition.value) as MinMaxObject;
    const clientUCDistribution = this.getClientUCDistribution();

    if (!clientUCDistribution) {
      return false;
    }

    return isInMinMaxRange(clientUCDistribution, limits);
  }

  async isUserSavingsTransferPactOk(condition: OfferCondition): Promise<boolean> {
    let isOk = false;

    if (!this.clientProfile.socialNumberB) {
      return isOk; // The promotional offer should never be displayed for a prospect when one or many criterias are selected in “Epargne - Transfert Fourgous” condition”:
    }

    const answers = JSON.parse(condition.value) as Array<string>;

    if (!this.clientProfile.socialNumberB && answers.length === 1 && parseInt(answers[0], 10) === collections.conditionSavingsTransferPacts[2].id) {
      return !this.clientProfile.doTransferContract;
    } else if (!this.clientProfile.socialNumberB) {
      isOk = false;
    } else {
      const transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.clientProfile.socialNumberB);
      const isUserHasEligibleContract = transferableContracts?.length > 0;

      if (answers.length === 2) {
        const answer1Id = parseInt(answers[0], 10);
        let is1Ok = false;
        const answer2Id = parseInt(answers[1], 10);
        let is2Ok = false;

        if (answer1Id === collections.conditionSavingsTransferPacts[0].id) {
          is1Ok = isUserHasEligibleContract;
        }
        if (answer1Id === collections.conditionSavingsTransferPacts[1].id) {
          is1Ok = this.clientProfile.doTransferContract;
        }
        if (answer1Id === collections.conditionSavingsTransferPacts[2].id) {
          is1Ok = this.clientProfile.doTransferContract === false;
        }
        if (answer2Id === collections.conditionSavingsTransferPacts[0].id) {
          is2Ok = isUserHasEligibleContract;
        }
        if (answer2Id === collections.conditionSavingsTransferPacts[1].id) {
          is2Ok = this.clientProfile.doTransferContract;
        }
        if (answer2Id === collections.conditionSavingsTransferPacts[2].id) {
          is2Ok = this.clientProfile.doTransferContract === false;
        }

        return is1Ok && is2Ok;
      } else {
        const answerId = parseInt(answers[0], 10);
        if (answerId === collections.conditionSavingsTransferPacts[0].id) {
          isOk = isUserHasEligibleContract;
        }
        if (answerId === collections.conditionSavingsTransferPacts[1].id) {
          isOk = this.clientProfile.doTransferContract;
        }
        if (answerId === collections.conditionSavingsTransferPacts[2].id) {
          if (!isUserHasEligibleContract) {
            // MIFS-336
            isOk = true;
          } else {
            isOk = this.clientProfile.doTransferContract === false;
          }
        }
      }
    }

    return isOk;
  }

  isUserSavingsInitialDepositOk(condition: OfferCondition): boolean {
    let isOk = false;
    const limits = JSON.parse(condition.value) as InitialDepositMinMaxObject;

    if (limits.valInitial.min === null && limits.valInitial.max === null) {
      limits.valInitial = null;
    }

    if (limits.valScheduled.min === null && limits.valScheduled.max === null) {
      limits.valScheduled = null;
    }

    if (limits.valInitial && limits.valScheduled) {
      if (this.clientProfile.initialPaymentAmount || this.clientProfile.scheduledPaymentAmount) {
        isOk = isInMinMaxRange(this.clientProfile.initialPaymentAmount, limits.valInitial) || isInMinMaxRange(this.clientProfile.scheduledPaymentAmount, limits.valScheduled);
      } else {
        isOk = false;
      }
    } else if (limits.valInitial) {
      if (this.clientProfile.initialPaymentAmount) {
        isOk = isInMinMaxRange(this.clientProfile.initialPaymentAmount, limits.valInitial);
      } else {
        isOk = false;
      }
    } else if (limits.valScheduled) {
      if (this.clientProfile.initialPaymentAmount) {
        isOk = isInMinMaxRange(this.clientProfile.scheduledPaymentAmount, limits.valScheduled);
      } else {
        isOk = false;
      }
    }

    return isOk;
  }

  isUserPensionCapitalGuaranteeOk(condition: OfferCondition): boolean {
    const limits = JSON.parse(condition.value) as MinMaxObject;
    const capitalInEuro = this.clientProfile.gpaProfile?.guaranteedCapitalCode;

    if (!capitalInEuro) {
      return false;
    }

    return isInMinMaxRange(capitalInEuro, limits);
  }

  isUserPensionCoveragex2x3Ok(condition: OfferCondition): boolean {
    let isOk = false;
    const answers = JSON.parse(condition.value) as Array<string>;
    const isMultipliedCoverage = this.clientProfile.gpaProfile?.isMultipliedCoverage;

    if (isMultipliedCoverage === undefined || isMultipliedCoverage === null) {
      return false;
    }

    if (answers) {
      if (answers.length === 2) {
        // any of cases
        isOk = true;
      } else if (answers[0] === collections.garanteex2x3Types[0].id.toString()) {
        // Sélectionné
        isOk = isMultipliedCoverage;
      } else if (answers[0] === collections.garanteex2x3Types[1].id.toString()) {
        // Non sélectionné
        isOk = !isMultipliedCoverage;
      }
    }

    return isOk;
  }

  isUserSponsorshipOk(condition: OfferCondition): boolean {
    let isOk = false;

    const answers = JSON.parse(condition.value) as Array<string>;
    const advertisementSourceCode = this.clientProfile.advertisementSource?.advertisementSourceCode;
    const isAdvertisementSourceCodeNotSelected = advertisementSourceCode === null;
    const isNotSponsorshipAnswer = advertisementSourceCode !== constants.sponsorshipCode;
    const isSponsorshipAnswer = advertisementSourceCode === constants.sponsorshipCode;
    const isSponsorNumberAnswerOk = this.clientProfile.advertisementSource?.sponsorNumber?.length === 7;

    if (answers) {
      if (answers[0] === collections.sponsorsipTypes[0].id.toString()) {
        // L’utilisateur a sélectionné la valeur “Parrainage”
        isOk = isSponsorshipAnswer && isSponsorNumberAnswerOk;
      } else if (answers[0] === collections.sponsorsipTypes[1].id.toString()) {
        // L’utilisateur a sélectionné une valeur différente de “Parrainage”
        isOk = isNotSponsorshipAnswer && advertisementSourceCode !== null;
      } else if (answers[0] === collections.sponsorsipTypes[2].id.toString()) {
        // L’utilisateur n’a sélectionné aucune valeur dans ce champs
        isOk = isAdvertisementSourceCodeNotSelected;
      }
    }

    return isOk;
  }

  getSubscriptionAgeMultiplier(type?: number): number {
    if (type === constants.ageTypes.hour.id) {
      return 60;
    } else if (type === constants.ageTypes.day.id) {
      return 24 * 60;
    } else {
      return 1;
    }
  }

  getClientUCDistribution(): number {
    if (!this.clientProfile.profileFunds || this.clientProfile.profileFunds?.length === 0) {
      return null;
    } else {
      const ucFonds = this.clientProfile.profileFunds.filter((fond: ProfileFund) => {
        return fond.fundId !== constants.euroFondId;
      });
      if (!ucFonds || ucFonds.length === 0) {
        return null;
      } else {
        let distribution = 0;

        ucFonds.forEach((fond: ProfileFund) => {
          distribution += fond.portion;
        });

        return distribution;
      }
    }
  }

  getOfferHeaderText(offer: Offer): string {
    if (!offer) {
      return '';
    }
    const overridedText = this.getOverridedByPageConditionsText(offer);
    const text = overridedText ? overridedText : offer.description.text;

    return text.replace('href="#"', 'href="javascript:void(0)" (click)="showConditionsModal()"');
  }

  getOverridedByPageConditionsText(offer: Offer): string {
    let overrideOfferText: string;

    offer?.sections.forEach((section: OfferSection) => {
      if (section.sectionName === this.pageName) {
        if (section.overrideOfferText === true) {
          overrideOfferText = section.offerText;
        }
      }
    });

    return overrideOfferText;
  }

  getOverridedByPageConditions(offer: Offer): OfferCondition[] {
    let overridedOfferConditions: OfferCondition[];

    offer.sections.forEach((section: OfferSection) => {
      if (section.sectionName === this.pageName) {
        if (section.overrideConditions === true) {
          overridedOfferConditions = section.conditions;
        }
      }
    });

    return overridedOfferConditions;
  }

  showConditionsModal(offer: Offer): void {
    const textWithoutSpaces = offer.description.generalConditions?.replace(/&nbsp;/g, '');

    if (textWithoutSpaces?.length > 0) {
      this.bsModalService.show(AlertModalComponent, {
        initialState: {
          type: modalTypes.info,
          body: getOfferFormatedGeneralConditions(offer)
        },
        class: 'modal-lg'
      });
    }
  }
}

export interface MinMaxObject {
  min?: number;
  max?: number;
}

export interface MinMaxWithTypeObject {
  min?: number;
  minType?: number;
  max?: number;
  maxType?: number;
}

export interface InitialDepositMinMaxObject {
  valInitial: MinMaxObject;
  valScheduled: MinMaxObject;
}
