<div class="card">
  <div class="row">
    <div class="col-12 text-left">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h5 class="header mt-3">{{ 'SUMMARY.MyGFDContractText' | labelText }}</h5>
          </div>
        </div>

        <div class="row" *ngIf="planDetails">
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
            <div class="txt-default mt-2 fw-600">
              {{ 'SUMMARY.ForesightGFDGuaranteedCapital' | labelText }} <span class="text-blue"> {{ planDetails?.mntCapital | euro : true }}</span>.
            </div>
            <div class="txt-default mt-4 mb-4 fw-600">
              {{ 'SUMMARY.ForesightIncluded' | labelText }} <br />
              <span class="answer text-blue"> - {{ 'SUMMARY.ForesightIncludedAllServices' | labelText }} </span><br />
            </div>
            <div class="txt-default fw-600">
              <span *ngIf="!isCotisationUniqueTypeSelected">
                {{ 'SUMMARY.ForesightGFDContribution1' | labelText }} <span class="text-blue"> {{ planDetails?.cotisation[0].mntCotisation | euro : true }} </span>
                {{ 'SUMMARY.ForesightGFDContribution2' | labelText }} <span class="text-blue"> {{ ansText }}</span
                >. <br />
                {{ 'SUMMARY.ForesightGFDNextContribution' | labelText }}
              </span>
              <span *ngIf="isCotisationUniqueTypeSelected">
                {{ 'SUMMARY.ForesightGFDUniqueContribution1' | labelText }} <span class="text-blue"> {{ planDetails?.cotisation[0].mntCotisation | euro : true }}</span
                >. <br />
                {{ 'SUMMARY.ForesightGFDNextContributionCotisationUnique' | labelText }}
              </span>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyContract()">{{ 'Common.Modify' | labelText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
