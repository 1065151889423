import { Validators, ValidatorFn } from '@angular/forms';
import { collections } from '../../constants/collections';
import { AbstractFormControl } from '../abstract-form-control';
import { constants } from '@shared/constants/constants';

export class NumberFormControl extends AbstractFormControl {
  validators: any;
  constructor(required: boolean = false, disabled: boolean = false, private min: number = 0, private max: number = 9, private onlyInt: boolean = false, private options?: NumberOptions) {
    super(required, disabled);
    this.setValue(null);

    if (options?.allowNegativeNumbers === true) {
      min = constants.maxNegativeNumber;
    }

    this.updateValidators(required, min, this.options?.min, this.options?.max);
  }

  set maxValue(max: number) {
    this.options = this.options || {};
    this.options.max = max;

    this.updateValidators(this.isRequired, this.min, this.options?.min, this.options?.max);
  }

  get minLength(): number {
    return this.min;
  }

  get maxLength(): number {
    return this.max;
  }

  get onlyIntegers(): boolean {
    return this.onlyInt === true;
  }

  get readOnlyCss(): boolean {
    return this.options?.readOnlyCss;
  }

  get disabledGreenBorder(): boolean {
    return this.options?.disabledGreenBorder;
  }

  get allowNegativeNumbers(): boolean {
    return this.options?.allowNegativeNumbers;
  }

  get placeholder(): string {
    return this.options?.placeholder;
  }

  get separator(): string {
    return this.onlyIntegers === true ? 'separator.0' : 'separator.2';
  }

  get requiredErrorMessage(): string {
    if (this.options && this.options.requiredErrorMessage) {
      return this.options.requiredErrorMessage;
    } else {
      return collections.messages.required;
    }
  }

  get maxValueErrorMessage(): string {
    if (this.options && this.options.maxValueErrorMessage) {
      return this.options.maxValueErrorMessage.replace('<max>', this.options.max.toString());
    } else {
      return null;
    }
  }

  get minValueErrorMessage(): string {
    if (this.options) {
      if (this.options.min && this.options.minValueErrorMessage) {
        return this.options.minValueErrorMessage.replace('<min>', this.options.min.toString());
      } else {
        return this.options.requiredErrorMessage;
      }
    } else {
      return null;
    }
  }

  protected getValidators(): ValidatorFn[] {
    return this.validators;
  }

  setValue(
    value: any,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    if (typeof value === 'string') {
      if (value) {
        value = Number.parseFloat(value);
      } else {
        value = null;
      }
    }
    super.setValue(value, options);
  }

  private updateValidators(required: boolean, minLength?: number, min?: number, max?: number): void {
    const validators = [];

    if (required) {
      validators.push(Validators.required);
    }

    if (minLength) {
      validators.push(Validators.minLength(minLength));
    }

    if (min) {
      validators.push(Validators.min(min));
    }

    if (max) {
      validators.push(Validators.max(max));
    }

    this.validators = validators;
    super.setValidators(validators);
  }

  get hideSuccessMark(): boolean {
    return this.options?.hideSuccessMark;
  }

  get hideErrorMessages(): boolean {
    return this.options?.hideErrorMessages;
  }
}

export interface NumberOptions {
  readOnlyCss?: boolean;
  allowNegativeNumbers?: boolean;
  max?: number;
  min?: number;
  requiredErrorMessage?: string;
  maxValueErrorMessage?: string;
  minValueErrorMessage?: string;
  hideSuccessMark?: boolean;
  hideErrorMessages?: boolean;
  placeholder?: string;
  disabledGreenBorder?: boolean;
}
