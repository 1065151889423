import { NgModule } from '@angular/core';
import { FractionPipe } from './fraction.pipe';
import { EuroPipe } from './euro.pipe';
import { FondsFilterPipe } from './fonds-filter.pipe';
import { IbanPipe } from './iban.pipe';
import { LabelTextPipe } from './label-text.pipe';
import { PerformancePipe } from './performance.pipe';
import { RoundTo2DecimalPipe } from './roundTo2Decimal.pipe';
import { SafeHtml } from './safe-html.pipe';
import { DatePipe } from './date.pipe';
import { DateTimePipe } from './date-time.pipe';
import { RangePipe } from './range.pipe';
import { FooterLinksValuePipe } from './footer-links-value.pipe';
import { YesNoPipe } from './yesNo.pipe';
import { AgeTypeNamePipe } from './ageTypeName.pipe';
import { WithdrawalTypePipe } from './withdrawalType.pipe';
import { PerformanceYearPipe } from './performance-year.pipe';
import { PerformanceRangePipe } from './performance-range.pipe';
import { PhoneAsterisksPipe } from './phone-asterisks.pipe';
import { PerformanceFractionPipe } from './performance-fraction.pipe';

@NgModule({
  declarations: [
    LabelTextPipe,
    EuroPipe,
    IbanPipe,
    FondsFilterPipe,
    SafeHtml,
    PerformancePipe,
    FractionPipe,
    RoundTo2DecimalPipe,
    DatePipe,
    DateTimePipe,
    RangePipe,
    FooterLinksValuePipe,
    YesNoPipe,
    AgeTypeNamePipe,
    WithdrawalTypePipe,
    PerformanceYearPipe,
    PhoneAsterisksPipe,
    PerformanceFractionPipe,
    PerformanceRangePipe
  ],
  imports: [],
  exports: [
    LabelTextPipe,
    EuroPipe,
    IbanPipe,
    FondsFilterPipe,
    SafeHtml,
    PerformancePipe,
    FractionPipe,
    RoundTo2DecimalPipe,
    DatePipe,
    DateTimePipe,
    RangePipe,
    FooterLinksValuePipe,
    YesNoPipe,
    AgeTypeNamePipe,
    WithdrawalTypePipe,
    PerformanceYearPipe,
    PhoneAsterisksPipe,
    PerformanceFractionPipe,
    PerformanceRangePipe
  ],
  providers: [EuroPipe, PerformancePipe, LabelTextPipe, FractionPipe]
})
export class PipesModule {}
