import { Component, Input, OnInit } from '@angular/core';
import { AlertModalComponent, modalTypes } from '@shared/components/modals/alert-modal/alert-modal.component';
import { constants } from '@shared/constants/constants';
import { getCurrentProductName, getCurrentProductType, isCems, isCims, isGfd, isGpa, isPeri } from '@shared/extensions/extensions';
import { LabelTextPipe } from '@shared/pipes/label-text.pipe';
import { GpaConfiguration } from '@webapi/MIF.Subscription.WebApi';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-view-gpa-offer',
  templateUrl: './view-gpa-offer.component.html',
  styleUrls: ['./view-gpa-offer.component.scss']
})
export class ViewGpaOfferComponent implements OnInit {
  @Input() gpaConfiguration: GpaConfiguration;

  isCems = isCems();
  isCims = isCims();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();

  private product: string;

  constructor(
    private bsModalService: BsModalService,
    private labelTextPipe: LabelTextPipe
  ) { }

  ngOnInit(): void {
    this.product = getCurrentProductType();
  }

  public showGpaOfferDetailsModal(): void {
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        title: this.gpaOfferModalTitle,
        body: this.gpaOfferModalBody,
        okButton: this.labelTextPipe.transform('Common.Fermer')
      },
      class: 'modal-lg'
    });
  }

  get title(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningTitle');
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferWarningTitle');
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferWarningTitle');
      }
      default: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningTitle');
      }
    }
  }

  get mainText(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningBody', this.gpaConfiguration?.gpaCost);
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferWarningBody', this.gpaConfiguration?.gpaCost);
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferWarningBody', this.gpaConfiguration?.gpaCost);
      }
      default: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningBody', this.gpaConfiguration?.gpaCost);
      }
    }
  }

  get link(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningLink');
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferWarningLink');
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferWarningLink');
      }
      default: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningLink');
      }
    }
  }

  get extraText(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningLinkExtraText');
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferWarningLinkExtraText');
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferWarningLinkExtraText');
      }
      default: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferWarningLinkExtraText');
      }
    }
  }

  get gpaOfferModalTitle(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalTitle');
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalTitle');
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalTitle');
      }
      default: {
        return this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalTitle');
      }
    }
  }

  get gpaOfferModalBody(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        const text11 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_1');
        const text12 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_2');
        const text13 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_3');
        const text2 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText2', this.gpaConfiguration?.gpaGuaranteedCapital, this.gpaConfiguration?.gpaCost);
        const text3 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText3', getCurrentProductName());

        return `${text11}<b>${text12}</b>${text13} <br />${text2} <br />${text3}`;
      }
      case constants.productNames.cims.name: {
        const text11 = this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalText1_1');
        const text12 = this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalText1_2');
        const text13 = this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalText1_3');
        const text2 = this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalText2', this.gpaConfiguration?.gpaGuaranteedCapital, this.gpaConfiguration?.gpaCost);
        const text3 = this.labelTextPipe.transform('Beneficiaries.CimsGpaOfferModalText3', getCurrentProductName());

        return `${text11}<b>${text12}</b>${text13} <br />${text2} <br />${text3}`;
      }
      case constants.productNames.peri.name: {
        const text11 = this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalText1_1');
        const text12 = this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalText1_2');
        const text13 = this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalText1_3');
        const text2 = this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalText2', this.gpaConfiguration?.gpaGuaranteedCapital, this.gpaConfiguration?.gpaCost);
        const text3 = this.labelTextPipe.transform('Beneficiaries.PeriGpaOfferModalText3', getCurrentProductName());

        return `${text11}<b>${text12}</b>${text13} <br />${text2} <br />${text3}`;
      }
      default: {
        const text11 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_1');
        const text12 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_2');
        const text13 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText1_3');
        const text2 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText2', this.gpaConfiguration?.gpaGuaranteedCapital, this.gpaConfiguration?.gpaCost);
        const text3 = this.labelTextPipe.transform('Beneficiaries.CemsGpaOfferModalText3', getCurrentProductName());

        return `${text11}<b>${text12}</b>${text13} <br />${text2} <br />${text3}`;
      }
    }
  }

}
