import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { SpinnerService } from '@services/spinner.service';

@Component({
  selector: 'app-subscription-time-end',
  templateUrl: './subscription-time-end.component.html',
  styleUrls: ['./subscription-time-end.component.scss']
})
export class SubscriptionTimeEndComponent implements OnInit {

  constructor(
    private router: Router,
    private progressBarService: ProgressBarService,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
    this.progressBarService.sendStep(0);
    setTimeout(() => {
      this.spinnerService.hide();
    }, 1000)
  }

  goToNewSubscription(): void {
    this.router.navigate(['membership-check']);
  }

}
