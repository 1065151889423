import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { InfoFormControl } from './info-form-control';

@Component({
    selector: 'app-info-form-control',
    templateUrl: './info-form-control.component.html',
    styleUrls: ['./info-form-control.component.scss']
})
export class InfoFormControlComponent extends AbstractFormControlComponent<InfoFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;

    get isMultiline(): boolean {
        return this.control.isMultiline;
    }

    get rowsCount(): number {
        return this.control.rowsCount || 1;
    }

    get valueLength(): number {
        if (!this.control.value) {
            return 0;
        }
        
        const value = this.control.value as string;
        return value.length;
    }

    get maxLength(): number {
        return this.control.maxLength;
    }

    getCaretPos(oField: any) {
        if (oField.selectionStart || oField.selectionStart === '0') {
            this.control.caretPos = oField.selectionStart;
        }
    }
}
