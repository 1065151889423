import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CoordonneesCompteBancairePrelevement, GetCoordonneesComptesBancairesPrelevementRequest, Client, GetCoordonneesComptesBancairesPrelevementResponse } from '../MIF.Subscription.Parrot';
import { ContratTransferableFourgousExtended } from './ws-polices.service';

@Injectable({
  providedIn: 'root'
})
export class WsCoordonneesBancairesService {
  private coordonnees: CoordonneesCompteBancairePrelevement[] = [];

  constructor(private webApi: Client) { }

  public async getCoordonneesComptesBancairesPrelevement(numeroInterne: number, numeroContrat: number, resolve: any) {
    const request = new GetCoordonneesComptesBancairesPrelevementRequest({
      numeroInterne: numeroInterne,
      numeroContrat: numeroContrat,
    });

    let response = await this.webApi.getCoordonneesComptesBancairesPrelevement(request).toPromise();

    resolve(response.coordonneesCompteBancairePrelevement && response.coordonneesCompteBancairePrelevement[0]);
  }

  public async getCoordonneesComptesBancairesPrelevementArray(numeroInterne: number): Promise<CoordonneesCompteBancairePrelevement[]> {
    const request = new GetCoordonneesComptesBancairesPrelevementRequest({
      numeroInterne: numeroInterne,
    });

    return this.webApi.getCoordonneesComptesBancairesPrelevement(request).pipe(
      map((response: GetCoordonneesComptesBancairesPrelevementResponse) => {
        return response.coordonneesCompteBancairePrelevement;
      })
    ).toPromise();
  }

  public async getCoordonneesComptesBancairesPrelevementForContractsArray(numeroInterne: number, transferableContracts: ContratTransferableFourgousExtended[]): Promise<CoordonneesCompteBancairePrelevement[]> {
    let promises: Promise<CoordonneesCompteBancairePrelevement>[] = [];

    transferableContracts?.forEach((contract: ContratTransferableFourgousExtended) => {
      promises.push(
        new Promise<any>((resolve) => {
          this.getCoordonneesComptesBancairesPrelevement(numeroInterne, contract.numeroContrat, resolve);
        })
      );
    });

    await Promise.all(promises).then(async (coordonnees) => {
      this.coordonnees = coordonnees.filter((item: CoordonneesCompteBancairePrelevement) => {
        return !!item?.numeroContrat;
      });
    });

    return new Promise(resolve => {
      resolve(this.coordonnees);
    })

  }
}