import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DistributionPercentFormControl } from './distribution-percent-form-control';

@Component({
  selector: 'app-distribution-percent-form-control',
  templateUrl: './distribution-percent-form-control.component.html',
  styleUrls: ['./distribution-percent-form-control.component.scss']
})
export class DistributionPercentFormControlComponent extends AbstractFormControlComponent<DistributionPercentFormControl> implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() title: string;
  @Input() controlName: string;

  ngOnInit(): void {
  }
}
