import { ValidatorFn } from '@angular/forms';
import { dateInputValidator } from '../../validators/dateInputValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class DateInputFormControl extends AbstractFormControl {
    constructor(required: boolean = false, disabled: boolean = false, private options?: DateInputOptions) {
        super(required, disabled);
        super.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [dateInputValidator()];
    }

    // get hideSuccessMark(): boolean {
    //     return this.options?.hideSuccessMark;
    // }
}

export interface DateInputOptions {
    readOnlyCss?: boolean;
    //hideSuccessMark?: boolean;
}
