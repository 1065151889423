import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fraction'
})
export class FractionPipe implements PipeTransform {
  constructor() {

  }

  transform(value: number): string {
    if (!value && value !== 0) {
      return '';
    }

    return `${value} %`.replace('.', ',');
  }
}
