import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fondsFilter'
})
export class FondsFilterPipe implements PipeTransform {
  transform(fonds: any[], fondType: number): any {
    if (!fonds || !fondType) {
      return fonds;
    }

    return fonds.filter(f => f.value.fond.typeFonds.id === fondType);
  }
}
