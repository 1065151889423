<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill">
    <mat-label>{{ title }}</mat-label>

    <input
      matInput
      [class.is-invalid]="control.invalid && control.touched"
      type="password"
      class="form-control form-control-sm"
      [formControlName]="controlName"
      (ngModelChange)="change($event)"
      [placeholder]="placeholderText"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.required">{{ control.requiredErrorMessage }}</span>
      <span *ngIf="control.errors?.maxlength">{{ 'HOME.WrongEntryErrorText1' | labelText }} {{ control.maxLength }} {{ 'HOME.WrongEntryErrorText2' | labelText }} </span>
    </mat-error>
  </mat-form-field>
</div>
