<div class="card mt-5 text-left">
  <div class="card-body">
    <div class="row mt-4 mb-5">
      <div class="col text-center">
        <span class="success-mark big">
          <fa-icon [icon]="faCheck"></fa-icon>
        </span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col text-center">
        <h3 [innerHtml]="title"></h3>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col text-left">
        <p class="mb-0">{{ openDocLinkTitle }}</p>
        <div class="crecap recap-my-profil">
          <ul class="list-arrows">
            <li>
              <a href="javascript:void(0)" (click)="openDoc()" class="link-like gray"> {{ openDocLinkText }} </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 text-left" [innerHTML]="afterDocLinkText"></div>
    </div>

    <div class="row mb-3">
      <div class="col-12 text-left" [innerHTML]="afterDocLinkSecondText"></div>
    </div>

    <div class="row mb-3">
      <div class="col text-left" [innerHTML]="afterDocLinkThirdText"></div>
    </div>
  </div>
</div>
