import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-aderm-confirmation',
  templateUrl: './aderm-confirmation.component.html',
  styleUrls: ['./aderm-confirmation.component.scss']
})
export class AdermConfirmationComponent {
  public onClose: Subject<void> = new Subject<void>();
  public onContinue: Subject<void> = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  public onConfirm(): void {
    this.onContinue.next();
    this.bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next();
    this.bsModalRef.hide();
  }
}
