import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeAll]'
})
export class CapitalizeAllDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('window:keyup', ['$event']) OnBlur() {
    const value = this.ngControl.control.value;

    if (value) {
      this.ngControl.control.patchValue(value.allToUpperCase());
    }
  }
}
