import { isGfd } from '@shared/extensions/extensions';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ProgressBarService } from '@services/progress-bar.service';
import { getCurrentProductName, getCurrentProductType, isCems, isCims, isGpa, isPeri, openFileByExtentionInNewTab, openFileInNewTab } from '@extensions/extensions';
import { Profile, SignatureTransactionResponse } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';
import { constants } from '@shared/constants/constants';
import { LabelTextPipe } from '@shared/pipes/label-text.pipe';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit {
  faCheck: any = faCheck;
  signatureTransactionResponse: SignatureTransactionResponse;
  clientProfile: Profile;
  isCems = isCems();
  isGpa = isGpa();
  isPeri = isPeri();
  isCims = isCims();
  isGfd = isGfd();
  currentProduct: string;
  currentProductName: string;

  constructor(public router: Router, private route: ActivatedRoute, private progressBarService: ProgressBarService, private labelTextPipe: LabelTextPipe, private customerService: CustomerService) {}

  async ngOnInit(): Promise<void> {
    this.currentProduct = getCurrentProductType();
    this.currentProductName = getCurrentProductName();
    const userId = this.route.snapshot.queryParamMap.get('userId');
    this.clientProfile = await this.customerService.getUserData(userId);

    if (this.clientProfile === undefined) {
      this.customerService.clearAllData().then(() => {
        this.router.navigate(['subscription-time-end']);
      });
    }

    this.progressBarService.sendStep(0);
    this.signatureTransactionResponse = history.state.data;
  }

  async openDoc() {
    if (history.state.data) {
      openFileInNewTab(this.signatureTransactionResponse.signedDocument);
    } else {
      const context = await this.customerService.getSignedDocument();
      await openFileByExtentionInNewTab(context.fileName, context.base64Content);
    }
  }

  get isGpaProfile(): boolean {
    return !!this.clientProfile?.gpaProfile;
  }

  get title(): string {
    if ((this.isCems || this.isCims || this.isPeri || this.isGfd) && this.isGpaProfile) {
      return this.labelTextPipe.transform('FINISHED.FinishedTitleWithGpaProfile', this.currentProductName, constants.productNames.gpa.fullName);
    } else {
      return this.labelTextPipe.transform('FINISHED.FinishedTitle', this.currentProductName);
    }
  }

  get openDocLinkTitle(): string {
    switch (this.currentProduct) {
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('FINISHED.DocumentSectionPeri');
      }
      default: {
        return this.labelTextPipe.transform('FINISHED.DocumentSection');
      }
    }
  }

  get openDocLinkText(): string {
    switch (this.currentProduct) {
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform('SUMMARY.PDFTitleCims');
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform('FINISHED.MembershipRequest');
      }
      default: {
        return this.labelTextPipe.transform('FINISHED.MembershipForm');
      }
    }
  }

  get afterDocLinkText(): string {
    // todo: implement clientProfile.gpaProfile checking (and removing on prev step)
    switch (this.currentProduct) {
      case constants.productNames.peri.name: {
        if (this.isGpaProfile) {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete1')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete2')} </span> 
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete3')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete4')} </span>
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete5')}`;
        } else {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete1')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete2Peri')} </span> 
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete3')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete4')} </span>
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete5')}`;
        }
      }
      default: {
        if (this.isGpaProfile) {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete1')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete2')} </span> 
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete3')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete4')} </span>
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete5')}`;
        } else {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete1')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete2_GpaProfile')} </span> 
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete3')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete4')} </span>
          ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete5')}`;
        }
      }
    }
  }

  get afterDocLinkSecondText(): string {
    if (this.isGpaProfile) {
      return this.labelTextPipe.transform('FINISHED.FinishedTextInCaseGpaProfile');
    } else {
      switch (this.currentProduct) {
        case constants.productNames.peri.name: {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete7_1')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete7')} </span> 
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete8')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete9')} </span>
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete9_2')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete10')} </span>
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete10_2')}`;
        }
        default: {
          return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete6')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete7')} </span> 
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete8')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete9')} </span>
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete9_2')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete10')} </span>
        ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete11')}`;
        }
      }
    }
  }

  get afterDocLinkThirdText(): string {
    return `${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete12')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete13')} </span> 
      ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete14')} <span class="fw-600"> ${this.labelTextPipe.transform('FINISHED.FinishedTextIncomplete15')} </span>`;
  }
}
