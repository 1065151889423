import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ReportFile, ReportingClient } from '../MIF.Subscription.WebApi';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(private webApi: ReportingClient) { }

  public async recommendationReport(year: number): Promise<ReportFile> {
    return this.webApi.recommendationReport(year).pipe(
      map((response: ReportFile) => {
        return response;
      })
    ).toPromise();
  }
}
