import { FundsOrigin, ProfileQuestion, ProofOfFund, Profile } from '@webapi/MIF.Subscription.WebApi';
import { CSP, Informations, Professions, Theme } from '@webapi/MIF.Subscription.Parrot';
import { CheckboxListFormControl } from '@form/checkbox-list-form-control/checkbox-list-form-control';
import { NumberFormControl } from '@form/number-form-control/number-form-control';
import { SncfFormControl } from '@form/sncf-form-control/sncf-form-control';
import { DropDownFormControl } from '@form/dropdown-form-control/dropdown-form-control';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { WsCRMClientService } from '@shared/webapi/services/ws-crmclient.service';
import { CheckboxFormControl } from '@form/checkbox-form-control/checkbox-form-control';
import { DateInputFormControl } from '@form/date-input-form-control/date-input-form-control';
import { WsReferentielMetierService } from '@shared/webapi/services/ws-referentiel-metier.service';
import { CustomerService } from '@shared/webapi/services/customer.service';
import { getMyFinancialModel } from '@extensions/user-data-extensions';
import { WsCrmQuestionnaireService } from '@shared/webapi/services/ws-crm-questionnaire.service';
import moment from 'moment';
import { SettingsService } from '@shared/webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { WsCoordonneesBancairesService } from '@shared/webapi/services/ws-coordonnees-bancaires.service';
import { isCems, isCims, isPeri } from '@extensions/extensions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LabelTextPipe } from '@shared/pipes/label-text.pipe';
import { FileUploaderStatus } from '@shared/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-financial-situation',
  templateUrl: './financial-situation.component.html',
  styleUrls: ['./financial-situation.component.scss']
})
export class FinancialSituationComponent implements OnInit {
  isPeri = isPeri();
  isCems = isCems();
  isCims = isCims();

  clientProfile: Profile;

  ProfileStepName = ProfileStepName;
  form: FormGroup;
  extraTableForm: FormGroup;

  socioProfessionalCategories: CSP[];
  industries: Informations[];
  netMonthlyIncomes: Informations[];
  professionalSituations: Informations[];
  originsOfWealth: Informations[];
  originOfContractFundsItems: Informations[];

  officerOrPensionerControl: DropDownFormControl;
  sncfNumberControl: SncfFormControl;
  ratpNumberControl: NumberFormControl;
  professionalSituationControl: DropDownFormControl;
  industryControl: DropDownFormControl;
  socioProfessionalCategoryControl: DropDownFormControl;
  originOfWealthControl: CheckboxListFormControl;
  originOfContractFundsControl: CheckboxListFormControl;
  householdNetMonthlyIncomeControl: DropDownFormControl;
  originOfWealthOtherAnswerValue: string;
  originOfContractFundsOtherAnswerValue: string;
  originOfWealthOtherAnswerValueRestored: string;
  originOfContractFundsOtherAnswerValueRestored: string;
  myProfileFirstPayment: any;

  surrenderLifeInsurancePolicyControl: CheckboxFormControl;
  surrenderLifeInsurancePolicyDateControl: DateInputFormControl;
  surrenderLifeInsurancePolicyAmountControl: NumberFormControl;
  surrenderLifeInsurancePolicyUploaderFiles: File[];
  surrenderLifeInsurancePolicyRestoredFiles: File[];

  savingsControl: CheckboxFormControl;
  savingsDateControl: DateInputFormControl;
  savingsAmountControl: NumberFormControl;
  savingsUploaderFiles: File[];
  savingRestoredFiles: File[];

  successionControl: CheckboxFormControl;
  successionDateControl: DateInputFormControl;
  successionAmountControl: NumberFormControl;
  successionUploaderFiles: File[];
  successionRestoredFiles: File[];

  estateSaleControl: CheckboxFormControl;
  estateSaleDateControl: DateInputFormControl;
  estateSaleAmountControl: NumberFormControl;
  estateSaleUploaderFiles: File[];
  estateSaleRestoredFiles: File[];

  cashflowControl: CheckboxFormControl;
  cashflowAmountControl: NumberFormControl;
  cashflowUploaderFiles: File[];
  cashflowRestoredFiles: File[];

  otherControl: CheckboxFormControl;
  otherDateControl: DateInputFormControl;
  otherAmountControl: NumberFormControl;
  otherUploaderFiles: File[];
  otherRestoredFiles: File[];
  netMonthlyIncomesPersonal: any;
  originOfWealthOtherId: number;
  originOfContractFundsOtherId: number;
  profileThemes: Theme[] = [];
  extraTableAmountSumm: number;
  professionControl: DropDownFormControl;
  fundsOriginConfiguration: FundsOrigin;
  professionsCollection: Professions[];
  surrenderLifeInsurancePolicyUploaderStatus: any;
  savingsUploaderStatus: any;
  successionUploaderStatus: any;
  estateSaleUploaderStatus: any;
  cashflowUploaderStatus: any;
  otherUploaderStatus: any;

  constructor(
    private router: Router,
    private wsCRMClientService: WsCRMClientService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private progressBarService: ProgressBarService,
    private wsCrmQuestionnaireService: WsCrmQuestionnaireService,
    private customerService: CustomerService,
    private settingsService: SettingsService,
    private wsCoordonneesBancairesService: WsCoordonneesBancairesService,
    private bsModalService: BsModalService,
    private labelTextPipe: LabelTextPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myDetails.financial.initialStep);
    await this.fetchInitData();
    this.initNewFormGroups();
    this.initControls();
    this.restoreAnswersOnForm();
  }

  initNewFormGroups(): void {
    this.form = new FormGroup({
      areSncfRatp: new ComboBoxFormControl(
        true,
        false,
        {
          collection: collections.yesNo,
          valuePropertyName: 'description'
        },
        { defaultValue: constants.noAnswer }
      ),
      socioProfessionalCategory: new DropDownFormControl(
        true,
        false,
        {
          collection: this.socioProfessionalCategories,
          valuePropertyName: 'libelle',
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      profession: new DropDownFormControl(
        false,
        false,
        {
          valuePropertyName: 'libelle',
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      officerOrPensioner: new DropDownFormControl(false, false, {
        collection: collections.officerOrPensionerTypes,
        valuePropertyName: 'description'
      }),
      sncfNumber: new SncfFormControl(false, false),
      ratpNumber: new NumberFormControl(false, false, 7, 7, true, {
        min: 100000,
        minValueErrorMessage: collections.messages.ratpNumberError
      }),
      professionalSituation: new DropDownFormControl(
        false,
        false,
        {
          collection: this.professionalSituations,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      industry: new DropDownFormControl(
        true,
        false,
        {
          collection: this.industries,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      personalNetMonthlyIncome: new DropDownFormControl(
        true,
        false,
        {
          collection: this.netMonthlyIncomesPersonal,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      householdNetMonthlyIncome: new DropDownFormControl(
        true,
        false,
        {
          collection: this.netMonthlyIncomes,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      originOfWealth: new CheckboxListFormControl(
        true,
        false,
        {
          collection: this.originsOfWealth,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        { addOther: true }
      ),
      originOfContractFunds: new CheckboxListFormControl(
        true,
        false,
        {
          collection: this.originOfContractFundsItems,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.reference2KeyField
        },
        { addOther: true }
      )
    });

    this.extraTableForm = new FormGroup({
      surrenderLifeInsurancePolicy: new CheckboxFormControl(false, false),
      surrenderLifeInsurancePolicyDate: new DateInputFormControl(false, true),
      surrenderLifeInsurancePolicyAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true }),

      savings: new CheckboxFormControl(false, false),
      savingsDate: new DateInputFormControl(false, true),
      savingsAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true }),

      succession: new CheckboxFormControl(false, false),
      successionDate: new DateInputFormControl(false, true),
      successionAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true }),

      estateSale: new CheckboxFormControl(false, false),
      estateSaleDate: new DateInputFormControl(false, true),
      estateSaleAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true }),

      cashflow: new CheckboxFormControl(false, false),
      cashflowAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true }),

      other: new CheckboxFormControl(false, false),
      otherDate: new DateInputFormControl(false, true),
      otherAmount: new NumberFormControl(false, true, 0, null, true, { hideErrorMessages: true, hideSuccessMark: true })
    });
  }

  async fetchInitData(): Promise<void> {
    this.fundsOriginConfiguration = await this.settingsService.getFundsOriginConfiguration();
    this.socioProfessionalCategories = await this.wsCRMClientService.getSocioProfessionalCategories();
    this.industries = await this.wsCRMClientService.getIndustries();
    this.netMonthlyIncomesPersonal = await this.wsCRMClientService.getNetMonthlyIncomesPersonal();
    this.netMonthlyIncomes = await this.wsCRMClientService.getNetMonthlyIncomes();
    this.professionalSituations = await this.wsCRMClientService.getProfessionalSituations();
    this.originsOfWealth = await this.wsCRMClientService.getOriginsOfWealth();
    this.originOfWealthOtherId = await this.wsCRMClientService.getOriginsOfWealthOtherId();
    this.originOfContractFundsItems = await this.wsReferentielMetierService.getOriginOfContractFundsItems();
    this.originOfContractFundsOtherId = await this.wsReferentielMetierService.getOriginOfContractFundsOtherId();

    const questions = await this.wsCrmQuestionnaireService.getQuestions();
    this.profileThemes = questions.themes;
  }

  async restoreAnswersOnForm(): Promise<void> {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile) {
      this.myProfileFirstPayment = this.clientProfile.initialPaymentAmount;

      const dataModel = getMyFinancialModel(this.clientProfile, this.originOfWealthOtherId, this.originOfContractFundsOtherId);

      if (dataModel.proofOfWealthDescription) {
        this.originOfWealthOtherAnswerValueRestored = dataModel.proofOfWealthDescription;
        this.originOfWealthOtherAnswerValue = dataModel.proofOfWealthDescription;
      }

      if (dataModel.proofOfFundsDescription) {
        this.originOfContractFundsOtherAnswerValueRestored = dataModel.proofOfFundsDescription;
        this.originOfContractFundsOtherAnswerValue = dataModel.proofOfFundsDescription;
      }

      this.form.patchValue(dataModel);

      this.areSncfRatpChange(dataModel.areSncfRatp);
      this.officerOrPensionerChange(dataModel.officerOrPensioner);

      if (this.isShowExtraTable) {
        this.restoreFiles();
      }

      this.personalNetMonthlyIncomeChange({ value: dataModel.personalNetMonthlyIncome });

      if (dataModel.socioProfessionalCategory) {
        this.onSocioProfessionalCategoryChange({ value: dataModel.socioProfessionalCategory });
        this.professionControl.patchValue(dataModel.profession);
      }

      this.form.updateValueAndValidity();
    }
  }

  restoreFiles(): void {
    if (this.clientProfile.proofOfFunds?.length > 0) {
      this.restoreExtraTableFormModel();
    }
  }

  restoreExtraTableFormModel(): void {
    this.clientProfile.proofOfFunds.forEach((doc: ProofOfFund) => {
      switch (doc.typeId) {
        case collections.proofOfFundDocuments.surrenderLifeInsurancePolicy.typeId: {
          this.restoreSurrenderLifeInsurance(doc);
          break;
        }
        case collections.proofOfFundDocuments.savings.typeId: {
          this.restoreSavings(doc);
          break;
        }
        case collections.proofOfFundDocuments.succession.typeId: {
          this.restoreSuccession(doc);
          break;
        }
        case collections.proofOfFundDocuments.estateSale.typeId: {
          this.restoreEstateSale(doc);
          break;
        }
        case collections.proofOfFundDocuments.cashflow.typeId: {
          this.restoreCashflowFiles(doc);
          break;
        }
        case collections.proofOfFundDocuments.other.typeId: {
          this.restoreOtherFiles(doc);
          break;
        }
      }
    });
  }

  restoreSurrenderLifeInsurance(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      surrenderLifeInsurancePolicy: true,
      surrenderLifeInsurancePolicyDate: moment(doc.transactionDate).format(constants.dateInputFormat),
      surrenderLifeInsurancePolicyAmount: doc.transactionAmount
    });

    if (doc.file) {
      const file = new File([''], doc.file.fileName, {});

      if (!this.surrenderLifeInsurancePolicyRestoredFiles) {
        this.surrenderLifeInsurancePolicyRestoredFiles = [];
      }

      this.surrenderLifeInsurancePolicyRestoredFiles.push(file);
    }

    this.surrenderLifeInsurancePolicyChanged(true);
  }

  restoreSavings(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      savings: true,
      savingsDate: moment(doc.transactionDate).format(constants.dateInputFormat),
      savingsAmount: doc.transactionAmount
    });

    if (doc.file) {
      const file = new File([''], doc.file.fileName, {});

      if (!this.savingRestoredFiles) {
        this.savingRestoredFiles = [];
      }

      this.savingRestoredFiles.push(file);
    }

    this.savingsChanged(true);
  }

  restoreSuccession(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      succession: true,
      successionDate: moment(doc.transactionDate).format(constants.dateInputFormat),
      successionAmount: doc.transactionAmount
    });

    if (doc.file) {
      const file = new File([''], doc.file.fileName, {});

      if (!this.successionRestoredFiles) {
        this.successionRestoredFiles = [];
      }

      this.successionRestoredFiles.push(file);
    }

    this.successionChanged(true);
  }

  restoreEstateSale(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      estateSale: true,
      estateSaleDate: moment(doc.transactionDate).format(constants.dateInputFormat),
      estateSaleAmount: doc.transactionAmount
    });

    if (doc.file) {
      let file = new File([''], doc.file.fileName, {});

      if (!this.estateSaleRestoredFiles) {
        this.estateSaleRestoredFiles = [];
      }

      this.estateSaleRestoredFiles.push(file);
    }

    this.estateSaleChanged(true);
  }

  restoreCashflowFiles(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      cashflow: true,
      cashflowAmount: doc.transactionAmount
    });

    if (doc.file) {
      const file = new File([''], doc.file.fileName, {});

      if (!this.cashflowRestoredFiles) {
        this.cashflowRestoredFiles = [];
      }

      this.cashflowRestoredFiles.push(file);
    }

    this.cashflowChanged(true);
  }

  restoreOtherFiles(doc: ProofOfFund): void {
    this.extraTableForm.patchValue({
      other: true,
      otherDate: moment(doc.transactionDate).format(constants.dateInputFormat),
      otherAmount: doc.transactionAmount
    });

    if (doc.file) {
      const file = new File([''], doc.file.fileName, {});

      if (!this.otherRestoredFiles) {
        this.otherRestoredFiles = [];
      }

      this.otherRestoredFiles.push(file);
    }

    this.otherChanged(true);
  }

  initControls(): void {
    this.officerOrPensionerControl = this.form.get('officerOrPensioner') as DropDownFormControl;
    this.professionalSituationControl = this.form.get('professionalSituation') as DropDownFormControl;
    this.socioProfessionalCategoryControl = this.form.get('socioProfessionalCategory') as DropDownFormControl;
    this.professionControl = this.form.get('profession') as DropDownFormControl;
    this.sncfNumberControl = this.form.get('sncfNumber') as SncfFormControl;
    this.ratpNumberControl = this.form.get('ratpNumber') as NumberFormControl;
    this.industryControl = this.form.get('industry') as DropDownFormControl;
    this.originOfWealthControl = this.form.get('originOfWealth') as CheckboxListFormControl;
    this.originOfContractFundsControl = this.form.get('originOfContractFunds') as CheckboxListFormControl;
    this.householdNetMonthlyIncomeControl = this.form.get('householdNetMonthlyIncome') as DropDownFormControl;

    this.surrenderLifeInsurancePolicyControl = this.extraTableForm.get('surrenderLifeInsurancePolicy') as CheckboxFormControl;
    this.surrenderLifeInsurancePolicyDateControl = this.extraTableForm.get('surrenderLifeInsurancePolicyDate') as DateInputFormControl;
    this.surrenderLifeInsurancePolicyAmountControl = this.extraTableForm.get('surrenderLifeInsurancePolicyAmount') as NumberFormControl;

    this.savingsControl = this.extraTableForm.get('savings') as CheckboxFormControl;
    this.savingsDateControl = this.extraTableForm.get('savingsDate') as DateInputFormControl;
    this.savingsAmountControl = this.extraTableForm.get('savingsAmount') as NumberFormControl;

    this.successionControl = this.extraTableForm.get('succession') as CheckboxFormControl;
    this.successionDateControl = this.extraTableForm.get('successionDate') as DateInputFormControl;
    this.successionAmountControl = this.extraTableForm.get('successionAmount') as NumberFormControl;

    this.estateSaleControl = this.extraTableForm.get('estateSale') as CheckboxFormControl;
    this.estateSaleDateControl = this.extraTableForm.get('estateSaleDate') as DateInputFormControl;
    this.estateSaleAmountControl = this.extraTableForm.get('estateSaleAmount') as NumberFormControl;

    this.cashflowControl = this.extraTableForm.get('cashflow') as CheckboxFormControl;
    this.cashflowAmountControl = this.extraTableForm.get('cashflowAmount') as NumberFormControl;

    this.otherControl = this.extraTableForm.get('other') as CheckboxFormControl;
    this.otherDateControl = this.extraTableForm.get('otherDate') as DateInputFormControl;
    this.otherAmountControl = this.extraTableForm.get('otherAmount') as NumberFormControl;
  }

  get isSncfRatp(): boolean {
    return this.form.get('areSncfRatp').value === constants.yesAnswer;
  }

  get isSncf(): boolean {
    return this.form.get('officerOrPensioner').value === constants.sncfAswer;
  }

  get isRatp(): boolean {
    return this.form.get('officerOrPensioner').value === constants.ratpAswer;
  }

  get isShowExtraTable(): boolean {
    let isAnswer = false;

    if (this.clientProfile?.profileQuestions) {
      const answerIdToShowExtraTable = this.profileThemes[5].questions[0].reponses[0].identifiant;
      const question = this.clientProfile.profileQuestions.filter((item: ProfileQuestion) => {
        return item.questionId === this.profileThemes[5].questions[0].identifiant;
      });

      if (question && question.length > 0) {
        isAnswer = question[0].answerId === answerIdToShowExtraTable;
      }
    }

    const case1 = this.myProfileFirstPayment && this.myProfileFirstPayment >= this.fundsOriginConfiguration.minPayment;
    const case2 = this.myProfileFirstPayment >= this.fundsOriginConfiguration.minPaymentWithEstate && isAnswer;

    return case1 || case2;
  }

  get noOneSelectedInExtraTable(): boolean {
    return (
      !this.surrenderLifeInsurancePolicyControl.value &&
      !this.savingsControl.value &&
      !this.successionControl.value &&
      !this.estateSaleControl.value &&
      !this.cashflowControl.value &&
      !this.otherControl.value
    );
  }

  get selectedInExtraTableItemsSummInvalid(): boolean {
    const minSumm = this.clientProfile.initialPaymentAmount;
    this.extraTableAmountSumm = 0;

    if (this.surrenderLifeInsurancePolicyControl.value === true && this.surrenderLifeInsurancePolicyAmountControl.value) {
      this.extraTableAmountSumm += this.surrenderLifeInsurancePolicyAmountControl.value;
    }

    if (this.savingsControl.value === true && this.savingsAmountControl.value) {
      this.extraTableAmountSumm += this.savingsAmountControl.value;
    }

    if (this.successionControl.value === true && this.successionAmountControl.value) {
      this.extraTableAmountSumm += this.successionAmountControl.value;
    }

    if (this.estateSaleControl.value === true && this.estateSaleAmountControl.value) {
      this.extraTableAmountSumm += this.estateSaleAmountControl.value;
    }

    if (this.cashflowControl.value === true && this.cashflowAmountControl.value) {
      this.extraTableAmountSumm += this.cashflowAmountControl.value;
    }

    if (this.otherControl.value === true && this.otherAmountControl.value) {
      this.extraTableAmountSumm += this.otherAmountControl.value;
    }

    return this.extraTableAmountSumm < minSumm;
  }

  surrenderLifeInsurancePolicyUploaderChanged(files: File[]): void {
    this.surrenderLifeInsurancePolicyUploaderFiles = files;
  }

  savingsUploaderChanged(files: File[]): void {
    this.savingsUploaderFiles = files;
  }

  successionUploaderChanged(files: File[]): void {
    this.successionUploaderFiles = files;
  }

  estateSaleUploaderChanged(files: File[]): void {
    this.estateSaleUploaderFiles = files;
  }

  cashflowUploaderChanged(files: File[]): void {
    this.cashflowUploaderFiles = files;
  }

  otherUploaderChanged(files: File[]): void {
    this.otherUploaderFiles = files;
  }

  surrenderLifeInsurancePolicyUploaderStatusChanged(status: any): void {
    this.surrenderLifeInsurancePolicyUploaderStatus = status;
  }

  savingsUploaderStatusChanged(status: any): void {
    this.savingsUploaderStatus = status;
  }

  successionUploaderStatusChanged(status: any): void {
    this.successionUploaderStatus = status;
  }

  estateSaleUploaderStatusChanged(status: any): void {
    this.estateSaleUploaderStatus = status;
  }

  cashflowUploaderStatusChanged(status: any): void {
    this.cashflowUploaderStatus = status;
  }

  otherUploaderStatusChanged(status: any): void {
    this.otherUploaderStatus = status;
  }

  surrenderLifeInsurancePolicyChanged(value?: boolean): void {
    if (value) {
      this.surrenderLifeInsurancePolicyDateControl.markAsRequredAndEnable();
      this.surrenderLifeInsurancePolicyAmountControl.markAsRequredAndEnable();
    } else {
      this.surrenderLifeInsurancePolicyDateControl.markAsNotRequredAndDisable();
      this.surrenderLifeInsurancePolicyAmountControl.markAsNotRequredAndDisable();
    }
  }

  savingsChanged(value?: boolean): void {
    if (value) {
      this.savingsDateControl.markAsRequredAndEnable();
      this.savingsAmountControl.markAsRequredAndEnable();
    } else {
      this.savingsDateControl.markAsNotRequredAndDisable();
      this.savingsAmountControl.markAsNotRequredAndDisable();
    }
  }

  successionChanged(value?: boolean): void {
    if (value) {
      this.successionDateControl.markAsRequredAndEnable();
      this.successionAmountControl.markAsRequredAndEnable();
    } else {
      this.successionDateControl.markAsNotRequredAndDisable();
      this.successionAmountControl.markAsNotRequredAndDisable();
    }
  }

  estateSaleChanged(value?: boolean): void {
    if (value) {
      this.estateSaleDateControl.markAsRequredAndEnable();
      this.estateSaleAmountControl.markAsRequredAndEnable();
    } else {
      this.estateSaleDateControl.markAsNotRequredAndDisable();
      this.estateSaleAmountControl.markAsNotRequredAndDisable();
    }
  }

  cashflowChanged(value?: boolean): void {
    if (value) {
      this.cashflowAmountControl.markAsRequredAndEnable();
    } else {
      this.cashflowAmountControl.markAsNotRequredAndDisable();
    }
  }

  otherChanged(value?: boolean): void {
    if (value) {
      this.otherDateControl.markAsRequredAndEnable();
      this.otherAmountControl.markAsRequredAndEnable();
    } else {
      this.otherDateControl.markAsNotRequredAndDisable();
      this.otherAmountControl.markAsNotRequredAndDisable();
    }
  }

  areSncfRatpChange(value: any): void {
    if (value === constants.yesAnswer) {
      this.officerOrPensionerControl.markAsRequred();
      this.professionalSituationControl.markAsRequred();
      this.industryControl.markAsNotRequred();
      this.industryControl.patchValue(null);
      this.professionControl.markAsNotRequred();
      this.professionControl.patchValue(null);
      this.socioProfessionalCategoryControl.markAsNotRequred();
      this.socioProfessionalCategoryControl.patchValue(null);
    } else {
      this.industryControl.markAsRequred();
      this.professionControl.markAsRequred();
      this.officerOrPensionerControl.markAsNotRequred();
      this.officerOrPensionerControl.patchValue(null);
      this.professionalSituationControl.markAsNotRequred();
      this.professionalSituationControl.patchValue(null);
      this.socioProfessionalCategoryControl.markAsRequred();
      this.ratpNumberControl.markAsNotRequred();
      this.ratpNumberControl.patchValue(null);
      this.sncfNumberControl.markAsNotRequred();
      this.sncfNumberControl.patchValue(null);
    }
  }

  officerOrPensionerChange(value: any): void {
    if (value === constants.ratpAswer) {
      this.ratpNumberControl.markAsRequred();
      this.sncfNumberControl.markAsNotRequred();
      this.sncfNumberControl.patchValue(null);
    }
    if (value === constants.sncfAswer) {
      this.sncfNumberControl.markAsRequred();
      this.ratpNumberControl.markAsNotRequred();
      this.ratpNumberControl.patchValue(null);
    }
  }

  onSocioProfessionalCategoryChange(event: any): void {
    const selectedCode = event.value;

    this.socioProfessionalCategories.forEach(type => {
      if (type.id === selectedCode) {
        this.professionsCollection = type.professions;
      }
    });

    this.professionControl.patchValue(null);

    this.professionControl.markRequiredOrNot(!!this.professionsCollection);

    if (this.professionsCollection) {
      this.professionsCollection = this.professionsCollection?.sort((a: Professions, b: Professions) => (a.ordre > b.ordre ? 1 : b.ordre > a.ordre ? -1 : 0));
      this.professionControl.setCollection(this.professionsCollection);
    }
  }

  get isOriginOfWealthOtherAnswer(): boolean {
    if (!this.originOfWealthControl.value) {
      return false;
    }

    return this.originOfWealthControl.value.indexOf(constants.otherAnswer) !== -1;
  }

  get isProfessionsCollection(): boolean {
    return !!this.professionsCollection;
  }

  get isOriginOfContractFundsOtherAnswer(): boolean {
    if (!this.originOfContractFundsControl.value) {
      return false;
    }

    return this.originOfContractFundsControl.value.indexOf(constants.otherAnswer) !== -1;
  }

  get isOriginOfWealthOtherAnswerValid(): boolean {
    if (!this.isOriginOfWealthOtherAnswer) {
      return true;
    }

    return this.originOfWealthOtherAnswerValue && this.originOfWealthOtherAnswerValue !== '';
  }

  get isOriginOfContractFundsOtherAnswerValid(): boolean {
    if (!this.isOriginOfContractFundsOtherAnswer) {
      return true;
    }

    return this.originOfContractFundsOtherAnswerValue && this.originOfContractFundsOtherAnswerValue !== '';
  }

  get isFormDisabled(): boolean {
    if (this.isShowExtraTable) {
      const isSmtWrongWithFiles =
        this.surrenderLifeInsurancePolicyUploaderStatus === FileUploaderStatus.INVALID ||
        this.savingsUploaderStatus === FileUploaderStatus.INVALID ||
        this.successionUploaderStatus === FileUploaderStatus.INVALID ||
        this.estateSaleUploaderStatus === FileUploaderStatus.INVALID ||
        this.cashflowUploaderStatus === FileUploaderStatus.INVALID ||
        this.otherUploaderStatus === FileUploaderStatus.INVALID;

      return this.form.invalid || (this.isShowExtraTable && (this.noOneSelectedInExtraTable || this.extraTableForm.invalid || this.selectedInExtraTableItemsSummInvalid || isSmtWrongWithFiles));
    } else {
      return this.form.invalid;
    }
  }

  originOfWealthOtherChange(event: string): void {
    this.originOfWealthOtherAnswerValue = event;
  }

  originOfContractFundsOtherChange(event: string): void {
    this.originOfContractFundsOtherAnswerValue = event;
  }

  personalNetMonthlyIncomeChange(event: any): void {
    if (event) {
      const selectedAmountId = event.value as number;
      const selectedAmountIndex = this.netMonthlyIncomesPersonal
        .map(function (e: any) {
          return e.id;
        })
        .indexOf(selectedAmountId);

      if (selectedAmountIndex !== -1) {
        const newHouserHoldCollection = this.netMonthlyIncomes.slice(selectedAmountIndex);
        const isExistInNewCollection =
          newHouserHoldCollection.filter((item: Informations) => {
            return item.id === this.householdNetMonthlyIncomeControl.value;
          })?.length > 0;

        this.householdNetMonthlyIncomeControl.setCollection(newHouserHoldCollection);

        if (!isExistInNewCollection) {
          this.householdNetMonthlyIncomeControl.setValue(null);
          this.householdNetMonthlyIncomeControl.updateValueAndValidity();
        }
      }
    }
  }

  goToMyDetails(): void {
    this.router.navigate(['my-details'], { queryParams: { userId: this.customerService.userId } });
  }

  goToMyContacts(): void {
    this.router.navigate(['my-details/contacts'], { queryParams: { userId: this.customerService.userId } });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid && this.isOriginOfWealthOtherAnswerValid && this.isOriginOfContractFundsOtherAnswerValid) {
      this.processSuccess();
    }
  }

  async processSuccess(): Promise<void> {
    let model = this.form.getRawValue() as MyFinancialFormModel;

    model.originOfWealthOtherId = this.originOfWealthOtherId;
    model.proofOfWealthDescription = this.originOfWealthOtherAnswerValue;

    model.originOfContractFundsOtherId = this.originOfContractFundsOtherId;
    model.proofOfFundsDescription = this.originOfContractFundsOtherAnswerValue;

    if (this.isSncfRatp) {
      model.socioProfessionalCategory = null;
      model.industry = null;

      if (this.isSncf) {
        model.ratpNumber = null;
      } else {
        model.sncfNumber = null;
      }
    } else {
      model.ratpNumber = null;
      model.sncfNumber = null;
    }

    model = this.extendModelByFiles(model);

    const userId = await this.customerService.updateMyFinancialModel(model, this.bsModalService, this.labelTextPipe);

    if (userId !== null) {
      if (
        (this.isCems || this.isCims) &&
        (!this.clientProfile.initialPaymentAmount || this.clientProfile.initialPaymentAmount === null) &&
        (!this.clientProfile.scheduledPaymentAmount || this.clientProfile.scheduledPaymentAmount === null)
      ) {
        if (this.clientProfile.socialNumberB) {
          const bancairesPrelevements = await this.wsCoordonneesBancairesService.getCoordonneesComptesBancairesPrelevementArray(parseInt(this.clientProfile.socialNumberB, 10));

          if (bancairesPrelevements?.length === 1) {
            const bic = bancairesPrelevements[0]?.BIC;
            const iban = bancairesPrelevements[0]?.IBAN;
            const rum = await this.checkAndUpdateRum(bancairesPrelevements[0]?.RUM);

            await this.customerService.updateBicIbanRum(bic, iban, rum);
          } else {
            this.router.navigate(['my-details/bank-details'], { queryParams: { userId } });
          }
        } else {
          this.router.navigate(['my-details/bank-details'], { queryParams: { userId } });
        }
        // this.router.navigate(['signature'], { queryParams: { userId: userId } }); // MIFS-581
        this.router.navigate(['my-details/bank-details'], { queryParams: { userId } });
      } else {
        this.router.navigate(['my-details/bank-details'], { queryParams: { userId } });
      }
    }
  }

  async checkAndUpdateRum(rum?: string): Promise<string> {
    if (!rum || rum.trim() === '') {
      rum = await this.wsReferentielMetierService.getRum();
    }

    return rum;
  }

  extendModelByFiles(model: MyFinancialFormModel): MyFinancialFormModel {
    if (this.surrenderLifeInsurancePolicyControl.value === true) {
      model.surrenderLifeInsurancePolicyFiles = this.surrenderLifeInsurancePolicyUploaderFiles;
      model.surrenderLifeInsurancePolicyDate = moment.utc(this.surrenderLifeInsurancePolicyDateControl.value, 'DDMMYYYY').toDate();
      model.surrenderLifeInsurancePolicyAmount = this.surrenderLifeInsurancePolicyAmountControl.value;
    } else {
      model.surrenderLifeInsurancePolicyFiles = [];
    }

    if (this.savingsControl.value === true) {
      model.savingsFiles = this.savingsUploaderFiles;
      model.savingsDate = moment.utc(this.savingsDateControl.value, 'DDMMYYYY').toDate();
      model.savingsAmount = this.savingsAmountControl.value;
    } else {
      model.savingsFiles = [];
    }

    if (this.successionControl.value === true) {
      model.successionFiles = this.successionUploaderFiles;
      model.successionDate = moment.utc(this.successionDateControl.value, 'DDMMYYYY').toDate();
      model.successionAmount = this.successionAmountControl.value;
    } else {
      model.successionFiles = [];
    }

    if (this.estateSaleControl.value === true) {
      model.estateSaleFiles = this.estateSaleUploaderFiles;
      model.estateSaleDate = moment.utc(this.estateSaleDateControl.value, 'DDMMYYYY').toDate();
      model.estateSaleAmount = this.estateSaleAmountControl.value;
    } else {
      model.estateSaleFiles = [];
    }

    if (this.cashflowControl.value === true) {
      model.cashflowFiles = this.cashflowUploaderFiles;
      model.cashflowAmount = this.cashflowAmountControl.value;
    } else {
      model.cashflowFiles = [];
    }

    if (this.otherControl.value === true) {
      model.otherFiles = this.otherUploaderFiles;
      model.otherDate = moment.utc(this.otherDateControl.value, 'DDMMYYYY').toDate();
      model.otherAmount = this.otherAmountControl.value;
    } else {
      model.otherFiles = [];
    }

    return model;
  }

  get showControlsWithErrors(): string {
    let res = '';

    Object.keys(this.form.controls).forEach(key => {
      if (this.form.controls[key].errors) {
        res += `${key}, `;
      }
    });

    return res;
  }
}

export interface MyFinancialFormModel {
  areSncfRatp: string;
  socioProfessionalCategory: number;
  profession?: number;
  officerOrPensioner: string;
  sncfNumber: string;
  ratpNumber: number;
  professionalSituation: number;
  industry: number;
  personalNetMonthlyIncome: number;
  householdNetMonthlyIncome: number;
  originOfWealth: string[];
  originOfContractFunds: string[];
  proofOfWealthDescription?: string;
  proofOfFundsDescription?: string;
  originOfWealthOtherId?: number;
  originOfContractFundsOtherId?: number;

  surrenderLifeInsurancePolicyFiles?: File[];
  surrenderLifeInsurancePolicyDate?: Date;
  surrenderLifeInsurancePolicyAmount?: number;

  savingsFiles?: File[];
  savingsDate?: Date;
  savingsAmount?: number;

  successionFiles?: File[];
  successionDate?: Date;
  successionAmount?: number;

  estateSaleFiles?: File[];
  estateSaleDate?: Date;
  estateSaleAmount?: number;

  cashflowFiles?: File[];
  cashflowAmount?: number;

  otherFiles?: File[];
  otherDate?: Date;
  otherAmount?: number;
}

export interface ExtraTableFormModel {
  surrenderLifeInsurancePolicy?: boolean;
  surrenderLifeInsurancePolicyDate?: Date;
  surrenderLifeInsurancePolicyAmount?: number;

  savings?: boolean;
  savingsDate?: Date;
  savingsAmount?: number;

  succession?: boolean;
  successionDate?: Date;
  successionAmount?: number;

  estateSale?: boolean;
  estateSaleDate?: Date;
  estateSaleAmount?: number;

  cashflow?: boolean;
  cashflowAmount?: number;

  other?: boolean;
  otherDate?: Date;
  otherAmount?: number;
}
