import { constants } from '@constants/constants';
import { Pipe, PipeTransform } from '@angular/core';
import { Link } from '@webapi/MIF.Subscription.WebApi';

@Pipe({
  name: 'footerLinksValue'
})
export class FooterLinksValuePipe implements PipeTransform {
  constructor() {

  }
  transform(item: Link): string {
    let res = '';

    if (!item?.value) {
      return res;
    }

    res = item.typeCode === constants.footerLinksTypes.document ? `Document ${item.value}` : item.value;

    return res;
  }
}
