<div class="form-group mat-combobox" [class.required]="isRequired" [class.disabled]="isDisabled" [formGroup]="parentForm">
    <mat-label *ngIf="title" class="pr-2">{{ title }}</mat-label>
    <br />
    <mat-radio-group (change)="change()" [formControlName]="controlName">
        <mat-radio-button *ngFor="let item of collection; let i = index" value="{{ item[keyPropertyName] || item }}" [class.selected-item]="isSelected(item[keyPropertyName] || item)">
            {{ item[valuePropertyName] || item }}
        </mat-radio-button>
    </mat-radio-group>

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
        <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <!-- <div class="small" *ngIf="control.invalid && control.touched">
        <div class="text-danger small" *ngIf="control.errors?.required">*Required</div>
    </div> -->
    <!-- <mat-error>
        <span *ngIf="control.errors?.required">*Required</span>
    </mat-error> -->
</div>