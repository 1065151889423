<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.ResidenceQuestions" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="residence-questions">
  <div class="text-center mt-5 mb-5">
    <h5>{{ 'ELIGIBITY.QuestionsToDefineYourProfile' | labelText }}</h5>
  </div>
  <div>
    <div class="card">
      <div class="card-body mt-4 ml-3">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouFrenchTaxResident' | labelText }}
                <app-tooltip [title]="'Home.AreYouFrenchTaxResidentTooltipTitle' | labelText" [text]="'Home.AreYouFrenchTaxResidentTooltipText' | labelText" [tooltipPosition]="'bottom'">
                </app-tooltip>
              </label>
              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{ 'active-btn': frenchResidence, 'active-btn2': check1 }"
                  (click)="switchIfFrenchResidence()"
                  [disabled]="(ageNotCapable || americanResidence) && !check1"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'error-btn': notFrenchResidence }"
                  (click)="switchIfNotFrenchResidence()"
                  [disabled]="ageNotCapable || americanResidence"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="check-mark" *ngIf="frenchResidence"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span class="check-mark error" *ngIf="notFrenchResidence"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="notFrenchResidence">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouMajor' | labelText }}
                <app-tooltip [title]="'Home.AreYouMajorTooltipTitle' | labelText" [text]="'Home.AreYouMajorTooltipText' | labelText" [tooltipPosition]="'bottom'"></app-tooltip>
              </label>
              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{ 'active-btn': ageCapable, 'active-btn2': check2 }"
                  (click)="switchIfAgeCapable()"
                  [disabled]="(notFrenchResidence || americanResidence) && !check2"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'error-btn': ageNotCapable }"
                  (click)="switchIfAgeNotCapable()"
                  [disabled]="notFrenchResidence || americanResidence"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="check-mark" *ngIf="ageCapable"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span class="check-mark error" *ngIf="ageNotCapable"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="ageNotCapable">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
            <div class="question-block">
              <label>
                {{ 'QUESTIONNAIRE.AreYouAUsCitizen' | labelText }}
                <app-tooltip [title]="'Home.AreYouAUsCitizenTooltipTitle' | labelText" [text]="'Home.AreYouAUsCitizenTooltipText' | labelText" [tooltipPosition]="'bottom'"></app-tooltip>
              </label>

              <div class="buttons-block">
                <button
                  class="btn btn-primary radio-label mr-2"
                  type="button"
                  [ngClass]="{ 'error-btn': americanResidence }"
                  (click)="switchIfAmericanResidence()"
                  [disabled]="notFrenchResidence || ageNotCapable"
                >
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label"
                  type="button"
                  [ngClass]="{ 'active-btn': notAmericanResidence, 'active-btn2': check3 }"
                  (click)="switchIfNotAmericanResidence()"
                  [disabled]="(notFrenchResidence || ageNotCapable) && !check3"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="check-mark" *ngIf="notAmericanResidence"><fa-icon [icon]="faCheck"></fa-icon></span>
                <span class="check-mark error" *ngIf="americanResidence"><fa-icon [icon]="faTimes"></fa-icon></span>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="americanResidence">
            <div class="error-block">
              <span class="text">
                {{ 'Common.YouCannotSubscribeOnline' | labelText }}
              </span>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <button type="button" class="btn btn-primary validation-button" (click)="goToProfile()" [disabled]="!notAmericanResidence || !ageCapable || !frenchResidence">
              {{ 'Common.Validate' | labelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
