<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.Signature" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="row mt-4">
  <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10">
    <p *ngIf="!isPeri" class="main-intro text-center" [innerHtml]="'SUMMARY.IntroText' | labelText"></p>
    <p *ngIf="isPeri" class="main-intro text-center" [innerHtml]="'SUMMARY.IntroTextPeri' | labelText"></p>
  </div>
  <div class="col-xs-offset-1 col-xs-10 col-sm-4 col-md-3 col-lg-2">
    <button type="submit" class="btn btn-blue" [disabled]="!dataFetched" (click)="getSubscriptionContract()">{{ 'SUMMARY.PrintSummary' | labelText }}</button>
  </div>
</div>

<div #pdfContent class="root-pdf-content">
  <div class="headline hide-in-web-app">
    <img src="{{ locationOrigin }}/assets/img/logo.svg" alt="" class="logo" />
    <div class="headertext">
      <h1 *ngIf="!isPeri && !isCims">
        {{ 'SUMMARY.PDFTitle' | labelText }}
      </h1>
      <h1 *ngIf="isPeri">
        {{ 'SUMMARY.PDFTitlePeri' | labelText }}
      </h1>
      <h1 *ngIf="isCims">
        {{ 'SUMMARY.PDFTitleCims' | labelText }}
      </h1>
    </div>
  </div>

  <div class="mt-4 mb-5">
    <div class="card">
      <div class="row">
        <div class="col-12 text-left">
          <div class="card-body">
            <div class="row mt-2">
              <div class="col">
                <h1 class="section-title">{{ 'SUMMARY.Summary' | labelText }}</h1>
                <h5 class="header mt-4" *ngIf="isCems || isPeri || isCims">
                  {{ 'SUMMARY.ProfilSection' | labelText }}
                </h5>
                <h5 class="header mt-4" *ngIf="isGpa || isGfd">
                  {{ 'SUMMARY.HeathQuestions' | labelText }}
                </h5>
              </div>
            </div>

            <div class="row" *ngIf="isCems || isPeri || isCims">
              <div class="col">
                <div class="recap recap-my-profil" [innerHTML]="questionsListHtml"></div>
                <div class="txt-default" *ngIf="isQuestion12AnsweredYes">
                  <div [innerHTML]="question12YesAnswerExtraText | safeHtml"></div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="isGpa">
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                <div class="label-answer-section">
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues1' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues2' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues3' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues4' | labelText }}</span>
                  </p>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyProfile()">{{ 'Common.Modify' | labelText }}</button>
              </div>
            </div>

            <div class="row" *ngIf="isGfd">
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                <div class="label-answer-section">
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues1' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues2' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues3' | labelText }}</span>
                  </p>
                  <p>
                    <span class="answer">{{ 'SUMMARY.HealthQuestionsQues4' | labelText }}</span>
                  </p>
                </div>
                <h5 class="header mt-4" *ngIf="isGfd">
                  {{ 'SUMMARY.FuneralsNeedStatutGfdTitle' | labelText }}
                </h5>
                <div class="label-answer-section">
                  <p>
                    <span class="answer">{{ 'SUMMARY.FuneralsNeedStatut' | labelText }}</span>
                  </p>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyProfile()">{{ 'Common.Modify' | labelText }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-6" *ngIf="recommandation && recommendationProfile">
      <div class="col">
        <div class="recap">
          <div class="recap-my-profil-footer" [style.background-color]="recommendationProfile.color">
            <div class="title">
              {{ 'SUMMARY.ProfilText' | labelText }} <strong>"{{ recommandation.profil.libelle }}"</strong>
            </div>
            <div class="content">
              <p>
                {{ recommandation.profil.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pdf-page-break-after" *ngIf="clientProfile && recommendationProfile">
      <div class="col">
        <div class="recap recap-my-profil-with-footer" [style.background-color]="recommendationProfile.color">
          <div class="recap-my-profil-footer">
            <div class="title">
              {{ 'SUMMARY.ManageMode' | labelText }} <strong>"{{ managementTypeName }}"</strong>
            </div>
            <div class="content">
              <p>
                {{ managementTypeText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" *ngIf="isGpa && clientProfile?.gpaProfile">
      <app-signature-gpa-plan [clientProfile]="clientProfile" [offer]="offer"></app-signature-gpa-plan>
    </div>

    <div class="mt-4" *ngIf="isGfd && clientProfile?.gfdProfile">
      <app-signature-gfd-plan [clientProfile]="clientProfile"></app-signature-gfd-plan>
    </div>

    <div class="mt-4 pdf-page-break-after" *ngIf="isCems || isPeri || isCims">
      <div class="card pdf-my-contract">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body" *ngIf="clientProfile">
              <div class="row">
                <div class="col">
                  <h5 *ngIf="isCems" class="header mt-3">{{ 'SUMMARY.MyLifeInsuranceContract' | labelText }}</h5>
                  <h5 *ngIf="isCims" class="header mt-3">{{ 'SUMMARY.MyLifeInsuranceContractCims' | labelText }}</h5>
                  <h5 *ngIf="isPeri" class="header mt-3">{{ 'SUMMARY.MyLifeInsuranceContractPeri' | labelText }}</h5>
                </div>
              </div>

              <div *ngIf="isCems || isCims">
                <div class="recap-warning-blue" *ngIf="clientProfile.isRecommendationsFollowed || manualDistributionEqualsRecommendet">
                  <span *ngIf="isCems">
                    {{ 'SUMMARY.SubscribeFollowingRecommendation' | labelText }}
                  </span>
                  <span *ngIf="isCims">
                    {{ 'SUMMARY.SubscribeFollowingRecommendationCims' | labelText }}
                  </span>
                  <br />
                  {{ 'SUMMARY.FundDistributionMatchesYourProfile' | labelText }}
                </div>

                <div class="recap-warning-blue red" *ngIf="!clientProfile.isRecommendationsFollowed && !manualDistributionEqualsRecommendet">
                  {{ 'SUMMARY.WarningRecommendationNotFollowesCEMS' | labelText : currentProductName }}
                  <br />
                  {{ 'SUMMARY.WarningRecommendationNotFollowed' | labelText : currentProductName }}
                </div>
              </div>

              <div *ngIf="isPeri">
                <div class="recap-warning-blue" *ngIf="clientProfile.isRecommendationsFollowed || manualDistributionEqualsRecommendet">
                  {{ 'SUMMARY.WarningRecommendationFollowedPeri' | labelText }}
                  <br />
                  {{ 'SUMMARY.FundDistributionMatchesYourProfile' | labelText }}
                </div>

                <div class="recap-warning-blue red" *ngIf="!clientProfile.isRecommendationsFollowed && !manualDistributionEqualsRecommendet">
                  {{ 'SUMMARY.WarningRecommendationNotFollowedPeri' | labelText }}
                  <br />
                  {{ 'SUMMARY.WarningRecommendationNotFollowedPeri2' | labelText }}
                </div>
              </div>

              <div class="row mt-4" *ngIf="isPeri">
                <div class="col">
                  <h5 class="header">{{ 'SUMMARY.MyExpectedRetirementDate' | labelText }}</h5>
                  <div class="recap-section">
                    <div class="txt-default fs-14">
                      {{ 'SUMMARY.MyExpectedRetirementDate2' | labelText }}
                      <span class="fw-600 text-blue">
                        {{ moment.utc(clientProfile.retirementPlan?.expectedRetirementDate).format(constants.dateInputFormat) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <h5 class="header">{{ 'SUMMARY.MyPayments' | labelText }}</h5>
                  <div class="recap-section">
                    <div class="txt-default fs-14" [innerHTML]="paymentsText"></div>
                  </div>
                </div>
              </div>

              <div class="row mt-4" *ngIf="isPeri">
                <div class="col">
                  <h5 class="header">{{ 'SUMMARY.MyTaxPlan' | labelText }}</h5>
                  <div class="recap-section">
                    <div class="txt-default fs-14">
                      <div class="txt-default fs-14" [innerHTML]="periTaxDeductionText"></div>
                      <div class="txt-default fs-14">
                        {{ 'SUMMARY.MyTaxPlanText1' | labelText }}
                      </div>
                      <div class="txt-default fs-14 mt-3">
                        {{ 'SUMMARY.MyTaxPlanText2' | labelText }} <span class="fw-600 text-blue">{{ 'SUMMARY.MyTaxPlanText3' | labelText }}</span> de <span class="fw-600 text-blue">10 €</span>
                        {{ 'SUMMARY.MyTaxPlanText4' | labelText }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4" *ngIf="recommandation">
                <div class="col">
                  <span *ngIf="clientProfile && recommendationProfile && clientProfile.managementTypeCode === constants.gestionSousMandat.id">
                    <h5 class="header">{{ 'SUMMARY.ManageModeTitle' | labelText }} : {{ constants.gestionSousMandat.name }} {{ selectedProfile.name }}</h5>
                  </span>
                  <h5 class="header" *ngIf="clientProfile && recommendationProfile && clientProfile.managementTypeCode === constants.gestionLibre.id">
                    {{ 'SUMMARY.ManageModeTitle' | labelText }} : {{ constants.gestionLibre.name }}
                  </h5>
                  <h5 class="header" *ngIf="clientProfile && recommendationProfile && clientProfile.managementTypeCode === constants.gestionHorizon.id">
                    {{ 'SUMMARY.ManageModeTitle' | labelText }} : {{ constants.gestionHorizon.name }} {{ selectedProfile.name }}
                  </h5>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <h5 class="header">{{ 'SUMMARY.MySelectionOfFundsAndDistribution' | labelText }}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <table class="invisible-table">
                    <tbody>
                      <tr *ngFor="let fund of selectedFunds; let i = index">
                        <td>
                          <span class="answer"> {{ getSelectedFondText(fund) }} </span>
                        </td>
                        <td style="text-align: right" class="answer-td">
                          <span class="answer"> {{ getRepartition(fund) | fraction }} </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p class="fw-600 txt-default mt-3" [innerHtml]="afterRepartitionText"></p>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <button class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToRecomendation()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <h5 class="header">{{ 'SUMMARY.PaymentFeesCompl' | labelText }}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                  <table class="invisible-table">
                    <tbody>
                      <tr *ngFor="let fund of selectedFunds; let i = index">
                        <td>
                          <span class="answer"> {{ fund.libelle }}</span>
                        </td>
                        <td style="text-align: right" class="answer-td">
                          <span class="answer"> {{ getFee(fund) }} </span>
                        </td>
                        <td *ngIf="fund.isScpiFundType()" style="text-align: left" class="answer-td">
                          <span class="scpiFeesTextSummary">
                            {{ 'Common.FeesInCaseWithdrawalText' | labelText : fund.getFeesInCaseWithdrawal() }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" *ngIf="(isCems || isPeri || isCims) && euroFondPercent">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 *ngIf="isCims || isCems" class="header mt-3">{{ 'SUMMARY.ArrayEuroMifSection' | labelText }}</h5>
                  <h5 *ngIf="isPeri" class="header mt-3">{{ 'SUMMARY.ArrayEuroMifSectionPeri' | labelText }}</h5>
                </div>
              </div>

              <div class="txt-default mt-2 fw-600">{{ 'SUMMARY.ArrayEuroMifSectionBeforeTable' | labelText }}</div>

              <div class="row mt-4">
                <div class="col">
                  <div class="table-responsive">
                    <table class="table table-bordered tableauRachat">
                      <thead>
                        <tr>
                          <td>{{ 'SUMMARY.ArrayEuroMifSectionYear' | labelText }}</td>
                          <td *ngFor="let item of tableItems">
                            {{ item.date }}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ 'SUMMARY.ArrayEuroMifSectionPaidAmounts' | labelText }}</td>
                          <td *ngFor="let item of tableItems">
                            {{ item.sumPaid | euro }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ 'SUMMARY.ArrayEuroMifSectionValues' | labelText }}</td>
                          <td *ngFor="let item of tableItems">
                            {{ item.repurchaseValue | euro }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="txt-default mt-2 fw-600" [innerHTML]="'SUMMARY.ArrayEuroMifSectionAfterTable' | labelText"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="header mt-3">{{ 'SUMMARY.BeneficiariesSectionText' | labelText }}</h5>
                </div>
              </div>

              <div class="row" *ngIf="clientProfile && countries && nationalities && civilities">
                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-10">
                  <div *ngIf="isCims" class="txt-default mt-2 fw-600" [innerHTML]="primaryBeneficiaryText"></div>
                  <div class="txt-default mt-2 fw-600" *ngIf="!isGfd">
                    {{ 'SUMMARY.ChooseText1' | labelText }} <span class="text-blue">{{ 'SUMMARY.ChooseText2' | labelText }} {{ beneficiaryClause }} </span>
                  </div>
                  <div class="txt-default mt-2 fw-600" [innerHTML]="beneficiaryClauseText"></div>
                  <div *ngIf="isCims" class="txt-default mt-2 fw-600">
                    {{ 'SUMMARY.ContractExpirationDateTitleCims' | labelText }}
                    <span class="text-blue"> {{ moment.utc(clientProfile.cimsProfile.contractExpirationDate).format(constants.dateInputFormat) }} </span>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2">
                  <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyBeneficiaries()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-content hide-in-pdf" *ngIf="isSpecialGpaOfferAvailable">
      <div class="recap-header-promo">
        <div class="push-offer">
          <p>{{ 'SUMMARY.UpsellATDNBanner' | labelText }}</p>
        </div>
        <div class="recap-header-title">
          <p>
            {{ 'SUMMARY.UpsellATDNTitle1' | labelText }} <strong class="fs-30"> {{ gpaContractAmount | euro : true }} </strong> {{ 'SUMMARY.UpsellATDNTitle2' | labelText }}
            <strong class="fs-30"> {{ gpaContractAmountPerMonth | euro : true }} {{ 'SUMMARY.UpsellATDNTitle3' | labelText }}</strong
            >{{ 'SUMMARY.UpsellATDNTitle4' | labelText }}
          </p>
        </div>
      </div>

      <div class="recap recap-promo">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
            <p>
              {{ 'SUMMARY.UpsellATDNTitle5' | labelText }}
            </p>
            <p>
              <strong>{{ 'Common.Include' | labelText }} :</strong>
            </p>
            <ul class="list-arrows">
              <li *ngIf="gpaSubscriptionGarantiX2x3">{{ 'SUMMARY.UpsellATDNIncludedLine1' | labelText }}</li>
              <li>{{ 'SUMMARY.UpsellATDNIncludedLine2' | labelText }}</li>
              <li>{{ 'SUMMARY.UpsellATDNIncludedLine3' | labelText }}</li>
            </ul>
            <div class="checkbox checkbox-custom">
              <mat-checkbox [(ngModel)]="subscribeGpaPer1Euro" (ngModelChange)="subscribeGpaPer1EuroClick($event)" class="break-spaces">
                {{ 'SUMMARY.SubscribeGpaPer1EuroText1' | labelText }} {{ gpaContractAmountPerMonth | euro : true }} {{ 'SUMMARY.SubscribeGpaPer1EuroText2' | labelText }}
              </mat-checkbox>
            </div>
          </div>
        </div>

        <div class="row with-speech with-radius" *ngIf="subscribeGpaPer1Euro">
          <div class="col">
            <div class="speech">
              <div class="speech-title fw-600">{{ 'SUMMARY.UpsellATDNHealthQuestionsTitle' | labelText }}</div>
              <app-health-questionnaire
                [cemsSummary]="true"
                [subscribeGpaPer1Euro]="subscribeGpaPer1Euro"
                (onFormStatusChange)="onGpaSubscriptionHealthQuestionsChange($event)"
              ></app-health-questionnaire>
            </div>
          </div>
        </div>

        <div class="row with-speech with-radius mt-2" *ngIf="subscribeGpaPer1Euro">
          <div class="col">
            <div class="speech">
              <div class="speech-title fw-600 mb-4">{{ 'SUMMARY.UpsellATDNBenefitClauseTitle' | labelText }}</div>
              <h5 class="fs-16">{{ 'SUMMARY.UpsellATDNBenefitSameClause' | labelText }}</h5>
              <div class="buttons-block mb-4">
                <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': reuseBeneficiaries === true }" (click)="reuseBeneficiariesChanged(true)">
                  {{ 'Common.Yes' | labelText }}
                </button>
                <button
                  class="btn btn-primary radio-label reuse-beneficiaries-button"
                  type="button"
                  [ngClass]="{ 'active-btn': reuseBeneficiaries === false }"
                  (click)="reuseBeneficiariesChanged(false)"
                >
                  {{ 'Common.No' | labelText }}
                </button>
                <span class="success-mark" *ngIf="reuseBeneficiaries === true || reuseBeneficiaries === false">
                  <fa-icon [icon]="faCheck"></fa-icon>
                </span>
              </div>
              <div class="row" *ngIf="reuseBeneficiaries === false">
                <div class="col">
                  <div class="row">
                    <div class="col fs-16">{{ 'SUMMARY.UpsellATDNBenefiChoice' | labelText }}</div>
                  </div>

                  <span class="clause-standart-form-anchor"></span>
                  <div class="row with-speech white" *ngIf="isShowClauseStandart">
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-10">
                      <div class="speech">
                        <div class="speech-title">{{ 'BENEFICIARIES.StandardClause' | labelText }}</div>
                        <p>
                          <span class="fw-600">{{ 'BENEFICIARIES.BeneficiariesHelp' | labelText }}</span> <br />
                          {{ 'SUMMARY.StandardClauseDescription' | labelText }}
                        </p>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-2">
                      <div class="table-like">
                        <button
                          class="btn btn-primary radio-label mr-2"
                          type="button"
                          [ngClass]="{ 'active-btn': clauseStandard }"
                          (click)="switchGpaBeneficiareType(constants.beneficiaresTypes.standard.code, true)"
                        >
                          {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="row with-speech white">
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-10">
                      <div class="speech">
                        <div class="speech-title">{{ 'Beneficiaries.NominativeClause' | labelText }}</div>
                        <p *ngIf="!isCims">{{ 'BENEFICIARIES.NominativeClauseDescription' | labelText : 5 }}</p>
                        <p *ngIf="isCims">{{ 'BENEFICIARIES.NominativeClauseDescriptionCims' | labelText : 4 }}</p>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-2">
                      <div class="table-like">
                        <button
                          class="btn btn-primary radio-label mr-2"
                          type="button"
                          [ngClass]="{ 'active-btn': clauseNominative }"
                          (click)="switchGpaBeneficiareType(constants.beneficiaresTypes.nominative.code, true)"
                        >
                          {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                        </button>
                      </div>
                    </div>
                    <span class="clause-nominative-form-anchor"></span>
                    <div *ngIf="clauseNominative" class="col clause-nominative-form">
                      <app-clause-nominative
                        [cemsSummary]="true"
                        [subscribeGpaPer1Euro]="subscribeGpaPer1Euro"
                        (onFormStatusChange)="onGpaSubscriptionClauseNominativeChange($event)"
                      ></app-clause-nominative>
                    </div>
                  </div>

                  <div class="row with-speech white">
                    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-10">
                      <div class="speech">
                        <div class="speech-title">{{ 'BENEFICIARIES.BeneficiariesFreeTitle' | labelText }}</div>
                        <p>{{ 'BENEFICIARIES.BeneficiariesFreeDescription' | labelText }}</p>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-2">
                      <div class="table-like">
                        <button
                          class="btn btn-primary radio-label mr-2"
                          type="button"
                          [ngClass]="{ 'active-btn': clauseLibre }"
                          (click)="switchGpaBeneficiareType(constants.beneficiaresTypes.libre.code, true)"
                        >
                          {{ 'BENEFICIARIES.BeneficiariesButton' | labelText }}
                        </button>
                      </div>
                    </div>
                    <span class="clause-libre-form-anchor"></span>
                    <div *ngIf="clauseLibre" class="col">
                      <app-clause-libre [cemsSummary]="true" [subscribeGpaPer1Euro]="subscribeGpaPer1Euro" (onFormStatusChange)="onGpaSubscriptionClauseLibreChange($event)"></app-clause-libre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="text-center fw-600" *ngIf="subscribeGpaPer1Euro && gpaSubscriptionHealthQuestionsValid && this.isBeneficiariesValid">Votre offre de prévoyance vient d’être ajoutée !</h2>
      </div>
    </div>

    <div class="mt-4 pdf-page-break-after">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body" *ngIf="clientProfile">
              <div class="row">
                <div class="col">
                  <h5 class="header mt-3">{{ 'SUMMARY.DetailsSection' | labelText }}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <div class="label-answer-section">
                    <p>
                      {{ 'SUMMARY.DetailsSectionCivility' | labelText }} <span class="answer">{{ getReferencelibelleLongById(civilities, clientProfile.person.civilityCode) }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionUsagename' | labelText }} <span class="answer"> {{ clientProfile.person.lastName }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionBirthName' | labelText }} <span class="answer"> {{ clientProfile.person.birthName }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionFirstName' | labelText }} <span class="answer"> {{ clientProfile.person.firstName }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionBirthDate' | labelText }} <span class="answer"> {{ moment.utc(clientProfile.person.dateOfBirth).format(constants.dateInputFormat) }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionPostalCodeBirthCity' | labelText }} <span class="answer"> {{ clientProfile.placeOfBirth.zipCode }} - {{ clientProfile.placeOfBirth.city }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionBirthCountry' | labelText }} <span class="answer"> {{ getCountryById(countries, clientProfile.placeOfBirth.country) }}</span>
                    </p>
                    <p *ngIf="clientProfile.nir">
                      {{ 'SUMMARY.NirTitle' | labelText }} : <span class="answer"> {{ clientProfile.nir }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionFamilySituation' | labelText }} <span class="answer"> {{ getReferencelibelleLongById(familySituations, clientProfile.maritalStatusCode) }} </span>
                    </p>
                    <p *ngIf="isMaried">
                      {{ 'IDENTITY.MatrimonialRegime' | labelText }} : <span class="answer"> {{ getReferencelibelleLongById(matrimonialRegimes, clientProfile.matrimonialRegimeCode) }} </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="firstIdentificationDocuments">
                <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                  <div class="row with-speech">
                    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                      <div class="speech">
                        <div class="row">
                          <div class="col files">
                            <span class="label"> {{ firstIdentificationDocumentType }} </span>
                            <ul>
                              <li *ngFor="let file of firstIdentificationDocuments" (click)="getFile(file)">{{ file.name }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
                      <span class="icon icon-aside"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <div class="label-answer-section">
                    <p>
                      {{ 'Common.Adress' | labelText }} :
                      <span class="answer dib vat">
                        {{ clientProfile.currentAddress.addressLine }}<br />
                        {{ clientProfile.currentAddress.additionalAddress }}<br />
                        {{ clientProfile.currentAddress.additionalAddress2 }}<br *ngIf="clientProfile.currentAddress.additionalAddress2" />
                        {{ clientProfile.currentAddress.locality }}<br *ngIf="clientProfile.currentAddress.locality" />
                        {{ clientProfile.currentAddress.zipCode }} {{ clientProfile.currentAddress.city }}<br />
                        {{ getCountryById(countries, clientProfile.currentAddress.country) }}
                      </span>
                    </p>

                    <p *ngIf="clientProfile.contact.mobilePhone">
                      {{ 'SUMMARY.DetailsSectionPhone' | labelText }} <span class="answer"> +33{{ clientProfile.contact.mobilePhone }}</span>
                    </p>
                    <p *ngIf="clientProfile.contact.phone">
                      {{ 'SUMMARY.DetailsSectionFixPhone' | labelText }} <span class="answer"> +33{{ clientProfile.contact.phone }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionMailAdress' | labelText }} <span class="answer"> {{ clientProfile.contact.email }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToMyDetails()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>

              <div class="row" *ngIf="addressConfirmationDocuments">
                <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                  <div class="row with-speech">
                    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                      <div class="speech">
                        <div class="row">
                          <div class="col files">
                            <span class="label"> {{ 'SUMMARY.DetailsSectionProofOfAddress' | labelText }} </span>
                            <ul>
                              <li *ngFor="let file of addressConfirmationDocuments" (click)="getFile(file)">{{ file.name }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
                      <span class="icon icon-aside"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="clientProfile">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <div class="label-answer-section">
                    <p *ngIf="!clientProfile.professionalInfo.sncfValue && !clientProfile.professionalInfo.ratpValue">
                      {{ 'SUMMARY.DetailsSectionCSP' | labelText }}
                      <span class="answer">{{ getReferencelibelleById(socioProfessionalCategories, clientProfile.professionalInfo.socialProfessionCategoryCode) }}</span>
                    </p>
                    <p *ngIf="!clientProfile.professionalInfo.sncfValue && !clientProfile.professionalInfo.ratpValue && clientProfile.professionalInfo.professionCode">
                      {{ 'Common.Profession' | labelText }} : <span class="answer">{{ getReferencelibelleById(professionsCollection, clientProfile.professionalInfo.professionCode) }}</span>
                    </p>
                    <p *ngIf="!clientProfile.professionalInfo.sncfValue && !clientProfile.professionalInfo.ratpValue">
                      {{ 'SUMMARY.DetailsSectionActivity' | labelText }} <span class="answer">{{ getReferencelibelleLongById(industries, clientProfile.professionalInfo.activitySectorCode) }}</span>
                    </p>
                    <p *ngIf="clientProfile.professionalInfo.sncfValue || clientProfile.professionalInfo.ratpValue">
                      {{ 'SUMMARY.DetailsSectionSNCFOrRatp' | labelText }} <span class="answer">{{ clientProfile.professionalInfo.sncfValue ? 'SNCF' : 'RATP' }}</span>
                    </p>
                    <p *ngIf="clientProfile.professionalInfo.ratpValue">
                      {{ 'SUMMARY.MatriculeRATP' | labelText }} : <span class="answer">{{ clientProfile.professionalInfo.ratpValue }}</span>
                    </p>
                    <p *ngIf="clientProfile.professionalInfo.sncfValue">
                      {{ 'SUMMARY.MatriculeSNCF' | labelText }} : <span class="answer">{{ clientProfile.professionalInfo.sncfValue }}</span>
                    </p>
                    <p *ngIf="clientProfile.professionalInfo.sncfValue || clientProfile.professionalInfo.ratpValue">
                      {{ 'SUMMARY.DetailsSectionProfessionalStatus' | labelText }}
                      <span class="answer">{{ getReferencelibelleLongById(professionalSituations, clientProfile.professionalInfo.professionalStatusCode) }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionMontlyIncomeNetPersonal' | labelText }}
                      <span class="answer">{{ getReferencelibelleLongById(netMonthlyIncomesPersonal, clientProfile.professionalInfo.personalMonthlyNetIncomeCode) }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionMontlyIncomeNetHome' | labelText }}
                      <span class="answer">{{ getReferencelibelleLongById(netMonthlyIncomes, clientProfile.professionalInfo.householdMonthlyNetIncomeCode) }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.DetailsSectionHeritageOrigin' | labelText }} <span class="answer"> {{ proofOfWealthAssetsHtml }} </span>
                    </p>
                    <p>
                      {{ 'SUMMARY.AcknowledgeOrigin' | labelText }} <span class="answer">{{ proofOfFundsAssetsHtml }}</span>
                    </p>
                  </div>
                  <div class="label-answer-section">
                    <p>
                      <span class="answer"> {{ politicallyPersonText }} </span>
                    </p>
                    <p>
                      <span class="answer">{{ 'SUMMARY.DetailsSectionNationality' | labelText }} {{ getReferencelibelleLongById(nationalities, clientProfile.person.nationalityCode) }}</span>
                    </p>
                    <p>
                      <span class="answer" *ngIf="clientProfile.isFrenchTaxResident">{{ 'SUMMARY.DetailsSectionElgibilityQ1' | labelText }}</span>
                    </p>
                    <p>
                      <span class="answer" *ngIf="!clientProfile.isUsCitizen">{{ 'SUMMARY.DetailsSectionElgibilityQ2' | labelText }}</span>
                    </p>
                    <p>
                      <span class="answer" *ngIf="!clientProfile.isLegallyCapable">{{ 'SUMMARY.DetailsSectionElgibilityQ3' | labelText }}</span>
                    </p>
                    <p>
                      <span class="answer" *ngIf="isGpa || isGfd">{{ 'SUMMARY.DetailsSectionElgibilityQ3' | labelText }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToResidenceQuestions()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>

              <div class="row" *ngIf="clientProfile.proofOfFunds?.length > 0">
                <div class="col">
                  <h5 class="header mt-3">{{ 'SUMMARY.HertitageSection' | labelText }}</h5>
                </div>
              </div>

              <div class="row" *ngIf="clientProfile.proofOfFunds?.length > 0">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <div class="label-answer-section">
                    <p>{{ 'SUMMARY.HertitageSectionText' | labelText }}</p>
                    <ul>
                      <li *ngIf="surrenderLifeInsuranceHtml">
                        <p>
                          {{ 'ESTATE.OriginLifeAssurance' | labelText }} <span class="answer"> {{ surrenderLifeInsuranceHtml }} </span>
                        </p>
                      </li>
                      <li *ngIf="savingsHtml">
                        <p>
                          {{ 'ESTATE.OriginSaving' | labelText }} <span class="answer"> {{ savingsHtml }} </span>
                        </p>
                      </li>
                      <li *ngIf="successionHtml">
                        <p>
                          {{ 'ESTATE.OriginSuccession' | labelText }} <span class="answer"> {{ successionHtml }} </span>
                        </p>
                      </li>
                      <li *ngIf="estateSaleHtml">
                        <p>
                          {{ 'ESTATE.OriginRealEstate' | labelText }} <span class="answer"> {{ estateSaleHtml }} </span>
                        </p>
                      </li>
                      <li *ngIf="cashflowHtml">
                        <p>
                          {{ 'ESTATE.OriginCash' | labelText }} <span class="answer"> {{ cashflowHtml }} </span>
                        </p>
                      </li>
                      <li *ngIf="otherHtml">
                        <p>
                          {{ 'ESTATE.OriginOther' | labelText }} <span class="answer"> {{ otherHtml }} </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToFinancialSituation()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>

              <div class="row" *ngIf="isProofOfFundsFiles">
                <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                  <div class="row with-speech">
                    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                      <div class="speech">
                        <div class="row">
                          <div class="col files">
                            <span class="label"> {{ 'ESTATE.ColumnsProof' | labelText }} </span>
                            <ul>
                              <li *ngFor="let file of this.clientProfile.proofOfFunds" (click)="getProofOfFundsFile(file)">
                                <span *ngIf="file?.file?.fileName"> {{ file.file.fileName }} </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
                      <span class="icon icon-aside"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- GPA offer to display in contract -->

    <div [ngClass]="!subscribeGpaPer1Euro ? 'hide-in-web-app hide-in-pdf' : 'hide-in-web-app'">
      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.SpecialProvisionOfferText' | labelText }}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="txt-default mt-2 fw-600">
                      <span class="text-blue"> {{ 'Summary.UpsellATDNCheckbox1' | labelText }} {{ gpaContractAmountPerMonth | euro : true }} {{ 'Summary.UpsellATDNCheckbox2' | labelText }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.HeathQuestions' | labelText }}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="txt-default mt-2 fw-600">
                      <span class="text-blue">{{ 'SUMMARY.HealthQuestionsQues1' | labelText }}</span>
                    </div>
                    <div class="txt-default mt-2 fw-600">
                      <span class="text-blue">{{ 'SUMMARY.HealthQuestionsQues2' | labelText }}</span>
                    </div>
                    <div class="txt-default mt-2 fw-600">
                      <span class="text-blue">{{ 'SUMMARY.HealthQuestionsQues3' | labelText }}</span>
                    </div>
                    <div class="txt-default mt-2 fw-600">
                      <span class="text-blue">{{ 'SUMMARY.HealthQuestionsQues4' | labelText }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.MyGPAContractText' | labelText }}</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="txt-default mt-2 fw-600">
                      {{ 'SUMMARY.MyGpaContractAmountText' | labelText }} <span class="text-blue">{{ gpaContractAmount | euro : true }}</span
                      >.
                    </div>
                    <br />
                    <div class="txt-default mt-2 fw-600">
                      {{ 'SUMMARY.ForesightIncluded' | labelText }}
                      <ul class="text-blue">
                        <li>{{ 'SUMMARY.AllAssitanceServicesText' | labelText }}</li>
                        <li *ngIf="gpaSubscriptionGarantiX2x3">{{ 'SUMMARY.ProvisioningGuaranteeIncludedX2X3' | labelText }}</li>
                      </ul>
                    </div>
                    <div class="txt-default mt-2 fw-600">
                      {{ 'SUMMARY.SummaryForesightATDNContribution1' | labelText }}
                      <span class="text-blue">{{ gpaContractAmountPerMonth | euro : true }} {{ 'SUMMARY.SummaryForesightATDNContribution2' | labelText }}</span
                      >.
                    </div>
                    <div class="txt-default mt-2 fw-600">{{ 'SUMMARY.ProvisionATDNNextContributions' | labelText }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 pdf-page-break-after">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.BeneficiariesSectionText' | labelText }}</h5>
                  </div>
                </div>

                <div class="row" *ngIf="clientProfile && countries && nationalities && civilities">
                  <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="txt-default mt-2 fw-600">
                      {{ 'SUMMARY.ChooseText1' | labelText }} <span class="text-blue"> {{ 'SUMMARY.ChooseText2' | labelText }} {{ beneficiaryGpaClause }} </span>
                    </div>
                    <div class="txt-default mt-2 fw-600" [innerHTML]="beneficiaryGpaClauseText"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End: GPA offer to display in contract -->

    <div class="mt-4" *ngIf="clientProfile && isShowSEPA">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="header mt-3">{{ 'SUMMARY.MandateSection' | labelText }}</h5>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="label-answer-section">
                    <p>{{ 'SUMMARY.MandateSectionDeclaration1' | labelText }}</p>
                    <p>{{ 'SUMMARY.MandateSectionDeclaration2' | labelText }}</p>
                    <p>{{ 'SUMMARY.MandateSectionDeclaration3' | labelText }}</p>
                    <p>
                      {{ 'SUMMARY.MandateSectionDeclaration4' | labelText }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="ribDocuments">
                <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                  <div class="row with-speech">
                    <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                      <div class="speech">
                        <div class="row">
                          <div class="col files">
                            <span class="label"> {{ 'SUMMARY.MandateSectionRIB' | labelText }} </span>
                            <ul>
                              <li *ngFor="let file of ribDocuments" (click)="getFile(file)">{{ file.name }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hidden-xs col-sm-2 col-md-2 col-lg-2">
                      <span class="icon icon-aside"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                  <div class="label-answer-section">
                    <p>
                      {{ 'SUMMARY.CreditorNameTitle' | labelText }} : <span class="answer"> {{ sepaMandateConfiguration?.creditorName }} </span>
                    </p>
                    <p>
                      {{ 'SUMMARY.MandateSectionOrganismCreditorAgency' | labelText }} <span class="answer"> {{ sepaMandateConfiguration?.creditorOrganization }} </span>
                    </p>
                    <p>
                      {{ 'SUMMARY.MandateSectionCreditorId' | labelText }} <span class="answer"> {{ identifiantCreancierSEPA }} </span>
                    </p>
                  </div>

                  <div class="label-answer-section">
                    <p>
                      {{ 'SUMMARY.MandateSectionRUM' | labelText }} <span class="answer"> {{ rum }} </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                  <p class="underlined-subheader">{{ 'SUMMARY.MandateSectionPayer' | labelText }}</p>
                  <div class="label-answer-section">
                    <p>
                      {{ 'SUMMARY.MandateSectionPayerLastName' | labelText }} <span class="answer">{{ clientProfile.person.lastName }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.MandateSectionPayerFirstName' | labelText }} <span class="answer">{{ clientProfile.person.firstName }}</span>
                    </p>
                    <p>
                      {{ 'Common.Adress' | labelText }} :
                      <span class="answer dib vat">
                        {{ clientProfile.currentAddress.addressLine }}<br />
                        {{ clientProfile.currentAddress.additionalAddress }}<br />
                        {{ clientProfile.currentAddress.additionalAddress2 }}<br *ngIf="clientProfile.currentAddress.additionalAddress2" />
                        {{ clientProfile.currentAddress.locality }}<br *ngIf="clientProfile.currentAddress.locality" />
                        {{ clientProfile.currentAddress.zipCode }} {{ clientProfile.currentAddress.city }}<br />
                        {{ getCountryById(countries, clientProfile.currentAddress.country) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <p class="underlined-subheader">{{ 'BANK_DETAILS.YourBankDetails' | labelText }}</p>
                  <div class="label-answer-section">
                    <p *ngIf="this.clientProfile.iban">
                      {{ 'SUMMARY.MandateSectionIBAN' | labelText }} <span class="answer">{{ this.clientProfile.iban | iban }}</span>
                    </p>
                    <p *ngIf="this.clientProfile.bic">
                      {{ 'SUMMARY.MandateSectionBIC' | labelText }} <span class="answer"> {{ this.clientProfile.bic }} </span>
                    </p>
                    <p>
                      {{ 'SUMMARY.MandateSectionPaymentType' | labelText }} <span class="answer">{{ 'Common.RegularPayment' | labelText }}</span>
                    </p>
                    <p>
                      {{ 'SUMMARY.MandateSectionSignatureDate' | labelText }} <span class="answer"> {{ momentTz().tz('Europe/Paris').format(constants.dateInputFormat) }} </span>
                    </p>
                    <p>{{ 'SUMMARY.MandateSectionSignaturePlace' | labelText }} <span class="answer">PARIS</span></p>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                  <button type="submit" class="btn btn-blue modify-btn" href="javascript:void(0)" (click)="goToBankDetails()">{{ 'Common.Modify' | labelText }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="mifForm" *ngIf="mifForm" class="hide-in-pdf">
      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.CodePromoTitle' | labelText }}</h5>
                  </div>
                </div>

                <app-view-offer
                  [clientProfile]="clientProfileForViewOffer"
                  [pageName]="constants.pages.summary.name"
                  (onOffersItem)="onOffersItem($event)"
                  (onOffersItems)="onOffersItems($event)"
                  [manualOverrideOffer]="manualOverrideOffer"
                ></app-view-offer>

                <div class="row mb-2">
                  <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                    <app-name-with-digits-form-control [parentForm]="mifForm" [controlName]="'promoCode'" class="no-title" [title]="''"> </app-name-with-digits-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h5 class="header mt-3">{{ 'SUMMARY.FormAdvisorCodeTitle' | labelText }}</h5>
                    <p class="text-blue">{{ 'SUMMARY.FormAdvisorCode' | labelText }}</p>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                    <app-mif-advisor-code-form-control [parentForm]="mifForm" [controlName]="'mifAdvisorCode'" class="no-title" [title]="''"> </app-mif-advisor-code-form-control>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="isMifFormNotEmpty || offer" [ngClass]="isMifFormNotEmpty || offer ? 'hide-in-web-app' : 'hide-in-web-app hide-in-pdf'">
      <!-- <div *ngIf="isMifFormNotEmpry || offer"> -->
      <div class="mt-4">
        <div class="card">
          <div class="row">
            <div class="col-12 text-left">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <div class="label-answer-section">
                      <p>
                        <span [innerHtml]="getHowDidYouHearMif | safeHtml"></span>
                        <span [innerHtml]="getMifAdvisorInfo | safeHtml"></span>
                        <span *ngIf="this.offer">
                          {{ 'Summary.Offer' | labelText }} : <span class="answer">{{ this.offer?.description.membershipForm }}</span
                          >* ({{ this.offer?.promoCode }}).
                        </span>
                      </p>
                      <br />
                      <p></p>
                      <p *ngIf="offer" class="fs-12" [innerHtml]="getOfferFormatedGeneralConditionsForPdf(this.offer) | safeHtml"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row mb-5">
                <div class="col txt-default fs-14" *ngIf="isCems">
                  <div *ngIf="!subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationWithoutUpsellCEMSText' | labelText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                  <div *ngIf="subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationCEMSUpsellATDNText' | labelText : periodText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                </div>
                <div class="col txt-default fs-14" *ngIf="isCims">
                  <div *ngIf="!subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationWithoutUpsellCIMSText' | labelText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                  <div *ngIf="subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationCIMSUpsellATDNText' | labelText : periodText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                </div>
                <div class="col txt-default fs-14" *ngIf="isPeri">
                  <div *ngIf="!subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationWithoutUpsellPeriText' | labelText : periodText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                  <div *ngIf="subscribeGpaPer1Euro">
                    {{ 'SUMMARY.SavingValidationPERIUpsellATDNText' | labelText : periodText }}
                    <br />
                    {{ 'SUMMARY.ValidationDeclarationOnHonor' | labelText }}
                  </div>
                </div>
                <div class="col txt-default fs-14" *ngIf="isGpa">
                  {{ 'SUMMARY.ProvisioningValidationATDNText1' | labelText }}
                  <br />
                  {{ 'SUMMARY.ProvisioningValidationATDNText2' | labelText }}
                </div>
                <div class="col txt-default fs-14" *ngIf="isGfd">
                  {{ 'SUMMARY.ProvisioningValidationGFDText1' | labelText }}
                  <br />
                  {{ 'SUMMARY.ProvisioningValidationGFDText2' | labelText }}
                </div>
              </div>

              <div class="row" *ngIf="(isCems || isCims) && clientProfile && clientProfile.transferContractCode">
                <div class="col">
                  <mat-checkbox [(ngModel)]="finalAgreeContract" class="big-text" *ngIf="finalAgreeDocsContract && finalAgreeDocsContract.length > 0">
                    <span class="fs-14">
                      {{ 'SUMMARY.ValidationAcceptConditionsTransfert1' | labelText }}
                      <span (click)="finalAgreeContractFileClick()" class="link-like"> {{ 'SUMMARY.ValidationAcceptConditionsTransfert2' | labelText }} </span>
                      {{ 'SUMMARY.ValidationAcceptConditionsTransfert3' | labelText }}
                    </span>
                  </mat-checkbox>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col">
                  <mat-checkbox [(ngModel)]="finalAgree" class="big-text" *ngIf="finalAgreeDocs && finalAgreeDocs.length > 0">
                    <span>
                      <span class="fs-14">
                        {{ 'SUMMARY.FinalAgreeStartText' | labelText }}
                        <span *ngFor="let doc of finalAgreeDocs; let i = index">
                          <span *ngIf="finalAgreeDocs[i].libelleArticle"> {{ finalAgreeDocs[i].libelleArticle }} </span>
                          <span (click)="finalAgreeFileClick(i, $event)" class="link-like"
                            >{{ finalAgreeDocs[i].libelle }}<span *ngIf="finalAgreeDocs[i].nomenclature"> (réf. {{ finalAgreeDocs[i].nomenclature }})</span></span
                          >
                          <span *ngIf="finalAgreeDocs[i].libelleSuffixe"> {{ finalAgreeDocs[i].libelleSuffixe }}</span
                          ><span *ngIf="i < finalAgreeDocs?.length - 1">,</span><span *ngIf="i === finalAgreeDocs?.length - 1">.</span>
                        </span>
                        <br />
                        <span *ngIf="(subscribeGpaPer1Euro && subscribeGpaPer1EuroDocsLoaded) || isGpa || isGfd" class="fs-14">
                          {{ 'SUMMARY.ValidationAcceptRulesHealthQuestion' | labelText }} <br />
                        </span>
                        <span *ngIf="isGpa" class="fs-14">
                          {{ 'SUMMARY.ValidationATDNAcceptRulesNext' | labelText }}
                        </span>

                        <span *ngIf="isGfd" class="fs-14">
                          {{ 'SUMMARY.ValidationGFDAcceptRulesNext' | labelText }}
                        </span>
                      </span>
                    </span>

                    <span *ngIf="isCems || isPeri || isCims" class="fs-14"> {{ 'SUMMARY.FinalAgreeExtraText1' | labelText }} <br /> </span>

                    <span *ngIf="isCems || isPeri || isCims" class="fs-15 fw-700 text-black">
                      <br />
                      {{ 'SUMMARY.FinalAgreeExtraText2' | labelText }} <br />
                    </span>
                  </mat-checkbox>
                </div>
              </div>

              <div class="row text-center mt-4 hide-in-pdf">
                <div class="col">
                  <div class="valider-button-block">
                    <button *ngIf="isPeri" class="btn btn-blue valider" [disabled]="isSubmitDisabled || mifForm?.invalid" (click)="goToPhoneConfirmation()">
                      {{ 'SUMMARY.FinalAgreeIEndingMembership' | labelText }} <br />
                      {{ 'SUMMARY.SubmitText2' | labelText }}
                    </button>
                    <button *ngIf="!isPeri" class="btn btn-blue valider" [disabled]="isSubmitDisabled || mifForm?.invalid" (click)="goToPhoneConfirmation()">
                      {{ 'SUMMARY.SubmitText1' | labelText }} <br />
                      {{ 'SUMMARY.SubmitText2' | labelText }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row hide-in-pdf" *ngIf="isSpecialGpaOfferAvailable">
      <div class="col">
        <p class="recap-upsell-conditions">
          {{ summaryGpaOfferText }}
        </p>
      </div>
    </div>

    <div class="mt-4 hide-in-web-app">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col txt-default fs-14">
                  {{ 'SUMMARY.ComplementPDF' | labelText }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 hide-in-web-app">
      <div class="card signature">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="header mt-3">{{ 'SUMMARY.SignatureSection' | labelText }}</h5>
                </div>
              </div>

              <div class="row">
                <input type="text" name="SignatureContractant" id="SignatureContractant" class="signature" />
                <input type="text" name="TamponContralia" id="TamponContralia" class="signature" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
