import { Pipe, PipeTransform } from '@angular/core';
import { roundValue } from '@extensions/extensions';

@Pipe({
  name: 'roundTo2Decimal'
})
export class RoundTo2DecimalPipe implements PipeTransform {

  transform(value: number): number {
    if (!value && value !== 0) {
      return null;
    }

    return roundValue(value, 2);
  }
}
