<div class="my-profile-steps mb-5">
  <div class="text-center mt-5 mb-5" *ngIf="activeThemeNumber === 1">
    <h5>
      {{ 'QUESTIONNAIRE.Title1' | labelText }}
    </h5>
    <h5 *ngIf="isPeri">
      {{ 'QUESTIONNAIRE.PeriTitle1' | labelText }}
    </h5>
    <span>{{ 'QUESTIONNAIRE.Title2' | labelText }}</span>
  </div>

  <div class="steps-container mt-3">
    <div class="steps" [class.summary]="summary || showRecommendationState" [class.hidden]="!dataLoaded">
      <ol class="list-unstyled">
        <li *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" (click)="goToQuestion(number)" [ngClass]="{ 'opacity-of-pseudo': maxAnsweredThemeNumber + 2 > number }">
          <p class="step-number" [ngClass]="{ opacity: activeThemeNumber == number || maxAnsweredThemeNumber + 2 > number }">{{ number }}</p>
          <span class="icon" [ngClass]="{ 'icon-active': activeThemeNumber == number, 'icon-answered': maxAnsweredThemeNumber + 2 > number }"></span>
        </li>
      </ol>
      <span><img src="../../../assets/img/flag.svg" alt="" /></span>
    </div>

    <div>
      <div *ngIf="activeThemeNumber !== 1" class="prev-question mb-3" (click)="stepBack()"><img src="../../../assets/img/arrow_down.svg" alt="" /></div>
      <div class="summary-top-block" *ngIf="summary" [style.background-color]="getProfileColor()">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h1>
              {{ 'RECOMMENDATION.ProfileTitle' | labelText }}
              <strong>
                {{ recommandation.profil.libelle }}
              </strong>
            </h1>
            <p class="mt-3">
              {{ recommandation.profil.description }}
            </p>
          </div>
        </div>
      </div>
      <form [formGroup]="profileQuestionsForm" (keydown.enter)="$event.preventDefault()">
        <div class="card">
          <div class="card-body">
            <div [class.hidden]="showRecommendationState" class="warning" *ngIf="activeTheme?.ordre === 1">{{ 'RECOMMENDATION.ImportantStep' | labelText }}</div>
            <div [class.hidden]="showRecommendationState" class="warning" *ngIf="activeTheme?.ordre === 4">
              {{ 'RECOMMENDATION.FiscallyAttachedInfo' | labelText }}
            </div>
            <div [class.hidden]="showRecommendationState" class="warning" *ngIf="activeTheme?.ordre === 5">
              {{ 'RECOMMENDATION.OverallSavingCapacityInfo' | labelText }}
            </div>
            <div [class.hidden]="showRecommendationState" class="warning question7" *ngIf="activeTheme?.ordre === 7">{{ 'RECOMMENDATION.IndicativeValuesInfo' | labelText }}</div>

            <div *ngIf="!summary" class="row" [class.hidden]="showRecommendationState">
              <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10 centered-by-margin">
                <div class="main-question">
                  <div *ngIf="activeThemeNumber !== 1" class="prev-question mobile" (click)="stepBack()"><img src="../../../assets/img/arrow_down.svg" alt="" /></div>
                  <strong>{{ activeTheme?.ordre }}</strong>
                  <div [class.hidden]="showRecommendationState" class="next-question mobile" *ngIf="activeThemeNumber <= maxAnsweredThemeNumber && activeThemeNumber < 12" (click)="stepForward()">
                    <img src="../../../assets/img/arrow_down.svg" alt="" />
                  </div>

                  <div class="row" *ngIf="activeTheme?.ordre === 10 || activeTheme?.ordre === 11 || activeTheme?.ordre === 12">
                    <div class="col-md-12">
                      <p class="question mb-0" *ngIf="activeTheme?.ordre === 12" [innerHtml]="activeQuestionText"></p>
                      <p class="question" [innerHtml]="activeQuestionText2"></p>
                    </div>
                  </div>

                  <div class="row" *ngIf="activeTheme?.ordre !== 10 && activeTheme?.ordre !== 11 && activeTheme?.ordre !== 12">
                    <div class="col-xs-12 col-sm-8 col-md-6 col-lg-8 centered-by-margin">
                      <p class="question" [innerHtml]="activeQuestionText"></p>
                    </div>
                  </div>

                  <div class="last-question-block" *ngIf="activeTheme?.ordre === 11">
                    <!-- ROW 1 -->

                    <div class="row pb-2 pt-2 border-bottom-dashed">
                      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 dt">
                        <div class="label-like question-title">
                          {{ activeTheme?.questions[0].libelle }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 dt">
                        <div class="answers-block">
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 1, 0)"
                            class="btn btn-primary radio-label mr-2"
                            [ngClass]="{ 'active-btn': moneyMarket == true }"
                            (click)="moneyMarketFundsChange(true)"
                          >
                            {{ 'Common.Yes' | labelText }}
                          </button>
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 1, 1)"
                            class="btn btn-primary ml-1 radio-label"
                            [ngClass]="{ 'active-btn': moneyMarket == false }"
                            (click)="moneyMarketFundsChange(false)"
                          >
                            {{ 'Common.No' | labelText }}
                            <span class="check-mark" *ngIf="moneyMarket == false || moneyMarket == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation inline" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[0].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'COMMON.Continue' | labelText }}
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[0].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- ROW 2 -->

                    <div class="row pb-2 pt-2 border-bottom-dashed">
                      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 dt">
                        <div class="label-like question-title">
                          {{ activeTheme?.questions[1].libelle }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 dt">
                        <div class="answers-block">
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 2, 0)"
                            class="btn btn-primary radio-label mr-2"
                            [ngClass]="{ 'active-btn': bounds == true }"
                            (click)="boundsChanged(true)"
                          >
                            {{ 'Common.Yes' | labelText }}
                          </button>
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 2, 1)"
                            class="btn btn-primary ml-1 radio-label"
                            [ngClass]="{ 'active-btn': bounds == false }"
                            (click)="boundsChanged(false)"
                          >
                            {{ 'Common.No' | labelText }}
                            <span class="check-mark" *ngIf="bounds == false || bounds == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation inline" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[1].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[1].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- ROW 3 -->

                    <div class="row pb-2 pt-2 border-bottom-dashed">
                      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 dt">
                        <div class="label-like question-title">
                          {{ activeTheme?.questions[2].libelle }}
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 dt">
                        <div class="answers-block">
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 3, 0)"
                            class="btn btn-primary radio-label mr-2"
                            [ngClass]="{ 'active-btn': derivatives == true }"
                            (click)="derivativesChanged(true)"
                          >
                            {{ 'Common.Yes' | labelText }}
                          </button>
                          <button
                            (click)="onAnswerOnSpecificTheme(activeTheme, 3, 1)"
                            class="btn btn-primary ml-1 radio-label"
                            [ngClass]="{ 'active-btn': derivatives == false }"
                            (click)="derivativesChanged(false)"
                          >
                            {{ 'Common.No' | labelText }}
                            <span class="check-mark" *ngIf="derivatives == false || derivatives == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation inline" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[2].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[2].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2" *ngIf="incoherentAnswersIndexesForCurrentTheme?.length === 1">
                      <div class="col-12">
                        <span class="text-red"> {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }} {{ conditionQuestionThemeIndexes.sort().join(', ') }} </span>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="incoherentAnswersIndexesForCurrentTheme?.length > 1">
                      <div class="col-12">
                        <span class="text-red"> Votre réponse est en contradiction avec celles que vous avez données aux questions {{ conditionQuestionThemeIndexes.sort().join(', ') }} </span>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="activeTheme?.ordre === 10" class="questions-with-acceptance pt-2">
                    <div class="form-speech-radio">
                      <div class="row only-title">
                        <div class="hidden-xs hidden-sm col-md-4 col-lg-4 col-desktop-same-height centered-by-margin">
                          <div class="label-like">
                            {{ theme10[0].acceptance }}
                          </div>
                        </div>
                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>
                        <div class="hidden-xs hidden-sm col-md-4 col-lg-4">
                          <div class="label-like">
                            {{ theme10[0].disacceptance }}
                          </div>
                        </div>
                      </div>

                      <!-- ROW 1 -->

                      <div class="row border-bottom-dashed">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                          <div class="speech question-title">
                            <p>
                              <b>{{ theme10[1] }}</b>
                            </p>
                          </div>
                        </div>
                        <div class="col col-md-6 fs-13 mb-2 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].acceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 1, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn1 == true }"
                              (click)="activeBtn1Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 1, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn1 == false }"
                              (click)="activeBtn1Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn1 == false || activeBtn1 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[0].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[0].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>

                        <div class="col col-md-6 mb-2 mt-2 fs-13 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].disacceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 2, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn2 == true }"
                              (click)="activeBtn2Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 2, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn2 == false }"
                              (click)="activeBtn2Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn2 == false || activeBtn2 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[1].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[1].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- ROW 2 -->

                      <div class="row border-bottom-dashed">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                          <div class="speech question-title">
                            <p>
                              <b>{{ theme10[2] }}</b>
                              <app-tooltip [text]="'Questionnaire.Theme10Tooltip1Text' | labelText"> </app-tooltip>
                            </p>
                          </div>
                        </div>
                        <div class="col col-md-6 fs-13 mb-2 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].acceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 3, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn3 == true }"
                              (click)="activeBtn3Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 3, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn3 == false }"
                              (click)="activeBtn3Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn3 == false || activeBtn3 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[2].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[2].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>

                        <div class="col col-md-6 mb-2 mt-2 fs-13 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].disacceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 4, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn4 == true }"
                              (click)="activeBtn4Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 4, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn4 == false }"
                              (click)="activeBtn4Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn4 == false || activeBtn4 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[3].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[3].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- ROW 3 -->

                      <div class="row border-bottom-dashed">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                          <div class="speech question-title">
                            <p>
                              <b>{{ theme10[3] }}</b>
                              <app-tooltip [text]="'Questionnaire.Theme10Tooltip2Text' | labelText"> </app-tooltip>
                            </p>
                          </div>
                        </div>
                        <div class="col col-md-6 fs-13 mb-2 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].acceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 5, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn5 == true }"
                              (click)="activeBtn5Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 5, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn5 == false }"
                              (click)="activeBtn5Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn5 == false || activeBtn5 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[4].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[4].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>

                        <div class="col col-md-6 mb-2 mt-2 fs-13 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].disacceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 6, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn6 == true }"
                              (click)="activeBtn6Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 6, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn6 == false }"
                              (click)="activeBtn6Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn6 == false || activeBtn6 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[5].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[5].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- ROW 4 -->

                      <div class="row border-bottom-dashed">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                          <div class="speech question-title">
                            <p>
                              <b>{{ theme10[4] }}</b>
                              <app-tooltip [text]="'Questionnaire.Theme10Tooltip3Text' | labelText"> </app-tooltip>
                            </p>
                          </div>
                        </div>
                        <div class="col col-md-6 fs-13 mb-2 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].acceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 7, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn7 == true }"
                              (click)="activeBtn7Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 7, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn7 == false }"
                              (click)="activeBtn7Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn7 == false || activeBtn7 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[6].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[6].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>

                        <div class="col col-md-6 mb-2 mt-2 fs-13 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].disacceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 8, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn8 == true }"
                              (click)="activeBtn8Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 8, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn8 == false }"
                              (click)="activeBtn8Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn8 == false || activeBtn8 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[7].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[7].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- ROW 5 -->

                      <div class="row border-bottom-dashed">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                          <div class="speech question-title">
                            <p>
                              <b>{{ theme10[5] }}</b>
                              <app-tooltip [text]="'Questionnaire.Theme10Tooltip4Text' | labelText"> </app-tooltip>
                            </p>
                          </div>
                        </div>
                        <div class="col col-md-6 fs-13 mb-2 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].acceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 9, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn9 == true }"
                              (click)="activeBtn9Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 9, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn9 == false }"
                              (click)="activeBtn9Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn9 == false || activeBtn9 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[8].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[8].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div class="hidden-xs hidden-sm col-md-1 col-lg-1">
                          <div class="border-dashed"></div>
                        </div>

                        <div class="col col-md-6 mb-2 mt-2 fs-13 visible-xs visible-sm label-like text-center">
                          {{ theme10[0].disacceptance }}
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 dt">
                          <div class="answers-block specific">
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 10, 0)"
                              class="btn btn-primary radio-label mr-2"
                              [ngClass]="{ 'active-btn': activeBtn10 == true }"
                              (click)="activeBtn10Clicked(true)"
                            >
                              {{ 'Common.Yes' | labelText }}
                            </button>
                            <button
                              (click)="onAnswerOnSpecificTheme(activeTheme, 10, 1)"
                              class="btn btn-primary ml-1 radio-label"
                              [ngClass]="{ 'active-btn': activeBtn10 == false }"
                              (click)="activeBtn10Clicked(false)"
                            >
                              {{ 'Common.No' | labelText }}
                            </button>
                            <span class="check-mark question hideIn1259" *ngIf="activeBtn10 == false || activeBtn10 == true"><fa-icon [icon]="faCheck"></fa-icon></span>
                            <span class="check-mark exclamation" *ngIf="isIncoherentAnswerForSpecTheme(activeTheme?.questions[9].identifiant)">
                              <fa-icon [icon]="faExclamation"></fa-icon>
                              <div class="error-block question-error-block">
                                <span class="text">
                                  {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }}
                                  {{ getConditionQuestionThemeIndexesSpecificTheme(activeTheme?.questions[9].identifiant) }}
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="incoherentAnswersIndexesForCurrentTheme?.length === 1">
                      <div class="col-12">
                        <span class="text-red"> {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }} {{ conditionQuestionThemeIndexes.sort().join(', ') }} </span>
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="incoherentAnswersIndexesForCurrentTheme?.length > 1">
                      <div class="col-12">
                        <span class="text-red"> Votre réponse est en contradiction avec celles que vous avez données aux questions {{ conditionQuestionThemeIndexes.sort().join(', ') }} </span>
                      </div>
                    </div>

                    <div class="col-12 text-center mt-4 centered-by-margin">
                      <button type="button" class="btn btn-blue valider" [disabled]="isTheme10NotAnswered" (click)="stepForward()">
                        {{ 'Common.Validate' | labelText }}
                      </button>
                    </div>
                  </div>

                  <div class="questions-with-descr" *ngIf="activeTheme?.ordre === 7">
                    <div class="question-block" *ngFor="let answer of theme7; let i = index">
                      <div class="row">
                        <div class="col-md-8">
                          <p class="description">{{ answer?.description }}</p>
                          <p class="perspective">{{ answer?.perspective }}</p>
                        </div>
                        <div class="col-md-4 question-buttons text-center">
                          <button
                            class="btn btn-primary radio-label"
                            type="button"
                            [ngClass]="{ 'active-btn': activeAnswerIndex == i, 'list-group-item': true }"
                            (click)="answerChanged(activeTheme, i, answer.identifiant)"
                          >
                            {{ 'QUESTIONNAIRE.SelectionButton' | labelText }}
                            <span class="check-mark" *ngIf="activeAnswerIndex == i"><fa-icon [icon]="faCheck"></fa-icon></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="questions"
                    [ngClass]="{ 'small-btns-question': activeTheme?.ordre === 8 || activeTheme?.ordre === 12 }"
                    *ngFor="let answer of activeTheme?.questions[0].reponses; let i = index"
                  >
                    <div *ngIf="activeTheme?.ordre === 8">
                      <button
                        class="btn btn-primary radio-label small-btns"
                        type="button"
                        [ngClass]="{ 'active-btn': activeAnswerIndex == i, 'list-group-item': true }"
                        (click)="answerChanged(activeTheme, i, answer.identifiant)"
                      >
                        {{ answer.libelle }}
                        <span class="check-mark" *ngIf="activeAnswerIndex && activeAnswerIndex !== 20"><fa-icon [icon]="faCheck"></fa-icon></span>
                      </button>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 centered-by-margin">
                        <div *ngIf="activeTheme?.ordre !== 11 && activeTheme?.ordre !== 10 && activeTheme?.ordre !== 7 && activeTheme?.ordre !== 8">
                          <div class="buttons-block">
                            <button
                              class="btn btn-primary radio-label mr-2"
                              type="button"
                              [ngClass]="{ 'active-btn': activeAnswerIndex == i, 'list-group-item': true }"
                              (click)="answerChanged(activeTheme, i, answer.identifiant)"
                            >
                              {{ answer.libelle }}
                              <span class="check-mark" *ngIf="activeAnswerIndex == i"><fa-icon [icon]="faCheck"></fa-icon></span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 question-error-wrapper" *ngIf="activeTheme?.ordre === 3 && activeAnswerIndex == 0 && i == 0">
                        <div class="error-block question-error-block">
                          <span class="text">
                            {{ 'QUESTIONNAIRE.QuestionnaireInfo' | labelText }} <span class="fw-600">{{ 'QUESTIONNAIRE.PhoneNumber' | labelText }}</span>
                          </span>
                        </div>
                      </div>
                      <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-4 question-error-wrapper"
                        *ngIf="isIncoherentAnswerByIndex(activeTheme?.questions[0].identifiant, i) && activeTheme?.ordre !== 10 && activeTheme?.ordre !== 11"
                      >
                        <div class="error-block question-error-block">
                          <span class="text"> {{ 'Questionnaire.AnswerContradictionErrorText' | labelText }} {{ conditionQuestionThemeIndexes.sort().join(', ') }} </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isQuestion12AnsweredYes && activeTheme?.ordre === 12">
                    <div [innerHTML]="question12YesAnswerExtraText"></div>
                  </div>
                </div>
              </div>
            </div>

            <app-profile-summary
              [mandatGestion]="mandatGestion"
              [recommandation]="recommandation"
              [questions]="questions"
              [clientProfile]="clientProfile"
              [transferableContracts]="transferableContracts"
              *ngIf="summary"
            ></app-profile-summary>
          </div>
        </div>

        <div [class.hidden]="showRecommendationState" class="row mt-3" *ngIf="(this.isIncoherentAnswersForCurrentTheme && !summary && activeTheme?.ordre !== 10) || this.activeThemeNumber === 3">
          <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
            <button type="button" class="btn btn-blue valider" (click)="stepForward()" [disabled]="activeAnswerIndex === null">{{ 'Common.Validate' | labelText }}</button>
          </div>
        </div>

        <div [class.hidden]="showRecommendationState" class="row mt-3" *ngIf="activeTheme?.ordre == 11">
          <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
            <button type="button" class="btn btn-blue valider" (click)="stepForward()" [disabled]="isTheme11NotAnswered">{{ 'Common.Validate' | labelText }}</button>
          </div>
        </div>

        <div [class.hidden]="showRecommendationState" class="row mt-3" *ngIf="activeTheme?.ordre === 12 && !summary">
          <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
            <button (click)="goToSummary()" [disabled]="isGoToSummaryDisabled" class="btn btn-blue valider">{{ 'QUESTIONNAIRE.FinalSubmit' | labelText }}</button>
          </div>
        </div>
      </form>
      <div [class.hidden]="showRecommendationState" class="next-question mt-3" *ngIf="activeThemeNumber <= maxAnsweredThemeNumber && activeThemeNumber < 12" (click)="stepForward()">
        <img src="../../../assets/img/arrow_down.svg" alt="" />
      </div>
    </div>

    <div *ngIf="activeTheme?.ordre === 8" style="height: 5rem"></div>
  </div>
</div>

<app-kameleoon></app-kameleoon>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 1" [pageType]="ProfileStepName.MyProfileSteps1" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 2" [pageType]="ProfileStepName.MyProfileSteps2" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 3" [pageType]="ProfileStepName.MyProfileSteps3" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 4" [pageType]="ProfileStepName.MyProfileSteps4" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 5" [pageType]="ProfileStepName.MyProfileSteps5" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 6" [pageType]="ProfileStepName.MyProfileSteps6" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 7" [pageType]="ProfileStepName.MyProfileSteps7" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 8" [pageType]="ProfileStepName.MyProfileSteps8" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 9" [pageType]="ProfileStepName.MyProfileSteps9" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 10" [pageType]="ProfileStepName.MyProfileSteps10" [clientProfile]="clientProfile"></app-i-advize>
<app-i-advize *ngIf="clientProfile && activeTheme?.ordre === 11 && !summary" [pageType]="ProfileStepName.MyProfileSteps11" [clientProfile]="clientProfile"></app-i-advize>
