import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeAllNoEvents]'
})
export class CapitalizeAllNoEventsDirective {
  constructor(private elementRef: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const capitalizedValue = input.value?.allToUpperCase();

    this.elementRef.nativeElement.value = capitalizedValue;
  }
}
