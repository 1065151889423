<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyProfileStepSummary" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div>
  <app-view-offer [clientProfile]="clientProfileForViewOffer" [pageName]="constants.pages.recommendation.name" (onOffersCountChanged)="onOffersCountChanged($event)"></app-view-offer>

  <div class="row">
    <div class="col-md-12">
      <div class="summary-card" [class.only-one]="isOneMandat">
        <div class="row top-header">
          <div class="col-xs-12 col-sm-12 col-md-12" [ngClass]="offersCount > 0 ? 'col-lg-8' : 'col-lg-12'">
            <h2 [innerHTML]="'RECOMMENDATION.DurationPickerRecommandation' | labelText"></h2>
            <p>
              {{ recommandation.libelle }}
            </p>
            <h2 *ngIf="(!isPeri && !isSecurityProfileRecommended) || (isPeri && !isSecurityProfileRecommended)">
              <span *ngIf="!isPeri"> {{ 'Recommendation.ChoiceManageIntro' | labelText }} </span>
              <span *ngIf="isPeri"> {{ 'Recommendation.PeriChoiceManageIntro' | labelText }} </span>
            </h2>
          </div>
        </div>

        <div *ngIf="!isSecurityProfileRecommended || !isPeri">
          <div class="row tabs-block">
            <div class="tab" *ngIf="gestionHorizon" (click)="changeTab(constants.gestionHorizon.name)" [ngClass]="{ 'active-tab': activeGestionHorizonTab }">
              <h4><img *ngIf="activeGestionHorizonTab" src="assets/img/check.svg" alt="" />{{ 'Recommendation.FundChartTitleHorizon' | labelText }}</h4>
              <p class="mb-3 mt-2">{{ 'Recommendation.FundChartTitleSubtitleHorizon' | labelText }}</p>
            </div>

            <div class="tab" *ngIf="gestionSousMandat" (click)="changeTab(constants.gestionSousMandat.name)" [ngClass]="{ 'active-tab': activeGestionSousMandatTab }">
              <h4><img *ngIf="activeGestionSousMandatTab" src="assets/img/check.svg" alt="" />{{ 'RECOMMENDATION.FundChartTitleManage' | labelText }}</h4>
              <p class="mb-3 mt-2">{{ 'RECOMMENDATION.FundChartSubtitleManage' | labelText }}</p>
            </div>

            <div class="tab" *ngIf="gestionLibre" (click)="changeTab(constants.gestionLibre.name)" [ngClass]="{ 'active-tab': activeGestionLibreTab }">
              <h4><img *ngIf="activeGestionLibreTab" src="assets/img/check.svg" alt="" />{{ 'RECOMMENDATION.FundChartTitleFree' | labelText }}</h4>
              <p>{{ 'RECOMMENDATION.FundChartSubtitleFree' | labelText }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 centered-by-margin">
              <!-- ----------------------------------------- active Gestion Horizon Tab ----------------------------------------- -->
              <div class="tab-content pb-5" *ngIf="activeGestionHorizonTab">
                <p class="info-p"><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon> {{ 'Recommendation.FundChartPaymentMinimum' | labelText }}</p>
                <p class="text-left pl-3">{{ 'Recommendation.FundChartHorizonIntro' | labelText }}</p>
                <ul>
                  <li>
                    {{ 'Recommendation.FundChartHorizonText1' | labelText }}
                  </li>
                  <li>
                    {{ 'Recommendation.FundChartHorizonText2' | labelText }}
                  </li>
                  <li>{{ 'RECOMMENDATION.FundChartUnderMandateIntroThird' | labelText }}</li>
                </ul>
                <h4>{{ 'RECOMMENDATION.FundChartDistributionLabel' | labelText }} {{ recommandation.profil.libelle }}</h4>
                <div class="mb-5 plan-buttons">
                  <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isPrudente }" (click)="switchIfPrudente()">
                    {{ 'RECOMMENDATION.PrudentText' | labelText }}
                  </button>
                  <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isEquilibree }" (click)="switchIfEquilibree()">
                    {{ 'RECOMMENDATION.BalanceText' | labelText }}
                  </button>
                  <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isDynamique }" (click)="switchIfDynamique()">
                    {{ 'RECOMMENDATION.DynamicText' | labelText }}
                  </button>
                </div>

                <!-- peri pie-chart -->
                <div class="row text-left">
                  <div class="col-md-5">
                    <div class="chart-wrapper">
                      <canvas
                        *ngIf="chartPercentages"
                        baseChart
                        [legend]="false"
                        [options]="chartsOptions"
                        width="10rem"
                        height="10rem"
                        [data]="chartPercentages"
                        [labels]="chartLabels"
                        [colors]="chartColors"
                        [chartType]="'doughnut'"
                      >
                      </canvas>
                      <br />
                      <p class="text-center">
                        <app-tooltip
                          *ngIf="isPrudente"
                          [extraTitle]="'Recommendation.FundChartVisualizeText' | labelText"
                          [extraTitleClass]="'link-like'"
                          [text]="prudenteTooltipText"
                          [tooltipPosition]="'right'"
                          [class]="'peri-charts'"
                          [noIcon]="true"
                        >
                        </app-tooltip>
                        <app-tooltip
                          *ngIf="isEquilibree"
                          [extraTitle]="'Recommendation.FundChartVisualizeText' | labelText"
                          [extraTitleClass]="'link-like'"
                          [text]="equilibreeTooltipText"
                          [tooltipPosition]="'right'"
                          [class]="'peri-charts'"
                          [noIcon]="true"
                        >
                        </app-tooltip>
                        <app-tooltip
                          *ngIf="isDynamique"
                          [extraTitle]="'Recommendation.FundChartVisualizeText' | labelText"
                          [extraTitleClass]="'link-like'"
                          [text]="dynamiqueTooltipText"
                          [tooltipPosition]="'right'"
                          [class]="'peri-charts'"
                          [noIcon]="true"
                        >
                        </app-tooltip>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div>
                      <app-view-performance
                        [onTabChanged]="onTabChanged"
                        [isQuestion12AnsweredYes]="isQuestion12AnsweredYes"
                        [productType]="productType"
                        [type]="managementTypeCode"
                        [profile]="profileTypeCode"
                      ></app-view-performance>
                    </div>

                    <div *ngIf="!sereniteFondsExpanded && !fondsExpanded">
                      <p class="fond-item"><span></span> {{ GHSereniteTitle }}</p>
                      <p class="mb-0 pb-0">{{ GHSereniteLabel }}</p>
                      <a href="javascript:void(0)" (click)="expandSereniteFonds()">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                      <p class="fond-item"><span [style.background-color]="profileColor"></span> {{ GHPerformanceTitle }}</p>
                      <p class="mb-0 pb-0">{{ GHPerformanceLabel }}</p>
                      <!--todo: expand Performance Fonds -->
                      <a href="javascript:void(0)" (click)="expandFonds()">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                    </div>

                    <div *ngIf="sereniteFondsExpanded">
                      <div>
                        <span *ngFor="let sereniteFond of expandedSereniteFonds">
                          <p class="fond-item"><span [style.background-color]="getExpandedFondsColor(sereniteFond)"></span> {{ sereniteFond.title }}</p>
                          <!-- For the time being, MIF requested not to display the funds' description for the serenity pocket -->
                          <!-- <p class="mb-0 pb-0">{{ sereniteFond.description }}</p> -->
                          <a href="javascript:void(0)" (click)="getMandatManualByFondItem(sereniteFond)">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                        </span>
                        <span class="collapse-link-block">
                          <a href="javascript:void(0)" (click)="collapseSereniteFonds()"> {{ 'Recommendation.FundChartHorizonCollapse' | labelText }} </a>
                        </span>
                      </div>
                    </div>

                    <div *ngIf="fondsExpanded">
                      <div>
                        <span *ngFor="let item of expandedFonds">
                          <p class="fond-item"><span [style.background-color]="getExpandedFondsColor(item)"></span> {{ item.title }}</p>
                          <p class="mb-0 pb-0">{{ item.description }}</p>
                          <a href="javascript:void(0)" (click)="getMandatManualByFondItem(item)">{{ 'RECOMMENDATION.DetailFondLink' | labelText }}</a>
                        </span>
                        <span class="collapse-link-block">
                          <a href="javascript:void(0)" (click)="collapseFonds()">{{ 'Recommendation.FundChartHorizonCollapse2' | labelText }} </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ----------------------------------------- active Gestion Sous Mandat Tab ----------------------------------------- -->
              <div class="tab-content pb-5" *ngIf="activeGestionSousMandatTab">
                <p class="info-p"><fa-icon [icon]="faInfoCircle" class="mr-2"></fa-icon> {{ 'Recommendation.FundChartPaymentMinimum' | labelText }}</p>
                <p *ngIf="!isPeri" class="text-left pl-3">{{ 'Recommendation.FundChartUnderMandateIntro' | labelText }}</p>
                <p *ngIf="isPeri" class="text-left pl-3">{{ 'Recommendation.FundChartUnderMandateIntroPeri' | labelText }}</p>
                <ul>
                  <li>
                    {{ 'RECOMMENDATION.FundChartUnderMandateIntroFirst' | labelText }}
                  </li>
                  <li>
                    {{ 'RECOMMENDATION.FundChartUnderMandateIntroSecond' | labelText }}
                  </li>
                  <li>{{ 'RECOMMENDATION.FundChartUnderMandateIntroThird' | labelText }}</li>
                </ul>
                <h4>{{ 'RECOMMENDATION.FundChartDistributionLabel' | labelText }} {{ recommandation.profil.libelle }}</h4>
                <div class="mb-5 plan-buttons">
                  <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isPrudente }" (click)="switchIfPrudente()">
                    {{ 'RECOMMENDATION.PrudentText' | labelText }}
                  </button>
                  <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isEquilibree }" (click)="switchIfEquilibree()">
                    {{ 'RECOMMENDATION.BalanceText' | labelText }}
                  </button>
                  <button *ngIf="!isCims" class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': isDynamique }" (click)="switchIfDynamique()">
                    {{ 'RECOMMENDATION.DynamicText' | labelText }}
                  </button>
                  <button *ngIf="!isPeri && !isCims" class="btn btn-primary radio-label" type="button" [ngClass]="{ 'active-btn': isOffensive }" (click)="switchIfOffensive()">
                    {{ 'RECOMMENDATION.OffensiveText' | labelText }}
                  </button>
                </div>
                <!-- cems pie-chart -->
                <div class="row text-left">
                  <div class="col-md-5">
                    <div class="chart-wrapper">
                      <canvas
                        *ngIf="chartPercentages"
                        baseChart
                        [legend]="false"
                        [options]="chartsOptions"
                        width="10rem"
                        height="10rem"
                        [data]="chartPercentages"
                        [labels]="chartLabels"
                        [colors]="chartColors"
                        [chartType]="'doughnut'"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <div>
                      <app-view-performance
                        [onTabChanged]="onTabChanged"
                        [isQuestion12AnsweredYes]="isQuestion12AnsweredYes"
                        [productType]="productType"
                        [type]="managementTypeCode"
                        [profile]="profileTypeCode"
                      ></app-view-performance>
                    </div>

                    <div *ngIf="!fondsExpanded">
                      <p class="fond-item"><span></span> {{ GSMEuroTitle }}</p>
                      <p class="mb-0 pb-0">{{ GSMEuroLabel }}</p>
                      <a href="javascript:void(0)" (click)="getMandatManualById(1)">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                      <p class="fond-item"><span [style.background-color]="profileColor"></span> {{ GSMUCDistribution }}{{ 'Recommendation.PercentageInvestedOnUnitsText' | labelText }}</p>
                      <p class="mb-0 pb-0">{{ 'Recommendation.FundChartDistributionUnderMandateUC' | labelText }}</p>
                      <a href="javascript:void(0)" (click)="expandFonds()">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                    </div>

                    <div *ngIf="fondsExpanded">
                      <div>
                        <span *ngFor="let item of expandedFonds">
                          <p class="fond-item"><span [style.background-color]="getExpandedFondsColor(item)"></span> {{ item.title }}</p>
                          <p class="mb-0 pb-0">{{ item.description }}</p>
                          <a href="javascript:void(0)" (click)="getMandatManualByFondItem(item)">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                        </span>
                        <span class="collapse-link-block">
                          <a href="javascript:void(0)" (click)="collapseFonds()">{{ 'Recommendation.LessDetailFondsLink' | labelText }}</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-left mt-5">
                  <a href="javascript:void(0)" (click)="getSelectedMandatManual()" class="red-title" *ngIf="!isPeri">
                    {{ 'Recommendation.FundChartDicDocText' | labelText }} {{ selectedMandatGestion?.libelle }}
                  </a>
                </div>
              </div>
              <!-- ----------------------------------------- active Gestion Libre Tab ----------------------------------------- -->
              <div class="tab-content pb-5" *ngIf="activeGestionLibreTab">
                <p *ngIf="!isPeri" class="text-left">
                  {{ 'Recommendation.FundChartFreeIntro' | labelText }}
                </p>
                <p *ngIf="isPeri" class="text-left">
                  {{ 'Recommendation.FundChartFreeIntroPeri' | labelText }}
                </p>
                <h4>{{ 'Recommendation.FundChartDistributionLabel' | labelText }} {{ recommandation.profil.libelle }}</h4>
                <div class="row text-left">
                  <div class="col-md-5">
                    <div class="chart-wrapper">
                      <canvas
                        *ngIf="chartPercentages"
                        baseChart
                        [legend]="false"
                        [options]="chartsOptions"
                        width="10rem"
                        height="10rem"
                        [data]="chartPercentages"
                        [labels]="chartLabels"
                        [colors]="chartColors"
                        [chartType]="'doughnut'"
                      >
                      </canvas>
                    </div>
                  </div>

                  <div class="col-md-7">
                    <div>
                      <app-view-performance
                        [onTabChanged]="onTabChanged"
                        [isQuestion12AnsweredYes]="isQuestion12AnsweredYes"
                        [productType]="productType"
                        [type]="managementTypeCode"
                        [profile]="profileTypeCode"
                      ></app-view-performance>
                    </div>

                    <div
                      *ngIf="
                        mandatGestionId !== constants.securitaireProfileLess40KId && mandatGestionId !== constants.securitaireProfileMore40KId && mandatGestionId !== constants.securitaireProfileCims
                      "
                    >
                      <p class="fond-item"><span></span> {{ GLEuroTitle }}</p>
                      <p class="mb-0 pb-0">{{ GLEuroLabel }}</p>
                      <a href="javascript:void(0)" (click)="getSecuritaireMandatManualEuroById(1)">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                      <p class="fond-item"><span [style.background-color]="profileColor"></span> {{ GLUCTitle }}</p>
                      <p class="mb-0 pb-0">{{ GLUCLabel }}</p>
                      <a href="javascript:void(0)" (click)="getSecuritaireMandatManualUc()">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                    </div>

                    <div
                      *ngIf="
                        mandatGestionId === constants.securitaireProfileLess40KId || mandatGestionId === constants.securitaireProfileMore40KId || mandatGestionId === constants.securitaireProfileCims
                      "
                    >
                      <p class="fond-item"><span></span> {{ GLEuroTitle }}</p>
                      <p class="mb-0 pb-0">{{ GLEuroLabel }}</p>
                      <a href="javascript:void(0)" (click)="getSecuritaireMandatManualEuroById(1)">{{ 'Recommendation.DetailFondLink' | labelText }}</a>
                      <p *ngIf="mandatGestionId === constants.securitaireProfileMore40KId" class="fond-item"><span [style.background-color]="securityProfileColor"></span> {{ GLUCTitle }}</p>
                      <p *ngIf="mandatGestionId === constants.securitaireProfileMore40KId" class="mb-0 pb-0">{{ GLUCLabel }}</p>
                      <a *ngIf="mandatGestionId === constants.securitaireProfileMore40KId" href="javascript:void(0)" (click)="getSecuritaireMandatManualUc()">{{
                        'Recommendation.DetailFondLink' | labelText
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isPeri && isSecurityProfileRecommended">
          <div class="row mt-5 mb-5">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-5 centered-by-margin text-center">
              <button (click)="periSecurityProfileBtnClick()" class="btn btn-blue valider">{{ 'Recommendation.PeriSecuritySubmit' | labelText }}</button>
            </div>
          </div>
        </div>

        <!-- ----------------------------------------- buttons ----------------------------------------- -->

        <div *ngIf="isPeri && !isSecurityProfileRecommended">
          <div class="row mt-5">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
              <button [disabled]="isLessThanMinAmount" (click)="submitRecommendation()" class="btn btn-blue valider">
                <span *ngIf="activeGestionHorizonTab">{{ submitRecommendationGHText }}</span>
                <span *ngIf="activeGestionSousMandatTab">{{ submitRecommendationGSMText }}</span>
                <span *ngIf="activeGestionLibreTab"> {{ 'Recommendation.NavigationAcceptRecommandation' | labelText }} </span>
              </button>
            </div>
          </div>

          <div class="row mt-4" *ngIf="activeGestionLibreTab">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <a href="javascript:void(0)" (click)="notAcceptRecommendation()" class="red-title"> {{ 'Recommendation.NavigationRefuseRecommandationFonds' | labelText }} </a>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-left" *ngIf="isLessThanMinAmount">
              <span *ngIf="activeGestionHorizonTab" class="text-red amount-min-error"> {{ 'Recommendation.LessThanMinAmountGHText' | labelText }} {{ minAmountPerModeValue | euro }}. </span>
              <span *ngIf="activeGestionSousMandatTab" class="text-red amount-min-error"> {{ 'Recommendation.LessThanMinAmountGSMText' | labelText }} {{ minAmountPerModeValue | euro }}. </span>
              <a class="link-like black" href="javascript:void(0)" (click)="goToMyProfile()">{{ 'Recommendation.WarningInitialPaymentLink' | labelText }}</a>
            </div>
          </div>
        </div>

        <div *ngIf="!isPeri">
          <div class="row mt-5" *ngIf="activeGestionSousMandatTab">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
              <button [disabled]="isLessThanMinAmount" (click)="submitRecommendation()" class="btn btn-blue valider">
                {{ submitRecommendationGSMText }}
              </button>
            </div>
          </div>

          <div class="row mt-5" *ngIf="activeGestionLibreTab">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-center">
              <button (click)="submitRecommendation()" class="btn btn-blue valider">{{ 'Recommendation.NavigationAcceptRecommandation' | labelText }}</button>
            </div>
          </div>
          <div class="row mt-4" *ngIf="activeGestionLibreTab">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <a href="javascript:void(0)" (click)="notAcceptRecommendation()" class="red-title">{{ 'Recommendation.NavigationRefuseRecommandationFonds' | labelText }}</a>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-xs-10 col-sm-6 col-md-6 col-lg-4 centered-by-margin text-left" *ngIf="isLessThanMinAmount">
              <span class="text-red amount-min-error">
                {{ 'Recommendation.LessThanMinAmountGSMText' | labelText }} {{ minAmountPerModeValue | euro }} {{ 'Recommendation.LessThanMinAmountGSMExtraText' | labelText }}
              </span>
              <a class="link-like black" href="javascript:void(0)" (click)="goToMyProfile()">{{ 'Recommendation.WarningInitialPaymentLink' | labelText }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
