import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { DateInputFormControl } from './date-input-form-control';

@Component({
    selector: 'app-date-input-form-control',
    templateUrl: './date-input-form-control.component.html',
})
export class DateInputFormControlComponent extends AbstractFormControlComponent<DateInputFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    faCheck: any = faCheck;

    public get readOnlyCss(): boolean {
        return this.control.readOnlyCss || false;
    }

    // public get hideSuccessMark(): boolean {
    //     return this.control.hideSuccessMark || false;
    // }
}
