import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private subject = new Subject<any>();

  constructor() { }

  getStep(): Observable<any> {
    console.log(this.subject);
    return this.subject.asObservable();
  }

  sendStep(message: number): void {
    console.log(message);
    this.subject.next({ text: message });
  }
}
