import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ChangeHistory, Offer, OffersClient } from '../MIF.Subscription.WebApi';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  offers: Offer[];
  constructor(private webApi: OffersClient) { }

  public async getAllOffers(refresh: boolean = false): Promise<Offer[]> {
    if (this.offers && !refresh) {
      return new Promise(resolve => {
        resolve(this.offers);
      });
    } else {
      return this.webApi.getAllOffers().pipe(
        map((response: Offer[]) => {
          this.offers = response;
          return response;
        })
      ).toPromise();
    }
  }

  public async getChangeHistory(id: number): Promise<ChangeHistory[]> {
    return this.webApi.changeHistory(id).pipe(
      map((response: ChangeHistory[]) => {
        return response;
      })
    ).toPromise();
  }

  public async getOfferById(id: number): Promise<Offer> {
    return this.webApi.getById(id).pipe(
      map((response: Offer) => {
        return response;
      })
    ).toPromise();
  }

  public async saveOffer(offer: Offer): Promise<Offer> {
    if (offer.id > 0) {
      return this.webApi.update(offer.id, offer).pipe(
        map((response: Offer) => {
          return response;
        })
      ).toPromise();
    }

    return this.webApi.create(offer).pipe(
      map((response: Offer) => {
        return response;
      })
    ).toPromise();
  }

  public async deleteOffer(id: number): Promise<number> {
    return this.webApi.delete(id).pipe(
      map((id: number) => {
        return id;
      })
    ).toPromise();
  }

}
