import { debounceTime, map, tap } from 'rxjs/operators';
import { constants } from '@constants/constants';
import { ZipCodeFormControl } from '@form/zipcode-form-control/zipcode-form-control';
import { DateFormControl } from '@form/date-form-control/date-form-control';
import { NumberFormControl } from '@form/number-form-control/number-form-control';
import { NameFormControl } from '@form/name-form-control/name-form-control';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { collections } from '@constants/collections';
import { DropDownFormControl } from '@form/dropdown-form-control/dropdown-form-control';
import { WsCRMClientService } from '@webapi/services/ws-crmclient.service';
import { NameWithDigitsFormControl } from '@form/name-with-digits-form-control/name-with-digits-form-control';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent, modalTypes } from '@modals/alert-modal/alert-modal.component';
import { Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { Pays, Informations } from '@webapi/MIF.Subscription.Parrot';
import { CustomerService } from '@webapi/services/customer.service';
import { ClauseNominativeModel, getClauseNominativeModel } from '@extensions/user-data-extensions';
import { Beneficiaries, Profile, Subscription } from '@webapi/MIF.Subscription.WebApi';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import { SettingsService } from '@webapi/services/settings.service';
import { ProfileStepName } from '@models/profile-step-name';
import { isCims, isGpa, isPeri } from '@extensions/extensions';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import moment from 'moment';
import { LabelsDataService } from '@services/labels-data.service';

@Component({
  selector: 'app-clause-nominative',
  templateUrl: './clause-nominative.component.html',
  styleUrls: ['./clause-nominative.component.scss']
})
export class ClauseNominativeComponent implements OnInit, OnDestroy {
  @Input() cemsSummary: boolean;
  @Input() subscribeGpaPer1Euro: boolean;
  @Output() onFormStatusChange: EventEmitter<BeneficiareFormModelWithStatus> = new EventEmitter();

  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public nominativeFormFormGroup: FormGroup;
  public clientProfile: Profile;
  public removedBeneficiare: any = {};
  public ProfileStepName = ProfileStepName;

  public countries: Pays[];
  public nationalities: Informations[];
  public civilities: Informations[];
  public franceId: any;
  public franceNationalityId: any;
  public faPlus = faPlus;
  public faMinus = faMinus;
  public allocatedShareErrorText: string;
  public isEqualParts: boolean;
  public subscriptionConfiguration: Subscription;
  public beneficiariesConfiguration: Beneficiaries;
  public isPeri = isPeri();
  public isCims = isCims();
  public maxNumberOfBeneficiaries: number;
  public benMinDate: Date;
  public benMaxDate: Date;

  constructor(
    private router: Router,
    private wsCRMClientService: WsCRMClientService,
    private bsModalService: BsModalService,
    private customerService: CustomerService,
    private progressBarService: ProgressBarService,
    private settingsService: SettingsService,
    private labelTextPipe: LabelTextPipe,
    private labelsDataService: LabelsDataService
  ) {}

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  async ngOnInit(): Promise<void> {
    this.allocatedShareErrorText = this.labelTextPipe.transform('NOMINATIVE_CLAUSE.FormPartError');

    if (!this.cemsSummary) {
      this.progressBarService.sendStep(constants.steps.myBeneficiaries.nominative.initialStep);
    }
    await this.fetchInitData();
    this.initDobLimits();
    this.initNewFormGroup();

    this.clientProfile = await this.customerService.getUserData();

    this.restoreAnswersOnForm();
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    if (this.cemsSummary) {
      this.subscriptionHandler.subscriptions = this.nominativeFormFormGroup.valueChanges
        .pipe(
          debounceTime(100),
          tap(data => {
            const model = JSON.stringify(this.nominativeFormFormGroup.getRawValue());
            localStorage.setItem(constants.localStorageKeys.tempClauseNominativeForm, model);
          })
        )
        .subscribe();

      this.subscriptionHandler.subscriptions = this.nominativeFormFormGroup.statusChanges.subscribe((status: string) => {
        this.emitFormChangesEvent();
      });

      this.subscriptionHandler.subscriptions = this.beneficiaries.valueChanges
        .pipe(
          debounceTime(300),
          map(arrFormsValue => {
            arrFormsValue.forEach((values: any, index: number) => {
              const formGroup = this.beneficiaries.at(index) as FormGroup;
              const controls = formGroup.controls;
              const invalid = [];

              for (const name in formGroup.controls) {
                if (controls[name].invalid) {
                  invalid.push(name);
                }
              }

              if (invalid.length === 1 && invalid[0] === 'civility') {
                formGroup.get('civility').markAsDirty();
                formGroup.get('civility').markAsTouched();
                this.beneficiaries.updateValueAndValidity();
              }

              if (invalid.length === 1 && invalid[0] === 'allocatedShare') {
                formGroup.get('allocatedShare').markAsDirty();
                formGroup.get('allocatedShare').markAsTouched();
                this.beneficiaries.updateValueAndValidity();
              }
            });

            return arrFormsValue;
          })
        )
        .subscribe();
    }
  }

  isEqualPartsChanged(event: any): void {
    localStorage.setItem(constants.localStorageKeys.tempClauseNominativeFormIsEqual, JSON.stringify(event.checked));
    this.emitFormChangesEvent();
  }

  emitFormChangesEvent(): void {
    const valid = this.isAllFormGroupsValid();
    const beneficiaries = this.beneficiaries;
    const isEqualParts = this.isEqualParts;

    this.onFormStatusChange.emit({ status: valid, beneficiaries, isEqualParts });
  }

  async restoreAnswersOnForm(): Promise<void> {
    const isForGpaSubscription = this.cemsSummary || isGpa();
    const dataModel = getClauseNominativeModel(this.clientProfile, isForGpaSubscription);

    this.isEqualParts = dataModel.isEqualParts;

    if (dataModel.beneficiaries?.length > 0) {
      const countOfForms = dataModel.beneficiaries.length;
      let i = 1;

      while (i < countOfForms) {
        this.addBeneficiare();
        i++;
      }

      this.beneficiaries.patchValue(dataModel.beneficiaries);
      this.onFormStatusChange.emit({ status: true, beneficiaries: this.beneficiaries, isEqualParts: this.isEqualParts });
    }

    const isSavedData = dataModel.beneficiaries?.length > 0;

    if (this.subscribeGpaPer1Euro && !isSavedData) {
      const tempClauseNominativeFormKey = constants.localStorageKeys.tempClauseNominativeForm;
      const tempModelDataStr = localStorage.getItem(tempClauseNominativeFormKey);
      const tempClauseNominativeFormIsEqualStr = localStorage.getItem(constants.localStorageKeys.tempClauseNominativeFormIsEqual);

      if (tempModelDataStr) {
        const dataModel = JSON.parse(tempModelDataStr);

        if (dataModel?.beneficiaries?.length > 0) {
          const countOfForms = dataModel?.beneficiaries.length;
          let i = 1;

          while (i < countOfForms) {
            this.addBeneficiare();
            i++;
          }

          this.beneficiaries.patchValue(dataModel.beneficiaries);
        }
      }

      if (tempClauseNominativeFormIsEqualStr) {
        this.isEqualParts = JSON.parse(tempClauseNominativeFormIsEqualStr);
      }

      this.emitFormChangesEvent();
    }
  }

  initDobLimits(): void {
    this.benMinDate = moment().subtract(constants.clauseNominativeBeneficiaryMaxAge, 'year').add(1, 'day').toDate();
    this.benMaxDate = moment().toDate();
  }

  initNewFormGroup(): void {
    this.nominativeFormFormGroup = new FormGroup({
      beneficiaries: new FormArray([
        new FormGroup({
          id: new NumberFormControl(false, false),
          civility: new ComboBoxFormControl(true, false, {
            collection: this.civilities,
            valuePropertyName: constants.informationValueField,
            keyPropertyName: constants.referenceKeyField
          }),
          firstName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.firstName }),
          lastName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.lastName }),
          relation: new DropDownFormControl(
            true,
            false,
            {
              valuePropertyName: 'description',
              keyPropertyName: 'id',
              collection: collections.relation,
              disableChooseOption: false
            },
            { hideSuccessMark: true, hideErrorMessages: true, hideErrorMark: true }
          ),
          allocatedShare: new NumberFormControl(true, false, null, null, true, {
            min: 1,
            max: 100,
            requiredErrorMessage: this.allocatedShareErrorText,
            maxValueErrorMessage: this.allocatedShareErrorText,
            minValueErrorMessage: this.allocatedShareErrorText
          }),
          dob: new DateFormControl(true, false, {
            minDateErrorMessage: this.labelsDataService.getData('Home.AlreadyClientDateOfBirthError'),
            maxDateErrorMessage: this.labelsDataService.getData('Home.AlreadyClientDateOfBirthError')
          }),
          countryOfBirth: new DropDownFormControl(
            true,
            false,
            {
              collection: this.countries,
              valuePropertyName: constants.countryDescriptionField,
              keyPropertyName: constants.countryKeyField
            },
            {
              defaultValue: this.franceId
            }
          ),
          postalCodeOfBirth: new ZipCodeFormControl(true, false, { hideSuccessMark: true }),
          cityOfBirth: new NameFormControl(true, false, { capitalizeAll: true }),
          nationality: new DropDownFormControl(
            true,
            false,
            {
              collection: this.nationalities,
              valuePropertyName: constants.informationValueField,
              keyPropertyName: constants.referenceKeyField
            },
            {
              defaultValue: this.franceNationalityId
            }
          ),
          country: new DropDownFormControl(
            true,
            false,
            {
              collection: this.countries,
              valuePropertyName: constants.countryDescriptionField,
              keyPropertyName: constants.countryKeyField
            },
            {
              defaultValue: this.franceId
            }
          ),
          postalCode: new ZipCodeFormControl(true, false, { hideSuccessMark: true }),
          city: new NameFormControl(true, false, { capitalizeAll: true }),
          adress: new NameWithDigitsFormControl(true, false, { capitalizeAll: false })
        })
      ])
    });
  }

  async fetchInitData(): Promise<void> {
    this.beneficiariesConfiguration = await this.settingsService.getBeneficiariesConfiguration();
    this.maxNumberOfBeneficiaries = this.isCims ? this.beneficiariesConfiguration.cimsMaxNumberOfBeneficiaries : this.beneficiariesConfiguration.maxNumberOfBeneficiaries;
    this.subscriptionConfiguration = await this.settingsService.getSubscriptionConfiguration();
    this.countries = await this.wsCRMClientService.getAllCountries();
    this.nationalities = await this.wsCRMClientService.getNationalities();
    this.civilities = await this.wsCRMClientService.getCivilities();

    this.countries.forEach((item: any) => {
      if (item['alpha3'] === this.subscriptionConfiguration.defaultCountryCode) {
        this.franceId = item['id'];
      }
    });

    this.nationalities.forEach((item: any) => {
      if (item['code'] === this.subscriptionConfiguration.defaultNationalityCode) {
        this.franceNationalityId = item['id'];
      }
    });
  }

  get beneficiaries(): FormArray {
    return this.nominativeFormFormGroup?.get('beneficiaries') as FormArray;
  }

  get isPopBneficiariesExist(): boolean {
    return this.beneficiaries?.length > 1;
  }

  get beneficiariesCount(): number {
    return this.beneficiaries?.controls?.length;
  }

  addBeneficiare(): void {
    this.beneficiaries.push(
      new FormGroup({
        id: new NumberFormControl(false, false),
        civility: new ComboBoxFormControl(true, false, {
          collection: this.civilities,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        }),
        firstName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.firstName }),
        lastName: new NameFormControl(true, false, { capitalizeAll: true, maxLength: constants.maxLength.lastName }),
        relation: new DropDownFormControl(
          true,
          false,
          {
            valuePropertyName: 'description',
            keyPropertyName: 'id',
            collection: collections.relation
          },
          { hideSuccessMark: true, hideErrorMessages: true, hideErrorMark: true }
        ),
        allocatedShare: new NumberFormControl(true, false, null, null, true, {
          min: 1,
          max: 100,
          requiredErrorMessage: this.allocatedShareErrorText,
          maxValueErrorMessage: this.allocatedShareErrorText,
          minValueErrorMessage: this.allocatedShareErrorText
        }),
        dob: new DateFormControl(true, false, {
          minDateErrorMessage: this.labelsDataService.getData('Home.AlreadyClientDateOfBirthError'),
          maxDateErrorMessage: this.labelsDataService.getData('Home.AlreadyClientDateOfBirthError')
        }),
        countryOfBirth: new DropDownFormControl(
          true,
          false,
          {
            collection: this.countries,
            valuePropertyName: constants.countryDescriptionField,
            keyPropertyName: constants.countryKeyField
          },
          {
            defaultValue: this.franceId
          }
        ),
        postalCodeOfBirth: new ZipCodeFormControl(true, false, { hideSuccessMark: true }),
        cityOfBirth: new NameFormControl(true, false, { capitalizeAll: true }),
        nationality: new DropDownFormControl(
          true,
          false,
          {
            collection: this.nationalities,
            valuePropertyName: constants.informationValueField,
            keyPropertyName: constants.referenceKeyField
          },
          {
            defaultValue: this.franceNationalityId
          }
        ),
        country: new DropDownFormControl(
          true,
          false,
          {
            collection: this.countries,
            valuePropertyName: constants.countryDescriptionField,
            keyPropertyName: constants.countryKeyField
          },
          {
            defaultValue: this.franceId
          }
        ),
        postalCode: new ZipCodeFormControl(true, false, { hideSuccessMark: true }),
        city: new NameFormControl(true, false, { capitalizeAll: true }),
        adress: new NameWithDigitsFormControl(true, false, { capitalizeAll: false })
      })
    );

    const addedIndex = this.beneficiaries.length - 1;

    if (this.removedBeneficiare[addedIndex]) {
      this.beneficiaries.at(addedIndex).patchValue(this.removedBeneficiare[addedIndex].value);
    }
  }

  popBeneficiare(): void {
    const indexToBeDeleted = this.beneficiaries.length - 1;
    const itemToBeDeleted = this.beneficiaries.at(indexToBeDeleted);
    this.removedBeneficiare[indexToBeDeleted] = Object.assign({}, itemToBeDeleted);
    this.beneficiaries.removeAt(indexToBeDeleted);
  }

  onSubmit(): void {
    this.beneficiaries.controls.forEach((fg: FormGroup) => {
      fg.markAllAsTouched();
      fg.updateValueAndValidity();
    });

    if (this.isAllFormGroupsValid()) {
      if (this.isAllocatedShareSummSuccess()) {
        this.processSuccess();
      } else {
        this.bsModalService.show(AlertModalComponent, {
          initialState: {
            type: modalTypes.error,
            title: this.labelTextPipe.transform('NOMINATIVE_CLAUSE.ErrorTotalPartDifferentFrom100')
          },
          class: 'modal-lg'
        });
      }
    }
  }

  async processSuccess(): Promise<void> {
    const userId = await this.customerService.updateClauseNominativeModel(
      {
        beneficiaries: this.beneficiaries.getRawValue(),
        isEqualParts: this.isEqualParts
      } as ClauseNominativeModel,
      isGpa()
    );

    if (this.isCims) {
      this.router.navigate(['my-beneficiaries/contract'], { queryParams: { userId: this.customerService.userId } });
    } else {
      this.router.navigate(['/my-details'], { queryParams: { userId } });
    }
  }

  isAllFormGroupsValid(): boolean {
    let valid = true;

    this.beneficiaries.controls.forEach((fg: FormGroup) => {
      if (fg.valid === false) {
        valid = false;
      }
    });

    return valid;
  }

  isAllocatedShareSummSuccess(): boolean {
    let summ = 0;

    this.beneficiaries.controls.forEach((fg: FormGroup) => {
      const allocatedShareControl = fg.get('allocatedShare') as NumberFormControl;
      summ += allocatedShareControl.value;
    });

    return summ === 100;
  }

  get clauseNominativeSubTitle(): string {
    let text: string = '';

    if (this.isPeri) {
      text = this.labelTextPipe.transform('Beneficiaries.SubTitleTextPeri');
    } else if (this.isCims) {
      text = this.labelTextPipe.transform('Beneficiaries.SubTitleTextCims');
    } else {
      text = this.labelTextPipe.transform('Common.MyBeneficiairiesSubtitle');
    }

    return text;
  }
}

export interface BeneficiareFormModel {
  id?: number;
  civility: number;
  firstName: string;
  lastName: string;
  relation: number;
  allocatedShare?: number;
  dob: Date;
  countryOfBirth: number;
  postalCodeOfBirth: string;
  cityOfBirth: string;
  nationality: number;
  country: number;
  postalCode: string;
  city: string;
  adress: string;
}

export interface BeneficiareFormModelWithStatus {
  status: boolean;
  beneficiaries: FormArray;
  isEqualParts: boolean;
}
