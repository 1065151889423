<div class="health-questions">
  <div *ngIf="!cemsSummary" class="text-center mt-5 mb-5">
    <h5 [innerHTML]="'HEALTH_QUESTIONNAIRE.Title' | labelText"></h5>
  </div>
  <div class="card" *ngIf="form">
    <div class="card-body mt-4 ml-3" [class.pl-0]="cemsSummary">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
          <div class="question-block">
            <label class="mb-0">
              {{ 'HEALTH_QUESTIONNAIRE.Question1' | labelText }}
              <app-tooltip [title]="'HEALTH_QUESTIONNAIRE.ExampleReformDismissalTitle' | labelText" [text]="'HEALTH_QUESTIONNAIRE.ExampleReformDismissalText' | labelText" [tooltipPosition]="'top'">
              </app-tooltip>
            </label>
            <div class="buttons-block">
              <app-combobox-form-control (onChange)="isLaidOffForIllnessChange($event)" [parentForm]="form" [controlName]="'laidOffForIllness'" [title]="" class="no-title yes-invalid">
              </app-combobox-form-control>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isLaidOffForIllness">
          <div class="error-block">
            <span class="text">
              {{ 'Common.YouCannotSubscribeOnline' | labelText }}
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
          <div class="question-block">
            <label class="mb-0">
              {{ 'HEALTH_QUESTIONNAIRE.Question2' | labelText }}
              <app-tooltip [title]="'HEALTH_QUESTIONNAIRE.TemporaryDisabilityTitle' | labelText" [text]="'HEALTH_QUESTIONNAIRE.TemporaryDisabilityText' | labelText" [tooltipPosition]="'top'">
              </app-tooltip>
            </label>
            <div class="buttons-block">
              <app-combobox-form-control (onChange)="tempUnableWorkChange($event)" [parentForm]="form" [controlName]="'tempUnableWork'" [title]="" class="no-title yes-invalid">
              </app-combobox-form-control>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isTempUnableWork">
          <div class="error-block">
            <span class="text">
              {{ 'Common.YouCannotSubscribeOnline' | labelText }}
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
          <div class="question-block">
            <label class="mb-0"> {{ 'HEALTH_QUESTIONNAIRE.Question3' | labelText }} </label>
            <div class="buttons-block">
              <app-combobox-form-control (onChange)="offWorkMore30daysChange($event)" [parentForm]="form" [controlName]="'offWorkMore30days'" [title]="" class="no-title yes-invalid">
              </app-combobox-form-control>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isOffWorkMore30days">
          <div class="error-block">
            <span class="text">
              {{ 'Common.YouCannotSubscribeOnline' | labelText }}
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
          <div class="question-block">
            <label class="mb-0"> {{ 'HEALTH_QUESTIONNAIRE.Question4' | labelText }} </label>
            <div class="buttons-block">
              <app-combobox-form-control (onChange)="treatmentMore30daysChange($event)" [parentForm]="form" [controlName]="'treatmentMore30days'" [title]="" class="no-title yes-invalid">
              </app-combobox-form-control>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="isTreatmentMore30days">
          <div class="error-block">
            <span class="text">
              {{ 'Common.YouCannotSubscribeOnline' | labelText }}
            </span>
          </div>
        </div>
      </div>

      <div class="row mt-4" *ngIf="!cemsSummary">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary validation-button" (click)="submit()" [disabled]="form.invalid">
            {{ 'Common.Validate' | labelText }}
          </button>
        </div>
      </div>
    </div>
    <div class="warning" *ngIf="!cemsSummary">
      <span class="fw-600">{{ 'HEALTH_QUESTIONNAIRE.HelpTitle' | labelText }}</span> <br />
      {{ 'HEALTH_QUESTIONNAIRE.HelpContent' | labelText }}
    </div>
  </div>
</div>
