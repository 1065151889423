import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressBarService } from '@services/progress-bar.service';
import { constants } from '@constants/constants';
import { ProfileStepName } from '@models/profile-step-name';
import { Beneficiaries, GpaConfiguration, Profile } from '@webapi/MIF.Subscription.WebApi';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { CustomerService } from '@webapi/services/customer.service';
import { SettingsService } from '@webapi/services/settings.service';
import { getCurrentProductType, isCims, isPeri } from '@extensions/extensions';
import { WsGetPolices } from '@webapi/services/ws-get-polices.service';
import { GetPolicesResponse } from '@webapi/MIF.Subscription.Parrot';
import { checklIsSpecialGpaOfferAvailable } from '@shared/extensions/gpa-offer.extentions';
import { WsPolicesService } from '@webapi/services/ws-polices.service';

@Component({
  selector: 'app-my-beneficiaries',
  templateUrl: './my-beneficiaries.component.html',
  styleUrls: ['./my-beneficiaries.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyBeneficiariesComponent implements OnInit {
  public constants: any;
  public clientProfile: Profile;
  public beneficiariesConfiguration: Beneficiaries;
  public maxNumberOfBeneficiaries: number;
  public isPeri = isPeri();
  public isCims = isCims();
  public isProspect: boolean;
  public isGpaProposalAvailable: boolean;
  public gpaConfiguration: GpaConfiguration;
  private polices: GetPolicesResponse;
  private transferableContracts: any;
  private product: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private progressBarService: ProgressBarService,
    private customerService: CustomerService,
    private settingsService: SettingsService,
    private wsGetPolices: WsGetPolices,
    private wsPolicesService: WsPolicesService,
    private labelTextPipe: LabelTextPipe
  ) {}

  ProfileStepName = ProfileStepName;
  clauseStandard: boolean;
  clauseNominative: boolean;
  clauseLibre: boolean;

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myContracts.countOfTheSteps);
    this.product = getCurrentProductType();
    this.constants = constants;
    this.beneficiariesConfiguration = await this.settingsService.getBeneficiariesConfiguration();
    this.maxNumberOfBeneficiaries = this.isCims ? this.beneficiariesConfiguration.cimsMaxNumberOfBeneficiaries : this.beneficiariesConfiguration.maxNumberOfBeneficiaries;
    await this.restoreAnswersOnForm();
    await this.restorePolices();
    await this.restoreTransferableContracts();
    this.gpaConfiguration = await this.settingsService.getGpaConfiguration();
    await this.initExtraLogicForGpaSubscription();
  }

  async restoreAnswersOnForm() {
    this.clientProfile = await this.customerService.getUserData();

    if (this.clientProfile && this.clientProfile.beneficiaryClauseCode !== undefined) {
      this.switchBeneficiareType(this.clientProfile.beneficiaryClauseCode, true);
    }
  }

  async restorePolices() {
    if (this.clientProfile.isMember && this.clientProfile.socialNumberB) {
      this.polices = await this.wsGetPolices.getPolices(parseInt(this.clientProfile.socialNumberB, 10));
    }
  }

  async restoreTransferableContracts() {
    if (this.clientProfile.socialNumberB) {
      this.transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.clientProfile.socialNumberB);
    }
  }

  async initExtraLogicForGpaSubscription() {
    this.isGpaProposalAvailable = checklIsSpecialGpaOfferAvailable(this.clientProfile, this.gpaConfiguration, this.polices, this.transferableContracts);
  }

  async switchBeneficiareType(code: number, isRestoreMode?: boolean): Promise<void> {
    if (!isRestoreMode) {
      await this.customerService.updateBeneficiaryClauseCode(code);
    }

    switch (code) {
      case constants.beneficiaresTypes.standard.code: {
        this.clauseStandard = true;
        this.clauseLibre = false;
        this.clauseNominative = false;
        if (!isRestoreMode) {
          this.progressBarService.sendStep(constants.steps.myBeneficiaries.countOfTheSteps);
          if (this.isCims) {
            this.router.navigate(['my-beneficiaries/contract'], { queryParams: { userId: this.customerService.userId } });
          } else {
            this.router.navigate(['my-details'], { queryParams: { userId: this.customerService.userId } });
          }
        }
        break;
      }

      case constants.beneficiaresTypes.nominative.code: {
        this.clauseNominative = true;
        this.clauseLibre = false;
        this.clauseStandard = false;
        if (!isRestoreMode) {
          this.progressBarService.sendStep(constants.steps.myBeneficiaries.nominative.initialStep);
          if (this.isCims) {
            this.router.navigate(['my-beneficiaries/nominative'], { queryParams: { userId: this.customerService.userId } });
          } else {
            this.router.navigate(['nominative'], { relativeTo: this.route, queryParams: { userId: this.customerService.userId } });
          }
        }
        break;
      }

      case constants.beneficiaresTypes.libre.code: {
        this.clauseLibre = true;
        this.clauseStandard = false;
        this.clauseNominative = false;
        if (!isRestoreMode) {
          this.progressBarService.sendStep(constants.steps.myBeneficiaries.libre.initialStep);
          if (this.isCims) {
            this.router.navigate(['my-beneficiaries/libre'], { queryParams: { userId: this.customerService.userId } });
          } else {
            this.router.navigate(['libre'], { relativeTo: this.route, queryParams: { userId: this.customerService.userId } });
          }
        }
        break;
      }
    }
  }

  get subTitleText(): string {
    let text: string = '';

    if (this.isPeri) {
      text = this.labelTextPipe.transform('Beneficiaries.SubTitleTextPeri');
    } else if (this.isCims) {
      text = `${this.labelTextPipe.transform('Beneficiaries.SubTitleTextCims1')} <b>${this.labelTextPipe.transform('Beneficiaries.SubTitleTextCims2')}</b> ${this.labelTextPipe.transform(
        'Beneficiaries.SubTitleTextCims3'
      )}`;
    } else {
      text = this.labelTextPipe.transform('Common.MyBeneficiairiesSubtitle');
    }

    return text;
  }

  get isBeneficiaryPage(): boolean {
    return this.router.url.indexOf('/my-beneficiaries') !== -1;
  }

  get beneficiaryGpaOfferText(): string {
    switch (this.product) {
      case constants.productNames.cems.name: {
        return this.labelTextPipe.transform(
          'Beneficiaries.CemsGpaOfferFooterText',
          this.gpaConfiguration?.minAge,
          this.gpaConfiguration?.maxAge,
          this.gpaConfiguration?.gpaGuaranteedCapital,
          this.gpaConfiguration?.gpaCost
        );
      }
      case constants.productNames.cims.name: {
        return this.labelTextPipe.transform(
          'Beneficiaries.CimsGpaOfferFooterText',
          this.gpaConfiguration?.minAge,
          this.gpaConfiguration?.maxAge,
          this.gpaConfiguration?.gpaGuaranteedCapital,
          this.gpaConfiguration?.gpaCost
        );
      }
      case constants.productNames.peri.name: {
        return this.labelTextPipe.transform(
          'Beneficiaries.PeriGpaOfferFooterText',
          this.gpaConfiguration?.minAge,
          this.gpaConfiguration?.maxAge,
          this.gpaConfiguration?.gpaGuaranteedCapital,
          this.gpaConfiguration?.gpaCost
        );
      }
      default: {
        return this.labelTextPipe.transform(
          'Beneficiaries.CemsGpaOfferFooterText',
          this.gpaConfiguration?.minAge,
          this.gpaConfiguration?.maxAge,
          this.gpaConfiguration?.gpaGuaranteedCapital,
          this.gpaConfiguration?.gpaCost
        );
      }
    }
  }

  get clauseStandardText(): string {
    let text: string = '';

    if (this.isPeri) {
      text = this.labelTextPipe.transform('BENEFICIARIES.PeriStandardClauseDescription');
    } else if (this.isCims) {
      text = this.labelTextPipe.transform('BENEFICIARIES.CimsStandardClauseDescription');
    } else {
      text = this.labelTextPipe.transform('BENEFICIARIES.StandardClauseDescription');
    }

    return text;
  }

  get clauseNominativeText(): string {
    let text: string = '';

    if (this.isCims) {
      text = this.labelTextPipe.transform('BENEFICIARIES.NominativeClauseDescriptionCims', this.maxNumberOfBeneficiaries);
    } else {
      text = this.labelTextPipe.transform('BENEFICIARIES.NominativeClauseDescription', this.maxNumberOfBeneficiaries);
    }

    return text;
  }

  get clauseNominativeTooltipText(): string {
    let text: string = '';

    if (this.isCims) {
      text = this.labelTextPipe.transform('NOMINATIVE_CLAUSE.FormDefaultHeirHelpCims');
    } else {
      text = this.labelTextPipe.transform('NOMINATIVE_CLAUSE.FormDefaultHeirHelp');
    }

    return text;
  }

  get clauseLibreTooltipText(): string {
    let text: string = '';

    if (this.isCims) {
      text = this.labelTextPipe.transform('NOMINATIVE_CLAUSE.FormDefaultHeirHelpCims');
    } else {
      text = this.labelTextPipe.transform('NOMINATIVE_CLAUSE.FormDefaultHeirHelp');
    }

    return text;
  }
}
