import * as moment from 'moment';

export function matDatepickerMinValidator() {
    return (control: any) => {
        const date = control.value;
        const minDate = control.minDate;

        if (date && minDate) {
            minDate.value = minDate.value === undefined ? minDate : minDate.value;
            const controlValueDate = moment.utc(date);
            const controlMinDateLimit = moment.utc(minDate.value).subtract(1, 'day');
            
            if (controlValueDate < controlMinDateLimit) {
                return {
                    matDatepickerMin: true
                };
            } else {
                return null;
            }
        }
        return null;
    };
}
