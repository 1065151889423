<div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        {{ 'Payments.AdermConfirmationText1' | labelText }}
        <br />
        {{ 'Payments.AdermConfirmationText2' | labelText }}
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div>
      <button class="btn btn-blue" (click)="onCancel()">{{ 'COMMON.Back' | labelText }}</button>
      <button class="btn btn-success" (click)="onConfirm()">{{ 'COMMON.Validate' | labelText }}</button>
    </div>
  </div>
</div>
