import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, OnDestroy, Input } from '@angular/core';
import moment from 'moment';
import { AppConfigService } from 'projects/mif/app/services/app.config.service';
import { LazyLoadingScriptService } from 'projects/mif/app/services/lazy-loading-script.service';
import { constants } from '@constants/constants';
import { getCountryById } from '@extensions/extensions';
import { Profile } from '@webapi/MIF.Subscription.WebApi';
import { WsCRMClientService } from '@webapi/services/ws-crmclient.service';

@Component({
  selector: 'app-i-advize',
  templateUrl: './i-advize.component.html'
})
export class IAdvizeComponent implements OnInit, OnDestroy {
  @Input() pageType: string;
  @Input() clientProfile: Profile;

  iAdvizeScriptId: string = 'iAdvizeTest';
  countries: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private appConfigService: AppConfigService,
    private wsCRMClientService: WsCRMClientService,
    private lazyLoadService: LazyLoadingScriptService
  ) {}

  ngOnDestroy(): void {
    const elem = document.querySelector('#' + this.iAdvizeScriptId);
    document.querySelector('#' + this.iAdvizeScriptId)?.parentNode.removeChild(elem);
  }

  async ngOnInit() {
    this.countries = await this.wsCRMClientService.getAllCountries();

    this.addCustomData();

    this.lazyLoadService.loadScript('https://halc.iadvize.com/iadvize.js').subscribe();
  }

  addCustomData() {
    const address =
      this.clientProfile && this.clientProfile?.currentAddress
        ? `${this.clientProfile?.currentAddress?.addressLine ?? ''}, ${this.clientProfile?.currentAddress?.additionalAddress ?? ''}, ${this.clientProfile?.currentAddress?.additionalAddress2 ?? ''}, ${
            this.clientProfile?.currentAddress?.locality ?? ''
          } ${this.clientProfile?.currentAddress?.zipCode ?? ''}, ${this.clientProfile?.currentAddress?.city ?? ''}, ${
            this.clientProfile?.currentAddress?.country ? getCountryById(this.countries, this.clientProfile?.currentAddress?.country) : ''
          }}`
        : '';

    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.id = this.iAdvizeScriptId;
    srcScript.text = `
    (function() {
      <!-- START IADVIZE LIVECHAT -->
        window.iAdvizeInterface = window.iAdvizeInterface || [];
        iAdvizeInterface.config = {
          'sid': '${this.appConfigService.adviceId}'
        };
      <!-- END IADVIZE LIVECHAT --> 

      <!-- START IADVIZE CUSTOM DATA CODE -->
      var device = 'desktop';
      var expression = '(android|iphone|ipad|blackberry|windows phone|IEMobile|windows mobile|opera mini|blackberry|nokia|samsung)';
      var regex = new RegExp(expression,'gi');
      if (navigator.userAgent.match(regex)){
          if (((screen.width >= 480) && (screen.height >= 800)) || ((screen.width >= 800)
              && (screen.height >= 480)) || navigator.userAgent.match(/ipad/gi)){
              device = 'tablet';
          } else { device = 'mobile'; }
      } else { device = 'desktop'; }
      window.idzCustomData = {
          'device':device,
          'cart_amount':'0',
          'product_price':'',
          'page_type':'${this.pageType ?? ''}',
          'cust_name':'${this.clientProfile?.person.lastName ?? ''}',
          'cust_firstname':'${this.clientProfile?.person?.firstName ?? ''}',
          'cust_dob':'${this.clientProfile?.person?.dateOfBirth ? moment(this.clientProfile?.person.dateOfBirth).format(constants.dateInputFormat) : ''}',
          'cust_address':'${address}',
          'cust_phonenumber': '${this.clientProfile?.contact?.mobilePhone ?? ''}',
          'cust_email': '${this.clientProfile?.contact?.email ?? ''}'
  };
      <!--END IADVIZE CUSTOM DATA CODE-- >

    }());
`;
    this.renderer2.appendChild(this.document.body, srcScript);
  }
}
