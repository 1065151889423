import { constants } from '../../../shared/constants/constants';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { CheckboxListFormControl } from './checkbox-list-form-control';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NameWithDigitsFormControl } from '../name-with-digits-form-control/name-with-digits-form-control';

@Component({
    selector: 'app-checkbox-list-form-control',
    templateUrl: './checkbox-list-form-control.component.html',
})
export class CheckboxListFormControlComponent extends AbstractFormControlComponent<CheckboxListFormControl> implements OnInit, OnChanges {
    @Output() onChange: EventEmitter<boolean> = new EventEmitter();
    @Output() onOtherChange: EventEmitter<any> = new EventEmitter();
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Input() otherAnswer?: string;
    @Input() otherUniqClass?: string;
    @Input() inline: boolean;
    @Input() compact: boolean;

    faCheck: any = faCheck;
    otherAswer = constants.otherAnswer;

    form = new FormGroup({
        other: new NameWithDigitsFormControl(false, false, { capitalizeAll: false }),
    });

    ngOnInit(): void {
        if (this.otherAnswer) {
            this.form.patchValue({ 'other': this.otherAnswer })
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.otherAnswer && changes?.otherAnswer.currentValue !== undefined) {
            this.form.patchValue({ 'other': this.otherAnswer })
        }
    }

    otherChange(event: any): void {
        this.onOtherChange.emit(event);
    }

    change(): void {
        this.onChange.emit(this.control.value);
        const otherControl = this.form.get('other') as NameWithDigitsFormControl;

        setTimeout(() => {
            if (this.isOtherAnswer) {
                otherControl.markAsRequred();
                setTimeout(() => {
                    if (this.otherUniqClass) {
                        let otherInputs = document.getElementsByClassName(this.otherUniqClass)[0].getElementsByClassName('mat-input-element');
                        let el = otherInputs[0] as any;
                        if (!!el.value) {
                            return;
                        }
                        el.focus();
                    }
                })
            } else {
                otherControl.markAsNotRequred();
            }
        })
    }

    get isOtherAnswer(): boolean {
        if (!this.control.value) {
            return false;
        }

        return this.control.value?.indexOf(constants.otherAnswer) !== -1;
    }

    public get isInline(): boolean {
        return this.inline || false;
    }

    public get isCompact(): boolean {
        return this.compact || false;
    }

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }

    public get hideErrorMessages(): boolean {
        return this.control.hideErrorMessages || false;
    }

    get class(): string {
        if (this.otherUniqClass) {
            return `no-title ${this.otherUniqClass}`;
        }

        return 'no-title';
    }

    public get multiple(): string {
        return this.control.multiple ? "true" : "false";
    }

    public get addOther(): boolean {
        return this.control.addOther ?? false;
    }

    public get otherId(): number {
        return this.control.otherId;
    }

    public get collection(): any[] {
        return this.control.collection || [];
    }

    public get keyPropertyName(): string {
        return this.control.keyPropertyName || 'name';
    }

    public get valuePropertyName(): string {
        return this.control.valuePropertyName || 'name';
    }
}
